import React, { Component } from "react";
import Plot from "react-plotly.js";

export default class DonutChart extends Component {
  render() {
    return (
      <Plot
        data={[
          {
            values: [10, 20, 30, 40],
            labels: ["A", "B", "C", "D"],
            hole: 0.5,
            type: "pie",
          },
        ]}
        layout={{
          title: "Sample Donut Chart",
        }}
      />
    );
  }
}
