import React, { Component } from "react";
import { compose } from "redux";
import connect from "react-redux/es/connect/connect";
import withStyles from "@material-ui/core/styles/withStyles";
import { Typography } from "@material-ui/core/es";
import { metricActions } from "../Metrics/actions";
import Grid from "@material-ui/core/Grid";
import TableTitle from "../StyledComponents/Table/TitleTable";
import CreateTable from "../StyledComponents/Table/TableList";
import CardBody from "../StyledComponents/Card/CardBody";
import Card from "../StyledComponents/Card/Card";
import CardHeader from "../StyledComponents/Card/CardHeader";
import Plot from "react-plotly.js";
import tableStyle from "../assets/jss/core-dashboard/components/tableStyle";
import LoadingGrid from "../StyledComponents/Grid/LoadingGrid";
import { addCommas } from "../utils";
const styles = (theme) => {};

class LongitudinalView extends Component {
  toDisplayYear(year) {
    return `20${year.charAt(0)}${year.charAt(1)}-20${year.charAt(
      2
    )}${year.charAt(3)}`;
  }

  render() {
    const {
      pathwayLongitudinal,
      district,
      school,
      group,
      year,
      pathway,
      subgroups,
      loading,
      classes,
    } = this.props;
    const demoOrder = ["ALL", "SD", "SWD", "EL", "AA", "HI", "WH", "AS"];
    const demoAlias = {
      ALL: "Student Count",
      HI: "LX",
      SWD: "SwD",
      SD: "econ Dis",
    };
    const demographics = pathwayLongitudinal
      ? demoOrder.reduce((arr, curr) => {
          arr.push(curr);
          return arr;
        }, [])
      : [];
    const years =
      Object.keys((pathwayLongitudinal["ALL"] || {})["demo_pct"] || {}).reduce(
        (array, year) => {
          const greater = parseInt(year.charAt(0) + year.charAt(1)) > 14;
          if (greater) {
            return array.concat(year);
          }
          return array;
        },
        []
      ) || [];
    const pathwayTable =
      pathway !== ""
        ? years.map((year) => {
            const title = [
              {
                type: "LLA TITLE",
                color: null,
                textColor: "rgba(61,114,135,.8)",
                text: this.toDisplayYear(year).split("-")[1],
              },
            ];
            const data = demographics.map((group) => {
              const { meas_rate, numerator } = ((pathwayLongitudinal[group][
                "demo_pct"
              ] || {})[year] || {})[pathway] || { meas_rate: 0, numerator: 0 };
              return group !== "ALL"
                ? {
                    type: "LLA DEMOGRAPHIC",
                    subtext: `(${addCommas(`${numerator}`)} students)`,
                    maxWidth: "175px",
                    text: `${meas_rate}%`,
                  }
                : {
                    type: "LLA DEMOGRAPHIC",
                    maxWidth: "145px",
                    text: `${addCommas(`${numerator}`)} students`,
                  };
            });
            return title.concat(data);
          })
        : [["NO DATA"]];
    const headers = ["Name"].concat(
      demoOrder.map((curr) => {
        if (curr === "ALL") {
          return "Student Count";
        } else {
          const { name, alias } = subgroups[curr];
          return `${name} (${demoAlias[curr] || alias || curr})`;
        }
        return demoAlias[curr] || curr;
      })
    );
    const chartOrder = [
      "chron_abs",
      "perc_prof_math",
      "perc_prof_ela",
      "susp_core",
      "lla_pip",
      "lla_psc",
    ];
    const titles = [
      "Chronic Absence",
      "Percent Proficient Math",
      "Perfect Proficient ELA",
      "Suspension Rate",
      "Student Persistence in Pathway",
      "Post Secondary Credits",
    ];
    const names = {
      [pathway]: pathway,
      School: school.text,
      District: district.text,
      Overall: "Overall",
    };
    const lineCharts = chartOrder.map((key) => {
      const currData = (pathwayLongitudinal[group] || {})[key] || {};
      return [pathway, "School", "District", "Overall"].map((currKey) => {
        const res = years.reduce(
          (obj, year) => {
            const yearData = (currData[year] || {})[currKey];
            if (yearData) {
              const { numerator, denominator, meas_rate } = yearData;
              obj.x.push(year);
              obj.y.push(meas_rate);
              const text =
                `<b style="font-size:16px; text-align:center;border-bottom: 2px solid black">${names[currKey]}</b><br>` +
                `<b>Year: </b>20${year}<br>` +
                `<b>Numerator: </b>${addCommas(`${numerator}`)}<br>` +
                `<b>Denominator: </b>${addCommas(`${denominator}`)}<br>` +
                `<b>Percentage: </b>${meas_rate}%<br>`;
              obj.text.push(text);
            }
            return obj;
          },
          {
            hoverinfo: "text",
            name: names[currKey],
            text: [],
            x: [],
            y: [],
            type: "scatter",
          }
        );
        return res;
      });
    });
    return (
      <Grid
        style={{ margin: "0 2.5% 0 2.5%", width: "95%" }}
        justifyContent={"center"}
        container
        direction={"row"}
      >
        <TableTitle
          school={{}}
          district={{}}
          hideInfo
          pageTitle={"Longitudinal View"}
        />
        <Grid xs={12} item>
          <CreateTable
            loading={loading}
            data={pathwayTable}
            header={headers}
            title={"Pathway School Demographics"}
            index={0}
            hideFooter
          />
        </Grid>
        <Grid xs={12} container item>
          <CardBody id={"card-body"} style={{ width: "100%", padding: "0px" }}>
            <Grid justifyContent={"space-evenly"} container item>
              {lineCharts.map((chart, index) => (
                <Grid xs={10} lg={5} item>
                  <Card>
                    <CardHeader
                      className={classes.gradientBackground}
                      color="primary"
                    >
                      <h4 className={classes.cardTitleBlue}>{titles[index]}</h4>
                    </CardHeader>
                    <CardBody
                      style={{
                        paddingTop: "0px",
                        width: "100%",
                        marginTop: "10px",
                        paddingBottom: "10px",
                        textAlign: "center",
                      }}
                    >
                      {loading ? (
                        <LoadingGrid />
                      ) : Object.keys(chart).reduce((hide, key) => {
                          const curr = chart[key];
                          if (curr.x.length) {
                            return true;
                          }
                          return hide;
                        }, false) ? (
                        <div className={classes.plot} style={{ width: "100%" }}>
                          <Plot
                            style={{ width: "100%", height: "100%" }}
                            data={chart}
                            layout={{
                              margin: {
                                l: 60,
                                r: 60,
                                t: 20,
                                b: 60,
                              },
                              legend: {
                                orientation: "h",
                                x: 0,
                                y: 1.2,
                              },
                              xaxis: {
                                tickvals: years,
                                ticktext: years.map(
                                  (pw) =>
                                    `<b>${
                                      this.toDisplayYear(pw).split("-")[1]
                                    }</b>`
                                ),
                                linewidth: 6,
                                linecolor: "rgb(9, 75, 101)",

                                title: {
                                  text: "<b>Year</b>",
                                  font: {
                                    color: "rgb(9, 75, 101)",
                                  },
                                },
                              },
                              yaxis: {
                                title: {
                                  text: "<b>Measure Rate (%)</b>",
                                  font: {
                                    color: "rgb(9, 75, 101)",
                                  },
                                },
                                linewidth: 6,
                                linecolor: "rgb(9, 75, 101)",
                              },
                            }}
                          />
                        </div>
                      ) : (
                        <Typography
                          style={{
                            marginLeft: "5%",
                            color: "rgba(0,0,0,.1)",
                            fontWeight: "900",
                            float: "left",
                            fontSize: "36px",
                          }}
                        >
                          No Data
                        </Typography>
                      )}
                    </CardBody>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </CardBody>
        </Grid>
      </Grid>
    );
  }
}

const findInMenu = (array, key) => {
  const res = {
    code: "",
    text: "",
  };
  if (!array) {
    return res;
  }
  return array.reduce((found, curr) => {
    if (curr.code === key) {
      found = curr;
    }
    return found;
  }, res);
};

const mapState = (state) => {
  const { selected, menuObject } = state.menu;
  const district = findInMenu(menuObject["District"], selected["District"]);
  const school = findInMenu(menuObject["School"], selected["School"]);
  const year = selected["School Year"] ? selected["School Year"] : "";
  const pathway = findInMenu(menuObject["Pathway"], selected["Pathway"]);
  const { text, code } = pathway;
  const split = text.split(` (${code})`);
  const parsedPathway = split.length ? split[0] : "";
  const group = selected["Student Groups"];
  const {
    constants: { subgroups },
  } = state;
  const { pathwayLongitudinal, loading } = state.metrics;
  return {
    subgroups,
    loading,
    selected,
    district,
    school,
    year,
    group,
    pathway: parsedPathway,
    pathwayLongitudinal,
  };
};

const mapDispatch = (dispatch) => {
  return {
    getLongitudinalView: (selected) => {
      dispatch(metricActions.getLongitudinalView(selected));
    },
  };
};

export default compose(
  withStyles(tableStyle, { withTheme: true }),
  connect(mapState, mapDispatch)
)(LongitudinalView);
