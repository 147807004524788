import React from "react";

import MaterialTable, { MTableGroupbar } from "material-table";
import Paper from "@material-ui/core/Paper";
import AddBox from "@material-ui/icons/AddBox";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import CardHeader from "../StyledComponents/Card/CardHeader";
import CardBody from "../StyledComponents/Card/CardBody";
import Card from "../StyledComponents/Card/Card";
import LoadingGrid from "../StyledComponents/Grid/LoadingGrid";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
const tableIcons = {
  Add: AddBox,
  Check: Check,
  Clear: Clear,
  Delete: DeleteOutline,
  DetailPanel: ChevronRight,
  Edit: Edit,
  Export: SaveAlt,
  Filter: FilterList,
  FirstPage: FirstPage,
  LastPage: LastPage,
  NextPage: ChevronRight,
  PreviousPage: ChevronLeft,
  ResetSearch: Clear,
  Search: Search,
  SortArrow: ArrowUpward,
  ThirdStateCheck: Remove,
  ViewColumn: ViewColumn,
};

const exportCSV = ({ data, columns, fileName }) => {
  const csvStarter = "data:text/csv;charset=utf-8,";
  const headers = columns.map((e) => e.field).join(",") + "\n";
  const length = columns.length;
  const csv = data.reduce((csv, row) => {
    let str = "";
    columns.forEach((col, index) => {
      str += `${row[col.field]}`;
      if (index < length - 1) {
        str += ",";
      } else {
        str += "\n";
      }
    });
    return csv + str;
  }, csvStarter + headers);
  const encodedUri = encodeURI(csv);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", `${fileName}.csv`);
  document.body.appendChild(link); // Required for FF
  link.click();
  document.body.removeChild(link);
};

const CustomPagination = (props) => {
  const { page, rowsPerPage, count, data, columns, fileName } = props;
  const pages = Math.ceil(count / rowsPerPage);
  return (
    <Grid
      style={{
        margin: 0,
        borderTop: "2px solid #BBCAD0",
        width: "100%",
        fontSize: "16px",
      }}
      spacing={8}
      container
      alignItems={"center"}
      justifyContent={"flex-end"}
    >
      <Grid
        style={{ fontSize: 18, fontWeight: 800, color: "rgba(61,114,135,.8)" }}
        item
      >
        Download Data:
        <IconButton
          onClick={() => {
            exportCSV({ data, columns, fileName });
          }}
        >
          <SaveAlt />
        </IconButton>
      </Grid>
      <Grid
        style={{ fontSize: 18, fontWeight: 800, color: "rgba(61,114,135,.8)" }}
        item
      >
        Rows per page:
        <Select
          style={{ fontWeight: 400, textAlign: "center", marginLeft: "5px" }}
          value={props.rowsPerPage}
          onChange={(e) => {
            props.onChangeRowsPerPage(e);
          }}
        >
          <MenuItem
            style={{ background: "white", textAlign: "center" }}
            value={5}
          >
            5
          </MenuItem>
          <MenuItem
            style={{ background: "white", textAlign: "center" }}
            value={10}
          >
            10
          </MenuItem>
          <MenuItem
            style={{ background: "white", textAlign: "center" }}
            value={20}
          >
            20
          </MenuItem>
        </Select>
      </Grid>
      <Grid style={{ marginLeft: "16px" }} item>
        <IconButton
          disabled={page === 0}
          onClick={() => {
            props.onChangePage({}, 0);
          }}
        >
          <FirstPage />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton
          disabled={page === 0}
          onClick={() => {
            props.onChangePage({}, page - 1);
          }}
        >
          <ChevronLeft />
        </IconButton>
      </Grid>
      <Grid
        style={{ fontSize: 18, fontWeight: 800, color: "rgba(61,114,135,.8)" }}
        item
      >
        {page + 1}/{pages}
      </Grid>
      <Grid item>
        <IconButton
          disabled={page + 1 === pages}
          onClick={() => {
            props.onChangePage({}, page + 1);
          }}
        >
          <ChevronRight />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton
          disabled={page + 1 === pages}
          onClick={() => {
            props.onChangePage({}, pages - 1);
          }}
        >
          <LastPage />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export const PlotData = ({
  loading,
  data,
  columns,
  classes,
  fileName,
  noGroups,
}) => {
  return (
    <Card>
      <CardHeader className={classes.gradientBackground} color="primary">
        <h4 className={classes.cardTitleBlue}>Plot Data</h4>
      </CardHeader>
      <CardBody
        style={{
          paddingTop: "0px",
          width: "100%",
          marginTop: "10px",
          paddingBottom: "10px",
        }}
      >
        {loading ? (
          <LoadingGrid />
        ) : (
          <MaterialTable
            components={{
              Toolbar: (props) => <div style={{ width: "100%" }} />,
              Groupbar: (props) => {
                return (
                  <div
                    style={{
                      background: "rgba(0,0,0,.025)",
                      borderBottom: "2px solid #BBCAD0",
                    }}
                  >
                    <MTableGroupbar {...props} />
                  </div>
                );
              },
              Pagination: (props) => (
                <CustomPagination
                  {...props}
                  columns={columns}
                  fileName={fileName}
                  data={data}
                />
              ),
              Container: (props) => {
                return (
                  <Paper
                    {...props}
                    className={classes.table}
                    style={{ padding: 0 }}
                  />
                );
              },
            }}
            title={""}
            options={{
              rowStyle: (rowData, index) => {
                return {
                  padding: "5 0 5 0",
                  backgroundColor: index % 2 ? null : "rgba(1,61,84,.05)",
                  borderBottom: "0px solid white",
                };
              },
              grouping: !noGroups,
              exportButton: true,
              exportFileName: fileName,
            }}
            icons={tableIcons}
            columns={columns}
            data={data}
          />
        )}
      </CardBody>
    </Card>
  );
};
