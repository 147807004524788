import React, { Component, Fragment } from "react";
import { compose } from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import connect from "react-redux/es/connect/connect";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Plot from "react-plotly.js";
import Slider from "rc-slider";
import "../../node_modules/rc-slider/assets/index.css";
import { select, slider } from "../Menu/actions";
import { onDemandHelpers } from "./helpers";
import TableTitle from "../StyledComponents/Table/TitleTable";
import Card from "../StyledComponents/Card/Card";
import CardHeader from "../StyledComponents/Card/CardHeader";
import CardBody from "../StyledComponents/Card/CardBody";
import LoadingGrid from "../StyledComponents/Grid/LoadingGrid";
import tableStyle from "../assets/jss/core-dashboard/components/tableStyle";
import ReactDOM from "react-dom";
import { PlotData } from "./PlotData";
import { tableStyling } from "./plotDataStyles";
import { addCommas } from "../utils";
const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

export const ColumnInfo = ({ info, axis }) => {
  const { numerator, denominator, index_level, measure_rate } = info;
  return (
    <Grid
      style={{ borderRight: axis === "X-Axis" ? "1px solid white" : null }}
      container
      item
      direction={"column"}
      xs={6}
    >
      <Grid item style={{ paddingTop: "5px" }}>
        Measure Rate: {measure_rate || "No Data"}
      </Grid>
      <Grid item style={{ paddingTop: "2.5px" }}>
        Index Level: {index_level || "No Data"}
      </Grid>
      <Grid item style={{ paddingTop: "2.5px" }}>
        Numerator: {addCommas(`${numerator}`) || "No Data"}
      </Grid>
      <Grid item style={{ paddingTop: "2.5px" }}>
        Denominator: {addCommas(`${denominator}`) || "No Data"}
      </Grid>
    </Grid>
  );
};

export const HoverInfo = ({ info, top, left, baseColor, borderColor }) => {
  const { district, school, xLabel, yLabel, xData, yData } = info;
  return (
    <Grid
      container
      style={{
        position: "fixed",
        left: left + 175,
        background: baseColor,
        border: `5px solid ${borderColor}`,
        borderRadius: 10,
        color: "white",
        width: 350,
        top: top - 75,
        zIndex: 5000,
        textAlign: "center",
      }}
    >
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <Typography
            color={"inherit"}
            style={{ fontSize: "16px", padding: "0px", margin: "0px" }}
          >
            District: {district}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            color={"inherit"}
            style={{ fontSize: "16px", padding: "0px", margin: "0px" }}
          >
            School: {school}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        style={{
          border: "1px solid white",
          borderRadius: "5px",
          fontSize: "12px",
        }}
      >
        <Grid
          style={{
            borderBottom: "2px solid white",
            borderRight: "1px solid white",
          }}
          item
          xs={6}
        >
          <Typography
            color={"inherit"}
            style={{ padding: "5px", fontSize: "14px" }}
          >
            X-Axis: {xLabel}
          </Typography>
        </Grid>
        <Grid item xs={6} style={{ borderBottom: "2px solid white" }}>
          <Typography
            color={"inherit"}
            style={{ padding: "5px", fontSize: "14px" }}
          >
            Y-Axis: {yLabel}
          </Typography>
        </Grid>
        <ColumnInfo label={xLabel} info={xData} axis={"X-Axis"} />
        <ColumnInfo label={yLabel} info={yData} axis={"Y-Axis"} />
      </Grid>
    </Grid>
  );
};

class ScatterPlots extends Component {
  constructor(props) {
    super(props);
    /*
				TODO 1 : Default sliders state to empty once add and remove are implemented
		 */
    this.state = {
      sliders: {
        ALL: [0, 4520],
        AA: [0, 100],
        AS: [0, 100],
        WH: [0, 100],
        SWD: [0, 100],
        SD: [0, 100],
        PI: [0, 100],
        ELP: [0, 100],
        HI: [0, 100],
        HMLS: [0, 100],
        MR: [0, 100],
        EL: [0, 100],
        FI: [0, 100],
        AI: [0, 100],
      },
      anchorEl: null,
    };
    this.handleSlide = this.handleSlide.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleSlide = (key, value) => {
    const { moveSlider } = this.props;
    moveSlider(key, value);
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = (key) => {
    const { id } = this.state.anchorEl;
    const { select, menu, selected, constants, menuObject } = this.props;
    const input = {
      name: id,
      value: key,
    };
    const path = this.props.history.location.pathname;
    if (key) {
      select(input, menu, selected, path, constants, menuObject);
    }
    this.setState({ anchorEl: null });
  };

  // ADDED IN THE cohort_ag measure below 10/10/2019 - Dylan
  render() {
    const {
      scatterPlots,
      subgroup,
      xAxis,
      yAxis,
      match: { path },
      sliders,
      constants,
      measureLevels,
      selectedSchools,
      selectedDistricts,
      classes,
      year,
      loading,
      network,
    } = this.props;

    const { generateScatterPlotsData } = onDemandHelpers;

    const { anchorEl } = this.state;
    const college = path === "/dashboard/ondemand/scatterplots";
    const baseMeasureList = college
      ? constants["measures"]
      : constants["collegeMeasures"];
    const measureList = college
      ? {
          grad4y: { name: "4 Yr Graduation Rate" },
          cohort_ag: { name: "Cohort A-G Rate" },
          susp_core: { name: "CORE Suspension Rate" },
          chron_abs: { name: "Chronic Absence" },
          prior_dfm_ela: { name: "Prior DFM - ELA" },
          prior_dfm_math: { name: "Prior DFM - Math" },
          dfm_ela: { name: "DFM - ELA" },
          dfm_math: { name: "DFM - Math" },
          sbac_dfm_ela: { name: "SBAC Distance - ELA" },
          sbac_dfm_math: { name: "SBAC Distance - MATH" },
          caa_dfm_ela: { name: "CAA Distance - ELA" },
          caa_dfm_math: { name: "CAA Distance - MATH" },
          elpi: { name: "English Learner Progress" },
          el_redes: { name: "EL Re-Designation" },
          cc_fam_ovr: { name: "Family Culture Climate" },
          prior_growth_ela: { name: "Prior Growth - ELA" },
          prior_growth_math: { name: "Prior Growth - Math" },
          growth_ela: { name: "Growth - ELA" },
          growth_math: { name: "Growth - Math" },
          sel_stu_gm: { name: "Minimizing Fixed Mindset" },
          sel_stu_gmpos: { name: "Growth Mindset" },
          susp_state: { name: "LCAP Suspension Rate" },
          prior_perc_prof_ela: { name: "Prior Proficiency - ELA" },
          prior_perc_prof_math: { name: "Prior Proficiency - Math" },
          perc_prof_ela: { name: "Proficiency - ELA" },
          perc_prof_math: { name: "Proficiency - Math" },
          sel_stu_se: { name: "Self-Efficacy" },
          sel_stu_sm: { name: "Self-Management" },
          sel_stu_sa: { name: "Social Awareness" },
          cc_stf_ovr: { name: "Staff Culture Climate" },
          cc_stu_ovr: { name: "Student Culture Climate" },
          on_track_rate: { name: "On-Track Status" },
        }
      : {
          ...baseMeasureList,
          ...(!college
            ? {
                chron_abs: { name: "Chronic Absence" },
                dfm_math: { name: "DFM - Math" },
                sbac_dfm_ela: { name: "SBAC Distance - ELA" },
                sbac_dfm_math: { name: "SBAC Distance - MATH" },
                caa_dfm_ela: { name: "CAA Distance - ELA" },
                caa_dfm_math: { name: "CAA Distance - MATH" },
                dfm_ela: { code: "dfm_ela", name: "DFM - ELA" },
                elpi: { name: "English Learner Progress" },
                el_redis: { code: "el_redis", name: "EL Re-Designation" },
                sel_stu_gm: { name: "Minimizing Fixed Mindset" },
                sel_stu_gmpos: { name: "Growth Mindset" },
                perc_prof_math: { name: "Proficiency - Math" },
                perc_prof_ela: { name: "Proficiency - ELA" },
                susp_core: { name: "CORE Suspension Rate" },
                susp_state: { name: "LCAP Suspension Rate" },
              }
            : {}),
        };

    const { metrics, rangeX, rangeY } = Object.keys(scatterPlots).length
      ? scatterPlots
      : { metrics: {}, rangeX: [0, 100], rangeY: [0, 100] };
    const { data, customdata } = generateScatterPlotsData({
      measureList,
      scatterPlots: metrics,
      measureLevels,
      subgroup,
      sliders,
      xAxis,
      yAxis,
      selectedDistricts,
      selectedSchools,
    });

    const list = measureList;
    const xLabel = measureList[xAxis] ? measureList[xAxis]["name"] : "";
    const yLabel = measureList[yAxis] ? measureList[yAxis]["name"] : "";

    const tableData = Object.keys(metrics).reduce((array, key) => {
      const district = metrics[key];
      Object.keys(district).forEach((level) => {
        const schools = district[level];
        Object.keys(schools).forEach((school) => {
          const { x_axis, y_axis } = schools[school];
          const xData = (x_axis || {})[subgroup];
          const yData = (y_axis || {})[subgroup];
          if (xData && yData) {
            const res = {
              district: key,
              school,
              level,
            };
            [xData, yData].forEach((data, index) => {
              const prefix = index ? "y_" : "x_";
              Object.keys(data).forEach((attribute) => {
                res[`${prefix}${attribute}`] = parseInt(data[attribute]);
              });
            });
            array.push(res);
          }
        });
      });
      return array;
    }, []);

    const xDistance = [
      "dfm_math",
      "dfm_ela",
      "growth_math",
      "growth_ela",
      "sbac_dfm_ela",
      "sbac_dfm_math",
      "caa_dfm_ela",
      "caa_dfm_math",
    ].includes(xAxis);
    const yDistance = [
      "dfm_math",
      "dfm_ela",
      "growth_math",
      "growth_ela",
      "sbac_dfm_ela",
      "sbac_dfm_math",
      "caa_dfm_ela",
      "caa_dfm_math",
    ].includes(yAxis);

    function getColor(index) {
      switch (index) {
        case 10:
        case 9:
        case 8:
          return "green";
        case 7:
        case 6:
        case 5:
        case 4:
          return "orange";
        case 3:
        case 2:
        case 1:
          return "red";
        default:
          return null;
      }
    }

    const columns = [
      {
        title: "District",
        field: "district",
        cellStyle: tableStyling.district,
        headerStyle: tableStyling.darkHeaderFirst,
      },
      {
        title: "School",
        field: "school",
        cellStyle: tableStyling.school,
        headerStyle: tableStyling.darkHeader,
      },
      {
        title: "Measure Level",
        field: "level",
        cellStyle: { ...tableStyling.year, maxWidth: 80, height: 90 },
        headerStyle: { ...tableStyling.darkHeader, maxWidth: 80 },
      },
      {
        title: `${xLabel} - Measure Rate`,
        field: "x_measure_rate",
        cellStyle: { ...tableStyling.dataRow, textAlign: "center" },
        headerStyle: { ...tableStyling.title },
        grouping: false,
        render: (rowData) =>
          !rowData["x_measure_rate"] ? (
            <p style={{ margin: 5, color: "#d1d1d1" }}>No Data</p>
          ) : (
            `${rowData["x_measure_rate"]}${xDistance ? "" : "%"}`
          ),
      },
      {
        title: `${yLabel} - Measure Rate`,
        grouping: false,
        field: "y_measure_rate",
        cellStyle: { ...tableStyling.dataRow, textAlign: "center" },
        headerStyle: { ...tableStyling.title },
        render: (rowData) => {
          return !(rowData || {})["y_measure_rate"] ? (
            <p style={{ margin: 5, color: "#d1d1d1" }}>No Data</p>
          ) : (
            `${rowData["y_measure_rate"]}${yDistance ? "" : "%"}`
          );
        },
      },
      {
        title: `${xLabel} - Index Level`,
        field: "x_index_level",
        grouping: false,
        cellStyle: {
          ...tableStyling.dataRow,
          position: "relative",
        },
        headerStyle: tableStyling.title,
        render: (rowData) => {
          const { x_index_level } = rowData;
          //const color = (x_index_level) ? (x_index_level > 7) ? 'green' : (x_index_level > 3) ? 'orange' : 'red' : null
          return !rowData["x_index_level"] ? (
            <p style={{ margin: 5, color: "#d1d1d1" }}>No Data</p>
          ) : (
            <div
              className={classes[getColor(x_index_level) + "Backdrop"]}
              style={{
                verticalAlign: "center",
                position: "absolute",
                paddingTop: "10%",
                left: 0,
                top: 0,
                height: "100%",
                width: "100%",
              }}
            >
              <span
                style={{
                  color: "rgba(0,0,0,.7)",
                  fontWeight: "900",
                  fontSize: "36px",
                }}
              >
                {x_index_level}
                <small
                  style={{
                    marginLeft: "10px",
                    paddingBottom: "8px",
                    color: "rgba(0,0,0,.7)",
                    fontWeight: "400",
                    fontSize: "12px",
                  }}
                >
                  out of 10
                </small>
              </span>
            </div>
          );
        },
      },
      {
        title: `${yLabel} - Index Level`,
        field: "y_index_level",
        grouping: false,
        cellStyle: {
          ...tableStyling.dataRow,
          position: "relative",
        },
        headerStyle: tableStyling.title,
        render: (rowData) => {
          const { y_index_level } = rowData;
          return !rowData["y_index_level"] ? (
            <p style={{ margin: 5, color: "#d1d1d1" }}>No Data</p>
          ) : (
            <div
              className={classes[getColor(y_index_level) + "Backdrop"]}
              style={{
                paddingTop: "10%",
                verticalAlign: "center",
                position: "absolute",
                left: 0,
                top: 0,
                height: "100%",
                width: "100%",
              }}
            >
              <span
                style={{
                  color: "rgba(0,0,0,.7)",
                  fontWeight: "900",
                  fontSize: "36px",
                }}
              >
                {y_index_level}
                <small
                  style={{
                    marginLeft: "10px",
                    paddingBottom: "8px",
                    color: "rgba(0,0,0,.7)",
                    fontWeight: "400",
                    fontSize: "12px",
                  }}
                >
                  out of 10
                </small>
              </span>
            </div>
          );
        },
      },
    ];

    return (
      <Fragment>
        <MeasureList
          list={list}
          anchorEl={anchorEl}
          handleClose={this.handleClose}
        />
        <Grid
          style={{ margin: "25px 2.5% 0 2.5%", width: "95%" }}
          justifyContent={"center"}
          container
          direction={"row"}
        >
          <TableTitle
            school={{}}
            district={{}}
            pageTitle={"Scatter Plots"}
            hideInfo
          />
          <Grid xs={12} item>
            <Card>
              <CardHeader
                className={classes.gradientBackground}
                color="primary"
              >
                <h4 className={classes.cardTitleBlue}>{`${
                  network.text || ""
                }, ${xLabel} vs ${yLabel}, ${year}`}</h4>
              </CardHeader>
              <CardBody
                id={"card-body"}
                style={{
                  paddingTop: "0px",
                  width: "100%",
                  marginTop: "10px",
                  paddingBottom: "10px",
                  textAlign: "center",
                }}
              >
                <Grid style={{ width: "100%", height: "60vh" }} container item>
                  <Grid xs={2} md={2} lg={1} item>
                    <Button
                      id={"yAxis"}
                      onClick={(e) => this.handleClick(e)}
                      style={{
                        border: "2px solid  #BBCAD0",
                        width: "100%",
                        height: "85%",
                      }}
                    >
                      <span
                        style={{
                          background: "rgba(1,61,84,.1)",
                          borderBottom: "2px solid #BBCAD0",
                          color: "rgba(61,114,135,.8)",
                          fontSize: "14px",
                          fontWeight: "900",
                          padding: "12px",
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "10vh",
                        }}
                      >
                        Select Y-Axis
                      </span>
                      <span
                        style={{
                          color: "rgba(61,114,135,.8)",
                          fontSize: "14px",
                          fontWeight: "700",
                          overflow: "ellipsis",
                          marginTop: "10vh",
                          height: "40vh",
                          writingMode: "vertical-rl",
                          transform: "rotate(180deg)",
                          transformOrigin: "center",
                        }}
                      >
                        {yLabel}
                      </span>
                    </Button>
                  </Grid>
                  <Grid xs={10} lg={11} container item direction={"row"}>
                    <Grid xs={12} style={{ width: "100%", height: "85%" }} item>
                      {loading ? (
                        <LoadingGrid />
                      ) : (
                        <Plot
                          onClick={(data) => {
                            const key = data.points[0].text;
                            const info = customdata[key];
                            if (info) {
                              const card = document.getElementById("card-body");
                              const xy = card.getBoundingClientRect();
                              const xaxis = data.points[0].xaxis;
                              const yaxis = data.points[0].yaxis;
                              const top =
                                xy.y +
                                yaxis.l2p(data.points[0].y) +
                                yaxis._offset;
                              const left =
                                xy.x +
                                xaxis.l2p(data.points[0].x) +
                                xaxis._offset;
                              const hoverinfo =
                                document.getElementById("hoverinfo");
                              ReactDOM.render(
                                <HoverInfo
                                  classes={classes}
                                  info={info}
                                  top={top}
                                  left={left}
                                />,
                                hoverinfo
                              );
                            }
                          }}
                          onHover={(data) => {
                            const key = data.points[0].text;
                            const info = customdata[key];
                            const baseColor = data.points[0]["marker.color"];
                            const borderColor =
                              data.points[0]["marker.line.color"];
                            if (info) {
                              const card = document.getElementById("card-body");
                              const xy = card.getBoundingClientRect();
                              const xaxis = data.points[0].xaxis;
                              const yaxis = data.points[0].yaxis;
                              const top =
                                xy.y +
                                yaxis.l2p(data.points[0].y) +
                                yaxis._offset;
                              const left =
                                xy.x +
                                xaxis.l2p(data.points[0].x) +
                                xaxis._offset;
                              const hoverinfo =
                                document.getElementById("hoverinfo");
                              ReactDOM.render(
                                <HoverInfo
                                  classes={classes}
                                  info={info}
                                  top={top}
                                  left={left}
                                  baseColor={baseColor}
                                  borderColor={borderColor}
                                />,
                                hoverinfo
                              );
                            }
                          }}
                          onUnhover={() => {
                            const hoverinfo =
                              document.getElementById("hoverinfo");
                            ReactDOM.unmountComponentAtNode(hoverinfo);
                          }}
                          style={{ width: "100%", height: "100%" }}
                          data={[data]}
                          layout={{
                            margin: {
                              r: 50,
                              t: 50,
                              l: 50,
                              b: 50,
                            },
                            hovermode: "closest",
                            xaxis: {
                              range: [
                                parseInt(rangeX[0]) - 10,
                                parseInt(rangeX[1]) + 10,
                              ],
                            },
                            yaxis: {
                              range: [
                                parseInt(rangeY[0]) - 10,
                                parseInt(rangeY[1]) + 10,
                              ],
                            },
                          }}
                        />
                      )}
                    </Grid>
                    <Grid xs={12} style={{ height: "15%", width: "100%" }} item>
                      <Button
                        id={"xAxis"}
                        onClick={(e) => this.handleClick(e)}
                        style={{
                          border: "2px solid  #BBCAD0",
                          height: "100%",
                          width: "100%",
                        }}
                      >
                        <span
                          style={{
                            background: "rgba(1,61,84,.1)",
                            borderRight: "2px solid #BBCAD0",
                            color: "rgba(61,114,135,.8)",
                            fontSize: "14px",
                            fontWeight: "900",
                            paddingTop: "2.5%",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            height: "100%",
                            minWidth: "10%",
                            maxWidth: "20%",
                          }}
                        >
                          Select X-Axis
                        </span>
                        <span
                          style={{
                            color: "rgba(61,114,135,.8)",
                            fontSize: "14px",
                            fontWeight: "700",
                          }}
                        >
                          {xLabel}
                        </span>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
          </Grid>
          <Grid xs={12} item>
            <Card>
              <CardHeader
                className={classes.gradientBackground}
                color="primary"
              >
                <h4 className={classes.cardTitleBlue}>
                  {"Subset to School Characteristics"}
                </h4>
              </CardHeader>
              <CardBody
                id={"card-body"}
                style={{ width: "100%", padding: "10px" }}
              >
                <Grid
                  justifyContent={"center"}
                  spacing={24}
                  container
                  direction={"column"}
                >
                  <SliderList
                    subgroups={constants["subgroups"]}
                    sliders={sliders}
                    handleSlide={this.handleSlide}
                  />
                </Grid>
              </CardBody>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <PlotData
              loading={loading}
              noData={true}
              fileName={`${
                network.text || ""
              }, ${xLabel} vs ${yLabel}, ${year}`}
              classes={classes}
              data={tableData}
              columns={columns}
            />
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

const MeasureList = ({ list, anchorEl, handleClose }) => {
  return (
    <Menu
      id={"menu"}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={() => {
        handleClose("");
      }}
    >
      {Object.keys(list).map((key) => (
        <MenuItem
          key={`menu-${key}`}
          style={{ fontSize: "12px", padding: "2.5px" }}
          onClick={() => {
            handleClose(key);
          }}
        >
          {list[key].name}
        </MenuItem>
      ))}
    </Menu>
  );
};

const SliderList = ({ sliders, handleSlide, subgroups }) => {
  const sortedSliders = [{ key: "ALL", value: sliders["ALL"] }].concat(
    Object.keys(sliders).reduce((array, key) => {
      if (key !== "ALL") {
        const cell = {
          key,
          value: sliders[key],
        };
        array.push(cell);
      }
      return array;
    }, [])
  );
  return (
    <Grid spacing={16} justifyContent={"space-evenly"} container item>
      {sortedSliders.map((cell) => {
        const { key, value } = cell;
        const label = Object.keys(subgroups).length
          ? key === "SD"
            ? "Socio-Economically Disadvantaged"
            : subgroups[key]["name"]
          : key;
        const text = key === "ALL" ? "Select School Size" : `Percent ${label}`;
        const markTicks =
          key === "ALL"
            ? [
                "0",
                "500",
                "1000",
                "1500",
                "2000",
                "2500",
                "3000",
                "3500",
                "4000",
                "4520",
              ]
            : [
                "0",
                "10",
                "20",
                "30",
                "40",
                "50",
                "60",
                "70",
                "80",
                "90",
                "100",
              ];
        const marks = markTicks.reduce((obj, curr) => {
          obj[curr] = {
            label: curr,
            style: {
              background: null,
            },
          };
          return obj;
        }, {});
        return (
          <Grid style={{ marginBottom: "25px", width: "22%" }} item>
            <Typography
              style={{
                whiteSpace: "nowrap",
                fontSize: "12px",
                fontWeight: "700",
                marginBottom: "5px",
                color: "rgb(79, 128, 147)",
              }}
            >
              {text}
            </Typography>
            <Range
              dotStyle={{
                width: "1px",
                height: "8px",
                background: "rgb(79, 128, 147)",
                border: "none",
                marginBottom: "-7px",
              }}
              marks={marks}
              trackStyle={[{ background: "rgb(79, 128, 147)" }]}
              handleStyle={[
                {
                  background: "rgb(79, 128, 147)",
                  width: 9,
                  height: 25,
                  border: "none",
                  borderRadius: "50% 75% 50% 75% 50% 25% 50% 25%",
                  cursor: "pointer",
                  marginTop: "-14px",
                },
              ]}
              min={0}
              max={key === "ALL" ? 4520 : 100}
              key={"slider:" + key}
              defaultValue={key === "ALL" ? [0, 4520] : [0, 100]}
              value={value}
              onChange={(value) => {
                handleSlide(key, value);
              }}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

const findInMenu = (array, key) => {
  const res = {
    code: "",
    text: "",
  };
  if (!array) {
    return res;
  }

  return array.reduce((found, curr) => {
    if (curr.code === key) {
      found = curr;
    }
    return found;
  }, res);
};

const mapState = (state) => {
  const { selected, menuObject, menu, sliders } = state.menu;
  const { subgroups, measures, collegeMeasures } = state.constants;
  const network = findInMenu(menuObject["Network"], selected["Network"]);
  const measureLevels = selected["multi_level"] || {};
  const selectedDistricts = selected["multi_district"] || {};
  const selectedSchools = selected["multi_school"] || {};
  const selectedGroups = selected["multi_groups"] || {};
  const subgroup = selected["Student Groups"];
  const year = selected["School Year"] ? selected["School Year"] : "";
  const measure = selected["Measure"];
  const data_points = selected["Data Points"];
  const { scatterPlots, loading } = state.metrics;
  const { xAxis, yAxis } = selected;
  return {
    network,
    loading,
    xAxis,
    yAxis,
    menu,
    scatterPlots,
    selected,
    year,
    measure,
    data_points,
    measureLevels: Object.keys(measureLevels),
    selectedDistricts,
    selectedSchools,
    selectedGroups,
    subgroup,
    sliders,
    menuObject,
    constants: {
      subgroups,
      measures,
      collegeMeasures,
    },
  };
};

const mapDispatch = (dispatch) => {
  return {
    select: (input, menu, selected, path, constants, menuObject) => {
      dispatch(select(input, menu, selected, path, constants, menuObject));
    },
    moveSlider: (key, value) => {
      dispatch(slider(key, value));
    },
  };
};

export default compose(
  withStyles(tableStyle, { withTheme: true }),
  connect(mapState, mapDispatch)
)(ScatterPlots);
