import React, { Component } from "react";
import { compose } from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import connect from "react-redux/es/connect/connect";
import { helpers } from "./helpers";
import { lcapConstants } from "../Constants/actions";
import Grid from "@material-ui/core/Grid";
import CreateTable from "../StyledComponents/Table/TableList";
import tableStyle from "../assets/jss/core-dashboard/components/tableStyle.jsx";
import TitleTable from "../StyledComponents/Table/TitleTable";

class FiveByFiveSchools extends Component {
  constructor(props) {
    super(props);
    const {
      getLcapConstants,
      lcap: { values },
    } = this.props;
    if (!Object.keys(values).length) {
      getLcapConstants();
    }
  }
  render() {
    const {
      measure,
      measureInfo,
      year,
      measureLevel,
      subgroups,
      lcap,
      schools5x5,
      district,
      loading,
    } = this.props;
    const { values, text, grids } = lcap;
    const currentInfo = values[measure] ? values[measure][measureLevel] : {};
    const yearNumber = year ? parseInt(year.split("-")[0]) : undefined;
    const pastYear = `${yearNumber - 1}-${yearNumber}`;
    const data = schools5x5[measure]
      ? year
        ? schools5x5[measure][year] || {}
        : {}
      : {};
    const otherYear = schools5x5[measure]
      ? Object.keys(schools5x5[measure]).reduce((found, curr) => {
          return curr !== year ? curr : found;
        }, undefined)
      : undefined;
    const fiveByFive = helpers.createFiveByFive({
      data,
      otherYear,
      metrics: schools5x5,
      measure,
      subgroups,
    });
    const { topBar, sideBar } = helpers.parseFiveByFiveLabels({
      currentInfo,
      text,
      measureInfo,
    });
    const { name } = measureInfo ? measureInfo : { name: measure };
    const currGrid =
      grids && currentInfo ? (grids[measure] || {})[measureLevel] : undefined;
    const { head, body } = helpers.formatFiveByFive({
      fiveByFive,
      sideBar,
      topBar,
      year,
      pastYear,
      currGrid,
    });
    return (
      <Grid
        style={{ width: "95%", margin: "0 2.5% 0 2.5%" }}
        justifyContent={"center"}
        container
        direction={"row"}
      >
        <TitleTable
          school={{ text: "District Overall", code: "overall" }}
          hideInfo
          district={district}
          pageTitle={"LCAP 5x5 Schools"}
        />
        <Grid item xs={12}>
          <CreateTable
            loading={loading}
            pageType={"LCAP"}
            typeOptions={{
              x: `Change from ${pastYear} to ${year} in ${name}`,
              y: `Status in ${year}: ${name}`,
            }}
            type={"5x5"}
            data={body || []}
            header={head || []}
            title={`Measure: ${name} ${year}`}
            index={0}
            selectedMeasure={measure}
          />
        </Grid>
      </Grid>
    );
  }
}

const findInMenu = (array, key) => {
  const res = {
    code: "",
    text: "",
  };
  if (!array) {
    return res;
  }
  return array.reduce((found, curr) => {
    if (curr.code === key) {
      found = curr;
    }
    return found;
  }, res);
};

const mapState = (state) => {
  const { selected, menuObject } = state.menu;
  const { subgroups, lcap, measures } = state.constants;
  const district = findInMenu(menuObject["District"], selected["District"]);
  const school = findInMenu(menuObject["School"], selected["School"]);
  const measureLevel = selected["School Level"];
  const year = selected["School Year"] ? selected["School Year"] : "";
  const measure = selected["Measure"];
  const { schools5x5, loading } = state.metrics;
  const measureInfo = measures[measure] || {};
  return {
    loading,
    subgroups,
    lcap,
    measures,
    schools5x5,
    selected,
    district,
    school,
    year,
    measure,
    measureLevel,
    measureInfo,
  };
};

const mapDispatch = (dispatch) => {
  return {
    getLcapConstants: () => {
      dispatch(lcapConstants());
    },
  };
};

export default compose(
  withStyles(tableStyle, { withTheme: true }),
  connect(mapState, mapDispatch)
)(FiveByFiveSchools);
