import {
  FETCH_MENU_SUCCESS,
  DEEP_LINK_SUCCESS,
  MOVE_SLIDER,
  CLOSE_DEEPLINK,
  GENERATE_DEEP_LINK_SUCCESS,
  TOGGLE_VISIBILITY,
  SCATTER_PLOT_NEW_VALUE,
} from "./actions";
import { LOGIN_SUCCESS } from "../Login/actions";

const initialState = {
  menu: {},
  selected: {},
  sliders: {
    ALL: [0, 4520],
    AA: [0, 100],
    AS: [0, 100],
    WH: [0, 100],
    SWD: [0, 100],
    SD: [0, 100],
    PI: [0, 100],
    ELP: [0, 100],
    HI: [0, 100],
    HMLS: [0, 100],
    MR: [0, 100],
    EL: [0, 100],
    FI: [0, 100],
    AI: [0, 100],
  },
  deepLinkURL: "",
  menuObject: {},
  deepLink: false,
  showDeeplink: false,
};

export default function menu(state = initialState, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        selected: {},
      };
    case FETCH_MENU_SUCCESS:
      return {
        ...state,
        menu: action.menu,
        selected: state.deepLink ? state.selected : action.selected,
        menuObject: action.menuObject,
        deepLink: false,
      };
    case DEEP_LINK_SUCCESS:
      return {
        ...state,
        selected: action.selected,
        sliders: action.sliders ? action.sliders : state.sliders,
        deepLink: true,
      };
    case GENERATE_DEEP_LINK_SUCCESS:
      return {
        ...state,
        deepLinkURL: action.url,
        showDeeplink: true,
      };
    case CLOSE_DEEPLINK:
      return {
        ...state,
        showDeeplink: false,
        // deepLinkURL: "",
      };
    case MOVE_SLIDER:
      return {
        ...state,
        sliders: {
          ...state.sliders,
          [action.key]: action.value,
        },
      };
    case TOGGLE_VISIBILITY:
      return {
        ...state,
        selected: {
          ...state.selected,
          [action.name]: {
            ...state.selected[action.name],
            visibility: !state.selected[action.name].visibility,
          },
        },
      };
    case SCATTER_PLOT_NEW_VALUE:
      switch (action.name) {
        case "multi_groups":
          return {
            ...state,
            selected: {
              ...state.selected,
              [action.name]: {
                ...state.selected[action.name],
                [action.key]: action.value,
              },
            },
          };
        default: {
          return {
            ...state,
            selected: {
              ...state.selected,
              [action.name]: {
                ...state.selected[action.name],
                list: {
                  ...state.selected[action.name].list,
                  [action.key]: action.value,
                },
              },
            },
          };
        }
      }

    default:
      return state;
  }
}
