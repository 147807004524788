import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { compose } from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import connect from "react-redux/es/connect/connect";
import TableTitle from "../StyledComponents/Table/TitleTable";
import Card from "../StyledComponents/Card/Card";
import CardHeader from "../StyledComponents/Card/CardHeader";
import tableStyle from "../assets/jss/core-dashboard/components/tableStyle";
import { networkView, schoolAndNetworkView } from "./Helpers/constants";
import Typography from "@material-ui/core/es/Typography";
import MenuSelect from "./MenuSelect";
import NetworkTable from "./NetworkTable";
import LoadingGrid from "../StyledComponents/Grid/LoadingGrid";

const styles = (theme) => ({
  overviewText: {
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "25px",
    fontSize: "20px",
    color: "rgba(60,114,135,.9)",
    lineHeight: "40px",
  },
});

class NetworkView extends Component {
  state = {
    predict_options: "measure_delta",
    success_options: "probability_grad_ag",
    grade_options:
      this.props.measureLevel === "HS"
        ? "10"
        : this.props.measureLevel === "MS"
        ? "07"
        : "05",
    year_options: "2019",
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.measureLevel !== this.props.measureLevel) {
      this.setState({
        grade_options: this.handleGrade(this.props.measureLevel)[1].value,
      });
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleGrade = (type) => {
    switch (type) {
      case "HS":
        return [
          { name: "Grade 9", value: "09" },
          { name: "Grade 10", value: "10" },
          { name: "Grade 11", value: "11" },
          // {name: "Grade 12", value: "12"},
        ];
      case "MS":
        return [
          { name: "Grade 6", value: "06" },
          { name: "Grade 7", value: "07" },
          { name: "Grade 8", value: "08" },
        ];
      case "ES":
        return [
          { name: "Grade 4", value: "04" },
          { name: "Grade 5", value: "05" },
          { name: "Grade 6", value: "06" },
        ];
      default:
        return [
          { name: "Grade 4", value: "04" },
          { name: "Grade 5", value: "05" },
          { name: "Grade 6", value: "06" },
          { name: "Grade 7", value: "07" },
          { name: "Grade 8", value: "08" },
          { name: "Grade 9", value: "09" },
          { name: "Grade 10", value: "10" },
          { name: "Grade 11", value: "11" },
          // {name: "Grade 12", value: "12"}
        ];
    }
  };

  render() {
    const {
      classes,
      network,
      district,
      school,
      measureLevel,
      networkViewData,
      loading,
    } = this.props;
    const data = networkViewData || {};
    const gradeOptions = this.handleGrade(measureLevel);
    return (
      <Grid
        style={{ margin: "0 2.5% 0 2.5%", width: "95%" }}
        justifyContent={"center"}
        container
        direction={"row"}
      >
        <TableTitle
          school={""}
          district={""}
          hideInfo
          pageTitle={"Predictive Analytics"}
          secondaryTitle={"NETWORK VIEW"}
        />
        <Card>
          <CardHeader className={classes.gradientBackground} color="primary">
            <h4 className={classes.cardTitleBlue}>Overview</h4>
          </CardHeader>
        </Card>
        <Typography className={classes.overviewText}>
          {schoolAndNetworkView.overview}
        </Typography>
        <MenuSelect
          menuSelect={networkView.menuSelect}
          state={this.state}
          handleChange={this.handleChange}
          gradeOptions={gradeOptions}
        />
        {loading ? (
          <LoadingGrid />
        ) : (
          <NetworkTable
            state={this.state}
            data={data}
            district={district.text}
            school={school.text}
          />
        )}
      </Grid>
    );
  }
}

const findInMenu = (array, key) => {
  const res = {
    code: "",
    text: "",
  };
  if (!array) {
    return res;
  }

  return array.reduce((found, curr) => {
    if (curr.code === key) {
      found = curr;
    }
    return found;
  }, res);
};

const mapState = (state) => {
  const { selected, menuObject } = state.menu;
  const { networkViewPA, loading } = state.metrics;
  const network = findInMenu(menuObject["Network"], selected["Network"]);
  const district = findInMenu(menuObject["District"], selected["District"]);
  const school = findInMenu(menuObject["School"], selected["School"]);
  const measureLevel = selected["School Level"];
  return {
    network,
    district,
    school,
    measureLevel,
    networkViewData: networkViewPA,
    loading,
  };
};

const mapDispatch = (dispatch) => {
  return {};
};

export default compose(
  withStyles((theme) => ({
    ...tableStyle(theme),
    ...styles(theme),
  })),
  connect(mapState, mapDispatch)
)(NetworkView);
