import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import user from "./Login/reducer";
import menu from "./Menu/reducer";
import metrics from "./Metrics/reducer";
import constants from "./Constants/reducer";
import visualizations from "./Visualizations/reducer";
import smallMultiples from "./OnDemandAnalytics/SmallMultiples/reducer";
import predAnalytics from "./PredictiveAnalytics/reducer";
import mapView from "./reducers/Map/MapView";
import loading from "./reducers/Loading/Loading";
import onTrack from "./reducers/OnTrack/ontrack";
import { reducer as formReducer } from "redux-form";

const reducer = combineReducers({
  user,
  menu,
  metrics,
  constants,
  visualizations,
  predAnalytics,
  form: formReducer,
  smallMultiples,
  mapView,
  loading,
  onTrack,
});

const middleware = applyMiddleware(thunk);

const store = createStore(reducer, middleware);

export default store;
