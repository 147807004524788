import React, { Component } from "react";
//GIT COMMIT HASH ON LOGIN FOR HELP
const _versionNumber = process.env.REACT_APP_CODEBUILD_RESOLVED_SOURCE_VERSION
  ? process.env.REACT_APP_CODEBUILD_RESOLVED_SOURCE_VERSION
  : "local dev build";
class CommitId extends Component {
  render() {
    return (
      <small
        style={{
          position: "absolute",
          bottom: "8px",
          left: "16px",
          fontSize: "8pt",
          color: "gray",
        }}
      >
        commit id: {_versionNumber}
      </small>
    );
  }
}
export default CommitId;
