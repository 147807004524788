import React from "react";
import { Route } from "react-router-dom";
import Redirect from "react-router-dom/es/Redirect";

export const ProtectedRoute = ({ token, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("token") ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
            }}
          />
        )
      }
    />
  );
};
