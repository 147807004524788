// CONSTANT VARIABLES

const API = process.env.REACT_APP_API;

// ACTION TYPES

export const UPDATE_SCHOOL_VIEW = "UPDATE_SCHOOL_VIEW";
export const UPDATE_NETWORK_VIEW = "UPDATE_NETWORK_VIEW";

//ACTION CREATORS

const updateSchoolView = () => ({ type: UPDATE_SCHOOL_VIEW });
const updateNetworkView = () => ({ type: UPDATE_NETWORK_VIEW });

export const updateSchool = () => (dispatch) => {};

export const updateNetwork = () => (dispatch) => {};
