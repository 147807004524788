import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/es/Typography/Typography";
import { compose } from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import _ from "lodash";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const styles = (theme) => ({
  containerSpacing: {
    flexGrow: 1,
    paddingTop: "50px",
    paddingLeft: "15px",
    paddingRight: "10px",
  },
  selectMenu: {
    width: "100%",
    height: "50px",
    backgroundColor: "#608b9d",
  },
  selectHeader: {
    color: "white",
    fontSize: "24px",
    paddingLeft: "10px",
    fontWeight: "450",
  },
  selectMenuBody: {
    backgroundColor: "#d8e5ec",
    padding: "30px",
  },
  selectMenuText: {
    fontSize: "calc(16px + 1.5vw)",
    color: "#608b9d",
  },
  selectMenuOptionsText: {
    fontSize: "calc(16px + 1.5vw)",
    color: "#608b9d",
    backgroundColor: "#fbfcfd",
    paddingRight: "5px",
    paddingLeft: "5px",
    marginLeft: "10px",
    marginRight: "10px",
    fontWeight: "bold",
  },
  selectMenuOptionsDropdown: {
    fontSize: "calc(12px + 1.25vw)",
    color: "#608b9d",
    backgroundColor: "#fbfcfd",
  },
});

class MenuSelect extends Component {
  renderFields(fields, state, handleChange, gradeOptions) {
    return _.map(fields, ({ name, type, options, text }) => {
      return (
        <div>
          {type === "text" && (
            <Grid item>
              <Typography className={this.props.classes.selectMenuText}>
                {text}
              </Typography>
            </Grid>
          )}
          {type === "select" && (
            <Grid item>
              <FormControl margin="normal">
                <Select
                  name={name}
                  onChange={handleChange}
                  value={state[`${name}`]}
                  className={this.props.classes.selectMenuOptionsText}
                  disableUnderline
                >
                  {options.map((name) => (
                    <MenuItem
                      key={name.name}
                      value={name.value}
                      className={this.props.classes.selectMenuOptionsDropdown}
                    >
                      {name.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          {type === "selectGrade" && (
            <Grid item>
              <FormControl margin="normal">
                <Select
                  name={name}
                  onChange={handleChange}
                  value={state[`${name}`]}
                  className={this.props.classes.selectMenuOptionsText}
                  disableUnderline
                >
                  {gradeOptions.map((name) => (
                    <MenuItem
                      key={name.name}
                      value={name.value}
                      className={this.props.classes.selectMenuOptionsDropdown}
                    >
                      {name.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
        </div>
      );
    });
  }

  render() {
    const { classes, menuSelect, handleChange, state, gradeOptions } =
      this.props;
    return (
      <form>
        <Grid container className={classes.containerSpacing}>
          <Grid
            container
            item
            xs={12}
            className={classes.selectMenu}
            alignItems={"center"}
          >
            <Typography className={classes.selectHeader}>
              Predicted Student Outcomes by Class
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={12}
            alignItems={"center"}
            className={classes.selectMenuBody}
          >
            {this.renderFields(menuSelect, state, handleChange, gradeOptions)}
          </Grid>
        </Grid>
      </form>
    );
  }
}

export default compose(withStyles(styles, { withTheme: true }))(MenuSelect);
