import React, { Component } from "react";
import PropTypes from "prop-types";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import MenuIcon from "@material-ui/icons/Menu";
import MenuBar from "../../Menu/Menu";
import MoreIcon from "@material-ui/icons/MoreVert";
import MobileMenu from "../../Menu/MobileMenu";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Link from "@material-ui/core/Link";

// const drawerWidth = 230

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  appBarStyles: {
    position: "relative",
    backgroundColor: "#1d5e77 !important",
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  sectionDesktop: {
    width: "100%",
    // display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  root2: {
    width: "100%",
    maxWidth: "100%",
    flexDirection: "column",
    zIndex: "10000 !important",
    cursor: "pointer",
  },
  headerText: {
    height: 60,
    fontSize: 30,
    color: "#ffffff",
    backgroundColor: "#fdbf00",
    fontFamily: "Roboto",
    [theme.breakpoints.up("xl")]: {
      fontSize: 30,
      height: 60,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
      height: 40,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
      height: 60,
    },
  },
  headerContainer: {
    textAlign: "center",
  },
  lowerContainer: {
    paddingBottom: 50,
    backgroundColor: "#fdbf00",
    [theme.breakpoints.up("xl")]: {
      paddingBottom: 50,
    },
    [theme.breakpoints.down("md")]: {
      paddingBottom: 20,
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 20,
    },
  },
  openContainer: {
    overflow: "hidden !important",
    backgroundColor: "#fdbf00",
    [theme.breakpoints.down("md")]: {
      height: 80,
      overflow: "scroll",
    },
    [theme.breakpoints.down("sm")]: {
      height: 70,
      overflow: "scroll",
    },
  },
  italicText: {
    textAlign: "center",
    paddingTop: 15,
    fontFamily: "Roboto",
    fontSize: 20,
    color: "#014363",
    [theme.breakpoints.up("xl")]: {
      fontSize: 20,
      paddingTop: 15,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 18,
      paddingTop: 15,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
      paddingTop: 15,
    },
  },
});

class PrimaryAppBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      mobileMoreAnchorEl: null,
      showMobile: false,
      infoBarOpen: false,
    };
  }

  handleMobileMenuOpen = () => {
    this.setState({
      showMobile: true,
    });
  };

  handleMobileMenuClose = () => {
    this.setState({ showMobile: false });
  };

  handleInfoBarOpen = () => {
    this.setState({ infoBarOpen: !this.state.infoBarOpen });
  };

  render() {
    const { classes, handleDrawerToggle, path, toggleEdit } = this.props;

    const open = this.state.infoBarOpen;
    const text =
      "Attention: Due to the COVID-19 pandemic, some of the data that CORE uses to calculate our metrics were impacted. When looking at this dashboard, keep in mind that metrics for 2019-20 and 2020-21 might not be comparable to previous years.";
    return (
      <div className={classes.root}>
        <Grid
          container
          className={classes.root2}
          onClick={this.handleInfoBarOpen.bind(this)}
        >
          <Grid container>
            {open && (
              <Grid container xs={12} className={classes.lowerContainer}>
                <Grid
                  container
                  item
                  xs={12}
                  className={classes.openContainer}
                  justifyContent={"center"}
                >
                  <Hidden mdDown>
                    <Grid
                      item
                      wrap={"nowrap"}
                      xs={8}
                      className={classes.italicText}
                    >
                      {text}
                    </Grid>
                    <Grid
                      item
                      wrap={"nowrap"}
                      xs={8}
                      className={classes.italicText}
                    >
                      <Link
                        href="https://edanalytics.sharefile.com/home/shared/fo5d323e-3d39-46c4-9a19-cfd599617b03"
                        target="_blank"
                        color="inherit"
                      >
                        Assessment Status and Growth Reports (Sharefile Users
                        only)
                      </Link>
                    </Grid>
                  </Hidden>

                  <Hidden lgUp>
                    <Grid
                      item
                      wrap={"nowrap"}
                      xs={10}
                      className={classes.italicText}
                    >
                      {text}
                    </Grid>
                  </Hidden>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>

        <MobileMenu
          close={this.handleMobileMenuClose.bind(this)}
          showMenu={this.state.showMobile}
          path={path}
        />
        <div className={classes.appBarStyles}>
          <Toolbar>
            {/*Hamburger Menu for sidebar:*/}
            <IconButton
              className={classes.menuButton}
              // color="inherit"
              aria-label="Open drawer"
              onClick={handleDrawerToggle}
            >
              <MenuIcon style={{ color: "white" }} />
            </IconButton>

            <div className={classes.sectionDesktop}>
              <MenuBar toggleEdit={toggleEdit} path={path} />
            </div>

            <div className={classes.grow} />

            <div className={classes.sectionMobile}>
              <IconButton
                aria-haspopup="true"
                onClick={this.handleMobileMenuOpen}
                // color="inherit"
              >
                <MoreIcon style={{ color: "white" }} />
              </IconButton>
            </div>
          </Toolbar>
        </div>
        {/*{renderMobileMenu}*/}
      </div>
    );
  }
}

PrimaryAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(PrimaryAppBar);
