import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import { getMenu } from "../Menu/actions";
import logo from "../assets/images/coreLogo.png";
import name from "../assets/images/coreName.png";
import Grid from "@material-ui/core/Grid";
import "../app.css";
class LoadingScreen extends Component {
  constructor(props) {
    super(props);
    const { history, fetchMenu, selected } = this.props;
    const pathname = props.location.pathname;
    const res_path = pathname.substring(8, pathname.length);
    const isPublic = res_path.split("/").reduce((isPublic, curr) => {
      if (curr === "public") {
        return true;
      }
      return isPublic;
    }, false);
    fetchMenu(res_path, history, isPublic, selected);
  }

  render() {
    return (
      <Grid
        justifyContent={"center"}
        alignItems={"center"}
        container
        direction={"column"}
        style={{ height: "100%", marginTop: "10%", width: "100%" }}
      >
        <Grid item>
          <img
            src={logo}
            alt={"spinning core logo"}
            style={{
              height: "40vh",
              width: "40vh",
            }}
            className={"rotatingImage"}
          />
        </Grid>
        <Grid item>
          <img
            src={name}
            alt={"core name"}
            style={{
              height: "auto",
              width: "50vh",
            }}
          />
        </Grid>
      </Grid>
    );
  }
}

const mapState = (state) => {
  const {
    menu: { selected },
  } = state;
  return {
    selected: selected || {},
  };
};

const mapDispatch = (dispatch) => {
  return {
    fetchMenu: (path, history, isPublic, selected) =>
      dispatch(getMenu(path, history, isPublic, selected)),
  };
};

export default connect(mapState, mapDispatch)(LoadingScreen);
