import TableCell from "@material-ui/core/TableCell/TableCell";
import Typography from "@material-ui/core/Typography/Typography";
import Grid from "@material-ui/core/Grid/Grid";
import React from "react";
import Table from "@material-ui/core/Table/Table";
import TableHead from "@material-ui/core/TableHead/TableHead";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableBody from "@material-ui/core/TableBody/TableBody";
import Paper from "@material-ui/core/Paper";
import { columns, footers } from "./constants";
import { addCommas } from "../utils";

export const Title = ({ district, school }) => {
  const info = [
    {
      title: "District",
      text: district.text,
    },
    {
      title: "School Name",
      text: school.text,
    },
    {
      title: "School Code",
      text: school.code,
    },
    {
      title: "Address",
      text: "Address Inc",
    },
  ];
  return (
    <Grid container style={{ marginBottom: "80px", paddingLeft: "100px" }}>
      <Grid item xs={12} style={{ marginBottom: "20px" }}>
        <Typography variant={"h5"}>Core Index</Typography>
      </Grid>
      {info.map((curr) => (
        <Grid container xs={3} key={curr.title} direction={"column"}>
          <Grid item>
            <Typography>{curr.title}:</Typography>
          </Grid>
          <Grid item>
            <Typography>{curr.text}</Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export const parseTable = (
  table,
  metrics,
  years,
  measureConstants,
  subgroupConstants,
  baseSubgroup,
  pageType
) => {
  const { keyOrder, keys, columnType, demographic, title } = table;

  if (demographic) {
    const demographics = metrics["demo_pct"]
      ? metrics["demo_pct"][years[1]]
      : {};
    return createDemographicTable(demographics || {}, title, subgroupConstants);
  }
  const currColumns = columns[columnType] || [];
  const data = keys.map((measureCode) => {
    // const measureCode = keys[measureId]
    const measureConstant = measureConstants[measureCode];
    const measureData = metrics[measureCode];

    /**
     * Added in the cohort _ag to have the proper year attached.
     */
    return currColumns.map((column) => {
      const measConst =
        measureCode === "grad4y" || "cohort_ag"
          ? {
              ...measureConstant,
              subtext: `${measureConstant["subtext"]} ${
                years[1].split("-")[column.split("-")[1] === "CURR" ? 1 : 0]
              }`,
            }
          : measureConstant;
      return generateData(
        column,
        measureData,
        measConst,
        years,
        baseSubgroup,
        subgroupConstants,
        pageType,
        measureCode
      );
    });
  });
  return {
    title,
    columns: currColumns.map((column) =>
      getHeader(column, subgroupConstants, years[1])
    ),
    data,
    showFooter: true,
  };
};

export const renderTable = (table, index, type) => {
  return (
    <Paper>
      <Typography variant={"h4"}>{table.title}</Typography>
      <Table key={`table:${index}`}>
        <TableHead>
          <TableRow key={`table-head:${index}`}>
            {table.columns.map((head, hIndex) => (
              <TableCell key={`table-head-cell:${index}/${hIndex}`}>
                <Typography>{head}</Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {table.data.map((row, rIndex) => (
            <TableRow key={`table-row:${index}/${rIndex}`}>
              {row.map((cell, cIndex) =>
                renderCell(cell, `table-cell:${index}/${rIndex}/${cIndex}`)
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Grid container>
        {table.showFooter &&
          footers[type].map((cell) => (
            <Grid xs={4} container item>
              <Grid style={{ backgroundColor: cell.color }} xs={2} item />
              <Grid xs={10} item>
                <Typography>{cell.text}</Typography>
              </Grid>
            </Grid>
          ))}
      </Grid>
    </Paper>
  );
};

export const trimTable = (table) => {
  const { data, title } = table;
  const dontTrim = [
    "Racial/Ethnic Subgroup Performance: Social-Emotional & Culture-Climate Domain",
  ].includes(title);
  if (data.length === 0) {
    return {
      ...table,
      data: [],
    };
  }
  const trimData =
    data.reduce((array, row) => {
      const keep = row.reduce((keep, curr, index) => {
        if (index > 0 && curr !== "NO DATA") {
          return true;
        }
        return keep;
      }, false);
      if (keep || (dontTrim && row[0] !== "NO DATA")) {
        array.push(row);
      }
      return array;
    }, []) || [];

  //if (!trimData.length) {
  //	return false
  //}

  return {
    ...table,
    data: trimData,
  };
};

export const renderCell = (cell, key) => {
  if (cell === "NO DATA") {
    return (
      <TableCell key={key}>
        <Typography>No Data.</Typography>
      </TableCell>
    );
  }
  switch (cell.type) {
    case "LCAP PERFORMANCE":
      return (
        <TableCell style={{ backgroundColor: cell.color }} key={key}>
          <Typography>{cell.text}</Typography>
        </TableCell>
      );
    case "INDEX CHANGE":
      return (
        <TableCell style={{ backgroundColor: cell.color }}>
          <Typography>{cell.text}</Typography>
        </TableCell>
      );
    case "DEMOGRAPHIC":
      return (
        <TableCell>
          <Grid justifyContent={"space-between"} container>
            <Grid item>{cell.group}</Grid>
            <Grid item>{cell.text}</Grid>
          </Grid>
        </TableCell>
      );
    case "NAME":
      return (
        <TableCell key={key}>
          <Typography>{cell.text}</Typography>
        </TableCell>
      );
    case "INDEX LEVEL":
      return (
        <TableCell key={key} style={{ backgroundColor: cell.color }}>
          <Typography variant={"h6"}>{cell.text}</Typography>
          <Typography>out of 10</Typography>
        </TableCell>
      );
    case "SUBGROUP":
      return (
        <TableCell key={key} style={{ backgroundColor: cell.color }}>
          <Typography variant={"h6"}>{cell.text}</Typography>
          <Typography>{cell.subtext}</Typography>
        </TableCell>
      );
    case "METRIC RESULT":
      return (
        <TableCell key={key}>
          <Grid container>
            <Grid style={{ backgroundColor: "red" }} item xs={1}></Grid>
            <Grid item xs={2}>
              <Typography variant={"h6"}>{cell.text}---</Typography>
              <Typography>{cell.subtext}</Typography>
            </Grid>
          </Grid>
        </TableCell>
      );
    case "METRIC IMPROVE":
    case "METRIC CHANGE":
      return (
        <TableCell key={key}>
          <Typography variant={"h6"}>{cell.text}</Typography>
        </TableCell>
      );
    case "LOWEST PERFORMING":
      return (
        <TableCell key={key}>
          <Grid container>
            <Grid item>
              <Typography variant={"h6"}>{cell.text}</Typography>
            </Grid>
            <Grid item>
              <Typography>({cell.group})</Typography>
            </Grid>
          </Grid>
          <Typography>{cell.subtext}</Typography>
        </TableCell>
      );
    default:
      return <TableCell key={key} />;
  }
};

const getHeader = (column, subgroupConstants, year) => {
  const years = year.split("");
  const prevYear = years.slice(0, 5);
  const nextYear = years.slice(-2);
  const currYear = (prevYear + nextYear).split(",").join("");
  const lastYears = currYear.split("-");
  const prevLastYear = parseInt(lastYears[0]) - 1;
  const nextLastYear = parseInt(lastYears[1]) - 1;
  const lastYear = prevLastYear.toString() + "-" + nextLastYear.toString();
  const split = column.split("-");
  const { name, alias } = subgroupConstants[split[2]] || {};
  switch (split[0]) {
    case "NAME":
      return { text: "", maxWidth: 200 };
    case "INDEX LEVEL":
      // return {text:`Index Level ${split[1] === "CURR" ? years[1] : years[0]}`, maxWidth: '150px'}
      return {
        text: `Index Level ${split[1] === "CURR" ? currYear : lastYear}`,
        maxWidth: "150px",
      };

    case "INDEX CHANGE":
      return {
        text: `Change in Index Level from ${lastYear} to ${currYear}`,
        maxWidth: "150px",
      };
    case "SUBGROUP": {
      const show = !!split[3];
      const code = show ? `(${alias || split[2]})` : "";
      return { text: `${name} ${code}`, maxWidth: "160px" };
    }
    case "METRIC RESULT":
      return {
        text: `Metric Result ${split[1] === "CURR" ? currYear : lastYear}`,
        maxWidth: "150px",
      };
    case "METRIC IMPROVE":
      return {
        text: `Change needed to improve ${split[2]} Index ${
          split[2] === "1" ? "Level" : "Levels"
        }`,
        maxWidth: "150px",
      };
    case "METRIC CHANGE":
      return {
        text: `Change in Metric Performance from ${lastYear} to ${currYear}`,
        maxWidth: "150px",
      };
    case "LOWEST PERFORMING":
      return {
        text: `Lowest Performing Racial/Ethnic Subgroup`,
        maxWidth: "160px",
      };
    case "LCAP PERFORMANCE":
      return { text: "LCAP Color Status", maxWidth: "160px" };
    default:
      return { text: "", maxWidth: "200px" };
  }
};

const generateData = (
  column,
  data,
  measureConstant,
  years,
  baseSubgroup,
  subgroupConstants,
  pageType,
  measureCode
) => {
  const split = column.split("-");
  const { percent } = measureConstant;
  const percentSymbol = percent ? "%" : "";
  if (!data) {
    return "NO DATA";
  }
  const year = split[1] === "CURR" ? years[1] : years[0];
  const otherYear = split[1] === "CURR" ? years[0] : years[1];
  const currData = data[year] || {};
  const otherData = data[otherYear] || {};
  const { subtext } = measureConstant;
  switch (split[0]) {
    case "LCAP PERFORMANCE": {
      return currData[baseSubgroup]
        ? currData[baseSubgroup]["lcap_color_overall"]
          ? {
              type: "LCAP PERFORMANCE",
              text: currData[baseSubgroup]["lcap_color_overall"],
              color: currData[baseSubgroup]["lcap_color_overall"],
              code: measureCode,
            }
          : "NO DATA"
        : "NO DATA";
    }
    case "NAME": {
      const { lcap_text, text } = measureConstant;
      const display =
        pageType === "LCAP" ? lcap_text || text || "" : text || "";
      return text
        ? {
            type: "NAME",
            text: display,
            code: measureCode,
          }
        : "NO DATA";
    }
    case "INDEX LEVEL": {
      const info = currData[baseSubgroup] || {};
      const { index_level, index_level_color } = info;
      return index_level
        ? {
            type: "INDEX LEVEL",
            text: index_level,
            color: index_level_color,
            code: measureCode,
          }
        : "NO DATA";
    }
    case "INDEX CHANGE": {
      const index_level = otherData[baseSubgroup]
        ? otherData[baseSubgroup]["index_level"]
        : undefined;
      const pastIndex = currData[baseSubgroup]
        ? currData[baseSubgroup]["index_level"]
        : undefined;
      const diff =
        index_level && pastIndex ? index_level - pastIndex : undefined;
      return diff !== undefined
        ? {
            type: "INDEX CHANGE",
            text: Math.abs(diff),
            color: diff === 0 ? null : diff > 0 ? "green" : "red",
            code: measureCode,
          }
        : "NO DATA";
    }
    case "METRIC RESULT": {
      const meas_rate = currData[baseSubgroup]
        ? currData[baseSubgroup]["meas_rate"]
        : undefined;
      return meas_rate !== undefined
        ? {
            type: "METRIC RESULT",
            text: `${meas_rate}${percentSymbol}`,
            subtext,
            color: currData[baseSubgroup]["index_level_color"],
            lcapColor: currData[baseSubgroup]["lcap_color_status"],
            code: measureCode,
          }
        : "NO DATA";
    }
    case "METRIC CHANGE": {
      const meas_rate =
        otherData[baseSubgroup] !== undefined
          ? otherData[baseSubgroup]["meas_rate"]
          : undefined;
      const pastMeas =
        currData[baseSubgroup] !== undefined
          ? currData[baseSubgroup]["meas_rate"]
          : undefined;
      const meas_rate_change =
        pageType === "LCAP"
          ? otherData[baseSubgroup]
            ? otherData[baseSubgroup]["meas_rate_change"] !== undefined
              ? otherData[baseSubgroup]["meas_rate_change"]
              : undefined
            : undefined
          : meas_rate !== undefined && pastMeas !== undefined
          ? meas_rate - pastMeas
          : undefined;
      return meas_rate_change !== undefined && meas_rate_change !== null
        ? {
            type: "METRIC CHANGE",
            text: `${meas_rate_change.toFixed(1)}${percentSymbol}`,
            lcapColor: otherData[baseSubgroup]["lcap_color_change"],
            code: measureCode,
          }
        : "NO DATA";
    }
    case "METRIC IMPROVE": {
      const improve = split[2];
      const needed = currData[baseSubgroup]
        ? currData[baseSubgroup][`meas_rate_change_needed_${improve}`]
        : undefined;
      return needed
        ? {
            type: "METRIC IMPROVE",
            text: `${(Math.round(100 * needed) / 100.0).toFixed(
              1
            )}${percentSymbol}`,
            code: measureCode,
          }
        : "NO DATA";
    }
    case "LOWEST PERFORMING": {
      const { reverse } = measureConstant;
      const lowest = Object.keys(currData).reduce(
        (lowest, group) => {
          if (
            typeof subgroupConstants[group]["racial_or_ethnic"] != "undefined"
          ) {
            if (!subgroupConstants[group]["racial_or_ethnic"]) {
              return lowest;
            }
          }
          const { meas_rate, index_level_color } = currData[group];
          if (reverse) {
            return meas_rate !== undefined && meas_rate > lowest.measure_rate
              ? {
                  measure_rate: meas_rate,
                  group: subgroupConstants[group]["alias"] || group,
                  color: index_level_color,
                  code: measureCode,
                }
              : lowest;
          } else {
            return meas_rate !== undefined && meas_rate < lowest.measure_rate
              ? {
                  measure_rate: meas_rate,
                  group: subgroupConstants[group]["alias"] || group,
                  color: index_level_color,
                  code: measureCode,
                }
              : lowest;
          }
        },
        {
          measure_rate: reverse ? -10000 : 10000,
          group: "",
          code: measureCode,
        }
      );
      return lowest.group === ""
        ? "NO DATA"
        : {
            type: "LOWEST PERFORMING",
            text: `${lowest.measure_rate}${percentSymbol}`,
            group: lowest.group,
            subtext,
            color: lowest.color,
            code: measureCode,
          };
    }
    case "SUBGROUP": {
      const group = split[2];
      if (!currData[group]) {
        return "NO DATA";
      }
      const { meas_rate, index_level_color } = currData[group];
      return {
        type: "SUBGROUP",
        text: `${meas_rate}${percentSymbol}`,
        subtext,
        color: index_level_color,
        code: measureCode,
      };
    }
    default:
      return "NO DATA";
  }
};
const createDemographicTable = (demographics, title, subgroups) => {
  const totalStudents = demographics["ALL"]
    ? demographics["ALL"]["numerator"] || "0"
    : 0;
  const res = {
    title,
    columns: [
      {
        text: "Percent of School Population (number of students)",
        maxWidth: "33%",
      },
      { text: "", maxWidth: "33%" },
      {
        text: (
          <b
            style={{
              paddingTop: "0px",
              color: "rgba(61,114,135,.8)",
              fontSize: "14px",
              fontWeight: "900",
              padding: "12px",
            }}
          >
            Total Students:{" "}
            <b style={{ fontSize: "2rem" }}>{addCommas(`${totalStudents}`)}</b>
          </b>
        ),
        maxWidth: "33%",
      },
    ],
    data: [],
    showFooter: false,
  };
  const elAliases = {
    EL: "EL + <4 Yrs RFEP",
    ELP: "Curr EL",
    ELRFEP: "EL-RFEP",
  };
  const elNameAlias = {
    ELRFEP: "Reclassified Fluent English Proficient Students",
  };
  const demoTableColumns = [
    ["EL", "AA", "HI"],
    ["ELP", "AI", "MR"],
    ["ELRFEP", "AS", "PI"],
    ["FST", "FI", "WH"],
    ["HMLS", "", ""],
    ["SD", "", ""],
    ["SWD", "", ""],
  ];

  res.data = demoTableColumns.map((row) => {
    return row.map((group, index) => {
      if (!group) {
        return {};
      }
      const { numerator, meas_rate } = demographics[group] || {
        meas_rate: 0,
        numerator: 0,
      };
      const { name, alias } = subgroups[group];
      return {
        group: elAliases[group] || alias || group,
        type: "DEMOGRAPHIC",
        text: `${meas_rate}% (${addCommas(`${numerator}`)} Students)`,
        name: elNameAlias[group] || name,
        w: index ? 3 : 5,
      };
    });
  });
  return res;
};
