import {global_loading} from "../Loading/Loading";
import axios from "axios";

export const FETCH_MAP_DATA = "FETCH_MAP_DATA";

const API = process.env.REACT_APP_API;

export const fetchMapData =
  (network_code, district_code, level, school_year, student_groups, subject) =>
  (dispatch) => {
    const parsedYear = school_year
      .split("-")
      .reduce((array, string_it) => {
        array.push(string_it.slice(-2));
        return array;
      }, [])
      .join("");
    dispatch(
      global_loading({
        id: "loading1",
        value: true,
      })
    );
    try {
      axios
        .get(
          API +
            `/map/get_data?district_code=${district_code}&network_code=${network_code}&level=${level}&school_year=${parsedYear}&student_groups=${student_groups}&subject=${subject}`
        )
        .then((data) => {
          dispatch(
            global_loading({
              id: "loading1",
              value: false,
            })
          );
          dispatch({ type: FETCH_MAP_DATA, payload: data.data });
        });
    } catch (err) {
      console.log(err);
    }
  };
