export const tableStyling = {
  firstHeader: {
    background: "rgba(1, 61, 84, 0.1)",
    color: "rgba(61,114,135,.8)",
    borderBottom: "2px solid #BBCAD0",
    boxShadow: "none",
    fontWeight: "900",
    fontSize: "14px",
    marginRight: "5px",
    width: "200px",
  },
  firstData: {
    color: "rgba(61,114,135,.8)",
    fontWeight: "900",
    fontSize: "14px",
    marginRight: "5px",
    width: "200px",
  },
  title: {
    borderBottom: "2px solid #BBCAD0",
    color: "rgba(61,114,135,.8)",
    fontWeight: "900",
    fontSize: "14px",
    marginRight: "5px",
    width: "200px",
    borderLeft: "2px solid #BBCAD0",
    backgroundColor: "#FFFFFF !important",
  },
  year: {
    color: "rgba(61,114,135,.8)",
    fontWeight: "900",
    fontSize: "24px",
    marginRight: "5px",
    width: "200px",
    borderLeft: "2px solid #BBCAD0",
  },
  dataRow: {
    height: "70px",
    padding: "5px",
    color: "rgba(0,0,0,.7)",
    fontWeight: "900",
    fontSize: "24px",
    position: "relative",
    borderLeft: "2px solid #BBCAD0",
  },
  darkHeader: {
    borderLeft: "2px solid #BBCAD0",
    background: "rgba(1, 61, 84, 0.1)",
    color: "rgba(61,114,135,.8)",
    borderBottom: "2px solid #BBCAD0",
    fontWeight: "900",
    fontSize: "14px",
    marginRight: "5px",
    width: "200px",
  },
  darkHeaderFirst: {
    background: "rgba(1, 61, 84, 0.1)",
    color: "rgba(61,114,135,.8)",
    borderBottom: "2px solid #BBCAD0",
    fontWeight: "900",
    fontSize: "14px",
    marginRight: "5px",
    width: "200px",
  },
  district: {
    color: "rgba(61,114,135,.8)",
    fontWeight: "900",
    fontSize: "14px",
    marginRight: "5px",
    minWidth: "200px",
  },
  school: {
    color: "rgba(61,114,135,.8)",
    fontWeight: "900",
    fontSize: "14px",
    marginRight: "5px",
    minWidth: "250px",
    borderLeft: "2px solid #BBCAD0",
  },
};
