import React from "react";
import Grid from "@material-ui/core/Grid";
import Select, { components } from "react-select";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
export const OptionsCell = ({}) => {
  const type = "subgroups";

  return (
    <TableCell>
      <Grid conatiner>
        <Grid item xs={12}>
          <Button style={{ width: "100%", height: "100%" }}>+</Button>
        </Grid>
      </Grid>
    </TableCell>
  );
};
