import React, { Component } from "react";
import { compose } from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import connect from "react-redux/es/connect/connect";

const styles = (theme) => ({});

class AnalyticsDashboard extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <div>Base for Analytics Dashboard</div>;
  }
}

const mapState = (state) => {
  return {};
};

const mapDispatch = (dispatch) => {
  return {};
};

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapState, mapDispatch)
)(AnalyticsDashboard);
