import React from "react";
import { echartHelper, seriesFormatter } from "../Helpers/EchartHelpers";

/**
 *  Creates an echart for a stacked barchart
 * @param props.height: height of the chart
 * @param props.id: id for the element we are referring to
 * @param props.data: data that is going to be displayed as well as how it is going to be displayed
 * @returns {JSX.Element}
 * @author Justin Keeling & Alex Harry
 */
export const LineChart = (props) => {
  const {
    id,
    height,
    data: { xAxisLabels, title },
    data,
  } = props;

  const seriesDefaultFormat = {
    data: [],
    type: "line",
    clip: true,
    symbol: "circle",
    symbolSize: 12,
    lineStyle: {
      width: 4,
      type: "solid",
    },
    label: {
      show: true,
      position: "top",
      color: "#5676AC",
      fontSize: 12,
      fontWeight: "bold",
      formatter: "{c}%",
    },
  };

  const seriesObject = seriesFormatter({ data, seriesDefaultFormat });
  const option = {
    color: "#5676AC",
    xAxis: {
      type: "category",
      data: xAxisLabels,
      axisLabel: {
        fontSize: 12,
        fontWeight: "bold",
        color: "#5676AC",
      },
      axisTick: {
        show: false,
      },
    },
    grid: {
      left: "20%",
    },
    yAxis: {
      type: "value",
      max: 100,
      min: 0,
      interval: 25,
      name: "Percent of Students",
      nameLocation: "center",
      nameGap: 45,
      nameTextStyle: {
        fontSize: 12,
      },
      axisLabel: {
        formatter: "{value}%",
      },
    },
    title: {
      text: title,
      left: "center",
      textStyle: {
        fontSize: 15,
      },
    },
    series: seriesObject,
  };
  return echartHelper({ option, id, height, width: "100%" });
};
