import { flatten, sort } from "./helpers";

export const getDistrictMenu = ({ menu, network, type }) => {
  const networkOverall = [
    "5x5",
    "schoolreport",
    "metrictrends",
    "gaptrends",
  ].includes(type.subtype)
    ? []
    : [
        {
          code: "overall",
          text: "Network Overall",
        },
      ];
  switch (type.type) {
    case "academic": {
      switch (type.subtype) {
        default:
          return networkOverall.concat(
            sort(flatten(menu["districts"][network]), 0)
          );
      }
    }
    case "prelim":
    case "linkedlearning": {
      const hsDistricts = Object.keys(menu["districts"][network]).reduce(
        (obj, key) => {
          const curr = menu["districts"][network][key];
          if (curr["schoolLevels"]["HS"] !== undefined) {
            obj[key] = curr;
          }
          return obj;
        },
        {}
      );
      return sort(flatten(hsDistricts), 0);
    }
    default:
      return networkOverall.concat(
        sort(flatten(menu["districts"][network]), 0)
      );
  }
};
