import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

const parseOverview = (overview) => {
  return Object.keys(overview).reduce((object, ind) => {
    const currTable = overview[ind];
    const { title, keys, keyOrder, columnType } = currTable;
    const measureList = keyOrder.map((pk) => keys[pk]);
    object[ind] = {
      title,
      measureList,
    };
    return object;
  }, {});
};

export default class OverviewUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: "core",
      currentOverview: {},
    };
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { lcapOverview, coreIndex, measures } = this.props;
    const { currentOverview, selected } = this.state;
    const ready =
      !!Object.keys(lcapOverview).length || !!Object.keys(coreIndex).length;
    const empty = !Object.keys(currentOverview).length;
    if (prevState["selected"] !== selected || (empty && ready)) {
      const curr = selected === "core" ? coreIndex : lcapOverview;
      const overview = parseOverview(curr);
      this.setState({
        currentOverview: overview,
      });
    }
  }
  overviewSelect = (selected) => {
    this.setState({ selected });
  };
  render() {
    const { selected, currentOverview } = this.state;
    const { lcapOverview, coreIndex, measures } = this.props;
    return (
      <Paper>
        <Grid container>
          <Grid item>
            <Button
              onClick={() => {
                this.overviewSelect("core");
              }}
            >
              Core Index
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => {
                this.overviewSelect("lcap");
              }}
            >
              LCAP Overview
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}
