import React, { Component } from "react";
import { compose } from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import connect from "react-redux/es/connect/connect";
import Grid from "@material-ui/core/Grid";
import Plot from "react-plotly.js";
import { onDemandHelpers } from "./helpers";
import CreateTable from "../StyledComponents/Table/TableList";
import TableTitle from "../StyledComponents/Table/TitleTable";
import Card from "../StyledComponents/Card/Card";
import CardHeader from "../StyledComponents/Card/CardHeader";
import CardBody from "../StyledComponents/Card/CardBody";
import tableStyle from "../assets/jss/core-dashboard/components/tableStyle";
import { PlotData } from "./PlotData";
import LoadingGrid from "../StyledComponents/Grid/LoadingGrid";
import { tableStyling } from "./plotDataStyles";
import { addCommas } from "../utils";

class MetricTrends extends Component {
  render() {
    const {
      distance,
      subgroup,
      metricTrends,
      classes,
      network,
      district,
      school,
      measureInfo,
      parsedName,
      parsedDistrict,
      parsedNetwork,
      loading,
      measure,
    } = this.props;
    const college =
      this.props.match.path === "/dashboard/shiny/prelim/metrictrends";
    const { generateMetricTrendsData } = onDemandHelpers;
    const cols = [school.text, parsedDistrict, parsedNetwork, "Overall"];
    const years = Object.keys(
      Object.keys(metricTrends).reduce((years, key) => {
        Object.keys(metricTrends[key]).forEach((yr) => {
          years[yr] = "";
        });
        return years;
      }, {}) || {}
    ).sort((a, b) => {
      const cA = a.split("-")[1];
      const cB = b.split("-")[1];
      const c = parseInt(cA) - parseInt(cB);
      return c;
    });
    const noData = !years.length;
    const measureName = measureInfo["name"] || "";
    const hideFooter = college || distance ? [true, true] : [false, true, true];
    const titles =
      college || distance
        ? [
            `Measure Rate Comparison Among ${parsedName}`,
            `Numerator And Denominator Comparison Among ${parsedName}`,
          ]
        : [
            `Index Level Comparison Among ${parsedName}`,
            `Measure Rate Comparison Among ${parsedName}`,
            `Numerator And Denominator Comparison Among ${parsedName}`,
          ];
    const tableTypes =
      college || distance
        ? ["meas_rate", "numerator"]
        : ["index_level", "meas_rate", "numerator"];
    const { subtext, percent } = measureInfo || {};
    const percentSymbol = percent || college ? "%" : "";
    const tables = tableTypes.map((curr) => {
      return cols.map((col) =>
        [
          {
            type: "NAME",
            text: col,
          },
        ].concat(
          years.map((year) => {
            const currData = (metricTrends[col] || {})[year] || {};
            const {
              measure_rate,
              numerator,
              denominator,
              index_level_color,
              index_level,
            } = currData;
            switch (curr) {
              case "index_level": {
                return index_level
                  ? {
                      type: "INDEX LEVEL NO WIDTH",
                      text: index_level,
                      color: index_level_color,
                    }
                  : "NO DATA";
              }
              case "meas_rate": {
                return measure_rate !== undefined
                  ? {
                      type: "METRIC RESULT",
                      text: `${measure_rate}${distance ? "" : percentSymbol}`,
                      subtext,
                    }
                  : "NO DATA";
              }
              case "numerator": {
                return numerator || denominator
                  ? {
                      type: "NUMERATOR",
                      numerator,
                      denominator,
                      distance,
                      measure,
                    }
                  : "NO DATA";
              }
            }
          })
        )
      );
    });
    const { data, minY, maxY } = generateMetricTrendsData({
      metricTrends,
      years,
      distance,
      parsedName,
      subMenuMeasure:measure
    });
    const dist = distance ? 20 : 10;
    const range = [minY - dist, maxY + dist];
    const tickvals = Array.apply(
      null,
      new Array(Math.round(Math.abs(range[0] - range[1]) / 10 + 1))
    ).map((_, index) => {
      const val = Math.round((minY + 10 * index) / 5) * 5;
      return val % 10 ? val + 5 : val;
    });
    const ticktext = tickvals.map((val) => `<b>         ${val}</b>`);
    const startYear = (years[0] || "-").split("-")[0];
    const endYear = (years[years.length - 1] || "-").split("-")[1];
    const columns = [
      {
        title: "Name",
        field: "name",
        cellStyle: tableStyling.firstData,
        headerStyle: tableStyling.firstHeader,
      },
      {
        title: "Year",
        field: "year",
        cellStyle: tableStyling.year,
        headerStyle: tableStyling.darkHeader,
      },
      {
        grouping: false,
        title: distance ? "Average Distance From Standards" : "Measure Rate",
        field: "measure_rate",
        cellStyle: tableStyling.dataRow,
        headerStyle: tableStyling.title,
        render: (rowData) =>
          !rowData.measure_rate ? (
            <p style={{ margin: 5, color: "#d1d1d1" }}>No Data</p>
          ) : (
            `${rowData.measure_rate}${distance ? "" : "%"}`
          ),
      },
      {
        grouping: false,
        title: distance
          ? "Total Distance From Standards"
          : "Number of Students",
        field: "numerator",
        cellStyle: tableStyling.dataRow,
        headerStyle: tableStyling.title,
        render: (rowData) =>
          !rowData.numerator ? (
            <p style={{ margin: 5, color: "#d1d1d1" }}>No Data</p>
          ) : (
            `${addCommas(`${rowData.numerator}`)}`
          ),
      },
      {
        grouping: false,
        title: "Total Students",
        field: "denominator",
        cellStyle: tableStyling.dataRow,
        headerStyle: tableStyling.title,
        render: (rowData) =>
          !rowData.denominator ? (
            <p style={{ margin: 5, color: "#d1d1d1" }}>No Data</p>
          ) : (
            `${addCommas(`${rowData.denominator}`)}`
          ),
      },
    ];
    const tableData = Object.keys(metricTrends).reduce((array, name) => {
      const dataRows = Object.keys(metricTrends[name]).map((year) => {
        const { denominator, measure_rate, numerator } =
          metricTrends[name][year];
        const splitYear = year.split("-");
        const parsedYear = `${splitYear[0].charAt(2)}${splitYear[0].charAt(
          3
        )}-${splitYear[1].charAt(2)}${splitYear[1].charAt(3)}`;
        return {
          name,
          year: parsedYear,
          measure_rate,
          numerator,
          denominator,
        };
      });
      return array.concat(dataRows);
    }, []);
    const fileName = `MetricTrends-${subgroup}-${years[1]}-${measureName}`;
    const yLabel =
      `Measure Rate in ${measureName} among ${parsedName} from ${startYear}-${endYear}`
        .split(" ")
        .reduce(
          (s, w) => {
            const wLen = w.length;
            if (s.chars + wLen > 40) {
              s.label += `<br>${w}`;
              s.chars = wLen;
            } else {
              s.label += ` ${w}`;
              s.chars += wLen;
            }
            return s;
          },
          {
            label: "",
            chars: 0,
          }
        ).label;
    return (
      <Grid
        style={{ width: "95%" }}
        justifyContent={"center"}
        container
        direction={"row"}
      >
        <TableTitle
          school={school}
          district={district}
          hideInfo
          pageTitle={"Metric Trends"}
        />
        <Grid item xs={12}>
          <Card>
            <CardHeader className={classes.gradientBackground} color="primary">
              <h4 className={classes.cardTitleBlue}>Overview</h4>
            </CardHeader>
            <CardBody
              style={{
                paddingTop: "0px",
                width: "100%",
                marginTop: "10px",
                textAlign: "center",
              }}
            >
              <>
                <p className={classes.metricSubText}>
                  Due to the lack of standardized testing during the COVID-19
                  pandemic, ES/MS growth was not produced from 2019-20 to
                  2021-22, and HS growth was not produced from 2019-20.
                </p>
                {college ? (
                  <p
                    className={classes.metricSubText}
                    style={{ paddingTop: 20 }}
                  >
                    College data last processed by the NSC on 2/25/2018 and do
                    not include full spring 2018 college enrollment. 2 year
                    persistence rate in 2017 and 1 year enrollment in 2018 may
                    be incomplete.
                  </p>
                ) : (
                  <p className={classes.metricSubText}>
                    This display shows a three year trend for all CORE measures.
                    For comparison, the values for the entire CORE data
                    collaborative are displayed.
                  </p>
                )}
              </>
              {loading ? (
                <LoadingGrid />
              ) : noData ? null : (
                <div
                  className={classes.plot}
                  style={{ width: "100%", margin: "auto" }}
                >
                  <Plot
                    responsive={true}
                    autosize={true}
                    useResizeHandler={true}
                    style={{
                      width: "100%",
                      margin: "auto",
                    }}
                    data={data}
                    layout={{
                      font: {
                        color: "rgb(9, 75, 101)",
                        size: 12,
                      },
                      legend: {
                        bgcolor: "rgba(0,0,0,0)",
                        orientation: "h",
                        yanchor: "top",
                        xanchor: "center",
                        y: 1.25,
                        x: 0.5,
                      },
                      yaxis: {
                        automargin: true,
                        title: yLabel,
                        range,
                        tickvals,
                        ticktext,
                        linewidth: 6,
                        linecolor: "rgb(9, 75, 101)",
                      },
                      xaxis: {
                        title: "<b>Year</b>",
                        tickvals: years,
                        ticktext: years.map((year) => {
                          const splitYear = year.split("-");
                          return `<b>${splitYear[0].charAt(
                            2
                          )}${splitYear[0].charAt(3)}-${splitYear[1].charAt(
                            2
                          )}${splitYear[1].charAt(3)}</b>`;
                        }),
                        linewidth: 6,
                        linecolor: "rgb(9, 75, 101)",
                      },
                    }}
                  />
                </div>
              )}
            </CardBody>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <PlotData
            loading={loading}
            noData={noData}
            fileName={fileName}
            classes={classes}
            data={tableData}
            columns={columns}
          />
        </Grid>
        {tables.map((t, index) => (
          <Grid key={`CORE-INDEX-TABLE:${index}`} item xs={12}>
            <CreateTable
              loading={loading}
              data={t}
              header={["Name"].concat(
                years.map((year) => {
                  const splitYear = year.split("-");
                  return `${splitYear[0].charAt(2)}${splitYear[0].charAt(
                    3
                  )}-${splitYear[1].charAt(2)}${splitYear[1].charAt(3)}`;
                })
              )}
              hideFooter={hideFooter[index]}
              title={titles[index]}
              index={index}
            />
          </Grid>
        ))}
      </Grid>
    );
  }
}

const findInMenu = (array, key) => {
  const res = {
    code: "",
    text: "",
  };
  if (!array) {
    return res;
  }
  return array.reduce((found, curr) => {
    if (curr.code === key) {
      found = curr;
    }
    return found;
  }, res);
};

const mapState = (state) => {
  const { selected, menuObject } = state.menu;
  const {
    constants: { measures, subgroups, collegeMeasures },
  } = state;
  const coreMeasures = {
    ...measures,
    ...collegeMeasures,
  };
  const network = findInMenu(menuObject["Network"], selected["Network"]);
  const district = findInMenu(menuObject["District"], selected["District"]);
  const districtText = district.text.split(" - ")[0];
  const networkText = network.text.split(" - ")[0];
  const networkEqualDistrict = districtText === networkText;
  const parsedNetwork = networkEqualDistrict
    ? `${network.text}${network.text.length ? " - Network" : ""}`
    : `${network.text}${network.text.length ? " - Network" : ""}`;
  const parsedDistrict = networkEqualDistrict
    ? `${district.text}${district.text.length ? " - District" : ""}`
    : `${district.text}${district.text.length ? " - District" : ""}`;
  let school = findInMenu(menuObject["School"], selected["School"]);
  school = {
    ...school,
    text: `${school.text}${school.text.length ? " - School" : ""}`,
  };
  const measureLevel = selected["School Level"];
  const subgroup = selected["Student Groups"];
  const subgroupName = subgroups
    ? (subgroups[subgroup] || { name: "" }).name
    : "";
  const parsedName =
    subgroupName.split("(")[0].toLowerCase().includes("student") ||
    ["EL", "ELP", "RFEP"].includes(subgroup)
      ? subgroupName
      : `${subgroupName} Students`;
  const year = selected["School Year"] ? selected["School Year"] : "";
  const measure = selected["Measure"];
  const measureInfo = coreMeasures[measure] || {};
  const { metricTrends, loading } = state.metrics;
  const subgroupInfo = subgroups[subgroup] || {};
  const distance = [
    "dfm_math",
    "dfm_ela",
    "sbac_dfm_math",
    "sbac_dfm_ela",
    "caa_dfm_math",
    "caa_dfm_ela",
  ].includes(measure);
  return {
    distance,
    parsedName,
    subgroup,
    subgroupInfo,
    metricTrends,
    selected,
    network,
    district,
    parsedNetwork,
    parsedDistrict,
    school,
    year,
    measure,
    measureLevel,
    measureInfo,
    loading,
  };
};

export default compose(
  withStyles(tableStyle, { withTheme: true }),
  connect(mapState, null)
)(MetricTrends);
