export const menu_constants = {
  dashboard_options: {
    Network: {
      text: "Network",
      type: 0,
      key: "Network",
      maxWidth: "22.5%",
    },
    District: {
      text: "District",
      type: 0,
      key: "District",
      maxWidth: "22.5%",
    },
    "School Level": {
      text: "School Level",
      type: 1,
      key: "School Level",
      maxWidth: "10%",
    },
    "School Type": {
      text: "School Type",
      type: 1,
      key: "School Type",
      maxWidth: "10%",
    },
    School: {
      text: "School",
      type: 0,
      key: "School",
      maxWidth: "22.5%",
    },
    "School Year": {
      text: "School Year",
      type: 1,
      key: "School Year",
      maxWidth: "12.5%",
    },
    multi_district: {
      text: "District",
      type: 0,
      key: "multi_district",
      maxWidth: "25%",
    },
    multi_level: {
      text: "School Level",
      type: 1,
      key: "multi_level",
      maxWidth: "15%",
    },
    multi_school: {
      text: "School",
      type: 0,
      key: "multi_school",
      maxWidth: "25%",
    },
    multi_school_type: {
      text: "School Type",
      type: 1,
      key: "multi_school_type",
      maxWidth: "30%",
    },
  },
  extra_options: {
    multi_college_level: {
      text: "College Type",
      type: 1,
      key: "multi_college_level",
    },
    Pathway: {
      text: "Pathway",
      type: 0,
      key: "Pathway",
    },
    "Student Groups": {
      text: "Student Groups",
      type: 0,
      key: "Student Groups",
    },
    Measure: {
      text: "Measure",
      type: 0,
      key: "Measure",
    },
    multi_groups: {
      text: "Student Groups",
      type: 0,
      key: "multi_groups",
    },
    "Data Points": {
      text: "Data Points",
      type: 1,
      key: "Data Points",
    },
    "Growth Subject": {
      text: "Growth Subject",
      type: 0,
      key: "Growth Subject",
    },
    grades: {
      text: "Grade",
      type: 1,
      key: "grades",
    },
  },
  // buttons: ["xAxis", "yAxis"],
  menuLists: {
    "/public": [
      "Network",
      "District",
      "School Level",
      "School Type",
      "School",
      "School Year",
      "Pathway",
    ],
    "/public/": [
      "Network",
      "District",
      "School Level",
      "School Type",
      "School",
      "School Year",
      "Pathway",
    ],
    "/public/core": [
      "Network",
      "District",
      "School Level",
      "School Type",
      "School",
      "School Year",
      "Pathway",
    ],
    "/public/lcap": [
      "Network",
      "District",
      "School Level",
      "School Type",
      "School",
      "School Year",
      "Pathway",
      "Student Groups",
    ],
    "/public/lcap/lcap-5x5studentgroup": [
      "Network",
      "District",
      "School Level",
      "School Type",
      "School",
      "School Year",
      "Pathway",
      "Measure",
    ],
    "/public/lcap/lcap-5x5schools": [
      "Network",
      "District",
      "School Level",
      "School Year",
      "Data Points",
      "Measure",
    ],
    "/dashboard": [
      "Network",
      "District",
      "School Level",
      "School Type",
      "School",
      "School Year",
      "Pathway",
    ],
    "/dashboard/lcap": [
      "Network",
      "District",
      "School Level",
      "School Type",
      "School",
      "School Year",
      "Pathway",
      "Student Groups",
    ],
    "/dashboard/lcap/lcap-5x5studentgroup": [
      "Network",
      "District",
      "School Level",
      "School Type",
      "School",
      "School Year",
      "Pathway",
      "Measure",
    ],
    "/dashboard/lcap/lcap-5x5schools": [
      "Network",
      "District",
      "School Level",
      "School Year",
      "Data Points",
      "Measure",
      // "Student Groups"
    ],
    "/dashboard/academic/schoolreport": [
      "Network",
      "District",
      "School Level",
      "School Type",
      "School",
      "School Year",
      "Student Groups",
      "Growth Subject",
    ],
    "/dashboard/academic/scatterplot": [
      "Network",
      "District",
      "School Level",
      "School",
      "School Year",
      "Student Groups",
      "Growth Subject",
    ],

    "/dashboard/academic/barcharts": [
      "Network",
      "District",
      "School Level",
      "Growth Subject",
      "Student Groups",
    ],
    "/dashboard/ondemand/metrictrends": [
      "Network",
      "District",
      "School Level",
      "School",
      "Pathway",
      "Measure",
      "Student Groups",
    ],
    "/dashboard/ondemand/gaptrends": [
      "Network",
      "District",
      "School Level",
      "School",
      "Pathway",
      "Measure",
      "Student Groups",
    ],
    "/dashboard/ondemand/scatterplots": [
      "Network",
      "multi_district",
      "multi_level",
      "multi_school",
      "School Year",
      "Student Groups",
      "Data Points",
    ],
    "/dashboard/ondemand/mapview": [
      "Network",
      "District",
      "School Level",
      "School",
      "School Year",
      "Measure",
      "Student Groups",
    ],
    "/dashboard/shiny/prelim/collegedistribution": [
      "Network",
      "District",
      "School",
      "School Year",
      "Measure",
      "Student Groups",
    ],
    "/dashboard/shiny/prelim/metrictrends": [
      "Network",
      "District",
      "School",
      "Measure",
      "Student Groups",
    ],
    "/dashboard/shiny/prelim/gaptrends": [
      "Network",
      "District",
      "School",
      "Measure",
      "Student Groups",
    ],
    "/dashboard/shiny/prelim/scatterplots": [
      "Network",
      "multi_district",
      "multi_level",
      "multi_school",
      "School Year",
      "Student Groups",
      "Data Points",
    ],
    "/dashboard/shiny/prelim/barplotdistributions": [
      "Network",
      "District",
      "School Year",
      "multi_school_type",
      "multi_college_level",
      "Measure",
      "Student Groups",
    ],
    "/dashboard/linkedlearning/schoolsummary": [
      "Network",
      "District",
      "School",
      "School Year",
      "Student Groups",
    ],
    "/dashboard/linkedlearning/pathwaylongitudinal": [
      "Network",
      "District",
      "School",
      "School Year",
      "Student Groups",
      "Pathway",
    ],
    "/dashboard/admin": [],
    "/dashboard/visualizations/completeness": [
      "Network",
      "District",
      "School Level",
      "School Type",
      "School",
      "School Year",
    ],
    "/dashboard/predictiveanalytics/schoolview": [
      "Network",
      "District",
      "School Level",
      "School Type",
      "School",
    ],
    "/dashboard/predictiveanalytics/networkview": ["Network", "School Level"],
    "/dashboard/ontrack": [
      "Network",
      "District",
      "School Level",
      "School Type",
      "School",
      "School Year",
      "Student Groups",
      "grades",
    ],
    "/dashboard/ontrack/ontracklanes": [
      "Network",
      "District",
      "School Level",
      "School",
      "School Year",
      "Student Groups",
      "grades",
    ],
    "/dashboard/ontrack/ratesbysubject": [
      "Network",
      "District",
      "School Level",
      "School",
      "School Year",
      "Student Groups",
      "grades",
    ],
    "/dashboard/ontrack/gradedistribution": [
      "Network",
      "District",
      "School Level",
      "School Year",
      "Measure",
      "Student Groups",
      "grades",
    ],
    "/dashboard/analytics": [],
  },
  menuOptions: {
    "College Measures": [
      {
        code: "persist_2yr",
        text: "2 Year Persistence Rate",
      },
      {
        code: "grad_4yr",
        text: "4 Year Graduation Rate",
      },
      {
        code: "grad_5yr",
        text: "5 Year Graduation Rate",
      },
      {
        code: "grad_6yr",
        text: "6 Year Graduation Rate",
      },
      {
        code: "enroll_fall",
        text: "Fall Enrollment (1st Year) Enrollment Rate",
      },
      {
        code: "enroll_year",
        text: "First Year Enrollment",
      },
    ],
    "Data Points": ["Schools", "Pathways"],
    "Growth Subject": [
      {
        text: "Growth Math",
        code: "growth_math",
      },
      {
        text: "Growth ELA",
        code: "growth_ela",
      },
    ],
  },
};
