import { Component } from "react";
import Grid from "@material-ui/core/Grid/Grid";
import { compose } from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import connect from "react-redux/es/connect/connect";
import React from "react";
import { Title, parseTable, renderTable, trimTable } from "./helpers";
import TableTitle from "../StyledComponents/Table/TitleTable";
import CreateTable from "../StyledComponents/Table/TableList";

const styles = (theme) => ({
  tableMargin: {
    marginLeft: "2rem",
    marginRight: "2rem",
  },
});
class LcapOverview extends Component {
  render() {
    const {
      metrics,
      district,
      school,
      year,
      subgroups,
      lcapOverview,
      measures,
      subgroup,
      classes,
      loading,
    } = this.props;
    const years =
      year.split("-").length > 1 ? year.split("-") : ["2017", "2018"];
    const yearInfo = [
      `${parseInt(years[0]) - 1}-${years[0]}`,
      `${years[0]}-${years[1]}`,
    ];
    const tables = Object.keys(lcapOverview).length
      ? Object.keys(lcapOverview).map((key) => {
          const currentTable = lcapOverview[key];
          return parseTable(
            currentTable,
            metrics,
            yearInfo,
            measures,
            subgroups,
            subgroup,
            "LCAP"
          );
        })
      : [];
    const trimmed = tables.reduce((array, table) => {
      const trimmedTable = trimTable(table);
      if (trimmedTable) {
        array.push(trimmedTable);
      }
      return array;
    }, []);
    return (
      <Grid container justifyContent="center" className={classes.tableMargin}>
        <TableTitle
          school={school}
          district={district}
          pageTitle={"LCAP Overview"}
        />
        {trimmed.map((table, index) => {
          return (
            <Grid key={`LCAP-OVERVIEW-TABLE:${index}`} item xs={12}>
              <CreateTable
                loading={loading}
                pageType={"LCAP"}
                data={table.data}
                header={table.columns}
                title={table.title}
                index={index}
              />
            </Grid>
          );
        })}
      </Grid>
    );
  }
}

const findInMenu = (array, key) => {
  const res = {
    code: "",
    text: "",
  };
  if (!array) {
    return res;
  }
  return array.reduce((found, curr) => {
    if (curr.code === key) {
      found = curr;
    }
    return found;
  }, res);
};

const mapState = (state) => {
  const { selected, menuObject } = state.menu;
  const district = findInMenu(menuObject["District"], selected["District"]);
  const school = findInMenu(menuObject["School"], selected["School"]);
  const year = selected["School Year"] ? selected["School Year"] : "";
  const subgroup = selected["Student Groups"];
  const { metrics, loading } = state.metrics;
  const { subgroups, lcapOverview, measures } = state.constants;
  return {
    loading,
    subgroup,
    measures,
    metrics,
    selected,
    district,
    school,
    year,
    subgroups,
    lcapOverview,
  };
};

const mapDispatch = (dispatch) => {
  return {};
};

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapState, mapDispatch)
)(LcapOverview);
