import React, { Component, Fragment } from "react";
import { compose } from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import connect from "react-redux/es/connect/connect";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Plot from "react-plotly.js";
import tableStyle from "../assets/jss/core-dashboard/components/tableStyle.jsx";
import CardHeader from "../StyledComponents/Card/CardHeader";
import CardBody from "../StyledComponents/Card/CardBody";
import Card from "../StyledComponents/Card/Card";
import TitleTable from "../StyledComponents/Table/TitleTable";
import LoadingGrid from "../StyledComponents/Grid/LoadingGrid";
import ReactDOM from "react-dom";
import { addCommas } from "../utils";
import CustomToolTip from "../StyledComponents/Tooltip/CustomToolTip";

const order = [
  {
    text: "Growth Est:",
    code: "meas_rate",
  },
  {
    text: "Index Level:",
    code: "index_level",
  },
  {
    text: "Confidence Interval:",
    code: "ci",
  },
  {
    text: "Number of Students:",
    code: "n_students",
  },
];

const GrowthHoverGrid = ({ info, classes, fixed, left, top }) => {
  const {
    name,
    ci_lower,
    ci_upper,
    index_level,
    index_level_color,
    meas_rate,
    n_students,
    scale_score_conversion,
  } = info;
  info["ci"] = `${ci_lower}-${ci_upper}`;
  const scaleScore = scale_score_conversion
    ? Math.abs(scale_score_conversion)
    : 0;
  const scaleText = scale_score_conversion
    ? scale_score_conversion < 0
      ? " fewer"
      : " greater"
    : " greater";
  const average =
    index_level_color === "red"
      ? "below"
      : index_level_color === "green"
      ? "above"
      : "";
  return (
    <Grid
      id={`${name}-${fixed ? "true" : "false"}`}
      direction={"column"}
      className={
        fixed
          ? classes[`${index_level_color}TransparentBackdrop2`]
          : classes[`${index_level_color}TransparentBackdrop2`]
      }
      style={{
        width: "400px",
        height: "200px",
        borderRadius: "5px",
        padding: "5px",
        position: "fixed",
        left: left - 185,
        top: top - 225,
        zIndex: 5000,
      }}
      item
      container
    >

      <Grid justifyContent={"center"} container item direction={"column"}>
        <Grid
            className={classes[`${index_level_color}Backdrop`]}
            style={{ borderRadius: "5px" }}
            item
        >
          <Typography style={{ fontSize: "24px", fontWeight: "900" }}>
            {name}
          </Typography>
        </Grid>
        {order.map((curr) => (
          <Grid spacing={2} item container>
            <Grid xs={8} item>
              <Typography
                style={{
                  verticalAlign: "bottom",
                  fontSize: "14px",
                  fontWeight: "900",
                  float: "right",
                }}
              >
                {curr.text}
              </Typography>
            </Grid>
            <Grid xs={4} item>
              <Typography
                style={{
                  verticalAlign: "bottom",
                  fontSize: "14px",
                  fontWeight: "600",
                  float: "left",
                }}
              >
                {info[curr.code]}
              </Typography>
            </Grid>
          </Grid>
        ))}
        <Grid item>
          <Typography>
            The growth percentile is {meas_rate} which represents {average}{" "}
            average growth. Students in this case grew {scaleScore} scale score
            points {scaleText} than similar students.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const GrowthGrid = ({ info, classes }) => {
  const {
    name,
    ci_lower,
    ci_upper,
    index_level,
    index_level_color,
    meas_rate,
    n_students,
    scale_score_conversion,
  } = info;
  info["ci"] = `${ci_lower}-${ci_upper}`;
  // Removed the 0 and added in TBD if a scale_score_conversion returns a null
  const scaleScore = scale_score_conversion
    ? Math.abs(scale_score_conversion)
    : "TBD";
  const scaleText = scale_score_conversion
    ? scale_score_conversion < 0
      ? " fewer"
      : " greater"
    : " greater";
  const averageText =
    index_level_color === "green"
      ? "above"
      : index_level_color === "red"
      ? "below"
      : "";
  return (
    <Grid
      xl={3}
      lg={3}
      md={3}
      xs={5}
      direction={"column"}
      className={classes[`${index_level_color}TransparentBackdrop`]}
      style={{ borderRadius: "5px", padding: "5px", margin: "2.5px" }}
      item
      container
    >
      <Grid
        className={classes[`${index_level_color}Backdrop`]}
        style={{ borderRadius: "5px" }}
        item
      >
        <Typography style={{ fontSize: "2.5vh", fontWeight: "900" }}>
          {name}
        </Typography>
      </Grid>
      <Grid justifyContent={"center"} container item direction={"column"}>
        {order.map((curr) => (
          <Grid spacing={8} item container>
            <Grid xs={10} md={9} lg={8} item>
              <Typography
                style={{
                  verticalAlign: "bottom",
                  fontSize: "14px",
                  fontWeight: "900",
                  float: "right",
                }}
              >
                {curr.text}
              </Typography>
            </Grid>
            <Grid xs={2} md={3} lg={4} item>
              <Typography
                style={{
                  verticalAlign: "bottom",
                  fontSize: "14px",
                  fontWeight: "600",
                  float: "left",
                }}
              >
                {curr.code === "n_students"
                  ? addCommas(`${info[curr.code]}`)
                  : info[curr.code]}
              </Typography>
            </Grid>
          </Grid>
        ))}
        <Grid item>
          <Typography>
            The growth percentile is {meas_rate} which represents {averageText}{" "}
            average growth. Students in this case grew {scaleScore} scale score
            points {scaleText} than similar students.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const colorToRGB = (color) => {
  switch (color) {
    case "red":
      return "rgb(239,75,75)";
    case "orange":
      return "rgb(244,134,31)";
    case "blue":
      return "rgb(85,144,181)";
    case "green":
      return "rgb(103,185,72)";
    default:
      return "rgb(0,0,0)";
  }
};

class GrowthSchoolReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showHover: false,
      hoverInfo: {},
      left: 0,
      top: 0,
    };
    this.setHover = this.setHover.bind(this);
    this.hideHover = this.hideHover.bind(this);
  }
  setHover = (hoverInfo, left, top) => {
    this.setState({
      showHover: true,
      hoverInfo,
      left,
      top,
    });
  };
  hideHover = () => {
    this.setState({
      showHover: false,
    });
  };
  render() {
    const {
      growthSchoolReport,
      classes,
      title,
      district,
      school,
      loading,
      selected,
    } = this.props;
    const keys = Object.keys(growthSchoolReport);
    const n = 1.0 / keys.length;
    const gradeOrder = [
      "Grade Overall ES",
      "Grade Overall MS",
      "Grade Overall HS",
      "Grade 1",
      "Grade 2",
      "Grade 3",
      "Grade 4",
      "Grade 5",
      "Grade 6",
      "Grade 7",
      "Grade 8",
      "Grade 9",
      "Grade 10",
      "Grade 11",
      "Grade 12",
    ];
    const orderedKeys = gradeOrder.reduce((array, key) => {
      if (keys.includes(key)) {
        array.push(key);
      }
      return array;
    }, []);
    const conversion = orderedKeys.reduce((obj, key, index) => {
      obj[key] = 1.0 - (index / 1.0) * n;
      return obj;
    }, {});
    const tickText = orderedKeys.map(
      (key) => `<b>                  ${key}</b>`
    );
    const tickVals = orderedKeys.map((key) => conversion[key]);
    const customdata = {};
    const data = Object.keys(growthSchoolReport).reduce((array, key) => {
      const curr = growthSchoolReport[key];
      const yVal = conversion[key];
      const {
        name,
        ci_lower,
        ci_upper,
        index_level,
        index_level_color,
        meas_rate,
        n_students,
        scale_score_conversion,
      } = curr;
      customdata[key] = {
        name,
        ci_lower,
        ci_upper,
        index_level,
        index_level_color,
        meas_rate,
        n_students: addCommas(`${n_students}`),
        scale_score_conversion,
      };
      const color = colorToRGB(index_level_color);
      return array.concat([
        {
          type: "scatter",
          x: [ci_lower, ci_upper],
          y: [yVal, yVal],
          text: [ci_lower, ci_upper],
          hoverinfo: "text",
          mode: "markers+text+lines",
          textposition: "top center",
          marker: {
            color,
          },
          hoverlabel: {
            bgcolor: "white",
          },
        },
        {
          type: "scatter",
          x: [meas_rate],
          y: [yVal],
          hoverinfo: "none",
          hoverlabel: {
            bgcolor: "white",
          },
          mode: "markers+text",
          textposition: "top center",
          text: [`<b>${meas_rate}</b>`],
          hovertext: [`${key}`],
          textfont: {
            size: "14",
            color: "black",
          },
          marker: {
            symbol: "diamond-tall",
            size: 12,
            color,
          },
        },
      ]);
    }, []);
    const noData = growthSchoolReport
      ? !Object.keys(growthSchoolReport).length
      : true;
    return (
      <Grid
        style={{ width: "95%", margin: "0 2.5% 0 2.5%" }}
        justifyContent={"center"}
        container
        direction={"row"}
      >
        <TitleTable
          district={district}
          school={school}
          pageTitle={"School Report"}
        />
        <Grid xs={12} item>
          <Card>
            <CardHeader className={classes.gradientBackground} color="primary">
              {/* Left the Next Card Header in Place to bring back Primary Color at a later time*/}
              <CustomToolTip code={selected["Growth Subject"]}>
                <h4 className={classes.cardTitleBlue}>
                  {noData && !loading
                    ? "Sorry, there's no data for this graph."
                    : title}
                </h4>
              </CustomToolTip>
            </CardHeader>
            <CardBody
              id={"card-body"}
              style={{
                paddingTop: "0px",
                width: "100%",
                marginTop: "10px",
                paddingBottom: "10px",
              }}
            >
              {loading ? (
                <LoadingGrid />
              ) : noData ? null : (
                <div
                  className={classes.plot}
                  style={{ width: "100%", height: "100%" }}
                >
                  <Plot
                    onClick={(data) => {
                      const key = data.points[0].data.hovertext;
                      const info = customdata[key];
                      if (info) {
                        const card = document.getElementById("card-body");
                        const xy = card.getBoundingClientRect();
                        const xaxis = data.points[0].xaxis;
                        const yaxis = data.points[0].yaxis;
                        const top =
                          xy.y + yaxis.l2p(data.points[0].y) + yaxis._offset;
                        const left =
                          xy.x + xaxis.l2p(data.points[0].x) + xaxis._offset;
                        const hoverinfo = document.getElementById("hoverinfo");
                        ReactDOM.render(
                          <GrowthHoverGrid
                            classes={classes}
                            info={info}
                            top={top}
                            left={left}
                          />,
                          hoverinfo
                        );
                      }
                    }}
                    onHover={(data) => {
                      const key = data.points[0].data.hovertext;
                      const info = customdata[key];
                      if (info) {
                        const card = document.getElementById("card-body");
                        const xy = card.getBoundingClientRect();
                        const xaxis = data.points[0].xaxis;
                        const yaxis = data.points[0].yaxis;
                        const top =
                          xy.y + yaxis.l2p(data.points[0].y) + yaxis._offset;
                        const left =
                          xy.x + xaxis.l2p(data.points[0].x) + xaxis._offset;
                        const hoverinfo = document.getElementById("hoverinfo");
                        ReactDOM.render(
                          <GrowthHoverGrid
                            classes={classes}
                            info={info}
                            top={top}
                            left={left}
                          />,
                          hoverinfo
                        );
                      }
                    }}
                    onUnhover={(data) => {
                      const hoverinfo = document.getElementById("hoverinfo");
                      ReactDOM.unmountComponentAtNode(hoverinfo);
                    }}
                    style={{ width: "100%", height: "100%" }}
                    responsive={true}
                    autosize={true}
                    useResizeHandler={true}
                    data={data}
                    layout={{
                      hovermode: "closest",
                      autosize: true,
                      responsive: true,
                      shapes: [
                        {
                          type: "line",
                          x0: 50,
                          x1: 50,
                          y0: 0,
                          y1: 1.2,
                          layer: "below",
                          line: {
                            color: "rgb(77, 126, 145)",
                            width: 2,
                          },
                        },
                        {
                          type: "rect",
                          x0: 20,
                          y0: 0,
                          x1: 40,
                          y1: 1.2,
                          layer: "below",
                          opacity: 0.2,
                          line: {
                            color: "rgba(77, 126, 145, 0.7)",
                            width: 1,
                          },
                          fillcolor: "rgb(73,116,133)",
                        },
                        {
                          type: "rect",
                          x0: 60,
                          y0: 0,
                          x1: 80,
                          y1: 1.2,
                          layer: "below",
                          line: {
                            color: "rgba(77, 126, 145, 0.7)",
                            width: 1,
                          },
                          opacity: 0.2,
                          fillcolor: "rgb(73,116,133)",
                        },
                      ],
                      paper_bgcolor: "rgba(0,0,0,0)",
                      plot_bgcolor: "rgba(0,0,0,0)",
                      showlegend: false,
                      xaxis: {
                        showgrid: false,
                        title: {
                          text: "Student Growth Percentile",
                          font: {
                            size: 16,
                            color: "rgb(9, 75, 101)",
                          },
                        },
                        range: [0, 100],
                        tickvals: [0, 20, 40, 50, 60, 80, 100],
                        ticktext: [
                          "<b>0</b>",
                          "<b>20</b>",
                          "<b>40</b>",
                          "<b>Average Growth</b>",
                          "<b>60</b>",
                          "<b>80</b>",
                          "<b>100</b>",
                        ],
                        linewidth: 6,
                        linecolor: "rgb(9, 75, 101)",
                      },
                      yaxis: {
                        automargin: true,
                        title: {
                          text: "Grade",
                          font: {
                            size: 16,
                            color: "rgb(9, 75, 101)",
                          },
                        },
                        range: [0, 1.2],
                        gridcolor: "rgb(170,195,203)",
                        tickmode: "array",
                        ticktext: tickText,
                        tickvals: tickVals,
                        linewidth: 6,
                        linecolor: "rgb(9, 75, 101)",
                        tickcolor: "rgb(9, 75, 101)",
                        tickwidth: 3,
                        // range: Object.keys(growthSchoolReport)
                      },
                    }}
                  />
                </div>
              )}
            </CardBody>
          </Card>
        </Grid>
        <Grid container xs={12} item>
          <Card>
            <CardHeader className={classes.gradientBackground} color="primary">
              {/* Left the Next Card Header in Place to bring back Primary Color at a later time*/}
              {/*<CardHeader color="primary" >*/}
              <h4 className={classes.cardTitleBlue}>{"Metric Breakdown"}</h4>
              {/*<p className={classes.cardCategoryWhite}>*/}
              {/*	Here is a subtitle for this table*/}
              {/*</p>*/}
            </CardHeader>
            <CardBody
              style={{
                paddingTop: "0px",
                marginTop: "10px",
                paddingBottom: "10px",
              }}
            >
              {loading ? (
                <LoadingGrid />
              ) : noData ? null : (
                <Grid
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                  justifyContent={"space-around"}
                  spacing={24}
                  item
                  container
                >
                  {gradeOrder.map((key) =>
                    growthSchoolReport[key]
                      ? GrowthGrid({
                          info: growthSchoolReport[key],
                          classes,
                          left: 0,
                          top: 0,
                          fixed: false,
                        })
                      : null
                  )}
                </Grid>
              )}
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

const findInMenu = (array, key) => {
  const res = {
    code: "",
    text: "",
  };
  if (!array) {
    return res;
  }
  return array.reduce((found, curr) => {
    if (curr.code === key) {
      found = curr;
    }
    return found;
  }, res);
};

const mapState = (state) => {
  const { selected, menuObject } = state.menu;
  const {
    constants: { measures, subgroups },
  } = state;
  const district = findInMenu(menuObject["District"], selected["District"]);
  const school = findInMenu(menuObject["School"], selected["School"]);
  const group = selected["Student Groups"];
  const measureLevel = selected["School Level"];
  const year = selected["School Year"] ? selected["School Year"] : "";
  const measure = selected["Measure"];
  const growthSubject = selected["Growth Subject"];
  const subgroupName = subgroups ? (subgroups[group] || { name: "" }).name : "";
  const parsedName =
    subgroupName.split("(")[0].toLowerCase().includes("student") ||
    group === "EL"
      ? subgroupName
      : `${subgroupName} Students`;
  const growthName =
    (growthSubject || "growth_ela") === "growth_ela"
      ? "Growth in English Language Arts"
      : "Growth in Math";
  const title = `${growthName} among ${parsedName}, ${year}`;
  const { growthSchoolReport, loading } = state.metrics;
  return {
    growthSchoolReport,
    selected,
    district,
    school,
    year,
    measure,
    title,
    measureLevel,
    loading,
  };
};

const mapDispatch = (dispatch) => {
  return {};
};

export default compose(
  withStyles(tableStyle, { withTheme: true }),
  connect(mapState, mapDispatch)
)(GrowthSchoolReport);
