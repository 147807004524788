/**
 * CustomToolTip.jsx
 * @author Dylan Sadnick
 * 10-16-2019
 *
 * @returns A Custom Tool Tip that will wrap any child components.
 */

import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

/**
 * Return tool tips
 */
class CustomToolTip extends Component {
  /**
   * Basic Constructor
   * @param props
   */
  constructor(props) {
    super(props);
    this.lookUpMeasureDefinitions = this.lookUpMeasureDefinitions.bind(this);
  }

  /**
   * This will take in a measure code and look though the measure Definitions
   * and return the proper definition.
   *
   * @param code
   * @returns {string}
   */
  lookUpMeasureDefinitions(code) {
    return this.props.measureDefinitions[code]
      ? this.props.measureDefinitions[code]
      : "";
  }

  /**
   * Will return the Tool tip with the props.children to pass lower level components.
   * @returns {*}
   */
  render() {
    const { code, demo, placement } = this.props;
    const HtmlTooltip = withStyles((theme) => ({
      tooltip: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 500,
        fontSize: theme.typography.pxToRem(14),
        border: "1px solid #dadde9",
      },
    }))(Tooltip);

    return (
      <HtmlTooltip
        title={
          <React.Fragment>
            <b>{demo ? code : this.lookUpMeasureDefinitions(code)}</b>
          </React.Fragment>
        }
        placement={placement ? placement : "bottom-start"}
      >
        {this.props.children}
      </HtmlTooltip>
    );
  }
}

/**
 * Basic mapState ,Currently I only pull out the measureDefinitons to iterate over
 *
 * @param state
 * @returns {{measureDefinitions: *}}
 */
const mapState = (state) => {
  const { measureDefinitions } = state.constants;
  return {
    measureDefinitions,
  };
};

export default connect(mapState)(CustomToolTip);
