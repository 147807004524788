import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import connect from "react-redux/es/connect/connect";
import { compose } from "redux";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { getMenu, select, closeDeepLink } from "./actions";
import { menu_constants } from "./menu_constants";
import FormControl from "@material-ui/core/FormControl";
import InputBase from "@material-ui/core/InputBase";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import { IconButton } from "@material-ui/core";
import Cancel from "@material-ui/icons/Cancel";
const SecondaryBootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing.unit * 3,
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#318b45",
    color: "#d0dbdf",
    fontSize: 16,
    minWidth: 125,
    maxWidth: 175,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      backgroundColor: "#318b45",
    },
  },
}))(InputBase);
const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing.unit * 3,
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#1b5267",
    color: "#d0dbdf",
    fontSize: 16,
    minWidth: 125,
    maxWidth: 175,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      backgroundColor: "#1b5267",
    },
  },
}))(InputBase);

const styles = (theme) => ({
  root: {
    display: "flex",
    overflow: "hidden",
  },
  margin: {
    margin: theme.spacing.unit,
  },
  bootstrapFormLabel: {
    fontSize: 18,
  },
  selectLabel: {
    color: theme.palette.background.paper,
  },
  icon: {
    fill: "#d0dbdf",
  },
});

class Menu extends Component {
  constructor(props) {
    super(props);
    // const { get, path, selected, deepLink } = props;
    // get(path, selected, deepLink);
  }

  componentDidUpdate(prevProps) {
    const input = { name: "", value: "" };
    const oldPath = prevProps.path;
    const { select, menu, selected, path, constants } = this.props;
    if (oldPath !== path) {
      // select(input, menu, selected, path, constants);
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    const input = { name, value };
    const { select, menu, selected, path, constants, menuObject } = this.props;
    select(input, menu, selected, path, constants, menuObject);
  };

  handleCloseDeepLink = () => {
    this.props.exitDeepLink();
  };

  render() {
    const {
      selected,
      menuObject,
      path,
      classes,
      deepLinkURL,
      showMenu,
      close,
    } = this.props;
    const { dashboard_options, menuLists, extra_options } = menu_constants;
    const menuList = menuLists[path] || [];
    return (
      <Dialog
        onBackdropClick={() => {
          close();
        }}
        open={showMenu}
      >
        <Grid
          id={"mobile-menu"}
          style={{ backgroundColor: "#1d5e77" }}
          container
          direction={"column"}
        >
          <Grid
            id={"mobile-menu-main"}
            container
            justifyContent={"flex-start"}
            direction={"column"}
            alignItems={"center"}
            wrap="nowrap"
          >
            <Grid id={"mobile-menu-close"} style={{ width: "100%" }} item>
              <IconButton
                onClick={() => {
                  close();
                }}
                style={{ float: "right", color: "white" }}
              >
                <Cancel color={"inherit"} fontSize={"large"} />
              </IconButton>
            </Grid>
            {menuList.map((currColumn) => {
              const { text, type, key } = dashboard_options[currColumn] || {};
              const currMenu = menuObject[text] ? menuObject[text] : [];
              if (!Object.keys(dashboard_options).includes(key)) {
                return null;
              }
              return (
                <form
                  id={`mobile-menu-${text}`}
                  style={{ width: "100%" }}
                  className={classes.root}
                  key={key}
                >
                  <FormControl
                    className={classes.margin}
                    style={{ width: "100%" }}
                  >
                    <Grid style={{ width: "100%" }} xs={12} item zeroMinWidth>
                      <Typography className={classes.selectLabel}>
                        {text}
                      </Typography>
                    </Grid>
                    <Grid xs={12} item>
                      <Select
                        style={{ width: "100%" }}
                        inputProps={{
                          classes: {
                            icon: classes.icon,
                          },
                        }}
                        input={<BootstrapInput name={text} id={text} />}
                        value={selected[text] || ""}
                        onChange={(e) => {
                          this.handleChange(e);
                        }}
                      >
                        {type
                          ? currMenu.map((key) => (
                              <MenuItem key={key} value={key}>
                                {key}
                              </MenuItem>
                            ))
                          : currMenu.map((curr) => {
                              const { code, text } = curr;
                              return (
                                <MenuItem key={code} value={code}>
                                  {text}
                                </MenuItem>
                              );
                            })}
                      </Select>
                    </Grid>
                  </FormControl>
                </form>
              );
            })}
          </Grid>
          <Grid
            id={"mobile-menu-secondary"}
            style={{ backgroundColor: "#67b948" }}
            container
            item
            justifyContent={"flex-end"}
            direction={"column"}
            alignItems={"center"}
          >
            {menuList.map((currColumn) => {
              const { text, type, key } = extra_options[currColumn] || {};
              const currMenu = menuObject[text] ? menuObject[text] : [];
              if (
                !Object.keys(extra_options).includes(key) ||
                !currMenu.length
              ) {
                return null;
              }
              return (
                <form
                  id={`mobile-menu-${text}`}
                  style={{ width: "100%" }}
                  className={classes.root}
                  key={key}
                >
                  <FormControl
                    className={classes.margin}
                    style={{ width: "100%" }}
                  >
                    <Grid style={{ width: "100%" }} xs={12} item zeroMinWidth>
                      <Typography className={classes.selectLabel}>
                        {text}
                      </Typography>
                    </Grid>
                    <Grid style={{ width: "100%" }} item>
                      <Select
                        style={{ width: "100%" }}
                        inputProps={{
                          classes: {
                            icon: classes.icon,
                          },
                        }}
                        input={
                          <SecondaryBootstrapInput name={text} id={text} />
                        }
                        value={selected[text] || ""}
                        onChange={(e) => {
                          this.handleChange(e);
                        }}
                      >
                        {type
                          ? currMenu.map((key) => (
                              <MenuItem key={key} value={key}>
                                {key}
                              </MenuItem>
                            ))
                          : currMenu.map((curr) => {
                              const { code, text } = curr;
                              return (
                                <MenuItem key={code} value={code}>
                                  {text}
                                </MenuItem>
                              );
                            })}
                      </Select>
                    </Grid>
                  </FormControl>
                </form>
              );
            })}
          </Grid>
        </Grid>
      </Dialog>
    );
  }
}

const mapLogin = (state) => {
  const { menu, selected, menuObject, deepLink, deepLinkURL } = state.menu;
  const { subgroups, collegeMeasures, measures } = state.constants;
  return {
    menu,
    selected,
    menuObject,
    deepLink,
    deepLinkURL,
    constants: {
      subgroups,
      collegeMeasures,
      measures,
    },
  };
};

const mapDispatch = (dispatch) => {
  return {
    get: async (route, selected, deepLink) => {
      dispatch(getMenu(route, selected, deepLink));
    },
    select: (input, menu, selected, path, constants, menuObject) => {
      dispatch(select(input, menu, selected, path, constants, menuObject));
    },
    exitDeepLink: () => {
      dispatch(closeDeepLink());
    },
  };
};

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapLogin, mapDispatch)
)(Menu);
