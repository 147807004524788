import { UPDATE_NETWORK_VIEW, UPDATE_SCHOOL_VIEW } from "./actions";

const initialState = {
  active: {},
  schoolView: {},
  networkView: {},
};

export default function predAnalytics(state = initialState, action) {
  switch (action.type) {
    case UPDATE_SCHOOL_VIEW:
      return {
        ...state,
        schoolView: action.schoolView,
      };
    case UPDATE_NETWORK_VIEW:
      return {
        ...state,
        networkView: action.schoolView,
      };
    default:
      return state;
  }
}
