import React, { Component } from "react";
import Grid from "@material-ui/core/Grid/Grid";
import { compose } from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import connect from "react-redux/es/connect/connect";
import { parseTable, trimTable } from "./helpers";
import CreateTable from "../StyledComponents/Table/TableList";
import TableTitle from "../StyledComponents/Table/TitleTable";
import Link from "@material-ui/icons/Link";
import IconButton from "@material-ui/core/IconButton";

const styles = (theme) => ({
  tableMargin: {
    marginTop: "0px",
    marginBottom: "0px",
    marginLeft: "2.5%",
    marginRight: "2.5%",
    width: "95%",
  },
});

class CoreIndex extends Component {
  render() {
    const {
      metrics,
      district,
      school,
      year,
      subgroups,
      coreIndex,
      measures,
      classes,
      loading,
    } = this.props;
    const years =
      year.split("-").length > 1 ? year.split("-") : ["2017", "2018"];
    const yearInfo = [
      `${parseInt(years[0]) - 1}-${years[0]}`,
      `${years[0]}-${years[1]}`,
    ];
    const tables = Object.keys(coreIndex).length
      ? Object.keys(coreIndex).map((key) => {
          const currentTable = coreIndex[key];
          //todo DYLAN HERE
          return parseTable(
            currentTable,
            metrics,
            yearInfo,
            measures,
            subgroups,
            "ALL",
            "CORE"
          );
        })
      : [];
    const trimmed = tables.reduce((array, table) => {
      const trimmedTable = trimTable(table);
      if (trimmedTable) {
        array.push(trimmedTable);
      }
      return array;
    }, []);
    return (
      <Grid container justifyContent="center" className={classes.tableMargin}>
        <TableTitle
          school={school}
          district={district}
          pageTitle={"CORE Index"}
        />
        {trimmed.map((table, index) => {
          return (
            <Grid key={`CORE-INDEX-TABLE:${index}`} item xs={12}>
              <CreateTable
                loading={loading}
                data={table.data}
                header={table.columns}
                title={table.title}
                index={index}
              />
            </Grid>
          );
        })}
      </Grid>
    );
  }
}

const findInMenu = (array, key) => {
  const res = {
    code: "",
    text: "",
  };
  if (!array) {
    return res;
  }
  return array.reduce((found, curr) => {
    if (curr.code === key) {
      found = curr;
    }
    return found;
  }, res);
};

const mapState = (state) => {
  const { selected, menuObject } = state.menu;
  const district = findInMenu(menuObject["District"], selected["District"]);
  const school = findInMenu(menuObject["School"], selected["School"]);
  const year = selected["School Year"] ? selected["School Year"] : "";
  const { metrics, loading } = state.metrics;
  const { subgroups, coreIndex, measures, measureDefinitions } =
    state.constants;
  return {
    measures,
    metrics,
    selected,
    district,
    school,
    year,
    subgroups,
    coreIndex,
    loading,
    measureDefinitions,
  };
};

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapState)
)(CoreIndex);
