import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import { Route } from "react-router-dom";
import CoreIndex from "../../Overviews/CoreIndex";
import Overview from "../../Overviews/LcapOverview";
import FiveByFiveStudents from "../../LCAP/FiveByFiveStudents";
import FiveByFiveSchools from "../../LCAP/FiveByFiveSchools";
import GrowthSchoolReport from "../../AcademicMeasures/GrowthSchoolReport";
import GrowthScatterPlot from "../../AcademicMeasures/GrowthScatterPlot";
import MetricTrends from "../../OnDemandAnalytics/MetricTrends";
import GapTrends from "../../OnDemandAnalytics/GapTrends";
import ScatterPlot from "../../OnDemandAnalytics/ScatterPlots";
import CollegeBarPlots from "../../PreliminaryCollege/CollegeBarPlots";
import CollegeDistribution from "../../PreliminaryCollege/CollegeDistribution";
import SchoolSummary from "../../LinkedLearning/SchoolSummary";
import LongitudinalView from "../../LinkedLearning/LongitudinalView";
import AdminDashboard from "../../Admin/Dashboard";
import DataCompletness from "../../Visualizations/DataCompleteness";
import SmallMutliples from "../../OnDemandAnalytics/SmallMultiples/SmallMultiples";
import GrowthBarCharts from "../../AcademicMeasures/GrowthBarCharts/GrowthBarCharts";
import OnTrackView from "../../OnTrackStatus/OnTrackView";
import SchoolView from "../../PredictiveAnalytics/SchoolView";
import NetworkView from "../../PredictiveAnalytics/NetworkView";

import AnalyticsDashboard from "../../Analytics/AnalyticsDashboard";
import MapView from "../../Map/MapView";
import OnTrackLanes from "../../OnTrackStatus/OnTrackLanes";
import OnTrackRatesBySubject from "../../OnTrackStatus/OnTrackRatesBySubject";
import OnTrackGradeDistribution from "../../OnTrackStatus/OnTrackGradeDistribution";

const drawerWidth = 230;

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  main: {
    [theme.breakpoints.up("lg")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
});

const Main = (props) => {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <Grid container item className={classes.main}>
        <Route exact path={"/dashboard/admin"} component={AdminDashboard} />
        <Route exact path={"/dashboard"} component={CoreIndex} />
        <Route exact path={"/dashboard/lcap"} component={Overview} />
        <Route exact path={"/dashboard/ontrack"} component={OnTrackView} />
        <Route
          exact
          path={"/dashboard/ontrack/ontracklanes"}
          component={OnTrackLanes}
        />
        <Route
          exact
          path={"/dashboard/ontrack/ratesbysubject"}
          component={OnTrackRatesBySubject}
        />
        <Route
          exact
          path={"/dashboard/ontrack/gradedistribution"}
          component={OnTrackGradeDistribution}
        />
        <Route
          path={"/dashboard/lcap/lcap-5x5studentgroup"}
          component={FiveByFiveStudents}
        />
        <Route
          path={"/dashboard/lcap/lcap-5x5schools"}
          component={FiveByFiveSchools}
        />
        <Route
          path={"/dashboard/academic/schoolreport"}
          component={GrowthSchoolReport}
        />
        <Route
          path={"/dashboard/academic/scatterplot"}
          component={GrowthScatterPlot}
        />
        <Route
          path={"/dashboard/academic/barcharts"}
          component={GrowthBarCharts}
        />
        <Route
          path={"/dashboard/ondemand/metrictrends"}
          component={MetricTrends}
        />
        <Route path={"/dashboard/ondemand/gaptrends"} component={GapTrends} />
        <Route
          path={"/dashboard/ondemand/scatterplots"}
          component={ScatterPlot}
        />
        <Route path={"/dashboard/ondemand/mapview"} component={MapView} />
        <Route
          path={"/dashboard/shiny/prelim/collegedistribution"}
          component={CollegeDistribution}
        />
        <Route
          path={"/dashboard/shiny/prelim/gaptrends"}
          component={GapTrends}
        />
        <Route
          path={"/dashboard/shiny/prelim/metrictrends"}
          component={MetricTrends}
        />
        <Route
          path={"/dashboard/shiny/prelim/barplotdistributions"}
          component={CollegeBarPlots}
        />
        <Route
          path={"/dashboard/shiny/prelim/scatterplots"}
          component={ScatterPlot}
        />
        <Route
          path={"/dashboard/linkedlearning/schoolsummary"}
          component={SchoolSummary}
        />
        <Route
          path={"/dashboard/linkedlearning/pathwaylongitudinal"}
          component={LongitudinalView}
        />
        <Route
          path={"/dashboard/predictiveanalytics/schoolview"}
          component={SchoolView}
        />
        <Route
          path={"/dashboard/predictiveanalytics/networkview"}
          component={NetworkView}
        />
        <Route
          path={"/dashboard/visualizations/completeness"}
          component={DataCompletness}
        />
        <Route
          path={"/dashboard/ondemand/smallmultiples"}
          component={SmallMutliples}
        />
        <Route exact path={"/public"} component={CoreIndex} />
        <Route exact path={"/public/"} component={CoreIndex} />
        <Route exact path={"/public/core"} component={CoreIndex} />
        <Route exact path={"/public/lcap"} component={Overview} />
        <Route
          path={"/public/lcap/lcap-5x5studentgroup"}
          component={FiveByFiveStudents}
        />
        <Route
          path={"/public/lcap/lcap-5x5schools"}
          component={FiveByFiveSchools}
        />
        <Route path={"/dashboard/analytics"} component={AnalyticsDashboard} />
      </Grid>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(Main);
