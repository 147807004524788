import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  arrowContainer: {
    height: "132px",
    width: "132px",
    marginBottom: "50px",
  },
  arrow: {
    stroke: "#f29b37",
    fill: "#f29b37",
    backgroundColor: "#d8e5ec",
    strokeWidth: "5",
    strokeLinejoin: "round",
  },
  arrowTextGroup: {
    fill: "white",
    textAnchor: "middle",

    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  arrowText1: {
    fontSize: "25px",
    fontWeight: "bold",
  },
  arrowText2: {
    fontSize: "17px",
  },
});

class DownArrowSVG extends Component {
  render() {
    const { classes, x, y, measure_delta } = this.props;
    return (
      <svg x={x} y={y} className={classes.arrowContainer}>
        <g className={classes.arrow}>
          <path
            d={"M22,4 L22,65 L4,65 L66,128 L128,65 L110,65 L110,4 Z"}
          ></path>
        </g>
        <g className={classes.arrowTextGroup}>
          <text x={"66"} y={"40"} className={classes.arrowText1}>
            {measure_delta}%
          </text>
          <text x={"66"} y={"67"} className={classes.arrowText2}>
            DECREASE
          </text>
        </g>
      </svg>
    );
  }
}

export default withStyles(styles)(DownArrowSVG);
