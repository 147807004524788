import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { compose } from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import connect from "react-redux/es/connect/connect";
import AppWide from "./AppWide";
import LcapUpdate from "./LcapUpdate";
import OverviewUpdate from "./OverviewUpdate";
import {
  lcapConstants,
  updateConstants,
  updateLcapValues,
} from "../Constants/actions";
import Button from "@material-ui/core/Button";
const styles = (theme) => {};

class AdminDashboard extends Component {
  constructor(props) {
    super(props);
    const {
      getLcapConstants,
      lcap: { values },
    } = this.props;
    if (!Object.keys(values).length) {
      getLcapConstants();
    }
    this.state = {
      selected: "overviews",
    };
  }
  render() {
    const {
      measures,
      subgroups,
      collegeMeasures,
      lcap,
      lcapOverview,
      coreIndex,
      sendUpdate,
    } = this.props;
    const { selected } = this.state;
    const measureObject = Object.keys(measures).reduce((obj, key) => {
      obj[key] = {
        ...measures[key],
        college: false,
      };
      return obj;
    }, {});
    Object.keys(collegeMeasures).forEach((key) => {
      measureObject[key] = {
        ...collegeMeasures[key],
        college: true,
      };
    });
    const group = selected === "subgroups" ? subgroups : measureObject;
    return (
      <Paper>
        <Grid container>
          <Grid item>
            <Button
              onClick={() => {
                this.setState({ selected: "subgroups" });
              }}
            >
              Subgroups
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => {
                this.setState({ selected: "measures" });
              }}
            >
              Measures
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => {
                this.setState({ selected: "lcap" });
              }}
            >
              LCAP
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => {
                this.setState({ selected: "overviews" });
              }}
            >
              Overviews
            </Button>
          </Grid>
        </Grid>
        {selected === "lcap" ? (
          <LcapUpdate
            measures={measures}
            submit={this.props.submitLcapValues}
            lcap={lcap}
            overview={lcapOverview}
          />
        ) : selected === "overviews" ? (
          <OverviewUpdate
            coreIndex={coreIndex}
            lcapOverview={lcapOverview}
            measures={measures}
          />
        ) : (
          <AppWide sendUpdate={sendUpdate} selected={selected} group={group} />
        )}
      </Paper>
    );
  }
}

const mapState = (state) => {
  const { constants } = state;
  const {
    measures,
    subgroups,
    collegeMeasures,
    lcap,
    lcapOverview,
    coreIndex,
  } = constants;
  return {
    measures,
    subgroups,
    collegeMeasures,
    lcap,
    lcapOverview,
    coreIndex,
  };
};

const mapDispatch = (dispatch) => {
  return {
    getLcapConstants: () => {
      dispatch(lcapConstants());
    },
    sendUpdate: (selected, changes) => {
      dispatch(updateConstants(selected, changes));
    },
    submitLcapValues: (selected, measure, changes) => {
      dispatch(updateLcapValues(selected, measure, changes));
    },
  };
};

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapState, mapDispatch)
)(AdminDashboard);
