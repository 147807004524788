import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import connect from "react-redux/es/connect/connect";
import { compose } from "redux";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Select, { components } from "react-select";
import { generateDeeplink, getMenu, select } from "./actions";
import { menu_constants } from "./menu_constants";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Cancel from "@material-ui/icons/Cancel";
import Edit from "@material-ui/icons/Edit";
import { getArrayObjectIndex } from "./helpers";

const styles = (theme) => ({
  appBarStyles: {
    padding: "10px",
    zIndex: 50,
    backgroundColor: "#67b948",
  },
  sectionDesktop: {
    float: "right",
    // display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex !important",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing.unit,
  },
  bootstrapFormLabel: {
    fontSize: 18,
  },
  selectLabel: {
    color: theme.palette.background.paper,
  },
  icon: {
    fill: "#d0dbdf",
  },
  secondaryMenuContainerStyle: {
    display: "flex !important",
  },
});
const menuStyles = (longest, key, maxWidth) => ({
  clearIndicator: () => ({
    display: "none",
  }),
  menu: () => ({
    position: "absolute",
    backgroundColor: "#318b45",
    zIndex: 200,
    color: "#d0dbdf",
    fontSize: 16,
    borderRadius: "5px",
    cursor: "pointer",
    width: "100%",
  }),
  option: (_, state) => ({
    padding: "10px",
    minHeight: "40px",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    fontSize: "16px",
    backgroundColor: state.isFocused ? "#236030" : "#318b45",
    color: "#d0dbdf",
    "&:hover": {
      cursor: "pointer",
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    textOverflow: "ellipsis",
    color: "white",
    verticalAlign: "center",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "white",
    width: "100%",
    fontSize: "16px",
    textOverflow: "ellipsis",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  }),
  multiValueLabel: (base, { data }) => ({
    ...base,
    width: "90%",
    backgroundColor: data.configValue,
    color: "white",
  }),

  indicatorsContainer: () => ({
    position: "absolute",
    color: "#d0dbdf",
    right: 0,
    bottom: 0,
  }),
  input: () => ({
    color: "#d0dbdf",
    textOverflow: "ellipsis",
  }),
  control: () => ({
    width: "100%",
    padding: "5px",
    paddingTop: "10px",
    minHeight: "40px",
    textOverflow: "ellipsis",
    borderRadius: 10,
    maxHeight: "80px",
    overflowY: "auto",
    backgroundColor: "#318b45",
    color: "#d0dbdf",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#236030",
      color: "#d0dbdf",
    },
  }),
  groupHeading: (base) => ({
    ...base,
    textAlign: "center",
    fontSize: "1.85vh",
    fontWeight: "700",
    color: "#d0dbdf",
    borderBottom: "2px solid #d0dbdf",
    borderTop: "2px solid #d0dbdf",
  }),
});
const ColorSelectingOption = (props) => {
  const {
    data: { text, configValue, label },
  } = props;
  const maxWidth = label ? (label.length > 10 ? "50%" : false) : false;
  return (
    <div style={{ maxHeight: "40px", maxWidth }}>
      <components.MultiValueContainer {...props} />
    </div>
  );
};
const MenuLabel = (key, text, classes, clear, toggleEdit) => {
  switch (key) {
    case "multi_groups":
      return (
        <Grid alignItems={"center"} container item>
          <Grid item>
            <Typography className={classes.selectLabel}>{text}</Typography>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => {
                toggleEdit(key);
              }}
              fontSize={"small"}
            >
              <Edit fontSize={"small"} />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => {
                clear([], key, true);
              }}
            >
              <Cancel fontSize={"small"} />
            </IconButton>
          </Grid>
        </Grid>
      );
    default:
      return (
        <Grid xs={12} item>
          <Typography className={classes.selectLabel}>{text}</Typography>
        </Grid>
      );
  }
};

class SecondaryMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previousDistrict: {
        Network: "",
      },
    };
  }

  handleChange = (event, name, multi, type) => {
    const value = multi ? (event.length > 0 ? event : "CLEAR") : event.value;
    const input = { name, value };
    const { select, menu, selected, path, constants, menuObject } = this.props;
    this.setState({
      previousDistrict: selected,
    });
    select(input, menu, selected, path, constants, menuObject);
  };

  render() {
    const defaultSelections = {
      metricTrends: {
        "Student Groups": "All Students (ALL)",
        Measure: "NSC 2 & 4 Year College First Year Enroll Rate (Preliminary)",
        key: "label",
      },
      collegeDistribution: {
        "Student Groups": "All Students (ALL)",
        Measure: "NSC 2 & 4 Year College First Year Enroll Rate (Preliminary)",
        key: "label",
      },
      gapTrends: {
        "Student Groups": "Socio-Economically Disadvantaged Students (SD)",
        Measure: "NSC 2 & 4 Year College First Year Enroll Rate (Preliminary)",
        key: "label",
      },
      barPlots: {
        "Student Groups": "All Students (ALL)",
        Measure: "Fall Enrollment (1st Year) Enrollment Rate",
        key: "label",
      },
      scatterPlots: {
        "Student Groups": "All Students (ALL)",
        key: "label",
      },
    };
    const { selected, menuObject, path, classes, toggleEdit } = this.props;
    const { extra_options, menuLists } = menu_constants;
    const menuList = menuLists[path] || [];
    const stateChange =
      this.state.previousDistrict.Network !== selected["Network"];

    const showMenu = menuList.reduce((show, currColumn) => {
      const { key } = extra_options[currColumn] || {};
      const currMenu = menuObject[key] ? menuObject[key] : [];
      if (currMenu.length) {
        return true;
      }
      return show;
    }, false);

    if (!showMenu) {
      return <div style={{ width: "100%", height: "60px" }}></div>;
    }
    return (
      <div className={classes.sectionDesktop}>
        <div style={{ position: "relative" }} className={classes.appBarStyles}>
          <Toolbar>
            <Grid
              className={classes.secondaryMenuContainerStyle}
              container
              item
              justifyContent={"flex-start"}
              direction={"row"}
              alignItems={"flex-end"}
              wrap="nowrap"
            >
              {menuList.map((currColumn) => {
                const { text, type, key } = extra_options[currColumn] || {};
                const currMenu = menuObject[key] ? menuObject[key] : [];
                const maxWidth = "15vw";

                if (
                  !Object.keys(extra_options).includes(key) ||
                  !currMenu.length
                ) {
                  return null;
                }

                const isMulti = key.substring(0, 5) === "multi";
                const isList = ["multi_group", "multi_school"].includes(key);
                const options =
                  type === 0 && !isMulti
                    ? currMenu.map((curr) => {
                        return {
                          value: curr.code,
                          label: curr.text,
                        };
                      })
                    : type === 0 && isMulti
                    ? currMenu.map((curr) => {
                        return {
                          value: curr.text,
                          label: curr.text,
                        };
                      })
                    : currMenu.map((curr) => {
                        return {
                          label: curr,
                          value: curr,
                        };
                      });
                const selectedValue =
                  isList && selected[key]
                    ? selected[key].list
                    : selected[key] || (isMulti ? {} : "");
                let longest = 13;
                const flatValues = isMulti ? Object.keys(selectedValue) : [];

                const metricCollegeTrendKeys = [
                  "Measure",
                  "Student Groups",
                ].some((keyExists) => keyExists === currColumn);

                const label = options.reduce((obj, curr) => {
                  const { label, value } = curr;
                  const configValue = isMulti ? selectedValue[value] : "";
                  longest = Math.max(label.toString().length, longest);
                  if (isMulti) {
                    if (flatValues.includes(value)) {
                      return obj.concat([{ ...curr, configValue }]);
                    }
                  } else if (metricCollegeTrendKeys && stateChange) {
                    if (path === "/dashboard/shiny/prelim/metrictrends") {
                      return obj.concat(
                        getArrayObjectIndex(
                          options,
                          defaultSelections.metricTrends.key,
                          defaultSelections.metricTrends[currColumn]
                        )
                      );
                    } else if (
                      path === "/dashboard/shiny/prelim/collegedistribution"
                    ) {
                      return obj.concat(
                        getArrayObjectIndex(
                          options,
                          defaultSelections.collegeDistribution.key,
                          defaultSelections.collegeDistribution[currColumn]
                        )
                      );
                    } else if (path === "/dashboard/shiny/prelim/gaptrends") {
                      return obj.concat(
                        getArrayObjectIndex(
                          options,
                          defaultSelections.gapTrends.key,
                          defaultSelections.gapTrends[currColumn]
                        )
                      );
                    } else if (
                      path === "/dashboard/shiny/prelim/barplotdistributions"
                    ) {
                      return obj.concat(
                        getArrayObjectIndex(
                          options,
                          defaultSelections.barPlots.key,
                          defaultSelections.barPlots[currColumn]
                        )
                      );
                    } else if (
                      path === "/dashboard/shiny/prelim/scatterplots"
                    ) {
                      return obj.concat(
                        getArrayObjectIndex(
                          options,
                          defaultSelections.scatterPlots.key,
                          defaultSelections.scatterPlots[currColumn]
                        )
                      );
                    } else if (path === "/dashboard/ondemand/mapview") {
                      if (value === selectedValue) {
                        return obj.concat([curr]);
                      }
                    }
                  } else if (value === selectedValue) {
                    return obj.concat([curr]);
                  }
                  return obj;
                }, []);
                let value = isMulti ? label : label[0];
                if (currColumn === "Measure") {
                  value = options.find((el) => el.value === selectedValue);
                }

                return (
                  <Grid
                    style={{
                      marginRight: "5px",
                      marginTop: "5px",
                      paddingBottom: "5px",
                      minWidth: maxWidth,
                      maxWidth,
                    }}
                    container
                    item
                    direction={"column"}
                    key={key}
                  >
                    <Grid xs={12} item>
                      {MenuLabel(
                        key,
                        text,
                        classes,
                        this.handleChange.bind(this),
                        toggleEdit
                      )}
                    </Grid>
                    <Grid style={{ height: "50px" }} xs={12} item>
                      <Select
                        styles={menuStyles(longest, key, maxWidth)}
                        className={classes.input}
                        isMulti={isMulti}
                        components={{
                          MultiValueContainer: ColorSelectingOption,
                        }}
                        options={options}
                        value={value || options[0]}
                        onChange={(e) => {
                          this.handleChange(e, key, isMulti, type);
                        }}
                      />
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Toolbar>
        </div>
      </div>
    );
    //}
  }
}

const mapLogin = (state) => {
  const { menu, selected, menuObject, sliders } = state.menu;
  const adjustedMeasures =  menuObject["Measure"].filter((obj)=> !["growth_ela", "growth_math"].includes(obj.code))
  const { subgroups, measures, collegeMeasures } = state.constants;
  return {
    menu,
    selected,
    menuObject: {...menuObject, Measure: adjustedMeasures},
    sliders,
    constants: {
      subgroups,
      measures,
      collegeMeasures,
    },
  };
};

const mapDispatch = (dispatch) => {
  return {
    getMenu: (route) => {
      dispatch(getMenu(route));
    },
    select: (input, menu, selected, path, constants, menuObject) => {
      dispatch(select(input, menu, selected, path, constants, menuObject));
    },
    DeepLink: (path, selected, sliders) => {
      dispatch(generateDeeplink(path, selected, sliders));
    },
  };
};

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapLogin, mapDispatch)
)(SecondaryMenu);
