import { FETCH_METRICS, FETCH_METRICS_SUCCESS } from "./actions";

const initialState = {
  metrics: {},
  schools5x5: {},
  growthSchoolReport: {},
  gapTrends: {},
  metricTrends: {},
  scatterPlots: {},
  collegeDistribution: {},
  collegeBarPlot: {},
  schoolSummary: {},
  pathwayLongitudinal: {},
  schoolViewPA: {},
  networkViewPA: {},
  loading: false,
};

export default function metrics(state = initialState, action) {
  switch (action.type) {
    case FETCH_METRICS:
      return Object.assign({}, state, {
        loading: true,
      });
    case FETCH_METRICS_SUCCESS:
      return Object.assign({}, state, {
        [action.name]: action.metrics,
        loading: false,
      });
    default:
      return state;
  }
}
