import { FETCH_MAP_DATA } from "../../actions/Map/MapView";

const initialState = {
  mapView: {},
  coordinates: {
    latitude: [],
    longitude: [],
  },
};

export default function mapView(state = initialState, action) {
  switch (action.type) {
    case FETCH_MAP_DATA:
      return {
        ...state,
        mapView: action.payload.return_object,
        coordinates: {
          latitude: action.payload.coordinates.latitude,
          longitude: action.payload.coordinates.longitude,
        },
      };
    default:
      return state;
  }
}
