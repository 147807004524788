const styles = (theme) => ({
  redBar: {
    fill: "#ef4b4b",
    borderBottom: "1px solid white",
    backgroundImage:
      "repeating-linear-gradient(45deg,transparent,transparent 10px,rgba(0,0,0,.06) 0,rgba(0,0,0,.06) 11px)",
    background: "#ef4b4b",
    stroke: "#792323",
    strokeWidth: 0,
  },
  orangeBar: {
    background: "#f4861f",
    borderBottom: "1px solid white",
    backgroundImage:
      "repeating-linear-gradient(-45deg,transparent,transparent 10px,rgba(0,0,0,.06) 0,rgba(0,0,0,.06) 11px)",
    fill: "#f4861f",
    stroke: "#a05515",
    strokeWidth: 0,
  },
  greenBar: {
    background: "#67b948",
    backgroundImage:
      "repeating-linear-gradient(-45deg,transparent,transparent 5px,rgba(0,0,0,.06) 0,rgba(0,0,0,.06) 6px)",
    fill: "#67b948",
    stroke: "#396f30",
    strokeWidth: 0,
  },
  blueBar: {
    background: "#127ea8",
    backgroundImage:
      "repeating-linear-gradient(0deg,transparent,transparent 10px,rgba(0,0,0,.06) 0,rgba(0,0,0,.06) 11px)",
    fill: "#127ea8",
  },
  yellowBar: {
    background: "#f0f41f",
    backgroundImage:
      "repeating-linear-gradient(90deg,transparent,transparent 5px,rgba(0,0,0,.06) 0,rgba(0,0,0,.06) 6px)",
    fill: "#f0f41f",
  },
  redOutline: {
    fill: "#792323",
    strokeWidth: 0,
  },
  orangeOutline: {
    fill: "#a05515",
    strokeWidth: 0,
  },
  greenOutline: {
    fill: "#396f30",
  },
  tableRow: {
    paddingTop: 0,
    paddingBottom: 5,
    "&:nth-of-type(odd)": {
      backgroundColor: "rgba(1,61,84,.05)",
    },
  },
  tableHead: {
    color: "rgba(61,114,135,.8)",
    padding: 12,
    fontSize: 14,
    fontWeight: 900,
    borderBottom: "2px solid #BBCAD0",
    borderLeft: "2px solid #BBCAD0",
    textAlign: "center",
  },
  tableRowOpen: {
    paddingTop: 0,
    paddingBottom: 5,
    "&:nth-of-type(odd)": {
      backgroundColor: "rgba(1,61,84,.05)",
    },
  },
  tableCellHead: {
    borderBottom: "0px solid red",
    borderLeft: "2px solid #BBCAD0",
    textAlign: "center",
    color: "rgba(61,114,135,.8)",
    fontSize: "14px",
    fontWeight: "900",
    padding: "12px",
  },
  table: {
    border: "2px solid #BBCAD0",
    padding: "1px",
    boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
    margin: "2.5px",
    width: "100%",
    maxWidth: "calc(100% - 5px)",
    backgroundColor: "transparent",
    borderSpacing: "0",
    borderCollapse: "collapse",
  },
  tableCell: {
    padding: 0,
    borderBottom: "0px solid red",
    borderLeft: "2px solid #BBCAD0",
  },
  sortIcon: {
    marginLeft: 5,
    height: 30,
    padding: 0,
    background: null,
    "&:hover": {
      cursor: "pointer",
    },
  },
  changeRed: {
    backgroundColor: "#ef4b4b",
    backgroundImage:
      "repeating-linear-gradient(45deg,transparent,transparent 10px,rgba(0,0,0,.06) 0,rgba(0,0,0,.06) 11px)",
  },
  changeGreen: {
    backgroundColor: "#67b948",
    backgroundImage:
      "repeating-linear-gradient(-45deg,transparent,transparent 5px,rgba(0,0,0,.06) 0,rgba(0,0,0,.06) 6px)",
  },
  changeUpArrow: {
    height: "25px",
    width: "30px",
    position: "relative",
    transform: "rotate(-90deg)",
    top: "20%",
    left: "25%",
  },
  changeSideArrow: {
    height: "25px",
    width: "30px",
    position: "relative",
    top: "25%",
    left: "25%",
  },
  changeDownArrow: {
    height: "25px",
    width: "30px",
    transform: "rotate(90deg)",
    position: "relative",
    top: "20%",
    left: "25%",
  },
  removePrint: {
    backgroundImage: "none",
  },
  growthTooltip: {
    textAlign: "center",
    border: "2px solid #BBCAD0",
    borderRadius: 5,
    background: "white",
    opacity: 1,
    padding: 0,
    margin: 0,
    color: "rgba(61,114,135,.8)",
    fontSize: "14px",
    fontWeight: "900",
  },
  test: {
    padding: 2,
    margin: 0,
    opacity: 1,
    background: "rgba(255,255,255,0)",
  },
  toolTipTypography: {
    color: "rgba(61,114,135,.8)",
    fontSize: "14px",
    fontWeight: "900",
  },
});
export default styles;
