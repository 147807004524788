export const columns = {
  "INDEX RESULTS": [
    "NAME",
    "METRIC RESULT-PREV-CORE",
    "METRIC RESULT-CURR-CORE",
    "METRIC CHANGE-PREV-CURR",
    "INDEX LEVEL-CURR",
    "INDEX CHANGE-PREV-CURR",
  ],
  "AMOUNT OF IMPROVEMENT NEEDED": [
    "NAME",
    "INDEX LEVEL-CURR",
    "METRIC RESULT-CURR-CORE",
    "METRIC IMPROVE-CURR-1",
    "METRIC IMPROVE-CURR-2",
    "METRIC IMPROVE-CURR-3",
  ],
  "SUBGROUP PERFORMANCE ACADEMIC": [
    "NAME",
    "METRIC RESULT-CURR-CORE",
    "LOWEST PERFORMING-CURR",
    "SUBGROUP-CURR-SWD-",
    "SUBGROUP-CURR-SD-",
    "SUBGROUP-CURR-EL-",
    "SUBGROUP-CURR-ELP-",
    "SUBGROUP-CURR-ELRFEP-",
  ],
  "SUBGROUP PERFORMANCE SOCIAL": [
    "NAME",
    "METRIC RESULT-CURR-CORE",
    "LOWEST PERFORMING-CURR",
    "SUBGROUP-CURR-SWD-",
    "SUBGROUP-CURR-SD-",
    "SUBGROUP-CURR-EL-",
    "SUBGROUP-CURR-ELP-",
    "SUBGROUP-CURR-HMLS-",
  ],
  "RACIAL/ETHNIC PERFORMANCE": [
    "NAME",
    "SUBGROUP-CURR-AA-YES",
    "SUBGROUP-CURR-AI-YES",
    "SUBGROUP-CURR-AS-YES",
    "SUBGROUP-CURR-FI-YES",
    "SUBGROUP-CURR-HI-YES",
    "SUBGROUP-CURR-PI-YES",
    "SUBGROUP-CURR-MR-YES",
    "SUBGROUP-CURR-WH-YES",
  ],
  "LCAP OVERVIEW": [
    "NAME",
    "METRIC RESULT-PREV-LCAP",
    "METRIC RESULT-CURR-LCAP",
    "METRIC CHANGE-PREV-CURR",
    "LCAP PERFORMANCE-CURR",
  ],
};
export const footers = {
  LCAP: [
    {
      color: "blue",
      text: "Strongest Performance or Change",
    },
    {
      color: "green",
      text: "Strong Performance or Change",
    },
    {
      color: "yellow",
      text: "Medium Performance or Change",
    },
    {
      color: "orange",
      text: "Weak Performance or Change",
    },
    {
      color: "red",
      text: "Weakest Performance or Change",
    },
  ],
  CORE: [
    {
      color: "green",
      text: "Above Average (Index Levels 8, 9, and 10)",
    },
    {
      color: "orange",
      text: "Average (Index Levels 4, 5, 6, and 7)",
    },
    {
      color: "red",
      text: "Below Average (Index Levels 1, 2, and 3)",
    },
  ],
};
