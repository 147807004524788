import { Component } from "react";
import Grid from "@material-ui/core/Grid/Grid";
import { compose } from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import connect from "react-redux/es/connect/connect";
import React from "react";
import CardHeader from "../StyledComponents/Card/CardHeader";
import Card from "../StyledComponents/Card/Card";
import tableStyle from "../assets/jss/core-dashboard/components/tableStyle";
import LoadingGrid from "../StyledComponents/Grid/LoadingGrid";
import { DistributionTable } from "../StyledComponents/Table/onTrackGradeDistributionTable";
import styles from "../AcademicMeasures/GrowthBarCharts/styles.js";
import Typography from "@material-ui/core/Typography";
import TitleTable from "../StyledComponents/Table/TitleTable";
import { ColoredFont } from "../Helpers/GlobalHelpers";

const onTrackStyles = (theme) => ({
  onTrackRatesBySubjectRoot: {
    width: "100%",
    margin: "0 2.5% 0 2.5%",
  },
  overviewText: {
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "25px",
    fontSize: "20px",
    color: "rgba(60,114,135,.9)",
    lineHeight: "40px",
  },
  onTrackRatesLineGraph: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  colorBar: {
    padding: 5,
  },
  sortIcon: {
    marginLeft: 5,
    height: 30,
    padding: 0,
    background: null,
    "&:hover": {
      cursor: "pointer",
    },
  },
  gradeDistributionTableHead: {
    color: "rgba(61,114,135,.8)",
    padding: 12,
    fontSize: 14,
    fontWeight: 900,
    borderBottom: "2px solid #BBCAD0",
    borderLeft: "2px solid #BBCAD0",
    textAlign: "center",
  },
});

class OnTrackGradeDistribution extends Component {
  render() {
    const { data, classes, loading, selectedSubgroup, subgroups } = this.props;
    return (
      <Grid
        container
        justifyContent="center"
        className={classes.onTrackRatesBySubjectRoot}
      >
        <TitleTable
          hideInfo
          school={{}}
          district={{}}
          pageTitle={"Ordered Bar Charts"}
        />
        <Grid item style={{ padding: 10 }} xs={6}>
          <Typography>
            A school's or grade level team's on-track percentage represents how
            many students fall into the top three lanes —secondary potential,
            post-secondary promising, or post-secondary competitive. If a school
            has an on-track rate of 50%, half of their student population is
            on-track to graduate high-school and attend college. School group
            on-track percentages above 70% are highlighted{" "}
            <ColoredFont color={"green"} text={"green"} />, school groups with
            40-70% on track rates are highlighted{" "}
            <ColoredFont color={"orange"} text={"orange"} />, and those below
            40% are highlighted <ColoredFont color={"red"} text={"red"} />. The
            arrow in the 'Difference' column shows the difference in percentage
            points between the latest year and the prior year.
          </Typography>
        </Grid>
        <Grid item style={{ padding: 10, paddingRight: 20 }} xs={6}>
          <Typography>
            In the on track model, students fall into one of five "lanes"—highly
            vulnerable, vulnerable, post-secondary potential, post-secondary
            promising, or post-secondary competitive—based upon a 0 to 100 scale
            informed by students’ strength of course-taking schedule, course
            grades, attendance, and, when available, test scores. These lanes
            range from students at greatest risk of not graduating from high
            school to students who will almost certainly graduate prepared for
            four-year college success, with gradations in between.
          </Typography>
        </Grid>
        <Card className={classes.onTrackCard}>
          <CardHeader className={classes.gradientBackground} color="primary">
            <h4 className={classes.cardTitleBlue}>
              On-Track - Ordered Bar Charts
            </h4>
          </CardHeader>
        </Card>
        <Grid container style={{ margin: "2.5%", marginTop: "1%" }}>
          {loading ? (
            <LoadingGrid />
          ) : (
            <DistributionTable
              data={data}
              selectedSubgroup={selectedSubgroup}
              classes={classes}
              subgroups={subgroups}
            />
          )}
        </Grid>
      </Grid>
    );
  }
}

const findInMenu = (array, key) => {
  const res = {
    code: "",
    text: "",
  };
  if (!array) {
    return res;
  }
  return array.reduce((found, curr) => {
    if (curr.code === key) {
      found = curr;
    }
    return found;
  }, res);
};

const mapState = (state) => {
  const { gradeDistribution, loading } = state.onTrack;
  const selectedSubgroup = state.menu.selected["Student Groups"];
  const {
    constants: { subgroups },
  } = state;
  return {
    data: gradeDistribution,
    loading,
    selectedSubgroup,
    subgroups,
  };
};

export default compose(
  withStyles((theme) => ({
    ...tableStyle(theme),
    ...styles(theme),
    ...onTrackStyles(theme),
  })),
  connect(mapState)
)(OnTrackGradeDistribution);
