import React, { Component } from "react";
import { compose } from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import connect from "react-redux/es/connect/connect";
import Grid from "@material-ui/core/Grid";
import mapViewStyle from "../assets/jss/mapView";
import GoogleMapReact, { fitBounds } from "google-map-react";
import { fetchMapData } from "../actions/Map/MapView";
import logo from "../assets/images/coreLogo.png";
import { Typography } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { PlotData } from "../OnDemandAnalytics/PlotData";
import { tableStyling } from "../OnDemandAnalytics/plotDataStyles";
import { addCommas } from "../utils";
import TableTitle from "../StyledComponents/Table/TitleTable";
import { app_constants } from "../constants";
import Paper from "@material-ui/core/Paper";
import { footers } from "../Overviews/constants";
import { footer } from "../Helpers/GlobalHelpers";

/**
 * Component creates Google map and appends points to map regarding school location.
 * */

const colors = {
  green: "#67B848",
  red: "#ED4B4B",
  orange: "#F2851F",
  blue: "#1d5e77",
};

const MapToolTip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#ffffff",
    color: "#000000",
    fontSize: theme.typography.pxToRem(14),
  },
}))(Tooltip);

/**
 * Creates points on map.
 * */
const AnyReactComponent = (props) => {
  const { place, selectedSchool, district } = props;
  if (place.default_map_view) {
    return (
      <Paper
        style={{
          width: 200,
          fontFamily: ["Roboto", "Arial", "sansSerif"],
          height: 90,
          square: false,
          color: "#000000",
          fontSize: 25,
        }}
      >
        Data is Not Available for this selection.
      </Paper>
    );
  }

  return (
    <React.Fragment>
      <MapToolTip
        title={
          <React.Fragment>
            <Typography>District: {place.district_name}</Typography>
            <Typography>School: {place.school_name}</Typography>
            <Typography>Measure Rate: {place.meas_rate}%</Typography>
            <span>Index Value: </span>
            <span style={{ color: colors[place.index_level_color] }}>
              {place.index_level}
            </span>
            <Typography>Total Students: {place.denominator}</Typography>
          </React.Fragment>
        }
        placement={"top-start"}
      >
        <div
          style={{
            cursor: "pointer",
            height: selectedSchool && district !== "overall" ? 25 : 15,
            width: selectedSchool && district !== "overall" ? 25 : 15,
            backgroundColor: colors[place.index_level_color] || "#bbbbbb",
            borderRadius: "100%",
            display: "inline-block",
          }}
        />
      </MapToolTip>
    </React.Fragment>
  );
};

/**
 * Scales view of map based on coordinates provided.
 * @param places:{array}
 * @param latCoordinates
 * @param lngCoordinates
 * @return {object}
 * */
function scaleMap(places, latCoordinates, lngCoordinates) {
  let mapDiv = document.getElementById("map");

  const bounds = {
    nw: {
      lat: Math.max(...latCoordinates),
      lng: Math.min(...lngCoordinates),
    },
    se: {
      lat: Math.min(...latCoordinates),
      lng: Math.max(...lngCoordinates),
    },
  };
  const size = {
    width: mapDiv ? mapDiv.offsetWidth : window.innerWidth - 220, // Map width in pixels
    height: mapDiv ? mapDiv.offsetHeight : window.innerHeight - 177, // Map height in pixels
  };
  const { center, zoom } = fitBounds(bounds, size);
  return places.length === 1
    ? {
        center: {
          lat: Number(places[0].latitude),
          lng: Number(places[0].longitude),
        },
        zoom: places[0].default_map_view ? 7 : 15,
      }
    : {
        center: center,
        zoom: zoom,
      };
}

class MapView extends Component {
  constructor(props) {
    super(props);
    const { places, latCoordinates, lngCoordinates } = props;
    const mapScale = scaleMap(places, latCoordinates, lngCoordinates);
    this.state = {
      zoom: mapScale.zoom || 15,
      center: mapScale.center,
      key: process.env.REACT_APP_GOOGLE_MAP_API,
      places: places,
    };
  }

  componentDidMount() {
    const {
      fetchMapData,
      Network,
      District,
      schoolYear,
      schoolLevel,
      studentGroups,
      Measure,
    } = this.props;
    const measureHelper = !Object.keys(
      app_constants.collegeMeasureCodeToName
    ).includes(Measure)
      ? Measure
      : Object.keys(app_constants.measureCodeToName)[0];
    if (District !== "overall") {
      fetchMapData(
        Network,
        District,
        schoolLevel,
        schoolYear,
        studentGroups,
        measureHelper
      );
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      places,
      fetchMapData,
      Network,
      District,
      lngCoordinates,
      latCoordinates,
      schoolYear,
      schoolLevel,
      studentGroups,
      Measure,
    } = this.props;

    if (
      prevProps.lngCoordinates !== lngCoordinates ||
      prevProps.latCoordinates !== latCoordinates
    ) {
      this.setState(scaleMap(places, latCoordinates, lngCoordinates));
    }
    if (
      (prevProps.Network !== Network ||
        prevProps.District !== District ||
        prevProps.schoolYear !== schoolYear ||
        prevProps.schoolLevel !== schoolLevel ||
        prevProps.studentGroups !== studentGroups ||
        prevProps.Measure !== Measure) &&
      !Object.keys(app_constants.collegeMeasureCodeToName).includes(
        prevProps.Measure
      )
    ) {
      fetchMapData(
        Network,
        District,
        schoolLevel,
        schoolYear,
        studentGroups,
        Measure
      );
      this.setState(scaleMap(places, latCoordinates, lngCoordinates));
    }
  }

  render() {
    const {
      classes,
      places,
      isLoading,
      studentGroups,
      schoolYear,
      Measure,
      School,
      District,
      columnToggler,
    } = this.props;
    const fileName = `MapView-${studentGroups}-${schoolYear}-${Measure}`;
    const checkIfSelected = (place, selected) => {
      return selected === place;
    };

    function drawPoints() {
      const points = [];
      places.forEach((place) => {
        let selectedSchool = checkIfSelected(place.school_code, School);
        if (!selectedSchool) {
          points.unshift(
            <AnyReactComponent
              lat={Number(place.latitude)}
              lng={Number(place.longitude)}
              place={place}
              selectedSchool={selectedSchool}
              district={District}
            />
          );
        } else {
          points.push(
            <AnyReactComponent
              lat={Number(place.latitude)}
              lng={Number(place.longitude)}
              place={place}
              selectedSchool={selectedSchool}
              district={District}
            />
          );
        }
      });
      return points;
    }

    function createMapOptions() {
      const toggler = (places[0] || {}).default_map_view;
      return {
        minZoom: 7,
        scrollwheel: !toggler,
        disableDefaultUI: toggler,
        gestureHandling: toggler ? "none" : "greedy",
      };
    }

    const columns = [
      {
        title: "Name",
        field: "school_name",
        cellStyle: tableStyling.firstData,
        headerStyle: tableStyling.firstHeader,
        defaultSort: "asc",
      },
      {
        grouping: false,
        title: "Measure Rate",
        field: "meas_rate",
        cellStyle: tableStyling.dataRow,
        headerStyle: tableStyling.title,
        render: (rowData) =>
          !rowData.meas_rate ? (
            <p style={{ margin: 5, color: "#d1d1d1" }}>No Data</p>
          ) : (
            `${rowData.meas_rate}`
          ),
      },
      {
        grouping: false,
        title: "Index Value",
        field: "index_level",
        cellStyle: tableStyling.dataRow,
        headerStyle: tableStyling.title,
        render: (rowData) =>
          !rowData.index_level ? (
            <p style={{ margin: 5, color: "#d1d1d1" }}>No Data</p>
          ) : (
            `${rowData.index_level}`
          ),
      },
      {
        grouping: false,
        title: columnToggler
          ? "Total Distance From Standards"
          : "Number of Students",
        field: "numerator",
        cellStyle: tableStyling.dataRow,
        headerStyle: tableStyling.title,
        render: (rowData) =>
          !rowData.numerator ? (
            <p style={{ margin: 5, color: "#d1d1d1" }}>No Data</p>
          ) : (
            `${addCommas(`${rowData.numerator}`)}`
          ),
      },
      {
        grouping: false,
        title: "Total Students",
        field: "denominator",
        cellStyle: tableStyling.dataRow,
        headerStyle: tableStyling.title,
        render: (rowData) =>
          !rowData.denominator ? (
            <p style={{ margin: 5, color: "#d1d1d1" }}>No Data</p>
          ) : (
            `${addCommas(`${rowData.denominator}`)}`
          ),
      },
    ];
    return (
      <Grid container style={{ margin: "0 2.5% 0 2.5%", width: "95%" }}>
        <TableTitle
          school={School}
          district={District}
          hideInfo
          pageTitle={"Map View"}
          mapView={true}
        />
        <div className={classes.parentDiv} id={"map"}>
          {isLoading ? (
            <Grid
              justifyContent={"center"}
              alignItems={"center"}
              container
              direction={"column"}
              style={{ height: "100%", width: "100%" }}
            >
              <Grid item>
                <img
                  src={logo}
                  alt={"spinning core logo"}
                  style={{
                    height: "20vh",
                    width: "20vh",
                  }}
                  className={"rotatingImage"}
                />
              </Grid>
            </Grid>
          ) : (
            <GoogleMapReact
              bootstrapURLKeys={{ key: this.state.key }}
              defaultCenter={this.state.center}
              defaultZoom={this.state.zoom}
              zoom={this.state.zoom}
              center={this.state.center}
              options={createMapOptions}
            >
              {drawPoints()}
            </GoogleMapReact>
          )}
          <Grid container className={classes.legend}>
            {footer({ pageType: "mapView", title: "Plot Data", classes })}
          </Grid>
          <PlotData
            loading={isLoading}
            fileName={fileName}
            classes={classes}
            data={places}
            columns={columns}
          />
        </div>
      </Grid>
    );
  }
}

const mapState = (state) => {
  const loadingStates = state.loading;
  const isLoading = Object.values(loadingStates).some(
    (loading) => loading === true
  );
  const selected = state.menu.selected;
  const optionsAvailable = state.menu.menuObject.School;
  const { District, Network, Measure, School } = selected;
  const schoolSelected =
    School === "overall" ? (optionsAvailable[0] || {}).code || "" : School;
  const schoolYear = selected["School Year"];
  const schoolLevel = selected["School Level"];
  const studentGroups = selected["Student Groups"];
  const measure = selected["Measure"];
  const {
    mapView,
    coordinates: { latitude, longitude },
  } = state.mapView || {};
  const places = mapView[studentGroups] || [];
  const columnToggler = [
    "dfm_math",
    "dfm_ela",
    "sbac_dfm_math",
    "sbac_dfm_ela",
    "caa_dfm_math",
    "caa_dfm_ela",
  ].includes(measure);
  // Default case
  if (places.length === 0) {
    places.push({
      latitude: 36.7783,
      longitude: -119.4179,
      default_map_view: true,
    });
  }
  return {
    columnToggler,
    places,
    Network,
    District,
    latCoordinates: latitude,
    lngCoordinates: longitude,
    isLoading,
    schoolYear,
    schoolLevel,
    studentGroups,
    Measure,
    School: schoolSelected,
  };
};

const mapDispatch = (dispatch) => {
  return {
    fetchMapData: (
      network_code,
      district_code,
      level,
      school_year,
      student_groups,
      subject
    ) =>
      dispatch(
        fetchMapData(
          network_code,
          district_code,
          level,
          school_year,
          student_groups,
          subject
        )
      ),
  };
};

export default compose(
  withStyles(mapViewStyle, { withTheme: true }),
  connect(mapState, mapDispatch)
)(MapView);
