import {
  dangerColor,
  defaultFont,
  grayColor,
  infoColor,
  primaryColor,
  roseColor,
  successColor,
  warningColor,
} from "../../core-dashboard";

const tableStyle = (theme) => ({
  plot: {
    border: "2px solid #BBCAD0",
    boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
  },
  gradientBackground: {
    background:
      "linear-gradient(90deg, rgba(216,229,237,1) 69%, rgba(255,255,255,1) 82%) !important",
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontSize: "18px",
    fontWeight: "175",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  cardTitleBlue: {
    color: "#4D7E91",
    marginTop: "0px",
    minHeight: "auto",
    fontSize: "18px",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "0px",
    textDecoration: "none",
    fontWeight: "850",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  coreOrange: "#f4861f",
  coreRed: "#ef4b4b",
  coreGreen: "#67b948",
  noColorSideBar: "rgb(0,0,0,0)",
  warningTableHeader: {
    color: warningColor,
  },
  primaryTableHeader: {
    color: primaryColor,
  },
  dangerTableHeader: {
    color: dangerColor,
  },
  successTableHeader: {
    color: successColor,
  },
  infoTableHeader: {
    color: infoColor,
  },
  roseTableHeader: {
    color: roseColor,
  },
  grayTableHeader: {
    color: grayColor,
  },
  table: {
    border: "2px solid #BBCAD0",
    padding: "1px",
    boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
    margin: "2.5px",
    width: "100%",
    maxWidth: "calc(100% - 5px)",
    backgroundColor: "transparent",
    borderSpacing: "0",
    borderCollapse: "collapse",
  },
  tableRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: "rgba(1,61,84,.05)",
    },
  },
  tableCell: {
    minWidth: "140px",
    padding: "5px",
    borderBottom: "0px solid red",
    borderLeft: "2px solid #BBCAD0",
  },
  emptyCell: {
    borderBottom: "0px solid red",
    borderLeft: "2px solid #BBCAD0",
    background: "rgba(0,0,0,.025)",
  },
  noDataRow: {
    whiteSpace: "nowrap",
  },
  coreTableHead: {
    paddingTop: "0px",
    borderBottom: "2px solid #BBCAD0",
    color: "rgba(61,114,135,.8)",
    fontSize: "14px",
    fontWeight: "900",
    padding: "12px",
    // maxWidth:'250px'
  },
  tableHeadCell: {
    color: "inherit",
    ...defaultFont,
    fontSize: "1em",
    fontWeight: "900",
  },
  tableResponsive: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  tooltip: {
    fontSize: "1rem",
    textAlign: "center",
  },
  rowTitleGroup: {
    lineHeight: 1.125,
    marginLeft: "5px",
    color: "rgba(61,114,135,.8)",
    fontWeight: "900",
    fontSize: "14px",
    marginRight: "5px",
    maxWidth: "250px",
  },
  demoCellText: {
    color: "rgba(0,0,0,.7)",
    fontWeight: "400",
    fontSize: "14px",
    paddingTop: "3px",
  },
  metricText: {
    color: "rgba(0,0,0,.7)",
    fontWeight: "900",
    fontSize: "24px",
    position: "relative",
  },
  metricSubText: {
    color: "rgba(0,0,0,.5)",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: 1.125,
  },
  indexLevelNum: {
    margin: "0px",
    color: "rgba(0,0,0,.7)",
    fontWeight: "900",
    fontSize: "24px",
  },
  indexLevelText: {
    margin: "0px",
    color: "rgba(0,0,0,.5)",
    fontWeight: "400",
    fontSize: "12px",
  },
  indexChange: {
    color: "rgba(0,0,0,.7)",
    fontWeight: "900",
    fontSize: "24px",
  },
  lowestPerfSub: {
    color: "rgba(0,0,0,.3)",
    fontWeight: "900",
    fontSize: "14px",
  },
  noData: {
    padding: "0px",
    color: "rgba(0,0,0,.1)",
    fontWeight: "900",
    fontSize: "24px",
  },
  bottomBorderOnly: {
    borderBottom: "5px solid rgba(224, 224, 224, 1)",
  },
  orangeBackdrop: {
    textAlign: "center",
    backgroundColor: "#f4861f",
    backgroundImage:
      "repeating-linear-gradient(-45deg,transparent,transparent 8.125px,rgba(0,0,0,.06) 0,rgba(0,0,0,.06) 9.125px)",
  },
  greenBackdrop: {
    textAlign: "center",
    backgroundColor: "#67b948",
    backgroundImage:
      "repeating-linear-gradient(-45deg,transparent,transparent 5.5px,rgba(0,0,0,.06) 0,rgba(0,0,0,.06) 6.5px)",
  },
  clearBackdrop: {
    textAlign: "center",
  },
  redBackdrop: {
    textAlign: "center",
    backgroundColor: "#ef4b4b",
    backgroundImage:
      "repeating-linear-gradient(45deg,transparent,transparent 10.5px,rgba(0,0,0,.06) 0,rgba(0,0,0,.06) 11.5px)",
  },
  yellowBackdrop: {
    textAlign: "center",
    backgroundColor: "#f0f41f",
    backgroundImage:
      "repeating-linear-gradient(90deg,transparent,transparent 5px,rgba(0,0,0,.06) 0,rgba(0,0,0,.06) 6px)",
  },
  blueBackdrop: {
    textAlign: "center",
    backgroundColor: "#127ea8",
    backgroundImage:
      "repeating-linear-gradient(0deg,transparent,transparent 10px,rgba(0,0,0,.06) 0,rgba(0,0,0,.06) 11px)",
  },
  grayBorder: {
    position: "absolute",
    left: "0px",
    top: "0px",
    width: ".75rem",
    height: "100%",
    backgroundColor: "#474c55",
    backgroundImage:
      "repeating-linear-gradient(45deg,transparent,transparent 10px,rgba(0,0,0,.06) 0,rgba(0,0,0,.06) 11px)",
    zIndex: 1,
  },
  redBorder: {
    position: "absolute",
    left: "0px",
    top: "0px",
    width: ".75rem",
    height: "100%",
    backgroundColor: "#ef4b4b",
    backgroundImage:
      "repeating-linear-gradient(45deg,transparent,transparent 10px,rgba(0,0,0,.06) 0,rgba(0,0,0,.06) 11px)",
    zIndex: 1,
  },
  orangeBorder: {
    position: "absolute",
    left: "0px",
    top: "0px",
    width: ".75rem",
    height: "100%",
    backgroundColor: "#f4861f",
    backgroundImage:
      "repeating-linear-gradient(-45deg,transparent,transparent 10px,rgba(0,0,0,.06) 0,rgba(0,0,0,.06) 11px)",
    zIndex: 1,
  },
  yellowBorder: {
    position: "absolute",
    left: "0px",
    top: "0px",
    width: ".75rem",
    height: "100%",
    backgroundColor: "#f0f41f",
    backgroundImage:
      "repeating-linear-gradient(90deg,transparent,transparent 5px,rgba(0,0,0,.06) 0,rgba(0,0,0,.06) 6px)",
    zIndex: 1,
  },
  greenBorder: {
    position: "absolute",
    left: "0px",
    top: "0px",
    width: ".75rem",
    height: "100%",
    backgroundColor: "#67b948",
    backgroundImage:
      "repeating-linear-gradient(-45deg,transparent,transparent 5px,rgba(0,0,0,.06) 0,rgba(0,0,0,.06) 6px)",
    zIndex: 1,
  },
  blueBorder: {
    position: "absolute",
    left: "0px",
    top: "0px",
    width: ".75rem",
    height: "100%",
    backgroundColor: "#127ea8",
    backgroundImage:
      "repeating-linear-gradient(0deg,transparent,transparent 10px,rgba(0,0,0,.06) 0,rgba(0,0,0,.06) 11px)",
    zIndex: 1,
  },
  grayTransparentBackdrop: {
    position: "relative",
    left: 0,
    top: 0,
    backgroundColor: "rgba(179,179,183,0.5)",
    zIndex: 0,
    textAlign: "center",
  },
  redTransparentBackdrop: {
    position: "relative",
    left: 0,
    top: 0,
    backgroundColor: "rgba(239, 75, 75, .5)",
    zIndex: 0,
    textAlign: "center",
  },
  orangeTransparentBackdrop: {
    position: "relative",
    left: 0,
    top: 0,
    backgroundColor: "rgba(244, 134, 31, .5)",
    zIndex: 0,
    textAlign: "center",
  },
  yellowTransparentBackdrop: {
    position: "relative",
    left: 0,
    top: 0,
    backgroundColor: "rgba(240, 244, 31, .5)",
    zIndex: 0,
    textAlign: "center",
  },
  greenTransparentBackdrop: {
    position: "relative",
    left: 0,
    top: 0,
    backgroundColor: "rgba(103, 185, 72, .5)",
    zIndex: 0,
    textAlign: "center",
  },
  blueTransparentBackdrop: {
    position: "relative",
    left: 0,
    top: 0,
    backgroundColor: "rgba(18, 126, 168, .5)",
    zIndex: 0,
    textAlign: "center",
  },
  nullBackdrop: {
    textAlign: "center",
  },
  greenTransparentBackdrop2: {
    position: "relative",
    left: 0,
    top: 0,
    backgroundColor: "rgba(103, 185, 72,.95)",
    zIndex: 0,
    textAlign: "center",
  },
  redTransparentBackdrop2: {
    position: "relative",
    left: 0,
    top: 0,
    backgroundColor: "rgba(239, 75, 75,.95)",
    zIndex: 0,
    textAlign: "center",
  },
  orangeTransparentBackdrop2: {
    position: "relative",
    left: 0,
    top: 0,
    backgroundColor: "rgba(244, 134, 31,.95)",
    zIndex: 0,
    textAlign: "center",
  },
  arrowIconUp: {
    height: "25px",
    width: "30px",
    transform: "rotate(-90deg)",
    position: "relative",
    top: "30%",
  },
  arrowIconRight: {
    height: "25px",
    width: "30px",
    position: "relative",
    top: "30%",
    left: "0%",
  },
  arrowIconDown: {
    height: "25px",
    width: "30px",
    transform: "rotate(90deg)",
    position: "relative",
    top: "30%",
  },
  unsetPadding: {
    padding: "unset",
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
  },
  legendIndex: {
    paddingRight: "1rem",
    color: "black",
    fontSize: "12px",
    fontWeight: "500",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  },
  onTrackCard: {
    marginBottom: 0,
  },
});

export default tableStyle;
