export const initialLcapValueInfo = (values) => {
  if (!values) {
    return {};
  }
  return Object.keys(values).reduce((object, level) => {
    const { colorGrid, reversed } = values[level];
    const colors = reversed
      ? ["blue", "green", "yellow", "orange", "red"]
      : ["red", "orange", "yellow", "green", "blue"];
    const sliders = ["topBar", "sideBar"].reduce((object, axis) => {
      const curr = values[level][axis];
      object[axis] = colors.reduce(
        (array, color) => {
          switch (color) {
            case "blue":
            case "red":
              array.trackStyle.push({ backgroundColor: color });
              array.styles.push({
                backgroundColor: color,
                width: "20px",
                height: "20px",
              });
              array.values.push(curr[color][0]);
              break;
            case "yellow": {
              if (curr[color].length === 1) {
                array.values.push(curr[color][0]);
                array.styles.push({
                  backgroundColor: color,
                  width: "20px",
                  height: "20px",
                });
              } else {
                array.yellowRange = true;
                array.styles.push({
                  backgroundColor: color,
                  width: "20px",
                  height: "20px",
                });
                array.styles.push({
                  backgroundColor: color,
                  width: "20px",
                  height: "20px",
                });
                const max = Math.max(curr[color][0], curr[color][1]);
                const min = Math.min(curr[color][0], curr[color][1]);
                array.trackStyle.push({ backgroundColor: color });
                array.values.push(min, max);
              }
              break;
            }
            default: {
              array.trackStyle.push({ backgroundColor: color });
              array.trackStyle.push({ backgroundColor: color });
              array.styles.push({
                backgroundColor: color,
                width: "20px",
                height: "20px",
              });
              array.styles.push({
                backgroundColor: color,
                width: "20px",
                height: "20px",
              });
              const max = Math.max(curr[color][0], curr[color][1]);
              const min = Math.min(curr[color][0], curr[color][1]);
              array.values.push(min, max);
            }
          }
          return array;
        },
        {
          yellowRange: false,
          values: [],
          styles: [],
          trackStyle: [],
        }
      );
      return object;
    }, {});
    object[level] = {
      colorGrid,
      sliders,
    };
    return object;
  }, {});
};

export const yellowRangeChange = (info) => {
  const { yellowRange, values, styles, trackStyle } = info;
  if (yellowRange) {
    const colors = ["blue", "green", "green", "orange", "orange", "red"];
    return values.reduce(
      (object, curr, index) => {
        const currStyle = styles[index];
        if (index === 3) {
          const nextValue = values[index + 1];
          const median = (curr + nextValue) / 2;
          object.values.push(median);
          object.styles.push(currStyle);
        } else if (index < 4) {
          object.values.push(curr);
          object.styles.push(currStyle);
        } else if (index > 4) {
          object.values.push(curr);
          object.styles.push(currStyle);
        }
        return object;
      },
      {
        yellowRange: false,
        values: [],
        styles: [],
        trackStyle: colors.map((color) => {
          return { backgroundColor: color };
        }),
      }
    );
  } else {
    const colors = [
      "blue",
      "green",
      "green",
      "yellow",
      "orange",
      "orange",
      "red",
    ];
    return values.reduce(
      (object, curr, index) => {
        const currStyle = styles[index];
        const currTrack = trackStyle[index];
        if (index === 3) {
          object.values.push(curr - 0.5, curr + 0.5);
          object.styles.push(currStyle, currStyle);
        } else {
          object.values.push(curr);
          object.styles.push(currStyle);
        }
        return object;
      },
      {
        yellowRange: true,
        values: [],
        styles: [],
        trackStyle: colors.map((color) => {
          return { backgroundColor: color };
        }),
      }
    );
  }
};
