import axios from "axios";
import history from "../history";

/**
 * CONSTANT VARIABLES
 */
const API = process.env.REACT_APP_API;

/**
 * ACTION TYPES
 */
export const SUBMIT_LOGIN = "SUBMIT_LOGIN";
export const SUBMIT_LOGOUT = "SUBMIT_LOGOUT";
export const VERIFY_TOKEN = "VERIFY_TOKEN";
export const VERIFY_TOKEN_FAILURE = "VERIFY_TOKEN_FAILURE";
export const VERIFY_TOKEN_SUCCESS = "VERIFY_TOKEN_SUCCESS";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const UPDATE_USER_BETA_SUCCESS = "UPDATE_USER_BETA_SUCCESS";
/**
 * ACTION CREATORS
 */
const submitLogin = (email) => ({ type: SUBMIT_LOGIN, email });
const submitLogout = () => ({ type: SUBMIT_LOGOUT });
const verifyToken = (token) => ({ type: VERIFY_TOKEN, token });
const verifyTokenFailure = () => ({ type: VERIFY_TOKEN_FAILURE });
export const verifyTokenSuccess = (user) => ({
  type: VERIFY_TOKEN_SUCCESS,
  user,
});
const loginSuccess = () => ({ type: LOGIN_SUCCESS });
const updateUserBetaSuccess = (user) => ({
  type: UPDATE_USER_BETA_SUCCESS,
  user,
});
/**
 * THUNK CREATORS
 */

export const login = (input) => async (dispatch) => {
  try {
    const { email } = input;
    dispatch(submitLogin(email));
    const res = await axios.post(API + "/auth/login", input);
    const token = res.data.token;
    axios.defaults.headers.common["AUTHORIZATION"] = token;
    //localStorage is for dev purpose only
    localStorage.setItem("token", token);
    if (token) {
      dispatch(loginSuccess());
      const callbackRoute = getCookie("callback") || "/dashboard";
      history.push(`/loading${callbackRoute}`);
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateBeta = (user, values) => (dispatch) => {
  const differences = Object.keys(values).reduce(
    (object, key) => {
      const updated = values[key];
      const userValue = user[key];
      if (updated !== userValue) {
        object.values.push({
          Name: key,
          Value: `${updated}`,
        });
      }
      return object;
    },
    {
      user: user["user"]["username"],
      values: [],
    }
  );
  axios.post(API + "/user/update", differences).then((response) => {
    const newUser = {
      ...user,
    };
    Object.keys(values).forEach((key) => {
      newUser["user"][key] = values[key];
    });
    dispatch(updateUserBetaSuccess(newUser));
  });
};

export const logout = () => async (dispatch) => {
  try {
    await axios.get(API + "/auth/logout");
    axios.defaults.headers.common["AUTHORIZATION"] = null;
    dispatch(submitLogout());
    history.push("/login");
  } catch (err) {
    console.error(err);
  }
};

export const getToken = () => (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    axios.defaults.headers.common["AUTHORIZATION"] = token;
  }
  const path = history.location.pathname;
  const login = ["/", "/login"].includes(path);
  const loading = path.includes("loading");
  const tokenPath = path.includes("token");
  const inputPath = path.includes("input");
  if (!login) {
    const newPath =
      tokenPath || loading || inputPath ? path : `/loading${path}`;
    history.push(newPath);
  }
};

function getCookie(cname) {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export const verify = (token, redirect) => async (dispatch) => {
  try {
    dispatch(verifyToken(token));
    axios.defaults.headers.common["AUTHORIZATION"] = token;
    //localStorage is for dev purpose only
    localStorage.setItem("token", token);
    const res = await axios.get(API + "/auth/verify");
    if (res.status === 200) {
      if (res.data) {
        const user = res.data ? res.data["user"] || {} : {};
        dispatch(verifyTokenSuccess(user));
      }
      const callbackRoute = `/${redirect}`;
      history.push(`${callbackRoute}`);
    } else {
      dispatch(verifyTokenFailure());
      history.push("/login");
    }
  } catch (error) {
    console.error(error);
  }
};

axios.interceptors.response.use(
  function (res) {
    return res;
  },
  function (err) {
    if (err.response.status === 302) {
      const a = document.createElement("a");
      a.href = `${API}/auth/saml/login?relay_state=${err.response.data}`;
      document.body.appendChild(a);
      a.click();
    }
    if (err.response.status === 403) {
      const data = err.response.data;
      if (data === "BAD TOKEN") {
        try {
          const a = document.getElementById("saml-click");
          // a.href=href;
          a.click();
          // document.removeChild(a);
        } catch (error) {
          history.push("/");
        }
      } else {
        document.cookie = "selected={}";
        document.cookie = "callback=/dashboard";
        history.push("/");
      }
    }
    return Promise.resolve();
  }
);
