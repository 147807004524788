import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { compose } from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import connect from "react-redux/es/connect/connect";
import Card from "../StyledComponents/Card/Card";
import CardBody from "../StyledComponents/Card/CardBody";
import Table from "@material-ui/core/Table";
import { NetworkBody, NetworkHeader, TableHeader } from "./Helpers/helpers";
import tableStyle from "../assets/jss/core-dashboard/components/tableStyle";

const styles = (theme) => ({
  containerSpacing: {
    flexGrow: 1,
    paddingTop: "25px",
    paddingLeft: "15px",
    paddingRight: "10px",
    paddingBottom: "10px",
  },
  tableHead: {
    color: "rgba(61,114,135,.8)",
    padding: 12,
    fontSize: 14,
    fontWeight: 900,
    borderBottom: "2px solid #BBCAD0",
    borderLeft: "2px solid #BBCAD0",
    textAlign: "center",
  },
  tableCell: {
    padding: 0,
    borderBottom: "0px",
    borderLeft: "2px solid #BBCAD0",
  },
  tableCellHead: {
    borderBottom: "0px solid red",
    borderLeft: "2px solid #BBCAD0",
    textAlign: "center",
    color: "rgba(61,114,135,.8)",
    fontSize: "14px",
    fontWeight: "900",
    padding: "12px",
  },
  tableRow: {
    paddingTop: 0,
    paddingBottom: 5,
    "&:nth-of-type(odd)": {
      backgroundColor: "rgba(1,61,84,.05)",
    },
  },
  sortIcon: {
    marginLeft: 5,
    height: 30,
    padding: 0,
    background: null,
    "&:hover": {
      cursor: "pointer",
    },
  },
  greyBar: {
    fill: "#818283",
    borderBottom: "1px solid white",
    background: "#818283",
    stroke: "#792323",
    strokeWidth: 0,
  },
  blueBar: {
    fill: "#598fb2",
    borderBottom: "1px solid white",
    background: "#598fb2",
    stroke: "#792323",
    strokeWidth: 0,
  },
  orangeBar: {
    fill: "#f29b37",
    borderBottom: "1px solid white",
    background: "#f29b37",
    stroke: "#792323",
    strokeWidth: 0,
  },
  greenBar: {
    fill: "#91c24d",
    borderBottom: "1px solid white",
    background: "#91c24d",
    stroke: "#792323",
    strokeWidth: 0,
  },
  legendIndex: {
    textAlign: "center",
    color: "rgba(61,114,135,.8)",
    fontSize: "14px",
    fontWeight: "900",
    paddingRight: "1rem",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  },
  blueBackdrop: {
    height: "23px",
    width: "23px",
    textAlign: "center",
    backgroundColor: "#598fb2",
    borderRadius: 0,
    marginRight: "1rem",
  },
  orangeBackdrop: {
    height: "23px",
    width: "23px",
    textAlign: "center",
    backgroundColor: "#f29b37",
    borderRadius: 0,
    marginRight: "1rem",
  },
  greenBackdrop: {
    height: "23px",
    width: "23px",
    textAlign: "center",
    backgroundColor: "#91c24d",
    borderRadius: 0,
    marginRight: "1rem",
  },
});

class NetworkTable extends Component {
  state = {
    type: "success_name",
    order: true,
  };

  setSort = (key) => {
    const { type, order } = this.state;
    if (type === key) {
      this.setState({ order: !order });
    } else {
      this.setState({ type: key, order: true });
    }
  };

  render() {
    const { classes, data, district, school, state } = this.props;
    const { predict_options, success_options, grade_options, year_options } =
      state;
    const success =
      success_options === "probability_grad_ag"
        ? "Graduation with A-G Requirements met"
        : success_options === "probability_grad"
        ? "High School Graduation"
        : "Persistence into 2nd year of 4 year College/University";
    const title =
      predict_options === "measure_delta"
        ? `Improvement in predictions during the ${year_options} School Year `
        : `Predictions of ${success} in ${year_options}`;
    return (
      <Grid
        container
        className={classes.containerSpacing}
        alignItems={"center"}
      >
        <Grid container item xs={12}>
          <Card>
            {predict_options === "measure_delta" ? (
              <TableHeader classes={classes} />
            ) : (
              ""
            )}
            <CardBody>
              <Table className={classes.table}>
                <NetworkHeader
                  districtOverall={district}
                  data={data}
                  title={title}
                  sort={this.state.type}
                  order={this.state.order}
                  setSort={this.setSort.bind(this)}
                  classes={classes}
                  {...this.props}
                />
                <NetworkBody
                  data={data}
                  classes={classes}
                  grade={grade_options}
                  type={predict_options}
                  success={success_options}
                  year={year_options}
                  order={this.state.order}
                  sort={this.state.type}
                  {...this.props}
                />
              </Table>
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

const mapState = (state) => {
  return {};
};

const mapDispatch = (dispatch) => {
  return {};
};

export default compose(
  withStyles((theme) => ({
    ...tableStyle(theme),
    ...styles(theme),
  })),
  connect(mapState, mapDispatch)
)(NetworkTable);
