import React, { Component } from "react";
import { compose } from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import connect from "react-redux/es/connect/connect";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Plot from "react-plotly.js";
import TitleTable from "../StyledComponents/Table/TitleTable";
import Card from "../StyledComponents/Card/Card";
import CardHeader from "../StyledComponents/Card/CardHeader";
import CardBody from "../StyledComponents/Card/CardBody";
import tableStyle from "../assets/jss/core-dashboard/components/tableStyle.jsx";
import LoadingGrid from "../StyledComponents/Grid/LoadingGrid";
import ReactDOM from "react-dom";
import CustomToolTip from "../StyledComponents/Tooltip/CustomToolTip";

const GrowthHoverGrid = ({ info, classes, top, left }) => {
  const { school_name, color } = info;
  const order = [
    {
      text: "Growth Estimate:",
      code: "measure_rate",
    },
    {
      text: "Avg Distance to Meeting Standards:",
      code: "distance",
    },
  ];
  return (
    <Grid
      id={`${school_name}-fixed`}
      direction={"column"}
      className={classes[`${color}TransparentBackdrop2`]}
      style={{
        width: "400px",
        minHeight: "100px",
        borderRadius: "5px",
        padding: "5px",
        position: "fixed",
        left: left - 185,
        top: top - 120,
        zIndex: 5000,
      }}
    >
      <Grid
        className={classes[`${color}Backdrop`]}
        style={{ borderRadius: "5px", marginBottom: "15px" }}
        item
      >
        <Typography
          style={{
            fontSize: "1rem",
            fontWeight: "900",
          }}
        >
          {school_name}
        </Typography>
      </Grid>
      <Grid justifyContent={"center"} container item direction={"column"}>
        {order.map((curr) => (
          <Grid key={curr.code} spacing={2} item container justifyContent={"center"}>
            <Grid xs={8} item>
              <Typography
                style={{
                  verticalAlign: "bottom",
                  fontSize: "14px",
                  fontWeight: "900",
                  float: "right",
                }}
              >
                {curr.text}
              </Typography>
            </Grid>
            <Grid xs={2} item>
              <Typography
                style={{
                  verticalAlign: "bottom",
                  fontSize: "14px",
                  fontWeight: "600",
                  float: "left",
                }}
              >
                {info[curr.code]}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
const GrowthGrid = ({ info, classes }) => {
  const { school_name, measure_rate, distance, color } = info;
  const order = [
    {
      text: "Growth Estimate:",
      code: "measure_rate",
    },
    {
      text: "Avg Distance to Meeting Standards:",
      code: "distance",
    },
  ];
  return (
    <Grid
      id={`${school_name}`}
      direction={"column"}
      className={classes[`${color}TransparentBackdrop`]}
      style={{
        width: "400px",
        borderRadius: "5px",
        padding: "5px",
        margin: "2.5px",
        marginBottom: "10px",
      }}
      item
      container
    >
      <Grid
        className={classes[`${color}Backdrop`]}
        style={{ borderRadius: "5px", marginBottom: "15px" }}
        item
      >
        <Typography
          style={{
            fontSize: "1rem",
            fontWeight: "900",
          }}
        >
          {school_name}
        </Typography>
      </Grid>
      <Grid justifyContent={"center"} container item direction={"column"}>
        {order.map((curr) => (
          <Grid key={curr.code} spacing={2} item container justifyContent={"center"}>
            <Grid xs={8} item>
              <Typography
                style={{
                  verticalAlign: "bottom",
                  fontSize: "14px",
                  fontWeight: "900",
                  float: "right",
                }}
              >
                {curr.text}
              </Typography>
            </Grid>
            <Grid xs={2} item>
              <Typography
                style={{
                  verticalAlign: "bottom",
                  fontSize: "14px",
                  fontWeight: "600",
                  float: "left",
                }}
              >
                {info[curr.code]}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

const colorToRGB = (color) => {
  switch (color) {
    case "red":
      return "rgb(239,75,75)";
    case "orange":
      return "rgb(244,134,31)";
    case "blue":
      return "rgb(85,144,181)";
    case "green":
      return "rgb(103,185,72)";
    default:
      return "rgb(0,0,0)";
  }
};

class GrowthScatterPlot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comparing: [],
      showAll: true,
    };
    this.handleDotClick = this.handleDotClick.bind(this);
  }

  handleDotClick = (code) => {
    const { comparing } = this.state;
    if (comparing.includes(code)) {
      const newCompare = comparing.filter((key) => key !== code);
      this.setState({
        comparing: newCompare,
      });
    } else {
      this.setState({
        comparing: comparing.concat(code),
      });
    }
  };

  render() {
    const {
      growthScatterPlot,
      year,
      school,
      classes,
      district,
      title,
      loading,
      measureLevel,
      selected,
    } = this.props;
    const { comparing } = this.state;
    let rangeY = [-100, 100];
    const customdata = {};
    const data = growthScatterPlot
      ? Object.keys(growthScatterPlot).reduce(
          (object, key) => {
            const { school_name, measure_rate, distance, color } =
              growthScatterPlot[key];
            const selectName =
              measureLevel === "Overall"
                ? school_name.substr(0, school_name.length - 5)
                : school_name;
            const clicked = comparing.includes(key);
            const currObj =
              selectName === school.text
                ? object.selected
                : clicked
                ? object.clicked
                : object.other;
            currObj.x.push(measure_rate);
            currObj.y.push(distance);
            rangeY[0] = Math.min(distance - 5, rangeY[0]);
            rangeY[1] = Math.max(distance + 5, rangeY[1]);
            currObj.marker.color.push(colorToRGB(color));
            currObj.text.push(`${school_name}`);
            customdata[school_name] = {
              school_name,
              measure_rate,
              distance,
              color,
            };
            return object;
          },
          {
            selected: {
              mode: "markers",
              type: "scatter",
              hoverinfo: "none",
              x: [],
              y: [],
              text: [],
              marker: {
                size: 24,
                color: [],
              },
            },
            clicked: {
              mode: "markers",
              type: "scatter",
              hoverinfo: "none",
              x: [],
              y: [],
              text: [],
              color: [],
              marker: {
                size: 8,
                color: [],
              },
            },
            other: {
              mode: "markers",
              type: "scatter",
              hoverinfo: "none",
              x: [],
              y: [],
              text: [],
              color: [],
              marker: {
                size: 8,
                color: [],
              },
            },
          }
        )
      : {
          clicked: { x: [] },
          other: {},
          selected: {},
        };
    const canvas = document.createElement("canvas");
    const clickedAnnotations = (data.clicked.x || []).map((_, index) => {
      const x = data.clicked.x[index];
      const y = data.clicked.y[index] + 10;
      const text = `<b style="font-size:10px;color:rgb(6,35,50); opacity:.95;font-weight: 400">${data.clicked.text[index]}</b>`;
      const ctx = canvas.getContext("2d");
      ctx.font = "10px sans-serif";
      const len = ctx.measureText(data.clicked.text[index]).width / 1.75;
      return {
        x: x < 2 ? x + len / 15 : x > 98 ? x - len / 15 : x,
        y,
        text,
        showarrow: false,
        ax: x < 5 ? x + len : x > 98 ? x - len - 100 : 0,
        ay: y > rangeY[1] - 20 ? 20 : -20,
      };
    });
    const annotations =
      (data.selected.x || []).map((_, index) => {
        const x = data.selected.x[index];
        const y = data.selected.y[index] + 12;
        const text = `<b style="font-size:20px;color:rgb(6,35,50); opacity:.95;font-weight: 900">${data.selected.text[index]}</b>`;
        const ctx = canvas.getContext("2d");
        ctx.font = "20px sans-serif";
        const len = ctx.measureText(data.selected.text[index]).width / 1.75;
        return {
          x,
          y: y > rangeY[1] - 20 ? y - 24 : y,
          text,
          showarrow: true,
          ax: x < 10 ? x + len : x > 90 ? x - len - 100 : 0,
          ay: y > rangeY[1] - 20 ? 30 : -30,
        };
      }) || [];
    canvas.remove();
    const noData = growthScatterPlot
      ? !Object.keys(growthScatterPlot).length
      : true;
    return (
      <Grid
        style={{ width: "95%", margin: "0 2.5% 0 2.5%" }}
        justifyContent={"center"}
        container
        direction={"row"}
      >
        <TitleTable
          district={district}
          school={school}
          pageTitle={"Scatter Plot"}
          hideInfo
        />
        <Grid xs={12} item>
          <Card>
            <CardHeader className={classes.gradientBackground} color="primary">
              {/* Left the Next Card Header in Place to bring back Primary Color at a later time*/}
              {/*<CardHeader color="primary" >*/}
              <CustomToolTip code={selected["Growth Subject"]}>
                <h4 className={classes.cardTitleBlue}>
                  {noData && !loading
                    ? "Sorry, there's no data for this graph."
                    : title}
                </h4>
              </CustomToolTip>
              {/*<p className={classes.cardCategoryWhite}>*/}
              {/*	Here is a subtitle for this table*/}
              {/*</p>*/}
            </CardHeader>
            <CardBody
              id={"card-body"}
              style={{
                paddingTop: "0px",
                width: "100%",
                marginTop: "10px",
                paddingBottom: "10px",
              }}
            >
              {loading ? (
                <LoadingGrid />
              ) : noData ? null : (
                <div
                  className={classes.plot}
                  style={{ margin: "auto", width: "100%" }}
                >
                  <Plot
                    onClick={(data) => {
                      const key = data.points[0].text;
                      const info = customdata[key];
                      if (info) {
                        const card = document.getElementById("card-body");
                        const xy = card.getBoundingClientRect();
                        const xaxis = data.points[0].xaxis;
                        const yaxis = data.points[0].yaxis;
                        const top =
                          xy.y + yaxis.l2p(data.points[0].y) + yaxis._offset;
                        const left =
                          xy.x + xaxis.l2p(data.points[0].x) + xaxis._offset;
                        const hoverinfo = document.getElementById("hoverinfo");
                        this.handleDotClick(key);
                        ReactDOM.render(
                          <GrowthHoverGrid
                            classes={classes}
                            info={info}
                            top={top}
                            left={left}
                          />,
                          hoverinfo
                        );
                      }
                    }}
                    onHover={(data) => {
                      const key = data.points[0].text;
                      const info = customdata[key];
                      if (info) {
                        const card = document.getElementById("card-body");
                        const xy = card.getBoundingClientRect();
                        const xaxis = data.points[0].xaxis;
                        const yaxis = data.points[0].yaxis;
                        const top =
                          xy.y + yaxis.l2p(data.points[0].y) + yaxis._offset;
                        const left =
                          xy.x + xaxis.l2p(data.points[0].x) + xaxis._offset;
                        const hoverinfo = document.getElementById("hoverinfo");
                        ReactDOM.render(
                          <GrowthHoverGrid
                            classes={classes}
                            info={info}
                            top={top}
                            left={left}
                          />,
                          hoverinfo
                        );
                      }
                    }}
                    onUnhover={(data) => {
                      const hoverinfo = document.getElementById("hoverinfo");
                      ReactDOM.unmountComponentAtNode(hoverinfo);
                    }}
                    style={{ margin: "auto", width: "100%" }}
                    data={[data.selected, data.other, data.clicked]}
                    layout={{
                      annotations: annotations.concat(clickedAnnotations || []),
                      textfont: {
                        family: "sans serif",
                        size: 24,
                        color: "rgb(9, 75, 101)",
                      },
                      paper_bgcolor: "rgba(0,0,0,0)",
                      plot_bgcolor: "rgba(0,0,0,0)",
                      showlegend: false,
                      hovermode: "closest",
                      title: {
                        text: "Growth Vs Achievement Scatterplots",
                      },
                      shapes: [
                        {
                          type: "line",
                          x0: 50,
                          x1: 50,
                          y0: rangeY[0],
                          y1: rangeY[1],
                          layer: "below",
                          line: {
                            color: "rgb(77, 126, 145)",
                            width: 2,
                          },
                        },
                        {
                          type: "rect",
                          x0: 0,
                          y0: rangeY[0],
                          x1: 30,
                          y1: rangeY[1],
                          layer: "below",
                          opacity: 0.2,
                          line: {
                            color: "rgba(77, 126, 145, 0.7)",
                            width: 1,
                          },
                          fillcolor: "rgb(73,116,133)",
                        },
                        {
                          type: "rect",
                          x0: 70,
                          y0: rangeY[0],
                          x1: 100,
                          y1: rangeY[1],
                          layer: "below",
                          line: {
                            color: "rgba(77, 126, 145, 0.7)",
                            width: 1,
                          },
                          opacity: 0.2,
                          fillcolor: "rgb(73,116,133)",
                        },
                      ],
                      xaxis: {
                        showgrid: false,
                        title: "Student Growth Percentile",
                        range: [-1, 101],
                        tickvals: [0, 20, 40, 50, 60, 80, 100],
                        ticktext: [
                          "<b>0</b>",
                          "<b>20</b>",
                          "<b>40</b>",
                          "<b>Average Growth</b>",
                          "<b>60</b>",
                          "<b>80</b>",
                          "<b>100</b>",
                        ],
                        linewidth: 3,
                        linecolor: "rgb(9, 75, 101)",
                      },
                      yaxis: {
                        title: "Average Distance to Meeting Standards",
                        range: rangeY,
                        automargin: true,
                        gridcolor: "rgb(170,195,203)",
                        tickmode: "array",
                        linewidth: 3,
                        linecolor: "rgb(9, 75, 101)",
                        tickcolor: "rgb(9, 75, 101)",
                        tickwidth: 3,
                      },
                    }}
                  />
                </div>
              )}
            </CardBody>
          </Card>
        </Grid>
        <Grid xs={12} container item>
          <Card>
            <CardHeader className={classes.gradientBackground} color="primary">
              {/* Left the Next Card Header in Place to bring back Primary Color at a later time*/}
              <h4 className={classes.cardTitleBlue}>Metric Breakdown</h4>
            </CardHeader>
            <CardBody
              style={{
                paddingTop: "0px",
                width: "100%",
                marginTop: "10px",
                paddingBottom: "10px",
              }}
            >
              {loading ? (
                <LoadingGrid />
              ) : noData ? null : (
                <Grid
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                  justifyContent={"space-around"}
                  spacing={8}
                  item
                  container
                >
                  {growthScatterPlot &&
                    Object.keys(growthScatterPlot).map((key) => (
                      <GrowthGrid
                        info={growthScatterPlot[key]}
                        classes={classes}
                        key={key}
                      />
                    ))}
                </Grid>
              )}
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

const findInMenu = (array, key) => {
  const res = {
    code: "",
    text: "",
  };
  if (!array || !key) {
    return res;
  }
  return array.reduce((found, curr) => {
    if (curr.code === key) {
      found = curr;
    }
    return found;
  }, res);
};

const mapState = (state) => {
  const { selected, menuObject } = state.menu;
  const {
    constants: { measures, subgroups },
  } = state;
  const district = findInMenu(menuObject["District"], selected["District"]);
  const school = findInMenu(menuObject["School"], selected["School"]);
  const group = selected["Student Groups"];
  const measureLevel = selected["School Level"];
  const year = selected["School Year"] ? selected["School Year"] : "";
  const measure = selected["Measure"];
  const growthSubject = selected["Growth Subject"];
  const subgroupName = subgroups ? (subgroups[group] || { name: "" }).name : "";
  const parsedName =
    subgroupName.split("(")[0].toLowerCase().includes("student") ||
    group === "EL"
      ? subgroupName
      : `${subgroupName} Students`;
  const growthName =
    (growthSubject || "growth_ela") === "growth_ela"
      ? "English Language Arts"
      : "Math";
  const title = `${growthName} among ${parsedName}, ${year}`;
  const { growthScatterPlot, loading } = state.metrics;

  return {
    growthScatterPlot,
    selected,
    district,
    school,
    year,
    measure,
    measureLevel,
    title,
    loading,
  };
};

const mapDispatch = (dispatch) => {
  return {};
};

export default compose(
  withStyles(tableStyle, { withTheme: true }),
  connect(mapState, mapDispatch)
)(GrowthScatterPlot);
