import { Component } from "react";
import Grid from "@material-ui/core/Grid/Grid";
import { compose } from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import connect from "react-redux/es/connect/connect";
import React from "react";
import {
  Title,
  parseTable,
  renderTable,
  trimTable,
} from "../Overviews/helpers";
import TableTitle from "../StyledComponents/Table/TitleTable";
import CreateTable from "../StyledComponents/Table/TableList";
import CardHeader from "../StyledComponents/Card/CardHeader";
import Card from "../StyledComponents/Card/Card";
import tableStyle from "../assets/jss/core-dashboard/components/tableStyle";
import Table from "@material-ui/core/Table/Table";
import TableHead from "@material-ui/core/TableHead/TableHead";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody/TableBody";

const styles = (theme) => ({
  tableMargin: {
    marginLeft: "2rem",
    marginRight: "2rem",
  },
  overviewText: {
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "25px",
    fontSize: "20px",
    color: "rgba(60,114,135,.9)",
    lineHeight: "40px",
  },
});

class OnTrackView extends Component {
  render() {
    const { district, school, classes, onTrackTable, selected } = this.props;
    const gradeRows = onTrackTable.tableData || {};
    const onTrackColumns = Object.keys(gradeRows).length
      ? onTrackTable.columnHeaders
      : ["No data for current selection."];
    const filteredOnTrackColumns = onTrackColumns.filter(
      (col) => col != "Avg. Number of Credits Earned"
    );
    const getFilteredColumnHeaders = Object.keys(gradeRows).some((val) =>
      ["MS", "K8"].includes(val)
    )
      ? filteredOnTrackColumns
      : onTrackColumns;

    //in case of these two columns (on track attendance rate and % of students on-track, the row cells will be added % signs
    const getFilteredRowCells = (rowData, gradeString) => {
      return getFilteredColumnHeaders.map((col) => {
        if (col === "Grade") return gradeString;
        if (col === "On Track Attendance Rate") return rowData[col] + " %";
        if (col === "% of Students On-Track")
          return rowData["School On Track"] + " %";
        else return col in rowData ? rowData[col] : "No Data";
      });
    };

    return (
      <Grid container justifyContent="center" className={classes.tableMargin}>
        <TableTitle
          school={school}
          district={district}
          pageTitle={"On-Track Status"}
        />
        <Card>
          <CardHeader className={classes.gradientBackground} color="primary">
            <h4 className={classes.cardTitleBlue}>On-Track Status</h4>
          </CardHeader>
        </Card>
        <Table className={classes.table} style={{ minHeight: "30vh" }}>
          <TableHead className={classes.primaryTableHeader}>
            <TableRow style={{ border: "0px solid white" }}>
              {getFilteredColumnHeaders.map((col, i) => (
                <TableCell
                  key={col}
                  className={classes.tableCell}
                  style={{
                    background: i === 0 ? "rgba(1,61,84,.12)" : "white",
                    border: "2px solid #BBCAD0",
                    fontSize: "14px",
                    fontWeight: 600,
                    color: "rgba(61,114,135,.8)",
                    textAlign: "center",
                    marginTop: "15px",
                  }}
                >
                  {col}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(gradeRows).map((grade, rowIndex) => {
              const rowData = gradeRows[grade];
              const gradeString = isNaN(parseInt(rowData.Grade))
                ? "Overall"
                : rowData.Grade;
              // map Grade obj into array in order to iterate over
              const filteredCellArray = getFilteredRowCells(
                rowData,
                gradeString
              );
              return (
                <TableRow
                  key={grade.Grade}
                  style={{ borderBottom: "2px solid #BBCAD0" }}
                >
                  {filteredCellArray.map((cellData, i) => (
                    <TableCell
                      key={`${rowIndex}${i}`}
                      className={classes.tableCell}
                      style={{
                        background:
                          rowIndex % 2 === 0 ? "rgba(1,61,84,.09)" : "white",
                        fontSize: "14px",
                        border: "2px solid #BBCAD0",
                        fontWeight: i === 0 ? 600 : 400,
                        color: "rgba(61,114,135,.8)",
                        textAlign: "center",
                      }}
                    >
                      {i === 1 && cellData !== "No Data"
                        ? cellData + "%"
                        : cellData}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Grid>
    );
  }
}

const findInMenu = (array, key) => {
  const res = {
    code: "",
    text: "",
  };
  if (!array) {
    return res;
  }
  return array.reduce((found, curr) => {
    if (curr.code === key) {
      found = curr;
    }
    return found;
  }, res);
};

const mapState = (state) => {
  const { selected, menuObject } = state.menu;
  const district = findInMenu(menuObject["District"], selected["District"]);
  const school = findInMenu(menuObject["School"], selected["School"]);
  const { onTrackTable } = state.onTrack;
  return {
    selected,
    district,
    school,
    onTrackTable,
  };
};

export default compose(
  withStyles((theme) => ({
    ...tableStyle(theme),
    ...styles(theme),
  })),
  connect(mapState)
)(OnTrackView);
