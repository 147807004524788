export const smallMultiplesHelpers = {
  parseMeasureList,
  parseSubgroupList,
  parseInstitutionList,
};

function parseMeasureList(list) {
  const parsed = Object.keys(list)
    .map((key) => {
      const { code, name } = list[key];
      return {
        label: name,
        value: code,
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));
  return parsed;
}

function parseSubgroupList(list) {
  const parsed = Object.keys(list)
    .map((key) => {
      const { code, name, alias } = list[key];
      return {
        label: `${name} (${alias || code})`,
        value: code,
      };
    })
    .sort((a, b) =>
      a.value === "ALL" ? -100 : a.label.localeCompare(b.label)
    );
  return parsed;
}

function parseInstitutionList(networks, districts, schools) {
  const yearsObject = {};
  const networkList = Object.keys(networks).map((key) => {
    const { text, schoolLevels, schoolYears } = networks[key];
    Object.keys(schoolYears).forEach((year) => {
      yearsObject[year] = "";
    });
    const levels = Object.keys(schoolLevels);
    return {
      label: text,
      value: key,
      schoolLevels: ["ES", "K8", "MS", "HS", "Overall"].reduce((arr, curr) => {
        if (levels.includes(curr)) {
          return arr.concat({
            label: curr,
            value: curr,
          });
        }
        return arr;
      }, []),
      type: "network",
    };
  });
  const networkInfo = {
    label: "Networks",
    options: networkList,
  };
  const districtOptions = Object.keys(districts).reduce(
    (object, networkKey) => {
      const networkDistrict = districts[networkKey];
      Object.keys(networkDistrict).forEach((districtKey) => {
        const { text, schoolLevels } = networkDistrict[districtKey];
        const levels = Object.keys(schoolLevels);
        object[districtKey] = {
          value: districtKey,
          label: text,
          type: "district",
          schoolLevels: ["ES", "K8", "MS", "HS", "Overall"].reduce(
            (arr, curr) => {
              if (levels.includes(curr)) {
                return arr.concat({
                  label: curr,
                  value: curr,
                });
              }
              return arr;
            },
            []
          ),
        };
      });
      return object;
    },
    {}
  );
  const districtInfo = {
    label: "Districts",
    options: Object.keys(districtOptions).map((key) => districtOptions[key]),
  };
  const schoolOptions = Object.keys(districts).reduce((object, networkKey) => {
    const networkDistrict = districts[networkKey];
    Object.keys(networkDistrict).forEach((districtKey) => {
      const schoolList = schools[districtKey];
      object[districtKey] = Object.keys(schoolList).reduce(
        (parsedSchools, schoolLevel) => {
          const types = schoolList[schoolLevel];
          Object.keys(types).forEach((type) => {
            const schoolListByType = types[type];
            Object.keys(schoolListByType).forEach((schoolKey) => {
              if (parsedSchools[schoolKey]) {
                parsedSchools[schoolKey]["schoolLevels"][schoolLevel] = "";
              } else {
                const schoolInfo = schoolListByType[schoolKey];
                const schoolName = schoolInfo["text"];
                parsedSchools[schoolKey] = {
                  label: schoolName,
                  value: schoolKey,
                  schoolLevels: { [schoolLevel]: "" },
                  type: "school",
                };
              }
            });
          });
          return parsedSchools;
        },
        {}
      );
    });
    return object;
  }, {});
  const parsedOptions = Object.keys(schoolOptions).reduce((object, key) => {
    const schoolList = schoolOptions[key];
    object[key] = Object.keys(schoolList).map((school) => {
      const levels = Object.keys(schoolList[school]["schoolLevels"]);
      return {
        ...schoolList[school],
        schoolLevels: ["ES", "K8", "MS", "HS", "Overall"].reduce(
          (arr, curr) => {
            if (levels.includes(curr)) {
              return arr.concat({
                label: curr,
                value: curr,
              });
            }
            return arr;
          },
          []
        ),
      };
    });
    return object;
  }, {});
  return {
    years: Object.keys(yearsObject).map((year) => ({
      label: year,
      value: year,
    })),
    baseSelect: [networkInfo, districtInfo],
    schoolOptions: parsedOptions,
  };
}
