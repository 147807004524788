import {
  LOAD_ONTRACK_ECHART_LINE,
  LOAD_ONTRACK_TABLE,
  LOAD_ONTRACK_ECHART_BAR,
  LOAD_ONTRACK_GRADE_DIST,
  LOADING,
} from "../../actions/Ontrack/ontrack";

const initialState = {
  onTrackTable: {},
  gradeDistribution: { data: {}, headerList: [] },
  loading: false,
};

export default function onTrack(state = initialState, action) {
  switch (action.type) {
    case LOAD_ONTRACK_TABLE:
      return {
        ...state,
        onTrackTable: action.payload,
      };
    case LOAD_ONTRACK_ECHART_BAR:
      return {
        ...state,
        lanes: action.payload,
      };
    case LOAD_ONTRACK_ECHART_LINE:
      return {
        ...state,
        ratesBySubject: action.payload,
      };
    case LOAD_ONTRACK_GRADE_DIST:
      return {
        ...state,
        gradeDistribution: action.payload,
      };
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
}
