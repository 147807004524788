import { FETCH_COMPLETENESS, FETCH_COMPLETENESS_SUCCESS } from "./actions";

const initialState = {
  initialized: false,
  completeness: {},
};

export default function visualizations(state = initialState, action) {
  switch (action.type) {
    case FETCH_COMPLETENESS:
      return Object.assign({}, state, {
        initialized: false,
        completeness: {},
      });
    case FETCH_COMPLETENESS_SUCCESS:
      return Object.assign({}, state, {
        completeness: action.completeness,
      });
    default:
      return state;
  }
}
