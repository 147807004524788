import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
import React from "react";
import Typography from "@material-ui/core/Typography/Typography";

const GetTableSubscript = (props) => {
  const { title, classes, style } = props;
  const { scriptValue, scriptText } = getSubscript(title);

  const splitNewLine = scriptText.split("\n");
  const splitFinalText = [];

  splitNewLine.forEach((line, iter) => {
    if (iter !== splitNewLine.length - 1) {
      splitFinalText.push(
        <React.Fragment>
          {line}
          <br />
          <br />
        </React.Fragment>
      );
    } else {
      splitFinalText.push(<React.Fragment>{line}</React.Fragment>);
    }
  });

  return (
    <SubscriptTooltip
      placement={"top-start"}
      style={{ whiteSpace: "pre-line" }}
      title={splitFinalText}
    >
      <Typography className={classes[style]}>{scriptValue}</Typography>
    </SubscriptTooltip>
  );
};

const getSubscript = (title) => {
  switch (title) {
    case "Student Population (Based Upon Continuous Enrollment)":
      return {
        scriptValue: "*",
        scriptText:
          "Based on enrollment from the Fall Census day through a defined date in the spring, with no gaps greater than 30 days.",
      };
    case "Chronic Absenteeism":
      return {
        scriptValue: "*",
        scriptText:
          "For the 2019-20 school year, Chronic Absenteeism in the dashboard is based on enrollment days for students between the first day of school and the last date of reported data (may vary by school district).",
      };
    case "Suspension Rates (includes students suspended and/or expelled)":
      return {
        scriptValue: "*",
        scriptText:
          "For the 2019-20 school year, Suspension in the dashboard is based on enrollment days for students between the first day of school and the last date of reported data (may vary by school district).",
      };
    case "STATE Suspension Rates (includes students in school and/or out of school suspended)":
      return {
        scriptValue: "*",
        scriptText:
          "Suspension metrics in the dashboard are based on enrollment days for students between the first day of school and the official CDE “last day of attendance” tracking for 2019-20, which is February 29th, 2020.",
      };
    case "LCAP 5x5 Schools":
      return {
        scriptValue: "",
        scriptText:
          "Continuous enrollment in the 19-20 school year was calculated based on beginning of year enrollment and on February 29th, 2020\n" +
          "Chronic absenteeism metrics in the dashboard are based on enrollment days for students between the first day of school and the official CDE “last day of attendance” tracking for 2019-20, which is February 29th, 2020.\n" +
          "Suspension  metrics in the dashboard are based on enrollment days for students between the first day of school and the official CDE “last day of attendance” tracking for 2019-20, which is February 29th, 2020\n",
      };
    case "LCAP 5x5 Students":
      return {
        scriptValue: "",
        scriptText:
          "Continuous enrollment in the 19-20 school year was calculated based on beginning of year enrollment and on February 29th, 2020\n" +
          "Chronic absenteeism metrics in the dashboard are based on enrollment days for students between the first day of school and the official CDE “last day of attendance” tracking for 2019-20, which is February 29th, 2020.\n" +
          "Suspension  metrics in the dashboard are based on enrollment days for students between the first day of school and the official CDE “last day of attendance” tracking for 2019-20, which is February 29th, 2020\n",
      };

    default:
      return {
        scriptValue: "",
        scriptText: "",
      };
  }
};

const SubscriptTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#ffffff",
    color: "#4D7E91",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(14),
    border: "1px solid #4D7E91",
  },
}))(Tooltip);

export { GetTableSubscript };
