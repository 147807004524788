import React, { Component } from "react";
import { compose } from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import connect from "react-redux/es/connect/connect";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Plot from "react-plotly.js";
import TableTitle from "../StyledComponents/Table/TitleTable";
import Card from "../StyledComponents/Card/Card";
import CardHeader from "../StyledComponents/Card/CardHeader";
import CardBody from "../StyledComponents/Card/CardBody";
import tableStyle from "../assets/jss/core-dashboard/components/tableStyle";
import LoadingGrid from "../StyledComponents/Grid/LoadingGrid";
import withWidth from "@material-ui/core/withWidth";
import { PlotData } from "../OnDemandAnalytics/PlotData";
import { tableStyling } from "../OnDemandAnalytics/plotDataStyles";
import { addCommas } from "../utils";
import CustomToolTip from "../StyledComponents/Tooltip/CustomToolTip";

const styles = (theme) => ({
  tableMargin: {
    marginTop: "0px",
    marginBottom: "0px",
    marginLeft: "2rem",
    marginRight: "2rem",
  },
});

class CollegeDistribution extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      collegeDistribution,
      classes,
      loading,
      width,
      school,
      district,
      network,
      measure,
      year,
      selected,
    } = this.props;
    const pos = {
      [school.text]: 0,
      [district.text]: 1,
      [network.text]: 2,
    };
    const showAsColumn = !["xl", "lg", "md"].includes(width);
    const data = Object.keys(collegeDistribution).reduce(
      (array, key, index) => {
        const col = pos[key];
        const base = {
          values: [],
          labels: [],
          hovertemplate: [],
          type: "pie",
          hole: 0.5,
          name: key,
          title: `<b style="font-size:2.5vh">${key}</b>`,
          titlefont: {
            color: "rgb(61,114,135)",
          },
          domain: {
            x: showAsColumn
              ? [0, 1]
              : [0.33 * col + 0.025, 0.33 * (col + 1) - 0.025],
            y: showAsColumn
              ? [1 - 0.33 * (col + 1), 1 - 0.33 * col]
              : [0.25, 1],
          },
        };
        const curr = collegeDistribution[key];
        let max = 0;
        let tStudents = 0;
        let nStudents = 0;
        Object.keys(curr).forEach((college) => {
          const split = college.split(" ");
          const parsed = split.reduce(
            (s, c) => {
              s.count += c.length;
              if (s.count > 20) {
                s.count = 0;
                s.parsed = `${s.parsed}<br>${c}`;
              } else {
                s.parsed = `${s.parsed} ${c}`;
              }
              return s;
            },
            {
              parsed: "",
              count: 0,
            }
          ).parsed;
          const { meas_rate, numerator, denominator } = curr[college];
          base.labels.push(college);
          base.values.push(meas_rate);
          max += meas_rate;
          nStudents = denominator;
          tStudents += numerator;
          const text =
            `<b style="font-size:20px;vertical-align:center">${parsed}</b><br><b>Number of Students:</b> ${addCommas(
              `${numerator}`
            )}<br><b>Total Students:</b> ${addCommas(`${denominator}`)}` +
            `<extra><br><b style="text-align:center;width:100%;font-size:36px">${meas_rate}%</extra>`;
          base.hovertemplate.push(text);
        });
        if (max < 99) {
          const currSchool = "No Data";
          const meas_rate = 100 - max;
          const denominator = nStudents;
          const numerator = nStudents - tStudents;
          base.labels.push(currSchool);
          base.values.push(meas_rate);
          max += meas_rate;
          nStudents = denominator;
          tStudents += numerator;
          const text =
            `<b style="font-size:20px;vertical-align:center">No Data</b><br><b>Number of Students:</b> ${numerator}<br><b>Total Students:</b> ${denominator}` +
            `<extra><br><b style="text-align:center;width:100%;font-size:36px">${meas_rate}%</extra>`;
          base.hovertemplate.push(text);
        }

        array.data.push(base);
        return array;
      },
      {
        data: [],
        annotations: [],
      }
    );
    const noData = !Object.keys(data.data).length;
    const tableData = Object.keys(collegeDistribution).reduce((array, curr) => {
      const mapped = Object.keys(collegeDistribution[curr]).map((key) => {
        return {
          name: curr,
          college: key,
          ...collegeDistribution[curr][key],
        };
      });
      return array.concat(mapped);
    }, []);
    const columns = [
      {
        title: "Name",
        field: "name",
        cellStyle: tableStyling.firstData,
        headerStyle: tableStyling.firstHeader,
      },
      {
        title: "College",
        field: "college",
        cellStyle: tableStyling.school,
        headerStyle: tableStyling.darkHeader,
        // render: (rowData) => !rowData.twoYear ? <p style={{margin: 5, color: '#d1d1d1'}}>No Data</p> : <p>{rowData.twoYear}% <small>({rowData.twoYearStudents} Students)</small></p>
      },
      {
        title: "Measure Rate",
        field: "meas_rate",
        grouping: false,
        cellStyle: tableStyling.dataRow,
        headerStyle: tableStyling.title,
        render: (rowData) =>
          !rowData.meas_rate ? (
            <p style={{ margin: 5, color: "#d1d1d1" }}>No Data</p>
          ) : (
            <p>
              {rowData.meas_rate}%{" "}
              <small>({addCommas(`${rowData.numerator}`)} Students)</small>
            </p>
          ),
      },
      {
        grouping: false,
        title: "Total Students",
        field: "max",
        cellStyle: tableStyling.dataRow,
        headerStyle: tableStyling.title,
        render: (rowData) =>
          !rowData.denominator ? (
            <p style={{ margin: 5, color: "#d1d1d1" }}>No Data</p>
          ) : (
            `${addCommas(`${rowData.denominator}`)} Students`
          ),
      },
    ];
    return (
      <Grid
        style={{ margin: "0 2.5% 0 2.5%", width: "95%" }}
        justifyContent={"center"}
        container
        direction={"row"}
      >
        <TableTitle
          school={{}}
          district={{}}
          hideInfo
          pageTitle={`College Distribution`}
        />
        <Grid xs={12} item>
          <Card>
            <CardHeader className={classes.gradientBackground} color="primary">
              {/* Left the Next Card Header in Place to bring back Primary Color at a later time*/}
              {/*<CardHeader color="primary" >*/}
              <h4 className={classes.cardTitleBlue}>{"Overview"}</h4>
              {/*<p className={classes.cardCategoryWhite}>*/}
              {/*	Here is a subtitle for this table*/}
              {/*</p>*/}
            </CardHeader>
            <CardBody
              style={{
                paddingTop: "0px",
                width: "100%",
                marginTop: "10px",
                paddingBottom: "10px",
                textAlign: "left",
              }}
            >
              <Grid
                md={5}
                xs={12}
                className={classes.metricSubText}
                style={{ padding: "10px" }}
              >
                College data last processed by the NSC on 2/25/2018 and do not
                include full spring 2018 college enrollment. 2 year Persistence
                rate in 2017 and 1 year Enrollment in 2018 may be incomplete.
              </Grid>
            </CardBody>
          </Card>
        </Grid>
        <Grid xs={12} item>
          <Card>
            <CardHeader className={classes.gradientBackground} color="primary">
              {/* Left the Next Card Header in Place to bring back Primary Color at a later time*/}
              {/*<CardHeader color="primary" >*/}
              <CustomToolTip code={selected["Measure"]}>
                <h4 className={classes.cardTitleBlue}>
                  {noData
                    ? "Sorry, there's no data for this graph."
                    : `College Distribution of ${measure}, ${year}`}
                </h4>
              </CustomToolTip>
              {/*<p className={classes.cardCategoryWhite}>*/}
              {/*	Here is a subtitle for this table*/}
              {/*</p>*/}
            </CardHeader>
            <CardBody
              style={{
                paddingTop: "0px",
                height:
                  noData || loading ? "75px" : !showAsColumn ? "75vh" : "350vw",
                width: "100%",
                marginTop: "10px",
                paddingBottom: "10px",
                textAlign: "center",
              }}
            >
              {loading ? (
                <LoadingGrid />
              ) : noData ? null : (
                <div
                  className={classes.plot}
                  style={{ height: "100%", width: "100%" }}
                >
                  <Plot
                    style={{ height: "100%", width: "100%" }}
                    data={data.data}
                    layout={{
                      margin: {
                        r: 0,
                        l: 0,
                        t: 20,
                        b: 0,
                      },
                      xaxis: {
                        domain: [0, 1],
                      },
                      legend: {
                        orientation: "h",
                        x: 0,
                        y: 0,
                      },
                    }}
                  />
                </div>
              )}
            </CardBody>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <PlotData
            loading={loading}
            noData={noData}
            fileName={"distribution"}
            classes={classes}
            data={tableData}
            columns={columns}
          />
        </Grid>
      </Grid>
    );
  }
}

const findInMenu = (array, key) => {
  const res = {
    code: "",
    text: "",
  };
  if (!array) {
    return res;
  }
  return array.reduce((found, curr) => {
    if (curr.code === key) {
      found = curr;
    }
    return found;
  }, res);
};

const mapState = (state) => {
  const { selected, menuObject } = state.menu;
  const { measures, collegeMeasures } = state.constants;
  const district = findInMenu(menuObject["District"], selected["District"]);
  const school = findInMenu(menuObject["School"], selected["School"]);
  const network = findInMenu(menuObject["Network"], selected["Network"]);
  const subgroup = selected["Student Groups"];
  const year = selected["School Year"] ? selected["School Year"] : "";
  const measure = selected["Measure"];
  const measureName = measure ? collegeMeasures[measure]["name"] : "";
  const { collegeDistribution, loading } = state.metrics;
  return {
    collegeDistribution,
    district,
    school,
    network,
    year,
    measure: measureName,
    subgroup,
    loading,
    selected,
  };
};

const mapDispatch = (dispatch) => {
  return {};
};

export default compose(
  withWidth(),
  withStyles(tableStyle, { withTheme: true }),
  connect(mapState, mapDispatch)
)(CollegeDistribution);
