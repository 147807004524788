export const generateSchoolList = ({
  menu,
  selected,
  baseSchoolType,
  baseDistrict,
  baseSchoolLevel,
  path,
}) => {
  const schoolLevelExist =
    selected["District"] !== "overall"
      ? menu["schools"][baseDistrict][baseSchoolLevel]
        ? baseSchoolLevel
        : Object.keys(menu["schools"][baseDistrict])[0]
      : [];

  return selected["District"] === "overall"
    ? []
    : baseSchoolType === "All"
    ? Object.keys(menu["schools"][baseDistrict][schoolLevelExist] || {}).reduce(
        (obj, key) => {
          const curr =
            menu["schools"][baseDistrict][schoolLevelExist][key] || {};
          Object.keys(curr).forEach((schoolKey) => {
            const currSchool = curr[schoolKey];
            obj[currSchool.code] = currSchool;
          });
          return obj;
        },
        {}
      ) || {}
    : (menu["schools"][baseDistrict][schoolLevelExist] || {})[baseSchoolType] ||
      {};
};

export const scatterPlotSchoolList = ({
  menu,
  selected,
  multiple_districts,
}) => {
  const { multi_level, Network } = selected;
  const levels = Object.keys(multi_level);
  const overallDistrictList = Object.keys(menu["districts"][Network]);
  const selectedDistrictNames = Object.keys(selected["multi_district"].list);
  const districtCodes = selectedDistrictNames.reduce((array, previousValue) => {
    multiple_districts.forEach((district) => {
      if (previousValue === district.text) {
        array.push(district.code);
      }
    });
    return array;
  }, []);
  let districtList =
    districtCodes.length > 0 ? districtCodes : overallDistrictList;
  const schoolList =
    districtList.reduce((array, curr) => {
      const districtSchools = menu["schools"][curr];
      levels.forEach((level) => {
        const levelList = districtSchools[level] || {};
        Object.keys(levelList).forEach((type) => {
          Object.keys(levelList[type]).forEach((school_code) => {
            array.push({
              code: school_code,
              text: levelList[type][school_code]["text"],
            });
          });
        });
      });
      return array;
    }, []) || [];
  return schoolList;
};
