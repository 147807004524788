import axios from "axios";
const API = process.env.REACT_APP_API;

export const FETCH_COMPLETENESS = "FETCH_COMPLETENESS";
export const FETCH_COMPLETENESS_SUCCESS = "FETCH_COMPLETENESS_SUCCESS";

const fetchCompletness = () => ({ type: FETCH_COMPLETENESS });
const fetchCompletenessSuccess = (completeness) => ({
  type: FETCH_COMPLETENESS_SUCCESS,
  completeness,
});

export const visualizationActions = {
  getCompletness,
};

function getCompletness(district) {
  return (dispatch) => {
    dispatch(fetchCompletness());
    axios
      .get(API + `/visualization/completion?district=${district}`)
      .then((response) => {
        const { data } = response;
        dispatch(fetchCompletenessSuccess(data));
      });
  };
}
