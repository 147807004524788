/* eslint-disable no-dupe-keys */
import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "../Grid/GridItem.jsx";
import GridContainer from "../Grid/GridContainer.jsx";
import Table from "./Table.jsx";
import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardBody from "../Card/CardBody.jsx";
import Typography from "@material-ui/core/Typography";
import CustomTooltip from "../Tooltip/CustomToolTip";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {
  getSubscript,
  GetTableSubscript,
  SubscriptTooltip,
} from "../../Helpers/Table/Table";
// noinspection JSDuplicatedDeclaration
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontSize: "18px",
    fontWeight: "175",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  cardTitleBlue: {
    color: "#4D7E91",
    marginTop: "0px",
    minHeight: "auto",
    fontSize: "18px",
    // fontWeight: "175",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "0px",
    textDecoration: "none",
    fontWeight: "850",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  gradientBackground: {
    // background: 'rgb(216,229,237) !important',
    background:
      "linear-gradient(90deg, rgba(216,229,237,1) 69%, rgba(255,255,255,1) 82%) !important",
  },
  orangeBackdrop: {
    textAlign: "center",
    backgroundColor: "#f4861f",
    backgroundImage:
      "repeating-linear-gradient(-45deg,transparent,transparent 10px,rgba(0,0,0,.06) 0,rgba(0,0,0,.06) 11px)",
  },
  greenBackdrop: {
    textAlign: "center",
    backgroundColor: "#67b948",
    backgroundImage:
      "repeating-linear-gradient(-45deg,transparent,transparent 5px,rgba(0,0,0,.06) 0,rgba(0,0,0,.06) 6px)",
  },
  clearBackdrop: {
    textAlign: "center",
  },
  redBackdrop: {
    textAlign: "center",
    backgroundColor: "#ef4b4b",
    backgroundImage:
      "repeating-linear-gradient(45deg,transparent,transparent 10px,rgba(0,0,0,.06) 0,rgba(0,0,0,.06) 11px)",
  },
};

class CreateTable extends Component {
  render() {
    const {
      data,
      header,
      title,
      index,
      classes,
      pageType,
      type,
      typeOptions,
      hideFooter,
      loading,
      selectedMeasure,
    } = this.props;

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader className={classes.gradientBackground} color="primary">
              {/* Left the Next Card Header in Place to bring back Primary Color at a later time*/}
              {/*<CardHeader color="primary" >*/}
              <div style={{ display: "inline-block" }}>
                <div style={{ display: "inline-block" }}>
                  {selectedMeasure ? (
                    <CustomTooltip code={selectedMeasure}>
                      <h4 className={classes.cardTitleBlue}>{title}</h4>
                    </CustomTooltip>
                  ) : (
                    <h4 className={classes.cardTitleBlue}>{title}</h4>
                  )}
                </div>
                <div style={{ cursor: "pointer", display: "inline-block" }}>
                  <GetTableSubscript
                    title={title}
                    classes={classes}
                    style={"cardTitleBlue"}
                  />
                </div>
              </div>
              {/*</CardHeader>*/}
              {/*<p className={classes.cardCategoryWhite}>*/}
              {/*	Here is a subtitle for this table*/}
              {/*</p>*/}
            </CardHeader>
            <CardBody style={{ paddingTop: "0px", paddingBottom: "10px" }}>
              <Table
                loading={loading}
                hideFooter={hideFooter}
                pageType={pageType}
                type={type}
                typeOptions={typeOptions}
                title={title}
                key={`table:${index}`}
                tableHeaderColor="primary"
                tableHead={header}
                tableData={data}
                index={`${index}`}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(styles)(CreateTable);
