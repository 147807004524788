import React from "react";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import { OptionsCell } from "./SelectCells";

export const SmallMultiplesTable = ({}) => {
  return (
    <Table>
      <SmallMultipleHead />
    </Table>
  );
};

const SmallMultipleHead = ({}) => {
  return (
    <TableHead>
      <TableRow>
        <OptionsCell />
      </TableRow>
    </TableHead>
  );
};
