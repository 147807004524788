import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import connect from "react-redux/es/connect/connect";
import { compose } from "redux";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Select, { components } from "react-select";
import { getMenu, select, closeDeepLink, toggleVisibility } from "./actions";
import { menu_constants } from "./menu_constants";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisiblityOff from "@material-ui/icons/VisibilityOff";
import Cancel from "@material-ui/icons/Cancel";
import { FixedSizeList as List } from "react-window";
import { getArrayObjectIndex } from "./helpers";

class LargeMenuList extends Component {
  render() {
    const { children } = this.props;
    return (
      <List
        height={300}
        itemCount={children.length}
        itemSize={50}
        initialScrollOffset={0}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  }
}

const styles = (theme) => ({
  appMenuStyles: {
    width: "100%",
    paddingRight: "10px",
    paddingBottom: "5px",
    display: "flex !important",
  },
  root: {
    display: "flex",
    overflow: "hidden",
  },
  margin: {
    margin: theme.spacing.unit,
  },
  bootstrapFormLabel: {
    fontSize: 18,
  },
  selectLabel: {
    color: theme.palette.background.paper,
  },
  icon: {
    fill: "#d0dbdf",
  },
  inputRoot: {
    "label + &": {
      marginTop: theme.spacing.unit * 3,
    },
  },
  input: {
    container: {},
    borderRadius: 4,
    backgroundColor: "#1b5267",
    color: "#d0dbdf",
    fontSize: 16,
    width: "100%",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      backgroundColor: "#1b5267",
    },
  },
});
const menuStyles = () => ({
  clearIndicator: () => ({
    display: "none",
  }),
  menu: () => ({
    position: "absolute",
    backgroundColor: "#1b5267",
    zIndex: 200,
    color: "#d0dbdf",
    fontSize: 16,
    borderRadius: "5px",
    cursor: "pointer",
    width: "100%",
  }),
  option: (base, state) => ({
    padding: "10px",
    minHeight: "40px",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    fontSize: "16px",
    backgroundColor: state.isFocused ? "#122c3e" : "#1b5267",
    color: "#d0dbdf",
    "&:hover": {
      cursor: "pointer",
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    textOverflow: "ellipsis",
    color: "white",
    verticalAlign: "center",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "white",
    width: "100%",
    fontSize: "16px",
    textOverflow: "ellipsis",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  }),
  multiValueLabel: (base, { data }) => ({
    ...base,
    width: "90%",
    backgroundColor: data.configValue,
    color: "white",
  }),

  indicatorsContainer: () => ({
    position: "absolute",
    color: "#d0dbdf",
    right: 0,
    bottom: 0,
  }),
  input: () => ({
    color: "#d0dbdf",
    textOverflow: "ellipsis",
  }),
  control: () => ({
    width: "100%",
    padding: "5px",
    paddingTop: "10px",
    minHeight: "40px",
    textOverflow: "ellipsis",
    borderRadius: 10,
    maxHeight: "80px",
    overflowY: "auto",
    backgroundColor: "#1b5267",
    color: "#d0dbdf",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#122c3e",
      color: "#d0dbdf",
    },
  }),
  groupHeading: () => ({
    backgroundColor: "green",
  }),
});
const ColorSelectingOption = (props) => {
  const {
    data: { label },
  } = props;
  const maxWidth = label ? (label.length > 10 ? "50%" : false) : false;
  return (
    <div style={{ maxHeight: "40px", maxWidth }}>
      <components.MultiValueContainer {...props} />
    </div>
  );
};

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEdit: "",
      previousDistrict: "",
      previousNetwork: "",
    };
  }

  componentDidUpdate(prevProps) {
    const input = { name: "", value: "" };
    const oldPath = prevProps.path;
    const { select, menu, selected, path, constants, menuObject } = this.props;

    if (oldPath !== path) {
      if (
        [
          "/dashboard/shiny/prelim/gaptrends",
          "/dashboard/ondemand/gaptrends",
        ].includes(path) &&
        selected["Student Groups"] === "ALL"
      ) {
        input["name"] = "Student Groups";
        input["value"] = "SD";
      }
      select(input, menu, selected, path, constants, menuObject);
    }
  }

  handleChange = (event, name, multi) => {
    const value = multi ? (event.length > 0 ? event : "CLEAR") : event.value;
    const input = { name, value };
    const { select, menu, selected, path, constants, menuObject } = this.props;

    this.setState({
      previousDistrict: selected.District,
      previousNetwork: selected.Network,
    });
    select(
      input,
      menu,
      { ...selected, grades: "All" },
      path,
      constants,
      menuObject
    );
  };

  render() {
    const { selected, menuObject, path, classes, toggle, toggleEdit } =
      this.props;
    const { dashboard_options, menuLists } = menu_constants;
    const menuList = menuLists[path] || [];
    return (
      <Grid
        className={classes.appMenuStyles}
        container
        item
        justifyContent={"flex-start"}
        direction={"row"}
        alignItems={"flex-end"}
        wrap="nowrap"
      >
        {menuList.map((currColumn) => {
          const { text, type, key, maxWidth } =
            dashboard_options[currColumn] || {};

          const currMenu = menuObject[key] ? menuObject[key] : [];

          if (!Object.keys(dashboard_options).includes(key)) {
            return null;
          }
          const isMulti = key.substring(0, 5) === "multi";
          const isList = ["multi_district", "multi_school"].includes(key);
          const options =
            type === 0 && !isMulti
              ? currMenu.map((curr) => {
                  return {
                    value: curr.code,
                    label: curr.text === "K8" ? "K-8" : curr.text,
                  };
                })
              : type === 0 && isMulti
              ? currMenu.map((curr) => {
                  return {
                    value: curr.text,
                    label: curr.text === "K8" ? "K-8" : curr.text,
                  };
                })
              : currMenu.map((curr) => {
                  return {
                    label: curr === "K8" ? "K-8" : curr,
                    value: curr,
                  };
                });

          const optionToggler =
            selected["District"] === "overall" &&
            path === "/dashboard/ondemand/mapview" &&
            key === "School"
              ? []
              : options;

          const stateChange =
            this.state.previousDistrict !== selected["District"];
          const networkChange =
            this.state.previousNetwork !== selected["Network"];

          const large = optionToggler.length > 200;
          const selectedValue =
            isList && selected[key]
              ? selected[key].list
              : selected[key] || (isMulti ? {} : "");
          const visibility =
            isList && selected[key] ? selected[key].visibility : false;
          let longest = 13;
          const flatValues = isMulti ? Object.keys(selectedValue) : [];
          const selectedValueArray = [
            getArrayObjectIndex(optionToggler, "value", selectedValue),
          ];
          const label = optionToggler.reduce((obj, curr) => {
            const { label, value } = curr;
            const configValue = isMulti ? selectedValue[value] : "";
            longest = Math.max(label.toString().length, longest);
            if (isMulti) {
              if (flatValues.includes(value)) {
                return obj.concat([{ ...curr, configValue }]);
              }
            } else if (stateChange && key === "School Year") {
              if (path === "/dashboard/shiny/prelim/collegedistribution") {
                return obj.concat(
                  getArrayObjectIndex(optionToggler, "value", "2016-2017")
                );
              } else if (
                path === "/dashboard/shiny/prelim/barplotdistributions"
              ) {
                return obj.concat(
                  getArrayObjectIndex(optionToggler, "value", "2016-2017")
                );
              }
              return obj.concat(selectedValueArray);
            } else if (stateChange && key === "School") {
              if (path === "/dashboard/ondemand/mapview") {
                if (value === selectedValue) {
                  return obj.concat([curr]);
                }
              } else {
                return obj.concat(selectedValueArray);
              }
            } else if (value === selectedValue) {
              return obj.concat([curr]);
            }
            return obj;
          }, []);
          const value = isMulti ? label : label[0];
          return (
            <Grid
              style={{
                marginRight: "5px",
                marginTop: "5px",
                paddingBottom: "5px",
                minWidth: maxWidth,
                maxWidth,
              }}
              container
              item
              direction={"column"}
              key={key}
            >
              <Grid xs={12} item>
                {MenuLabel(
                  key,
                  text,
                  classes,
                  toggle,
                  visibility,
                  this.handleChange.bind(this),
                  toggleEdit
                )}
              </Grid>
              <Grid style={{ height: "50px" }} xs={12} item>
                {large ? (
                  <Select
                    styles={menuStyles(longest, key, maxWidth)}
                    className={classes.input}
                    isMulti={isMulti}
                    components={{
                      MultiValueContainer: ColorSelectingOption,
                      MenuList: LargeMenuList,
                    }}
                    options={optionToggler}
                    value={value || optionToggler[0] || {}}
                    onChange={(e) => {
                      this.handleChange(e, key, isMulti, type);
                    }}
                  />
                ) : (
                  <Select
                    styles={menuStyles(longest, key, maxWidth)}
                    className={classes.input}
                    isMulti={isMulti}
                    components={{
                      MultiValueContainer: ColorSelectingOption,
                    }}
                    options={optionToggler}
                    value={value || optionToggler[0] || {}}
                    onChange={(e) => {
                      this.handleChange(e, key, isMulti, type);
                    }}
                  />
                )}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    );
  }
}

const MenuLabel = (key, text, classes, toggle, visibility, clear) => {
  switch (key) {
    case "multi_district":
    case "multi_school":
      return (
        <Grid alignItems={"center"} container item style={{ color: "white" }}>
          <Grid item>
            <Typography className={classes.selectLabel}>{text}</Typography>
          </Grid>
          <Grid item>
            <IconButton
              color={"inherit"}
              onClick={() => {
                toggle(key);
              }}
              fontSize={"small"}
            >
              {visibility ? (
                <Visibility color={"inherit"} fontSize={"small"} />
              ) : (
                <VisiblityOff color={"inherit"} fontSize={"small"} />
              )}
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              color={"inherit"}
              onClick={() => {
                clear([], key, true);
              }}
            >
              <Cancel color={"inherit"} fontSize={"small"} />
            </IconButton>
          </Grid>
        </Grid>
      );

    default:
      return (
        <Grid xs={12} item>
          <Typography className={classes.selectLabel}>{text}</Typography>
        </Grid>
      );
  }
};

const mapLogin = (state) => {
  const { menu, selected, menuObject, deepLink, deepLinkURL } = state.menu;
  const { subgroups, collegeMeasures, measures } = state.constants;
  return {
    menu,
    selected,
    menuObject,
    deepLink,
    deepLinkURL,
    constants: {
      subgroups,
      collegeMeasures,
      measures,
    },
  };
};

const mapDispatch = (dispatch) => {
  return {
    get: async (route, selected, deepLink) => {
      dispatch(getMenu(route, selected, deepLink));
    },
    select: (input, menu, selected, path, constants, menuObject) => {
      dispatch(select(input, menu, selected, path, constants, menuObject));
    },
    exitDeepLink: () => {
      dispatch(closeDeepLink());
    },
    toggle: (name) => {
      dispatch(toggleVisibility(name));
    },
  };
};

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapLogin, mapDispatch)
)(Menu);
