import {
  FETCH_INITIAL_CONSTANTS_SUCCESS,
  FETCH_LCAP_CONSTANTS_SUCCESS,
  FETCH_INITIAL_DEFINITIONS_SUCCESS,
} from "./actions";

const initialState = {
  subgroups: {},
  collegeMeasures: {},
  measures: {},
  coreIndex: {},
  lcapOverview: {},
  lcap: {
    text: {},
    values: {},
  },
  measureDefinitions: {},
};

export default function constants(state = initialState, action) {
  switch (action.type) {
    case FETCH_INITIAL_CONSTANTS_SUCCESS:
      return {
        ...state,
        subgroups: action.constants["subgroups"],
        measures: action.constants["measures"],
        collegeMeasures: action.constants["collegeMeasures"],
        coreIndex: action.constants["coreOverview"],
        lcapOverview: action.constants["lcapOverview"],
      };
    case FETCH_LCAP_CONSTANTS_SUCCESS:
      return {
        ...state,
        lcap: {
          text: action.constants["lcapText"],
          values: action.constants["lcapValues"],
          grids: action.constants["lcapGrids"],
        },
      };
    case FETCH_INITIAL_DEFINITIONS_SUCCESS:
      return {
        ...state,
        measureDefinitions: action.measure_definitions,
      };
    default:
      return state;
  }
}
