/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table/Table";
import TableHead from "@material-ui/core/TableHead/TableHead";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody/TableBody";
import { IconButton, Typography } from "@material-ui/core";
import ChevronDown from "@material-ui/icons/ExpandMore";
import ChevronRight from "@material-ui/icons/ChevronRight";
import arrow from "../../static/arrow.png";
import Tooltip from "@material-ui/core/Tooltip";

const sortColumn = (data, sort) => {
  const { key, direction } = sort;
  return data.sort((a, b) => {
    const valA = direction ? a : b;
    const valB = direction ? b : a;

    if (typeof valA[key] === "string") {
      return valA[key].localeCompare(valB[key]);
    } else {
      return valA[key] - valB[key];
    }
  });
};

const SortIcon = ({ sort, setSort, classes, headerValue }) => {
  const direction = headerValue !== "School Name";
  const topColor =
    headerValue === sort.key && sort.direction === !direction
      ? "rgba(29,94,119,.75)"
      : "rgba(0,0,0,.25)";
  const bottomColor =
    headerValue === sort.key && sort.direction === direction
      ? "rgba(29,94,119,.75)"
      : "rgba(0,0,0,.25)";
  return (
    <svg viewBox={"0 0 24 24"} className={classes.sortIcon} style={{}}>
      <path
        onClick={() => {
          setSort({ key: headerValue, direction: !direction });
        }}
        fill={topColor}
        strokeLinejoin={"miter"}
        shapeRendering={"geometricPrecision"}
        strokeWidth={0}
        d={"M12 2 L 18 10 L 6 10 L 12 2z"}
      />
      <path
        onClick={() => {
          setSort({ key: headerValue, direction: direction });
        }}
        fill={bottomColor}
        strokeLinejoin={"miter"}
        shapeRendering={"geometricPrecision"}
        strokeWidth={0}
        d={"M12 22 L 18 14 L 6 14 L 12 22z"}
      />
    </svg>
  );
};

const DistributionTable = (props) => {
  const [sort, setSort] = useState({ key: "School Name", direction: true });
  const {
    data: { data, headerList },
    selectedSubgroup,
    classes,
    subgroups,
  } = props;
  const dataOverall = Object.values(data).reduce((array, val) => {
    if (selectedSubgroup in val["overall"]) {
      array.push(val["overall"][selectedSubgroup]);
    }
    return array;
  }, []);

  return (
    <Grid container>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {headerList &&
              headerList.map((col, i) => (
                <TableCell
                  className={classes.gradeDistributionTableHead}
                  key={col}
                >
                  <Grid
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Grid item>{col}</Grid>
                    {i !== 0 && (
                      <Grid item>
                        <SortIcon
                          headerValue={col}
                          setSort={setSort}
                          classes={classes}
                          sort={sort}
                        />
                      </Grid>
                    )}
                  </Grid>
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {dataOverall.length < 1 ? (
            <TableRow>
              <TableCell>
                <Grid
                  container
                  item
                  justifyContent={"center"}
                  alignItems={"center"}
                  style={{
                    fontSize: "16px",
                    fontWeight: 900,
                    color: "rgba(61,114,135,.8)",
                    opacity: 0.1,
                  }}
                >
                  No Data
                </Grid>
              </TableCell>
            </TableRow>
          ) : (
            sortColumn(dataOverall, sort).map((school, rowIndex) => {
              const [overallOpen, setOverallOpen] = useState(false);
              const [secondOverallOpen, setSecondOverallOpen] = useState(false);
              const dataSecondLevel = Object.values(
                data[school["School Name"]]["secondLevel"] || {}
              ).reduce((array, val) => {
                if (selectedSubgroup in val) {
                  array.push(val[selectedSubgroup]);
                }
                return array;
              }, []);

              const dataArrayOverall = Object.values(
                data[school["School Name"]]["overall"] || {}
              ).reduce((array, val) => {
                if (val["Subgroup"] !== selectedSubgroup) {
                  array.push(val);
                }
                return array;
              }, []);
              return (
                <>
                  <BuildRow
                    headerList={headerList}
                    rowIndex={rowIndex}
                    data={school}
                    open={overallOpen}
                    secondOpen={secondOverallOpen}
                    setOpen={setOverallOpen}
                    setSecondOpen={setSecondOverallOpen}
                    classes={classes}
                    subgroups={subgroups}
                  />
                  <>
                    {secondOverallOpen &&
                      sortColumn(dataArrayOverall, sort).map(
                        (sub, subIndex) => {
                          return (
                            <BuildRow
                              headerList={headerList}
                              rowIndex={rowIndex + rowIndex + subIndex}
                              data={sub}
                              open={false}
                              level={"third"}
                              showArrow={false}
                              classes={classes}
                              subgroups={subgroups}
                            />
                          );
                        }
                      )}
                  </>
                  <>
                    {sortColumn(dataSecondLevel, sort).map(
                      (grade, gradeIndex) => {
                        const [gradeOpen, setGradeOpen] = useState(false);
                        const dataThirdLevel = Object.values(
                          data[school["School Name"]]["thirdLevel"][
                            grade["Grade"]
                          ] || {}
                        ).reduce((array, val) => {
                          array.push(val);
                          return array;
                        }, []);
                        return (
                          overallOpen && (
                            <>
                              <BuildRow
                                headerList={headerList}
                                rowIndex={rowIndex + gradeIndex + 1}
                                data={grade}
                                open={gradeOpen}
                                setOpen={setGradeOpen}
                                level={"second"}
                                classes={classes}
                                subgroups={subgroups}
                              />
                              {sortColumn(dataThirdLevel, sort).map(
                                (sub, subIndex) => {
                                  return (
                                    gradeOpen && (
                                      <BuildRow
                                        headerList={headerList}
                                        rowIndex={
                                          rowIndex + gradeIndex + subIndex
                                        }
                                        data={sub}
                                        open={false}
                                        level={"third"}
                                        showArrow={false}
                                        classes={classes}
                                        subgroups={subgroups}
                                      />
                                    )
                                  );
                                }
                              )}
                            </>
                          )
                        );
                      }
                    )}
                  </>
                </>
              );
            })
          )}
        </TableBody>
      </Table>
    </Grid>
  );
};

const BuildRow = ({
  classes,
  headerList,
  rowIndex,
  data,
  open,
  secondOpen,
  setOpen,
  setSecondOpen,
  level = "overall",
  showArrow = true,
  subgroups,
}) => {
  return (
    <TableRow style={{ height: "50px" }}>
      {headerList.map((key, i) => {
        const isToolTipCells = i === 4 || i === 5 || i === 6;

        // loop over the data that is to be displayed
        let value = "";
        //allows blank spaces in the second and third levels for school name and grade columns. It also sets the value to no data if need be.
        if (
          !(level === "second" && key === "School Name") &&
          !(level === "third" && (key === "School Name" || key === "Grade"))
        ) {
          if (data[key] === 0 || data[key]) {
            value = data[key];
          } else {
            value = "No Data";
          }
        }
        if (isToolTipCells) {
          // This is for the columns that display the colored bar
          const colorClass = data["Color"].hasOwnProperty(key)
            ? classes[data["Color"][key]]
            : {};
          const colorBarCellStyle = {
            // styles the grid that is used for the bar
            opacity: level === "overall" ? 1 : level === "second" ? 0.75 : 0.3,
            position: "absolute",
            left: 0,
            zIndex: 6,
            width: `${value}%`,
            height: 42,
            color: "#000000",
          };
          if (value !== "No Data") {
            // displays the bar and the percentage value
            value = (
              <Grid container alignItems={"center"}>
                <Grid
                  container
                  item
                  className={colorClass}
                  justifyContent={"flex-end"}
                  alignItems={"center"}
                  style={colorBarCellStyle}
                />
                <Grid
                  container
                  item
                  justifyContent={"flex-end"}
                  alignItems={"center"}
                  style={{
                    position: "relative",
                    fontSize: level === "overall" ? 24 : 16,
                    fontWeight: 800,
                    zIndex: 7,
                    right: "5px",
                    color: "black",
                  }}
                >
                  {Math.round(Math.abs(value))}
                </Grid>
              </Grid>
            );
          }
        } else if (i === 7) {
          // last column that contains arrow and the difference value
          if (value > 0) {
            value = arrowHelper(
              <img
                alt={"changeUpArrow"}
                src={arrow}
                className={classes.changeUpArrow}
              ></img>,
              value,
              level
            );
          } else if (value < 0) {
            value = arrowHelper(
              <img
                alt={"changeDownArrow"}
                src={arrow}
                className={classes.changeDownArrow}
              ></img>,
              value,
              level
            );
          } else if (value === 0) {
            value = arrowHelper(
              <img
                alt={"changeSideArrow"}
                src={arrow}
                className={classes.changeSideArrow}
              ></img>,
              value,
              level
            );
          }
        }
        const toolTipInfoExists =
          "toolTipDisplay" in data ? key in data.toolTipDisplay : false;
        const hideToolTip = !isToolTipCells || value === "No Data";
        return (
          <Tooltip
            disableTouchListener={hideToolTip}
            disableHoverListener={hideToolTip}
            disableFocusListener={hideToolTip}
            placement={"top"}
            classes={{
              tooltip: classes.test,
              popper: classes.test,
            }}
            title={
              <Grid container className={classes.growthTooltip}>
                <Grid
                  container
                  item
                  xs={12}
                  style={{
                    borderBottom: "2px solid #BBCAD0",
                    padding: 5,
                  }}
                >
                  <Grid item xs={12} style={{ paddingBottom: 5, fontSize: 16 }}>
                    {data["School Name"]}
                  </Grid>
                  <Grid item xs={12} style={{ paddingBottom: 5 }}>
                    {data.grade} - {key}
                  </Grid>
                  <Grid item xs={12}>
                    {data["Subgroup"]}
                  </Grid>
                </Grid>
                <Grid item style={{ padding: 5 }} xs={12}>
                  {data[key]}% of Students On-Track
                </Grid>
                <Grid item style={{ padding: 5 }} xs={12}>
                  Number of Students Who Achieved On-Track Status:{" "}
                  {toolTipInfoExists &&
                    data.toolTipDisplay[key].nStudentsOnTrackStatus}
                </Grid>
                <Grid item style={{ padding: 5 }} xs={12}>
                  Number of Students:{" "}
                  {toolTipInfoExists && data.toolTipDisplay[key].nStudents}
                </Grid>
              </Grid>
            }
          >
            <TableCell
              className={classes.tableCell}
              key={key} // table cell the values reside in
              style={{
                background: rowIndex % 2 === 0 ? "rgba(1,61,84,.09)" : "white",
              }}
            >
              <Grid
                container
                style={{
                  fontSize: "14px",
                  height: "100%",
                  position: "relative",
                  fontWeight: 600,
                  color: "rgba(61,114,135,.8)",
                  textAlign: "center",
                }}
              >
                {i !== 0 ? (
                  <Grid
                    item
                    container
                    justifyContent={
                      i === 1 || value === "No Data" ? "flex-start" : "center"
                    }
                    style={{
                      paddingLeft: i === 1 || value === "No Data" ? "25px" : 0,
                      opacity: value === "No Data" ? 0.3 : 1,
                    }}
                  >
                    {key === "Subgroup" ? (
                      <Tooltip
                        placement={"top"}
                        classes={{
                          tooltip: classes.test,
                          popper: classes.test,
                        }}
                        title={
                          <div
                            className={classes.growthTooltip}
                            style={{ padding: 10 }}
                          >
                            {(subgroups[value] || {})["name"] || value}
                          </div>
                        }
                      >
                        <Typography className={classes.toolTipTypography}>
                          {subgroups[value]["alias"] || value}
                        </Typography>
                      </Tooltip>
                    ) : (
                      value
                    )}
                  </Grid>
                ) : (
                  showArrow && (
                    <Grid item xs={6}>
                      <IconButton
                        onClick={() => setOpen((tempOpen) => !tempOpen)}
                        style={{ padding: 0 }}
                      >
                        {open ? (
                          <ChevronDown
                            className={"rotatingDown"}
                            style={{
                              fontSize: 36,
                              color: "rgba(61,114,135,.8)",
                            }}
                          />
                        ) : (
                          <ChevronRight
                            className={"rotatingUp"}
                            style={{
                              fontSize: 36,
                              color: "rgba(61,114,135,.8)",
                            }}
                          />
                        )}
                      </IconButton>
                    </Grid>
                  )
                )}
                {level === "overall" && i === 0 && (
                  <Grid item xs={6}>
                    {open && (
                      <IconButton
                        onClick={() => setSecondOpen((tempOpen) => !tempOpen)}
                        style={{ padding: 0 }}
                      >
                        {secondOpen ? (
                          <ChevronDown
                            className={"rotatingDown"}
                            style={{
                              fontSize: 36,
                              color: "rgba(61,114,135,.8)",
                            }}
                          />
                        ) : (
                          <ChevronRight
                            className={"rotatingUp"}
                            style={{
                              fontSize: 36,
                              color: "rgba(61,114,135,.8)",
                            }}
                          />
                        )}
                      </IconButton>
                    )}
                  </Grid>
                )}
              </Grid>
            </TableCell>
          </Tooltip>
        );
      })}
    </TableRow>
  );
};

const arrowHelper = (imgTag, data, level) => {
  //returns the grid that contains the arrow and the value
  return (
    <Grid container alignItems={"center"}>
      <Grid
        item
        xs={6}
        style={{
          right: "10%",
          opacity: level === "overall" ? 1 : level === "second" ? 0.75 : 0.3,
        }}
      >
        {imgTag}
      </Grid>
      <Grid
        item
        xs={6}
        style={{
          fontSize: level === "overall" ? 24 : 16,
          fontWeight: 800,
          zIndex: 5,
          right: "5px",
          color: "black",
        }}
      >
        {Math.round(Math.abs(data))}
      </Grid>
    </Grid>
  );
};
export { DistributionTable };
