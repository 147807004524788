export const districtNameExtractor = (menu, startSelect) => {
  return (
    Object.keys(menu["districts"][startSelect["Network"]] || {}).sort(
      (a, b) => {
        return menu["districts"][startSelect["Network"]][a].text.localeCompare(
          menu["districts"][startSelect["Network"]][b].text
        );
      }
    )[0] || "overall"
  );
};
