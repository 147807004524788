import TableHead from "@material-ui/core/TableHead/TableHead";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import Table from "@material-ui/core/Table/Table";
import TableBody from "@material-ui/core/TableBody";
import React from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import { addCommas } from "../utils";

export const onDemandHelpers = {
  generateGapTrendsData,
  formatGapTrendsPlots,
  generateMetricTrendsData,
  generateMetricTrendsTable,
  generateScatterPlotsData,
};

function generateGapTrendsData({
  keys,
  gapTrends,
  subgroup,
  inverseGroup,
  subgroupName,
  measureInfo,
  dfmMeasure,
}) {
  const { name, reverse, percent } = measureInfo;
  const numeratorText = dfmMeasure
    ? "Total Distance From Standards:"
    : "Number of Students:";
  let scatterMinY;
  let scatterMaxY;
  const baseObject = {
    index: 1,
    barPlot: {},
    lineChart: {},
    barChartLayout: {
      maxY: 0,
      minY: 0,
    },
    lineChartLayout: {
      maxY: 0,
      minY: 0,
    },
    table: [],
  };
  const removeDuplicates = keys.reduce((arr, key) => {
    if (!arr.includes(key)) {
      arr.push(key);
    }
    return arr;
  }, []);
  const data = removeDuplicates.reduce((object, key, index) => {
    if (gapTrends[key]) {
      const xaxis = "x" + object.index;
      const lineChart = {
        title: key,
        position: object.index,
        chartData: {
          inverse: {
            x: [],
            y: [],
            text: [],
            hovertemplate: [],
            mode: "text+lines+markers",
            textposition: [],
            name: `Not ${subgroupName}`,
            showlegend: object.index === 1,
            xaxis,
            legendgroup: "a",
            marker: {
              size: 12,
            },
            line: {
              color: "#f79c1c",
            },
          },
          subgroup: {
            x: [],
            y: [],
            hovertemplate: [],
            text: [],
            showlegend: object.index === 1,
            legendgroup: "b",
            name: subgroupName,
            xaxis,
            textposition: [],
            mode: "text+lines+markers",
            marker: {
              size: 12,
            },
            line: {
              color: "#5590b5",
            },
          },
        },
      };
      const barChart = {
        barChartData: {
          x: [],
          y: [],
          marker: {
            color: [],
          },
          text: [],
          hoverinfo: "text",
          mode: "text",
          showlegend: false,
          textposition: "auto",
          xaxis,
          type: "bar",
        },
      };
      object.index++;
      key &&
        Object.keys(gapTrends[key]).forEach((year) => {
          const { chartData } = lineChart;
          let distance = {
            inverse: false,
            subgroup: false,
          };
          barChart.barChartData.x.push(year);
          gapTrends[key] &&
            [subgroup, inverseGroup].forEach((group) => {
              const currData = gapTrends[key][year][group];
              const plot =
                group === subgroup ? chartData.subgroup : chartData.inverse;
              plot.x.push(year);
              if (currData) {
                const { measure_rate, numerator, denominator } =
                  gapTrends[key][year][group];
                const measureText = dfmMeasure
                  ? `<b>Average Distance From Standards:</b> ${measure_rate}`
                  : `<b>Percentage:</b> ${measure_rate}%`;
                plot.text.push(`<b>${measure_rate}</b>`);
                const text =
                  `<b style="font-size:16px">${key}, ${year}</b>` +
                  `<br><b>${
                    group === subgroup ? subgroupName : `Not ${subgroupName}`
                  }</b>` +
                  `<br>${measureText}` +
                  `<br><b>${numeratorText}</b> ${addCommas(`${numerator}`)}` +
                  `<br><b>Total Number of Students:</b> ${addCommas(
                    `${denominator}`
                  )}` +
                  `<extra></extra>`;
                plot.hovertemplate.push(text);

                plot.y.push(measure_rate);
                scatterMaxY = scatterMaxY
                  ? Math.max(measure_rate, scatterMaxY)
                  : measure_rate;
                scatterMinY = scatterMinY
                  ? Math.min(measure_rate, scatterMinY)
                  : measure_rate;
                object.lineChartLayout.maxY = Math.max(
                  object.lineChartLayout.maxY,
                  measure_rate
                );
                object.lineChartLayout.minY = object.lineChartLayout.minY
                  ? Math.min(object.lineChartLayout.minY, measure_rate)
                  : measure_rate;

                if (group === subgroup) {
                  distance.subgroup = measure_rate;
                } else {
                  distance.inverse = measure_rate;
                }
              } else {
                plot.y.push(undefined);
                plot.text.push("");
                plot.hovertemplate.push("");
              }
            });
          const lastYsubgroup =
            chartData.subgroup.y[chartData.subgroup.y.length - 1];
          const lastYinverse =
            chartData.inverse.y[chartData.inverse.y.length - 1];
          const subgroupPos =
            lastYsubgroup && lastYinverse
              ? lastYsubgroup > lastYinverse
                ? "top center"
                : "bottom center"
              : "top center";
          const inversePos =
            lastYsubgroup && lastYinverse
              ? lastYsubgroup > lastYinverse
                ? "bottom center"
                : "top center"
              : "bottom center";
          chartData.subgroup.textposition.push(subgroupPos);
          chartData.inverse.textposition.push(inversePos);
          let dist = 0;
          if (distance.subgroup && distance.inverse) {
            dist =
              Math.round(100 * (distance.subgroup - distance.inverse)) / 100;
          }
          const { maxY, minY } = object.barChartLayout;
          object.barChartLayout.maxY = maxY ? Math.max(maxY, dist) : dist;
          object.barChartLayout.minY = minY ? Math.min(minY, dist) : dist;
          barChart.barChartData.y.push(dist);
          barChart.barChartData.text.push(`<b>${dist}</b>`);
          const color = !reverse
            ? dist > 0
              ? "rgb(103,185,72)"
              : "rgb(239,75,75)"
            : dist > 0
            ? "rgb(239,75,75)"
            : "rgb(103,185,72)";
          barChart.barChartData.marker.color.push(color);
          object.table.push({
            name: key,
            year: year,
            subgroup: distance.subgroup ? distance.subgroup : "No Data",
            inverse: distance.inverse ? distance.inverse : "No Data",
            gap: distance.inverse && distance.subgroup ? dist : "No Data",
          });
        });
      object.lineChart[key] = lineChart;
      object.barPlot[key] = barChart;
    }
    return object;
  }, baseObject);
  return {
    data,
    scatterMaxY: scatterMaxY || 100,
    scatterMinY: scatterMinY || 0,
  };
}

function formatGapTrendsPlots({ data, years }) {
  const tickvals = years.sort((a, b) => {
    const cA = a.split("-")[1];
    const cB = b.split("-")[1];
    return parseInt(cA) > parseInt(cB);
  });
  return Object.keys(data.lineChart).reduce(
    (array, key, index) => {
      const { chartData, position } = data.lineChart[key];
      const { barChartData } = data.barPlot[key];
      const len = Object.keys(data.lineChart).length;
      const l = (position - 1) / len;
      const r = l + 1.0 / len;
      const split = key.split(" ");
      const parsed = split.reduce(
        (s, c) => {
          s.count += c.length;
          if (s.count > 15) {
            s.count = 0;
            s.parsed = `${s.parsed}<br>${c}`;
          } else {
            s.parsed = `${s.parsed} ${c}`;
          }
          return s;
        },
        {
          parsed: "",
          count: 0,
        }
      ).parsed;
      array.xAxisPositions["xaxis" + position] = {
        title: {
          text: parsed,
          font: {
            size: 20,
          },
        },
        domain: [l + 0.025, r - 0.025],
        tickvals,
        ticktext: tickvals.map((year) => {
          const splitYear = year.split("-");
          return `<b>${splitYear[0].charAt(2)}${splitYear[0].charAt(
            3
          )}-${splitYear[1].charAt(2)}${splitYear[1].charAt(3)}</b>`;
        }),
        anchor: "x" + position,
      };
      array.barData.push(barChartData);
      Object.keys(chartData).forEach((group) => {
        array.data.push(chartData[group]);
      });
      return array;
    },
    {
      data: [],
      barData: [],
      xAxisPositions: {},
    }
  );
}

function generateMetricTrendsTable({ metricTrends, measureInfo }) {
  const { subtext, percent } = measureInfo || {};
  const percentSymbol = percent ? "%" : "";
  return metricTrends
    ? Object.keys(metricTrends).reduce((array, key) => {
        const curr = metricTrends[key];
        array[key] = [];
        curr &&
          Object.keys(curr).forEach((year) => {
            const {
              measure_rate,
              numerator,
              denominator,
              index_level_color,
              index_level,
            } = curr[year];
            array[key].push([
              {
                type: "NAME",
                text: year,
              },
              index_level
                ? {
                    type: "INDEX LEVEL",
                    text: index_level,
                    color: index_level_color,
                  }
                : "NO DATA",
              measure_rate
                ? {
                    type: "METRIC RESULT",
                    text: `${measure_rate}${percentSymbol}`,
                    subtext,
                  }
                : "NO DATA",
              numerator
                ? {
                    type: numerator ? "METRIC TABLE CELL" : "NO DATA",
                    text: numerator,
                  }
                : "NO DATA",
              denominator
                ? {
                    type: denominator ? "METRIC TABLE CELL" : "NO DATA",
                    text: denominator,
                  }
                : "NO DATA",
            ]);
          });
        return array;
      }, {})
    : {};
}

function generateMetricTrendsData({
  metricTrends,
  years,
  distance,
  parsedName,
    subMenuMeasure,
}) {
  let maxY = undefined;
  let minY = undefined;
  const data = metricTrends
    ? Object.keys(metricTrends).map((key) => {
        const curr = metricTrends[key];
        const object = {
          x: [],
          y: [],
          text: [],
          name: key,
          mode: "markers+lines",
          hoverinfo: "text",
          line: {
            width: 2,
          },
          marker: {
            size: 12,
          },
        };
        let numeratorText = "Number of Students:"
        if(subMenuMeasure === "growth_ela" ||subMenuMeasure === "growth_math"){
          numeratorText = 'Growth Score:'
        }
        if(distance){
          numeratorText ='Total Distance From Standards:'
        }

        years.forEach((year) => {
          object.x.push(year);
          const { measure_rate, numerator, denominator } = curr[year] || {};
          if (measure_rate !== undefined) {
            object.y.push(measure_rate);
            maxY =
              maxY !== undefined ? Math.max(measure_rate, maxY) : measure_rate;
            minY =
              minY !== undefined ? Math.min(measure_rate, minY) : measure_rate;
            const measureText = distance
              ? `<b>Average Distance From Standards:</b> ${measure_rate}`
              : `<b>Percentage:</b> ${measure_rate}%`;
            const text =
              `<b style="font-size:16px">${key}, ${year}</b>` +
              `<br><b>${parsedName}</b>` +
              `<br>${measureText}` +
              `<br><b>${numeratorText}</b> ${addCommas(`${numerator}`)}` +
              `<br><b>Total Number of Students:</b> ${addCommas(
                `${denominator}`
              )}`;
            object.text.push(text);
          } else {
            object.y.push(undefined);
            object.text.push("");
          }
        });
        return object;
      })
    : [];
  return {
    data,
    minY: minY || 0,
    maxY: maxY || 100,
  };
}

function generateScatterPlotsData({
  data_points,
  measureList,
  scatterPlots,
  measureLevels,
  subgroup,
  sliders,
  xAxis,
  yAxis,
  selectedDistricts,
  selectedSchools,
}) {
  const group = subgroup;
  const customdata = {};
  const {list :selectedSchoolList} = selectedSchools
  const {list: selectedDistrictList, visibility} = selectedDistricts
  const data = Object.keys(scatterPlots).reduce(
    (object, district) => {
      const currDistrict = scatterPlots[district];
      const districtColor = selectedDistrictList[district] || "#e65825";
      const showDistrict = visibility ? true : selectedDistrictList[district];
      showDistrict &&
        currDistrict &&
        measureLevels.forEach((measureLevel) => {
          const schoolList = currDistrict[measureLevel];
          schoolList &&
            Object.keys(schoolList).forEach((school) => {
              const schoolKey =
                data_points === "Pathways" ? school.split(" (")[0] : school;
              const currentSchool = schoolList[school];
              const currList = selectedSchools["list"];
              const schoolVisibility = selectedSchools["visibility"];
              const schoolSelect = currList[schoolKey];
              const schoolShow = schoolVisibility ? true : !!schoolSelect;
              const { demo_pct, x_axis, y_axis } = currentSchool;
              const sliderValues = Object.keys(sliders);
              const show = demo_pct
                ? sliderValues.reduce((show, key) => {
                    const currSlider = sliders[key];
                    const students = demo_pct[key]
                      ? key === "ALL"
                        ? demo_pct[key]["denominator"]
                        : demo_pct[key]["measure_rate"]
                      : 0;
                    return (
                      show &&
                      students >= currSlider[0] &&
                      students <= currSlider[1]
                    );
                  }, true)
                : true;
              const xData = x_axis ? x_axis[group] : false;
              const yData = y_axis ? y_axis[group] : false;
              const xLabel = measureList[xAxis]
                ? measureList[xAxis]["name"]
                : "";
              const yLabel = measureList[yAxis]
                ? measureList[yAxis]["name"]
                : "";
              if (schoolShow && show && xData && yData) {
                const size = !!schoolSelect ? 12 : 4;
                const borderColor = !!schoolSelect
                  ? currList[school]
                  : districtColor;
                const opacity = !!schoolSelect ? 0.5 : 1;
                object.x.push(xData.measure_rate);
                object.y.push(yData.measure_rate);
                object.marker.color.push(districtColor);
                object.marker.size.push(size);
                object.marker.symbol.push(selectedDistrictList[district] || selectedSchoolList[school] ? "triangle-up" : 'star');
                object.marker.opacity.push(opacity);
                object.marker.line.color.push(borderColor);
                const key = `${school} - ${measureLevel}`;
                customdata[key] = {
                  school,
                  district,
                  group,
                  xLabel,
                  yLabel,
                  xData,
                  yData,
                };
                object.text.push(key);
              }
            });
        });
      return object;
    },
    {
      x: [],
      y: [],
      text: [],
      marker: {
        symbol: [],
        color: [],
        size: [],
        opacity: [],
        line: {
          color: [],
          width: 2,
        },
      },
      hoverinfo: "none",
      mode: "markers",
      type: "scatter",
    }
  );
  return {
    data,
    customdata,
  };
}
