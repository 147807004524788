import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/es/Typography/Typography";
import { compose } from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { percentToPixel } from "./Helpers/helpers";
import UpArrowSVG from "./Helpers/SVG/upArrow";
import DownArrowSVG from "./Helpers/SVG/downArrowSVG";
import NeutralArrowSVG from "./Helpers/SVG/neutralArrow";

const styles = (theme) => ({
  containerSpacing: {
    flexGrow: 1,
    // paddingTop: "15px",
    paddingLeft: "15px",
    paddingRight: "10px",
  },
  subheader: {
    fontSize: "38px",
    color: "rgba(60,114,135,.9)",
    paddingBottom: "50px",
    paddingLeft: "3%",
  },
  cardHeader: {
    fontSize: "20px",
    color: "rgba(60,114,135,.9)",
    fontWeight: "bold",
    width: "350px",
    paddingBottom: "4%",
  },
  graph: {
    height: "300px",
    width: "350px",
    marginBottom: "50px",
    backgroundColor: "#e9eff3",
  },
  shading: {
    fill: "#d4e0e7",
  },
  dotText: {
    textAnchor: "middle",
    fontSize: "24px",
    fontWeight: "bold",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

class SchoolSummary extends Component {
  render() {
    const { classes, gradeSelect, yearSelect, schoolSummaryObj, handleTitle } =
      this.props;
    const gradeEdited = gradeSelect[0] === "0" ? gradeSelect[1] : gradeSelect;
    return (
      <Grid
        container
        className={classes.containerSpacing}
        alignItems={"center"}
      >
        <Grid container item xs={12} justifyContent={"center"}>
          <Typography className={classes.subheader}>
            {`${yearSelect} Schoolwide Summary for students in Grade ${gradeEdited}`}
          </Typography>
        </Grid>
        <Grid container alignItems={"flex-end"}>
          {Object.keys(schoolSummaryObj).map((success) => {
            const { measure_prior, measure_current, measure_delta } =
              schoolSummaryObj[success];
            const type =
              measure_prior === measure_current
                ? "neutral"
                : measure_prior < measure_current
                ? "positive"
                : "negative";
            const y1 = percentToPixel(200, measure_prior, 50);
            const y2 = percentToPixel(200, measure_current, 50);
            const y1Text = y1 - 15;
            const y2Text = y2 - 15;
            const path1 = `M50,${y1} L50,250 L300,250 L300,${y2} Z`;
            return (
              <Grid container item sm={4}>
                <Grid item xs={12}>
                  <Typography className={classes.cardHeader}>
                    {handleTitle(success)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <svg className={classes.graph}>
                    <g className={classes.shading}>
                      <path d={path1} stroke="none"></path>
                    </g>
                    <g>
                      <circle cx={"50"} cy={y1} r={"6"}></circle>
                      <circle cx={"300"} cy={y2} r={"6"}></circle>
                    </g>
                    <g className={classes.dotText}>
                      <text x="50" y={y1Text}>
                        {measure_prior}%
                      </text>
                      <text x="300" y={y2Text}>
                        {measure_current}%
                      </text>
                    </g>
                    {type === "positive" ? (
                      <UpArrowSVG
                        x={"110"}
                        y={"55"}
                        measure_delta={measure_delta}
                      />
                    ) : type === "negative" ? (
                      <DownArrowSVG
                        x={"110"}
                        y={"75"}
                        measure_delta={measure_delta}
                      />
                    ) : (
                      <NeutralArrowSVG
                        x={"110"}
                        y={"65"}
                        measure_delta={measure_delta}
                      />
                    )}
                  </svg>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    );
  }
}

export default compose(withStyles(styles, { withTheme: true }))(SchoolSummary);
