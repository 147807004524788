import React, { Component } from "react";
import { verify } from "./actions";
import connect from "react-redux/es/connect/connect";
import Grid from "@material-ui/core/Grid";
import logo from "../assets/images/coreLogo.png";
import name from "../assets/images/coreName.png";

class Token extends Component {
  constructor(props) {
    super(props);
    const { verifyToken, token, redirect } = this.props;
    verifyToken(token, redirect);
  }
  render() {
    return (
      <Grid
        justifyContent={"center"}
        alignItems={"center"}
        container
        direction={"column"}
        style={{ height: "100%", marginTop: "10%", width: "100%" }}
      >
        <Grid item>
          <img
            src={logo}
            alt={"spinning core logo"}
            style={{
              height: "40vh",
              width: "40vh",
            }}
            className={"rotatingImage"}
          />
        </Grid>
        <Grid item>
          <img
            src={name}
            alt={"core name"}
            style={{
              height: "auto",
              width: "50vh",
            }}
          />
        </Grid>
      </Grid>
    );
  }
}

const mapDispatch = (dispatch) => {
  return {
    verifyToken: (token, redirect) => {
      dispatch(verify(token, redirect));
    },
  };
};

export default connect(null, mapDispatch)(Token);
