export const Nav_ALL = [
  {
    subMenu: [
      {
        name: "LCAP Overview",
        id: "lcapOverview",
        value: "/dashboard/lcap",
        publicValue: "/public/lcap",
      },
      {
        name: "5x5 Schools",
        id: "5x5Schools",
        value: "/dashboard/lcap/lcap-5x5schools",
        publicValue: "/public/lcap/lcap-5x5schools",
      },
      {
        name: "5x5 Student Group",
        id: "5x5Students",
        value: "/dashboard/lcap/lcap-5x5studentgroup",
        publicValue: "/public/lcap/lcap-5x5studentgroup",
      },
    ],
  },
  {
    subMenu: [
      {
        name: "Growth - School Report",
        id: "acadGrowthReport",
        value: "/dashboard/academic/schoolreport",
      },
      {
        name: "Growth - Scatter Plot",
        id: "acadGrowthScatter",
        value: "/dashboard/academic/scatterplot",
      },
      {
        name: "Growth - Ordered Bar Charts",
        id: "acadBarCharts",
        value: "/dashboard/academic/barcharts",
      },
    ],
  },
  {
    subMenu: [
      {
        name: "Metric Trends",
        id: "onDemandMetricTrends",
        value: "/dashboard/ondemand/metrictrends",
      },
      {
        name: "Gap Trends",
        id: "onDemandGapTrends",
        value: "/dashboard/ondemand/gaptrends",
      },
      {
        name: "Scatter Plots",
        id: "onDemandScatterPlots",
        value: "/dashboard/ondemand/scatterplots",
      },
      {
        name: "Map View",
        id: "onDemandMapView",
        value: "/dashboard/ondemand/mapview",
      },
    ],
  },
  {
    subMenu: [
      {
        name: "College Distribution",
        id: "prelimCollegeDistribution",
        value: "/dashboard/shiny/prelim/collegedistribution",
      },
      {
        name: "Metric Trends",
        id: "prelimMetricTrends",
        value: "/dashboard/shiny/prelim/metrictrends",
      },
      {
        name: "Gap Trends",
        id: "prelimGapTrends",
        value: "/dashboard/shiny/prelim/gaptrends",
      },
      {
        name: "Scatter Plots",
        id: "prelimScatterPlots",
        value: "/dashboard/shiny/prelim/scatterplots",
      },
      {
        name: "Bar Plot Distribution",
        id: "prelimBarPlots",
        value: "/dashboard/shiny/prelim/barplotdistributions",
      },
    ],
  },
  {
    subMenu: [
      {
        name: "School Summary",
        id: "schoolSummary",
        value: "/dashboard/linkedlearning/schoolsummary",
      },
      {
        name: "Longitudinal View",
        id: "longView",
        value: "/dashboard/linkedlearning/pathwaylongitudinal",
      },
    ],
  },
  {
    subMenu: [
      {
        name: "CORE Resources",
        id: "extCoreResources",
        value:
          "https://coredistricts.org/our-data-research/improvement-measures/",
      },
      {
        name: "On-Track Methodology",
        id: "extCoreResourcesOn-TrackMethodology",
        value:
          "https://docs.google.com/document/d/1hZEr9hkooWWK3VSfa0GaWNddsVIN-Kno1Fz12I-YIJI/edit?usp=sharing",
      },
    ],
  },
  {
    subMenu: [
      {
        name: "School View",
        id: "paSchoolView",
        value: "/dashboard/predictiveanalytics/schoolview",
      },
      {
        name: "Network View",
        id: "paNetworkView",
        value: "/dashboard/predictiveanalytics/networkview",
      },
    ],
  },
  {
    subMenu: [
      {
        name: "On-Track Overview",
        id: "onTrackOverview",
        value: "/dashboard/ontrack",
      },
      {
        name: "On-Track Lanes",
        id: "onTrackLanes",
        value: "/dashboard/ontrack/ontracklanes",
      },
      {
        name: "A-G D/F Rates by Subject",
        id: "ratesBySubject",
        value: "/dashboard/ontrack/ratesbysubject",
      },

      {
        name: "On-Track - Ordered Bar Charts",
        id: "gradeDistribution",
        value: "/dashboard/ontrack/gradedistribution",
      },
    ],
  },
];
export const Nav_PUBLIC = [
  {
    subMenu: [
      {
        name: "LCAP Overview",
        id: "lcapOverview",
        value: "/public/lcap",
      },
      {
        name: "5x5 Schools",
        id: "5x5Schools",
        value: "/public/lcap/lcap-5x5schools",
      },
      {
        name: "5x5 Student Group",
        id: "5x5Students",
        value: "/public/lcap/lcap-5x5studentgroup",
      },
    ],
  },
];
