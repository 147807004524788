import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import tableStyle from "../../assets/jss/core-dashboard/components/tableStyle.jsx";
import RenderCell from "./TableCell";
import NoDataCell from "./NoDataCell";
import LoadingCell from "./LoadingCell";
import { footer } from "../../Helpers/GlobalHelpers";

// const footer = ({pageType, title,classes}) => {
// 	if (title === "Student Population (Based Upon Continuous Enrollment)") {
// 		return null
// 	}
//
// 	switch (pageType) {
// 		case 'LCAP':
// 			return (
// 				<Grid container justify={'flex-start'} alignItems="center" style={{paddingTop: "10px"}}>
// 					<Grid alignItems={'center'} container xs={12} md={6} lg={4} item>
// 						<Grid item><Avatar style={{ borderRadius: 0, marginRight:"1rem"}} className={classes.blueBackdrop}></Avatar> </Grid>
// 						<Grid item> <span className={classes.legendIndex}>Strongest Performance Or Change</span></Grid>
// 					</Grid>
// 					<Grid alignItems={'center'} container xs={12} md={6} lg={4} item>
// 						<Grid item><Avatar style={{ borderRadius: 0, marginRight:"1rem"}} className={classes.greenBackdrop}></Avatar></Grid>
// 						<Grid item><span className={classes.legendIndex}>Strong Performance or Change</span></Grid>
// 					</Grid>
// 					<Grid alignItems={'center'} container xs={12} md={6} lg={4} item>
// 						<Grid item><Avatar style={{ borderRadius: 0, marginRight:"1rem"}} className={classes.yellowBackdrop}></Avatar></Grid>
// 						<Grid item><span className={classes.legendIndex}>Medium Performance or Change</span></Grid>
// 					</Grid>
// 					<Grid alignItems={'center'} container xs={12} md={6} lg={4} item>
// 						<Grid item><Avatar style={{ borderRadius: 0, marginRight:"1rem"}} className={classes.orangeBackdrop}></Avatar></Grid>
// 						<Grid item><span className={classes.legendIndex}>Weak Performance or Change</span></Grid>
// 					</Grid>
// 					<Grid alignItems={'center'} container xs={12} md={6} lg={4} item>
// 						<Grid item>
// 							<Avatar style={{ borderRadius: 0, marginRight:"1rem"}} className={classes.redBackdrop}></Avatar>
// 						</Grid>
// 						<Grid item>
// 							<span className={classes.legendIndex}>Weakest Performance or Change</span>
// 						</Grid>
// 					</Grid>
// 				</Grid>
// 			)
// 		default:
// 			return (
// 				<Grid container justify={'flex-start'} alignItems="center" style={{paddingTop: "10px"}}>
// 					<Grid alignItems={'center'} container xs={12} md={6} lg={4} item>
// 						<Grid item><Avatar style={{ borderRadius: 0, marginRight:"1rem"}} className={classes.greenBackdrop}></Avatar></Grid>
// 						<Grid item><span className={classes.legendIndex}>Above Average (Index Levels 8, 9 and 10)</span></Grid>
// 					</Grid>
// 					<Grid alignItems={'center'} container xs={12} md={6} lg={4} item>
// 						<Grid item><Avatar style={{ borderRadius: 0, marginRight:"1rem"}} className={classes.orangeBackdrop}></Avatar></Grid>
// 						<Grid item>
// 							<span className={classes.legendIndex}>Average (Index Levels 4, 5, 6, 7)</span>
// 						</Grid>
// 					</Grid>
// 					<Grid alignItems={'center'} container xs={12} md={6} lg={4} item>
// 						<Grid item>
// 							<Avatar style={{ borderRadius: 0, marginRight:"1rem"}} className={classes.redBackdrop}></Avatar>
// 						</Grid>
// 						<Grid item>
// 							<span className={classes.legendIndex}>Below Average (Index Levels 1, 2, 3)</span>
// 						</Grid>
// 					</Grid>
// 				</Grid>
// 			)
// 	}
// }

const specialTable = ({
  loading,
  classes,
  tableHead,
  tableData,
  tableHeaderColor,
  index,
  title,
  pageType,
  type,
  typeOptions,
}) => {
  const demo =
    "Student Population (Based Upon Continuous Enrollment)" === title;
  switch (type) {
    case "5x5": {
      const { x, y } = typeOptions;
      return (
        <Table className={classes.table} style={{ minHeight: "50vh" }}>
          {tableHead !== undefined ? (
            <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
              <TableRow style={{ border: "0px solid white" }}>
                <TableCell
                  colSpan={2}
                  style={{
                    background: "rgba(1,61,84,.1)",
                    borderRight: "2px solid #BBCAD0",
                    borderBottom: "0px solid white",
                  }}
                />
                <TableCell
                  colSpan={5}
                  style={{
                    background: "rgba(1,61,84,.1)",
                    // borderLeft: '2px solid #BBCAD0',
                    fontSize: "14px",
                    fontWeight: 600,
                    color: "rgba(61,114,135,.8)",
                    textAlign: "center",
                    marginTop: "25px",
                    borderBottom: "2px solid #BBCAD0",
                  }}
                >
                  {x}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{
                    background: "rgba(1,61,84,.1)",
                    borderBottom: "2px solid #BBCAD0",
                  }}
                />
                {tableHead.map((prop, key) => {
                  return loading && key === 0 ? (
                    <LoadingCell
                      cell={"NO DATA"}
                      text={true}
                      key={"NO-DATA-TABLE-ROW-" + index}
                      cellStyle={{
                        paddingTop: "5px",
                        paddingBottom: "5px",
                        paddingLeft: 0,
                        paddingRight: 0,
                        background: "rgba(1,61,84,.1)",
                        // borderLeft: '2px solid #BBCAD0',
                        width: "250px",
                      }}
                    />
                  ) : (
                    <TableCell
                      className={classes.coreTableHead}
                      key={key}
                      style={{
                        paddingTop: "5px",
                        paddingBottom: "5px",
                        paddingLeft: 0,
                        paddingRight: 0,
                        background:
                          key === 0 && !demo ? "rgba(1,61,84,.1)" : null,
                        borderLeft:
                          key === 0 || demo
                            ? "0px solid red"
                            : "2px solid #BBCAD0",
                      }}
                    >
                      {prop}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
          ) : null}
          <TableBody>
            {tableData.length > 0 ? (
              tableData.map((row, rIndex) => (
                <TableRow
                  key={`${title}:row-${rIndex}`}
                  id={`${title}:row-${rIndex}`}
                  className={classes.tableRow}
                >
                  {rIndex === 0 ? (
                    <TableCell
                      rowSpan={5}
                      style={{
                        position: "relative",
                        padding: "0px",
                        borderRight: "2px solid #BBCAD0",
                        textAlign: "center",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: 600,
                          color: "rgba(61,114,135,.8)",
                          writingMode: "vertical-rl",
                          transform: "rotate(180deg)",
                          whiteSpace: "nowrap",
                          padding: "5px",
                        }}
                      >
                        {y}
                      </span>
                    </TableCell>
                  ) : null}
                  {row.map((cell, cIndex) => {
                    return (
                      <RenderCell
                        cell={cell}
                        pageType={pageType}
                        cellId={`table-cell:${index}/${rIndex}/${cIndex}`}
                        key={`table-cell:${index}/${rIndex}/${cIndex}`}
                        title={title}
                      />
                    );
                  })}
                </TableRow>
              ))
            ) : (
              <TableRow>
                {tableHead.map((h, index) => (
                  <NoDataCell
                    cell={"NO DATA"}
                    text={!index}
                    key={"NO-DATA-TABLE-ROW-" + index}
                  />
                ))}
              </TableRow>
            )}
          </TableBody>
        </Table>
      );
    }
    default:
      return (
        <Table className={classes.table}>
          {tableHead !== undefined ? (
            <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
              <TableRow>
                {tableHead.map((prop, key) => {
                  return (
                    <TableCell
                      className={classes.coreTableHead}
                      key={key}
                      style={{
                        padding: "5px",
                        background:
                          key === 0 && !demo ? "rgba(1,61,84,.1)" : null,
                        borderLeft:
                          key === 0 || demo
                            ? "0px solid red"
                            : "2px solid #BBCAD0",
                        // width: key === 0 ? '200px' : '140px'
                        width: prop.maxWidth,
                        maxWidth: prop.maxWidth,
                      }}
                    >
                      {prop.text !== undefined ? prop.text : prop}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
          ) : null}
          <TableBody>
            {!loading && tableData.length > 0 ? (
              tableData.map((row, rIndex) => (
                <TableRow
                  key={`${title}:row-${rIndex}`}
                  id={`${title}:row-${rIndex}`}
                  className={classes.tableRow}
                  style={{height:"48px"}}
                >
                  {row.map((cell, cIndex) => {
                    return (
                      <RenderCell
                        cell={cell}
                        pageType={pageType}
                        cellId={`table-cell:${index}/${rIndex}/${cIndex}`}
                        key={`table-cell:${index}/${rIndex}/${cIndex}`}
                        title={title}
                      />
                    );
                  })}
                </TableRow>
              ))
            ) : !loading ? (
              <TableRow>
                {tableHead.map((h, index) => (
                  <NoDataCell
                    cell={"NO DATA"}
                    text={!index}
                    key={"NO-DATA-TABLE-ROW-" + index}
                  />
                ))}
              </TableRow>
            ) : (
              <TableRow>
                {tableHead.map((h, index) => (
                  <LoadingCell
                    cell={"LOADING DATA"}
                    text={!index}
                    key={"LOAD-DATA-TABLE-ROW-" + index}
                  />
                ))}
              </TableRow>
            )}
          </TableBody>
        </Table>
      );
  }
};

function CustomTable({ ...props }) {
  const {
    loading,
    classes,
    tableHead,
    tableData,
    tableHeaderColor,
    index,
    title,
    pageType,
    type,
    typeOptions,
    header,
    data,
    hideFooter,
  } = props;
  return (
    <div className={classes.tableResponsive}>
      {specialTable({
        loading,
        classes,
        tableHead,
        tableData,
        tableHeaderColor,
        index,
        title,
        pageType,
        type,
        typeOptions,
        header,
        data,
      })}
      {!hideFooter && footer({ pageType, title, classes })}
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
};

CustomTable.propTypes = {
  classes: PropTypes.object.isRequired,
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string]))
  ),
};

export default withStyles(tableStyle)(CustomTable);
