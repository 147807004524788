import React from "react";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/es/Typography";
import {Box} from "@material-ui/core";

export const percentToPixel = (scale, percent, margin) => {
  return Math.abs(scale - (percent * scale) / 100 + margin);
};

const percentToScale = (percent, scale) => {
  return Math.abs(((percent * 100) / scale) * 0.5);
};

export const NetworkHeader = (props) => {
  const { title, sort, setSort, classes, order } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.tableHead} style={{ minWidth: "125px" }}>
          <Grid justifyContent={"space-around"} alignItems={"center"} container item>
            <Grid item>District</Grid>
            <Grid item>
              <SortIcon
                classes={classes}
                setSort={setSort}
                type={"district_name"}
                sort={sort}
                order={order}
              />
            </Grid>
          </Grid>
        </TableCell>
        <TableCell className={classes.tableHead} style={{ minWidth: "125px" }}>
          <Grid justifyContent={"space-around"} alignItems={"center"} container item>
            <Grid item>School</Grid>
            <Grid item>
              <SortIcon
                classes={classes}
                setSort={setSort}
                type={"school_name"}
                sort={sort}
                order={order}
              />
            </Grid>
          </Grid>
        </TableCell>
        <TableCell className={classes.tableHead} style={{ minWidth: "250px" }}>
          <Grid justifyContent={"space-around"} alignItems={"center"} container item>
            <Grid item>{title}</Grid>
            <Grid item>
              <SortIcon
                classes={classes}
                setSort={setSort}
                type={"success_name"}
                sort={sort}
                order={order}
              />
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

const colorObj = {};

export const NetworkBody = (props) => {
  const { classes, data, grade, type, success, order, sort, year } = props;
  const dataRow = Object.keys(data || {})
    .reduce((rows, district_name) => {
      colorObj[district_name] = generateRandomColor();
      const districtData = data[district_name] || { name: "", data: {} };
      const school_list = Object.keys(districtData || {}).map((school_name) => {
        return {
          school_name,
          district_name,
          percent: ((((districtData[school_name] || {})[year] || {})[grade] ||
            {})[success] || {})[type],
          type: type,
        };
      });
      return rows.concat(school_list);
    }, [])
    .sort((a, b) => {
      switch (sort) {
        case "school_name":
        case "district_name":
          return !order
            ? b[sort].localeCompare(a[sort])
            : a[sort].localeCompare(b[sort]);
        default: {
          const aValue = a.percent;
          const bValue = b.percent;
          const aNull = aValue === undefined;
          const bNull = bValue === undefined;
          if (aNull && bNull) {
            return 1;
          } else if (aNull) {
            return 1;
          } else if (bNull) {
            return -1;
          } else {
            return order ? bValue - aValue : aValue - bValue;
          }
        }
      }
    });
  return (
    <TableBody>
      {!data && (
        <TableRow
          style={{ border: "2px solid #BBCAD0", borderTop: "0px solid white" }}
        >
          <TableCell colSpan={5} className={classes.tableCell}>
            <Typography
              style={{
                paddingLeft: 20,
                color: "rgba(0,0,0,.1)",
                fontWeight: "900",
                fontSize: "16px",
              }}
            >
              No Data
            </Typography>
          </TableCell>
        </TableRow>
      )}
      {Object.keys(dataRow).map((row) => (
        <NetworkRow classes={classes} row={dataRow[row]} />
      ))}
    </TableBody>
  );
};

const NetworkRow = ({ row, classes }) => {
  const { district_name, school_name, percent, type } = row;
  const districtColor = colorObj[district_name];
  return (
    <TableRow className={classes.tableRow}>
      <Grid container>
        <div
          style={{
            backgroundColor: districtColor,
            width: "10px",
            height: "42px",
          }}
        />
        <TableCell
          title={district_name}
          className={classes.tableCellHead}
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textAlign: "left",
            maxWidth: "200px",
            maxHeight: 45,
            textOverflow: "ellipsis",
          }}
        >
          {district_name}
        </TableCell>
      </Grid>
      <TableCell
        title={school_name}
        className={classes.tableCellHead}
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textAlign: "left",
          maxWidth: "200px",
          maxHeight: 45,
          textOverflow: "ellipsis",
        }}
      >
        {school_name}
      </TableCell>
      <DataCell percent={percent} type={type} classes={classes} />
    </TableRow>
  );
};

const DataCell = ({ classes, percent, type }) => {
  const change = percent > 0 ? "positive" : percent < 0 ? "negative" : null;
  if (percent === undefined) {
    return (
      <TableCell
        className={classes.tableCell}
        style={{
          height: "100%",
          padding: "0",
          paddingLeft: 20,
          color: "rgba(0,0,0,.1)",
          fontWeight: "900",
          fontSize: "16px",
        }}
      >
        No Data
      </TableCell>
    );
  }
  switch (type) {
    case "measure_delta": {
      const color =
        change === "positive"
          ? "blue"
          : change === "negative"
          ? "orange"
          : "green";
      const toScale = percentToScale(percent, 55);
      const left = change === "positive" ? "50%" : `${50 - toScale}%`;
      return (
        <TableCell
          className={classes.tableCell}
          style={{ position: "relative", height: "100%", padding: 0 }}
        >
          <div
            className={classes[`${color}Bar`]}
            style={{
              position: "absolute",
              left: left,
              top: 0,
              zIndex: 4,
              width: `${toScale}%`,
              height: "100%",
            }}
          ></div>
          {change === "positive" ? (
            <Typography
              style={{
                position: "absolute",
                fontSize: 14,
                fontWeight: 800,
                zIndex: 5,
                top: "20%",
                right: "5px",
              }}
            >
              +{percent}%
            </Typography>
          ) : (
            <Typography
              style={{
                position: "absolute",
                fontSize: 14,
                fontWeight: 800,
                zIndex: 5,
                top: "20%",
                right: "5px",
              }}
            >
              {percent}%
            </Typography>
          )}
        </TableCell>
      );
    }
    default: {
      const color = type === "measure_current" ? "grey" : "blue";
      return (
        <TableCell
          className={classes.tableCell}
          style={{ position: "relative", height: "100%", padding: 0 }}
        >
          <div
            className={classes[`${color}Bar`]}
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              zIndex: 4,
              width: `${percent}%`,
              height: "100%",
            }}
          ></div>
          <Typography
            style={{
              position: "absolute",
              fontSize: 14,
              fontWeight: 800,
              zIndex: 5,
              top: "20%",
              right: "5px",
            }}
          >
            {percent}%
          </Typography>
        </TableCell>
      );
    }
  }
};

const SortIcon = ({ sort, type, setSort, classes, order }) => {
  const topColor =
    type === sort && order ? "rgba(29,94,119,.75)" : "rgba(0,0,0,.25)";
  const bottomColor =
    type === sort && !order ? "rgba(29,94,119,.75)" : "rgba(0,0,0,.25)";
  return (
    <svg
      onClick={() => {
        setSort(type);
      }}
      viewBox={"0 0 24 24"}
      className={classes.sortIcon}
      style={{}}
    >
      <path
        fill={topColor}
        strokeLinejoin={"miter"}
        shapeRendering={"geometricPrecision"}
        strokeWidth={0}
        d={"M12 2 L 18 10 L 6 10 L 12 2z"}
      />
      <path
        fill={bottomColor}
        strokeLinejoin={"miter"}
        shapeRendering={"geometricPrecision"}
        strokeWidth={0}
        d={"M12 22 L 18 14 L 6 14 L 12 22z"}
      />
    </svg>
  );
};

export const TableHeader = ({ classes }) => {
  return (
    <Grid
      container
      justifyContent={"flex-end"}
      alignItems="center"
      style={{ paddingRight: "20px" }}
    >
      <Grid
        container
        item
        alignItems={"center"}
        justifyContent={"flex-end"}
        xs={6}
        sm={3}
        md={2}
      >
        <Grid item xs={2}>
          <Box className={classes.blueBackdrop}></Box>
        </Grid>
        <Grid item xs={10}>
          <span className={classes.legendIndex}>Positive</span>
        </Grid>
      </Grid>
      <Grid
        container
        item
        alignItems={"center"}
        justifyContent={"flex-end"}
        xs={6}
        sm={3}
        md={2}
      >
        <Grid item xs={2}>
          <Box className={classes.orangeBackdrop}></Box>
        </Grid>
        <Grid item>
          <span className={classes.legendIndex}>Negative</span>
        </Grid>
      </Grid>
    </Grid>
  );
};

const generateRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};
