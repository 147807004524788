import { IS_LOADING } from "../../actions/Loading/Loading";

const initialState = {
  loading1: false,
  loading2: false,
};

export default function loading(state = initialState, action) {
  switch (action.type) {
    case IS_LOADING:
      return {
        ...state,
        [action.payload.id]: action.payload.value,
      };
    default:
      return state;
  }
}
