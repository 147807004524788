import React from "react";

import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";

import Grid from "@material-ui/core/Grid";

import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";

import Input from "@material-ui/core/Input";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import ArrowForward from "@material-ui/icons/ArrowForward";
import ArrowBackward from "@material-ui/icons/ArrowBack";

import Edit from "@material-ui/icons/Edit";
import Cancel from "@material-ui/icons/Cancel";

import Slider from "rc-slider";
import "../../node_modules/rc-slider/assets/index.css";
const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

const changeText = (key, change) => {
  const { old, curr } = change;
  switch (key) {
    case "alias": {
      if (!old) {
        return `Set Alias of ${curr}`;
      } else if (!curr) {
        return "Removed Alias";
      } else {
        return `Alias Changed from ${old} to ${curr}`;
      }
    }
    case "name": {
      return `Changed Display Name from ${old} to ${curr}`;
    }
    case "racial_or_ethnic": {
      if (curr) {
        return "Set as a Race or Ethnicity";
      } else {
        return "Removed as a Race or Ethnicity";
      }
    }
    case "percent": {
      if (curr) {
        return "Measure set as a percentage";
      } else {
        return "Measure set as not a percentage";
      }
    }
    case "college": {
      if (curr) {
        return "Measure set as a college measure";
      } else {
        return "Measure set as not a college measure";
      }
    }
    case "text": {
      if (!old) {
        return `Set Label of ${curr}`;
      } else if (!curr) {
        return "Removed Label";
      } else {
        return `Label changed from ${old} to ${curr}`;
      }
    }
    case "subtext": {
      if (!old) {
        return `Set Subtext of ${curr}`;
      } else if (!curr) {
        return "Removed Subtext";
      } else {
        return `Subtext changed from ${old} to ${curr}`;
      }
    }
    default:
      return `${key} - ${old} - ${curr}`;
  }
};

export const ChangeList = ({ submit, updates, open, close }) => {
  return (
    <Dialog
      onBackdropClick={() => {
        close();
      }}
      open={open}
    >
      <DialogContent>
        <Grid container direction={"column"}>
          {Object.keys(updates).map((key) => {
            const { title, changes } = updates[key];
            return (
              <Grid container direction={"column"}>
                <Grid item>
                  <Typography variant={"h6"}>{title}</Typography>
                </Grid>
                {Object.keys(changes).map((key) => {
                  return (
                    <Grid item>
                      <Typography>{changeText(key, changes[key])}</Typography>
                    </Grid>
                  );
                })}
              </Grid>
            );
          })}
          <Grid item>
            <Button
              onClick={() => {
                submit();
              }}
            >
              Submit Changes
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export const UpdateTable = ({
  infoTable,
  columns,
  table,
  updateInfo,
  onChange,
  toggleEdit,
}) => {
  const { numResults, page } = table;
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Actions</TableCell>
          {columns.map((head) => (
            <TableCell>{head}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {infoTable.map((row, index) =>
          index >= numResults * page && index < numResults * (page + 1) ? (
            <TableRow>
              {row.map((cell) => (
                <EditableCell
                  cellState={updateInfo[cell.code]}
                  toggleEdit={toggleEdit}
                  onChange={onChange}
                  cell={cell}
                />
              ))}
            </TableRow>
          ) : null
        )}
      </TableBody>
    </Table>
  );
};

const EditableCell = ({ cellState, cell, onChange, toggleEdit }) => {
  const edit = cellState ? cellState.edit : false;
  const { value, type, editable } = cell;
  const display = cellState && edit && editable ? cellState[type] : value;
  switch (type) {
    case "EDIT":
      return (
        <TableCell>
          <IconButton
            onClick={() => {
              toggleEdit(cell);
            }}
          >
            {edit ? <Cancel /> : <Edit />}
          </IconButton>
        </TableCell>
      );
    case "percent":
    case "college":
    case "racial_or_ethnic":
      return (
        <TableCell>
          <Checkbox
            disabled={!edit}
            onChange={onChange(cell, true)}
            checked={display}
          />
        </TableCell>
      );
    default:
      if (edit && editable) {
        return (
          <TableCell>
            <Input onChange={onChange(cell, false)} value={display} />
          </TableCell>
        );
      } else {
        return (
          <TableCell>
            <Typography>{display}</Typography>
          </TableCell>
        );
      }
  }
};

export const Footer = ({ handleNav, table, submit }) => {
  const { nPages, page } = table;
  return (
    <TableFooter>
      <Grid alignItems={"center"} container>
        <Grid item>
          <IconButton
            onClick={() => {
              handleNav("PREV");
            }}
          >
            <ArrowBackward />
          </IconButton>
        </Grid>
        <Grid item>
          {page + 1}/{nPages}
        </Grid>
        <Grid item>
          <IconButton
            onClick={() => {
              handleNav("NEXT");
            }}
          >
            <ArrowForward />
          </IconButton>
        </Grid>
        <Grid item>
          <Button
            onClick={() => {
              submit();
            }}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </TableFooter>
  );
};

export const ColorCell = ({ onGridSelect, row, column, color }) => {
  return (
    <TableCell
      onClick={() => {
        onGridSelect(row, column);
      }}
      style={{ border: "1px solid grey", width: "20%", backgroundColor: color }}
    ></TableCell>
  );
};

export const SelectedColorCell = ({ row, column, color, selectColor }) => {
  const colors = ["red", "orange", "yellow", "green", "blue", "gray"];
  return (
    <TableCell
      style={{
        padding: "0px",
        border: "1px solid grey",
        width: "20%",
        backgroundColor: "black",
      }}
    >
      <Grid style={{ width: "100%", height: "100%" }} container>
        {colors.map((cColor) => (
          <Grid
            onClick={() => {
              selectColor(row, column, cColor);
            }}
            xs={2}
            item
            style={{ height: "100%", backgroundColor: cColor }}
          />
        ))}
      </Grid>
    </TableCell>
  );
};

export const LcapGridTable = ({
  submit,
  changes,
  selectColor,
  onGridSelect,
  grids,
  selectedGrid,
  gridList,
  onSelect,
  selectedCell,
}) => {
  const currentGrid = grids ? grids[selectedGrid] : null;
  return (
    <Grid container direction={"column"}>
      <Grid container item>
        <Grid direction={"column"} container item>
          <Grid item>
            <Typography>Selected Grid</Typography>
          </Grid>
          <Grid item>
            <Select onChange={onSelect} value={selectedGrid}>
              {gridList.map((key) => (
                <option value={key}>Grid {key}</option>
              ))}
            </Select>
          </Grid>
        </Grid>
        <Grid item>
          <Button
            onClick={() => {
              submit();
            }}
          >
            Submit Changes
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        <Table style={{ width: "60vw", height: "60vh" }}>
          <TableBody>
            {["blue", "green", "yellow", "orange", "red"].map((row) => (
              <TableRow>
                {["red", "orange", "yellow", "green", "blue"].map((col) => {
                  const change = changes
                    ? changes[row]
                      ? changes[row][col]
                      : null
                    : null;
                  const color = currentGrid
                    ? change
                      ? change
                      : currentGrid[row][col]
                    : "black";
                  if (row === selectedCell[0] && col === selectedCell[1]) {
                    return (
                      <SelectedColorCell
                        selectColor={selectColor}
                        row={row}
                        column={col}
                        color={"black"}
                      />
                    );
                  }
                  return (
                    <ColorCell
                      onGridSelect={onGridSelect}
                      row={row}
                      column={col}
                      color={color}
                    />
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

export const LcapValues = ({
  onSelect,
  onChange,
  submit,
  onCheck,
  onSlide,
  info,
  gridList = [],
  measureList,
  selectedMeasure,
}) => {
  if (!Object.keys(info).length) {
    return <Typography>Loading</Typography>;
  }
  return (
    <Table style={{ width: "80vw" }}>
      <TableHead>
        <TableRow>
          <TableCell>
            <Select onChange={onSelect} value={selectedMeasure}>
              {measureList.map((key) => (
                <option value={key}>{key}</option>
              ))}
            </Select>
          </TableCell>
          <TableCell>
            <Button
              onClick={() => {
                submit();
              }}
            >
              Submit Changes
            </Button>
          </TableCell>
        </TableRow>
      </TableHead>
      {["ES", "K8", "MS", "HS"].map((key) => {
        const currentLevel = info[key];
        const { topBar, sideBar } = currentLevel["sliders"];
        return (
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography variant={"h5"}>{key}</Typography>
              </TableCell>
              <TableCell>
                <Select
                  onChange={(e) => {
                    onChange(key, e);
                  }}
                  value={info[key]["colorGrid"]}
                >
                  {gridList.map((key) => (
                    <option value={key}>Grid {key}</option>
                  ))}
                </Select>
              </TableCell>
              <TableCell />
            </TableRow>
            <TableRow>
              <TableCell>TopBar</TableCell>
              <TableCell>
                <Grid container direction={"column"}>
                  <Grid item>
                    <Typography>Yellow Range</Typography>
                  </Grid>
                  <Grid item>
                    <Checkbox
                      onChange={() => {
                        onCheck(key, "topBar");
                      }}
                      checked={topBar["yellowRange"]}
                    />
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell style={{ width: "100%" }}>
                <Range
                  onChange={(e) => {
                    onSlide("topBar", key, e);
                  }}
                  min={topBar["values"][0] - 1}
                  max={topBar["values"][topBar["values"].length - 1] + 1}
                  count={topBar["styles"].length}
                  handleStyle={topBar["styles"]}
                  value={topBar["values"]}
                  trackStyle={topBar["trackStyle"]}
                  pushable={0.1}
                  step={0.1}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>SideBar</TableCell>
              <TableCell>
                <Grid container direction={"column"}>
                  <Grid item>
                    <Typography>Yellow Range</Typography>
                  </Grid>
                  <Grid item>
                    <Checkbox
                      onChange={() => {
                        onCheck(key, "sideBar");
                      }}
                      checked={sideBar["yellowRange"]}
                    />
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell>
                <Range
                  onChange={(e) => {
                    onSlide("sideBar", key, e);
                  }}
                  min={sideBar["values"][0] - 1}
                  max={sideBar["values"][sideBar["values"].length - 1] + 1}
                  count={sideBar["styles"].length}
                  handleStyle={sideBar["styles"]}
                  value={sideBar["values"]}
                  trackStyle={sideBar["trackStyle"]}
                  pushable={0.1}
                  step={0.1}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        );
      })}
    </Table>
  );
};

export const OverviewTables = ({ overview }) => {
  return <Table></Table>;
};
