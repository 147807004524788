import React, { Component } from "react";
import { compose } from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import connect from "react-redux/es/connect/connect";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Plot from "react-plotly.js";
import TableTitle from "../StyledComponents/Table/TitleTable";
import Card from "../StyledComponents/Card/Card";
import CardHeader from "../StyledComponents/Card/CardHeader";
import CardBody from "../StyledComponents/Card/CardBody";
import tableStyle from "../assets/jss/core-dashboard/components/tableStyle";
import LoadingGrid from "../StyledComponents/Grid/LoadingGrid";
import { PlotData } from "../OnDemandAnalytics/PlotData";
import { tableStyling } from "../OnDemandAnalytics/plotDataStyles";
import { addCommas } from "../utils";

const styles = (theme) => ({
  tableMargin: {
    marginTop: "0px",
    marginBottom: "0px",
    marginLeft: "2rem",
    marginRight: "2rem",
  },
});

class CollegeBarPlots extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      college_levels,
      network,
      district,
      collegeBarPlot,
      classes,
      loading,
      year,
      measure,
      subgroup,
      school_types,
    } = this.props;
    const all = school_types.includes("All");
    const showTwo = college_levels.includes("Two Year");
    const showFour = college_levels.includes("Four Year");
    const data = Object.keys(collegeBarPlot).reduce((object, key) => {
      const currDistrict = collegeBarPlot[key];
      Object.keys(currDistrict).forEach((school) => {
        const currSchool = currDistrict[school];
        const { type } = currSchool;
        if (all || school_types.includes(type)) {
          const measureKeys = Object.keys(currSchool);
          object[school] = {
            max: 0,
            twoYear: 0,
            twoYearStudents: 0,
            fourYearStudents: 0,
            nStudents: 0,
            fourYear: 0,
            district,
            selected: district === key,
          };
          measureKeys.forEach((measure) => {
            if (
              (measure === "twoYear" && showTwo) ||
              (measure === "fourYear" && showFour)
            ) {
              object[school][`${measure}Students`] =
                currSchool[measure]["numerator"];
              object[school][`nStudents`] = currSchool[measure]["denominator"];
              object[school][measure] = currSchool[measure]["measure_rate"];
              object[school].max += currSchool[measure]["measure_rate"] || 0;
            }
          });
        }
      });
      return object;
    }, {});
    const keys = Object.keys(data);
    const sorted = keys.sort((a, b) => {
      const aMax = data[a].max;
      const bMax = data[b].max;
      return aMax - bMax;
    });
    const district_keys = sorted.reduce((arr, key) => {
      const { selected } = data[key];
      if (selected) {
        return arr.concat(key);
      }
      return arr;
    }, []);
    let localMax = 0;
    const district_data = district_keys.reduce(
      (obj, key) => {
        const { twoYear, fourYear, selected, district } = data[key];
        obj.twoYear.x.push(key);
        obj.twoYear.y.push(twoYear || 0);
        obj.twoYear.text.push(twoYear || 0);
        obj.fourYear.x.push(key);
        obj.fourYear.y.push(fourYear || 0);
        obj.fourYear.text.push(fourYear || "");
        localMax = Math.max((twoYear || 0) + (fourYear || 0), localMax);
        return obj;
      },
      {
        twoYear: {
          x: [],
          y: [],
          text: [],
          hoverinfo: "x+y",
          textposition: "auto",
          name: "Two Year Colleges",
          type: "bar",
          marker: {
            color: "#66A5AD",
          },
        },
        fourYear: {
          x: [],
          y: [],
          text: [],
          hoverinfo: "x+y",
          textposition: "auto",
          name: "Four Year Colleges",
          type: "bar",
          marker: {
            color: "#07575B",
          },
        },
      }
    );
    let overallMax = 0;
    const overall_data = sorted.reduce(
      (obj, key) => {
        const { twoYear, fourYear, selected, district } = data[key];
        obj.twoYear.x.push(key);
        overallMax = Math.max((twoYear || 0) + (fourYear || 0), overallMax);
        obj.twoYear.y.push(twoYear || 0);
        obj.twoYear.marker.opacity.push(selected ? 1 : 0.5);
        obj.fourYear.x.push(key);
        obj.fourYear.y.push(fourYear || 0);
        obj.fourYear.marker.opacity.push(selected ? 1 : 0.5);
        return obj;
      },
      {
        twoYear: {
          x: [],
          y: [],
          hoverinfo: "x+y",
          textposition: "auto",
          name: "Two Year Colleges",
          type: "bar",
          marker: {
            color: "#66A5AD",
            opacity: [],
          },
        },
        fourYear: {
          x: [],
          y: [],
          hoverinfo: "x+y",
          textposition: "auto",
          name: "Four Year Colleges",
          type: "bar",
          marker: {
            color: "#07575B",
            opacity: [],
          },
        },
      }
    );
    const noDataDistrict = !district_keys.length;
    const noDataNetwork = !sorted.length;
    const tickValues = district_keys;
    const columns = [
      {
        title: "School Name",
        field: "key",
        cellStyle: tableStyling.firstData,
        headerStyle: tableStyling.darkHeaderFirst,
      },
      {
        title: "Two Year College",
        field: "twoYear",
        grouping: false,
        cellStyle: tableStyling.dataRow,
        headerStyle: tableStyling.title,
        render: (rowData) =>
          !rowData.twoYear ? (
            <p style={{ margin: 5, color: "#d1d1d1" }}>No Data</p>
          ) : (
            <p>
              {rowData.twoYear}%{" "}
              <small>
                ({addCommas(`${rowData.twoYearStudents}`)} Students)
              </small>
            </p>
          ),
      },
      {
        title: "Four Year College",
        field: "fourYear",
        grouping: false,
        cellStyle: tableStyling.dataRow,
        headerStyle: tableStyling.title,
        render: (rowData) =>
          !rowData.fourYear ? (
            <p style={{ margin: 5, color: "#d1d1d1" }}>No Data</p>
          ) : (
            <p>
              {rowData.fourYear}%{" "}
              <small>
                ({addCommas(`${rowData.fourYearStudents}`)} Students)
              </small>
            </p>
          ),
      },
      {
        title: "Overall (Two and Four Year)",
        field: "max",
        grouping: false,
        cellStyle: tableStyling.dataRow,
        headerStyle: tableStyling.title,
        render: (rowData) =>
          !rowData.max ? (
            <p style={{ margin: 5, color: "#d1d1d1" }}>No Data</p>
          ) : (
            <p>
              {rowData.max}%{" "}
              <small>
                (
                {addCommas(
                  `${rowData.twoYearStudents + rowData.fourYearStudents}`
                )}{" "}
                Students)
              </small>
            </p>
          ),
      },
      {
        title: "Total Students",
        field: "nStudents",
        grouping: false,
        cellStyle: tableStyling.dataRow,
        headerStyle: tableStyling.title,
        render: (rowData) =>
          !rowData.max ? (
            <p style={{ margin: 5, color: "#d1d1d1" }}>No Data</p>
          ) : (
            `${addCommas(`${rowData.nStudents}`)} Students`
          ),
      },
    ];
    const tickText = district_keys.map((key) => {
      const split = key.split(" ");
      const parsed = split.reduce(
        (s, c) => {
          s.count += c.length;
          if (s.count > 15) {
            s.count = 0;
            s.parsed = `${s.parsed}<br>${c}`;
          } else {
            s.parsed = `${s.parsed} ${c}`;
          }
          return s;
        },
        {
          parsed: "",
          count: 0,
        }
      ).parsed;
      return parsed;
    });
    const tableData = sorted.map((key) => ({ ...data[key], key }));
    return (
      <Grid
        style={{ margin: "0 2.5% 0 2.5%", width: "95%" }}
        justifyContent={"center"}
        container
        direction={"row"}
      >
        <TableTitle
          school={{}}
          district={{}}
          hideInfo
          pageTitle={"College Bar Plots"}
        />
        <Grid xs={12} item>
          <Card>
            <CardHeader className={classes.gradientBackground} color="primary">
              {/* Left the Next Card Header in Place to bring back Primary Color at a later time*/}
              {/*<CardHeader color="primary" >*/}
              <h4 className={classes.cardTitleBlue}>{district}</h4>
              {/*<p className={classes.cardCategoryWhite}>*/}
              {/*	Here is a subtitle for this table*/}
              {/*</p>*/}
            </CardHeader>
            <CardBody
              style={{
                paddingTop: "0px",
                width: "100%",
                marginTop: "10px",
                paddingBottom: "10px",
                textAlign: "center",
              }}
            >
              {loading ? (
                <LoadingGrid />
              ) : noDataDistrict ? (
                <Typography
                  style={{
                    float: "left",
                    marginLeft: "5%",
                    color: "rgba(0,0,0,.1)",
                    fontWeight: "900",
                    fontSize: "36px",
                  }}
                >
                  No Data
                </Typography>
              ) : (
                <div className={classes.plot} style={{ width: "100%" }}>
                  <Plot
                    style={{ width: "100%" }}
                    data={[district_data.twoYear, district_data.fourYear]}
                    layout={{
                      margin: {
                        l: "auto",
                        r: "auto",
                        b: "auto",
                        t: 0,
                      },
                      legend: {
                        orientation: "h",
                        x: 0,
                        y: 1,
                      },
                      barmode: "stack",
                      xaxis: {
                        tickvals: district_keys,
                        linewidth: 3,
                        linecolor: "rgb(9, 75, 101)",
                        ticktext: tickText,
                        tickvalues: tickValues,
                      },
                      yaxis: {
                        range: [0, Math.min(100, localMax + 10)],
                        linewidth: 3,
                        linecolor: "rgb(9, 75, 101)",
                        title: `Measure Rate among ${subgroup} in<br>${measure}, ${year}<br>(%)`,
                      },
                    }}
                  />
                </div>
              )}
            </CardBody>
          </Card>
        </Grid>
        <Grid xs={12} item>
          <Card>
            <CardHeader className={classes.gradientBackground} color="primary">
              {/* Left the Next Card Header in Place to bring back Primary Color at a later time*/}
              {/*<CardHeader color="primary" >*/}
              <h4 className={classes.cardTitleBlue}>{network}</h4>
              {/*<p className={classes.cardCategoryWhite}>*/}
              {/*	Here is a subtitle for this table*/}
              {/*</p>*/}
            </CardHeader>
            <CardBody
              style={{
                paddingTop: "0px",
                width: "100%",
                marginTop: "10px",
                paddingBottom: "10px",
                textAlign: "center",
              }}
            >
              {loading ? (
                <LoadingGrid />
              ) : noDataNetwork ? (
                <Typography
                  style={{
                    float: "left",
                    marginLeft: "5%",
                    color: "rgba(0,0,0,.1)",
                    fontWeight: "900",
                    fontSize: "36px",
                  }}
                >
                  No Data
                </Typography>
              ) : (
                <div className={classes.plot} style={{ width: "100%" }}>
                  <Plot
                    style={{ width: "100%" }}
                    data={[overall_data.twoYear, overall_data.fourYear]}
                    layout={{
                      margin: {
                        l: "auto",
                        r: "auto",
                        b: "auto",
                        t: 0,
                      },
                      barmode: "stack",
                      legend: {
                        orientation: "h",
                        x: 0,
                        y: 1,
                      },
                      xaxis: {
                        showticklabels: false,
                        linewidth: 3,
                        linecolor: "rgb(9, 75, 101)",
                      },
                      yaxis: {
                        range: [0, Math.min(100, overallMax + 10)],
                        linewidth: 3,
                        linecolor: "rgb(9, 75, 101)",
                        title: `Measure Rate among ${subgroup} in<br>${measure}, ${year}<br>(%)`,
                      },
                    }}
                  />
                </div>
              )}
            </CardBody>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <PlotData
            loading={loading}
            noData={!sorted.length}
            fileName={`Bar Plot Distribution-${network}-${district}-${measure}-${year}-${subgroup}`}
            classes={classes}
            data={tableData}
            columns={columns}
            noGroups={true}
          />
        </Grid>
      </Grid>
    );
  }
}

const findInMenu = (array, key) => {
  const res = {
    code: "",
    text: "",
  };
  if (!array) {
    return res;
  }
  return array.reduce((found, curr) => {
    if (curr.code === key) {
      found = curr;
    }
    return found;
  }, res);
};

const mapState = (state) => {
  const { selected, menuObject } = state.menu;
  const district = findInMenu(
    menuObject["District"],
    selected["District"]
  ).text;
  const network = findInMenu(menuObject["Network"], selected["Network"]).text;
  const school = findInMenu(menuObject["School"], selected["School"]);
  const year = selected["School Year"] ? selected["School Year"] : "";
  const measure = findInMenu(menuObject["Measure"], selected["Measure"]).text;
  const subgroup = findInMenu(
    menuObject["Student Groups"],
    selected["Student Groups"]
  ).text.split("(")[0];
  const hasStudents = subgroup.split(" ").reduce((has, curr) => {
    if (["Student", "Students"].includes(curr)) {
      return true;
    }
    return has;
  }, false);
  const school_types = Object.keys(selected["multi_school_type"]);
  const college_levels = Object.keys(selected["multi_college_level"]);
  const subgroup_name = hasStudents ? subgroup : `${subgroup} Students`;
  const { collegeBarPlot, loading } = state.metrics;
  return {
    college_levels,
    collegeBarPlot,
    district,
    year,
    measure,
    subgroup: subgroup_name,
    loading,
    network,
    school_types,
  };
};

const mapDispatch = (dispatch) => {
  return {};
};

export default compose(
  withStyles(tableStyle, { withTheme: true }),
  connect(mapState, mapDispatch)
)(CollegeBarPlots);
