import React, { Component, Fragment } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import connect from "react-redux/es/connect/connect";
import { compose } from "redux";
import Grid from "@material-ui/core/Grid";
import TableTitle from "../../StyledComponents/Table/TitleTable";
import { smallMultiplesHelpers } from "./helpers";
import Select, { components } from "react-select";
import { SmallMultiplesTable } from "./Components/Table";

const styles = (theme) => ({});

class SmallMultiples extends Component {
  constructor(props) {
    super(props);
    const { parseMeasureList, parseSubgroupList, parseInstitutionList } =
      smallMultiplesHelpers;
    const { measures, schools, districts, networks, subgroups } = props;
    const { years, baseSelect, schoolOptions } = parseInstitutionList(
      networks,
      districts,
      schools
    );
    this.state = {
      measureList: parseMeasureList(measures),
      subgroupList: parseSubgroupList(subgroups),
      baseInstitutions: baseSelect,
      schoolOptions,
      selectedInstitution: null,
      selectedSchool: null,
      years,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {}

  render() {
    const {
      subgroupList,
      measureList,
      baseInstitutions,
      years,
      selectedInstitution,
      schoolOptions,
      selectedSchool,
    } = this.state;
    return (
      <Grid container style={{ width: "95%", margin: "0 2.5% 0 2.5%" }}>
        <TableTitle
          school={{}}
          district={{}}
          pageTitle={"Small Multiples"}
          hideInfo
        />
        <Grid container item xs={12}>
          <Grid item xs={4}>
            <Select options={subgroupList} />
          </Grid>
          <Grid item xs={4}>
            <Grid item xs={12}>
              <Select options={measureList} />
            </Grid>
            <Grid item xs={12}>
              <Select options={years} />
            </Grid>
          </Grid>
          <Grid container item xs={4}>
            <Grid item xs={12}>
              <Select
                value={selectedInstitution}
                id={"institution-list"}
                options={baseInstitutions}
                onChange={(e) => {
                  this.setState({
                    selectedInstitution: e,
                    selectedSchool: null,
                  });
                }}
              />
            </Grid>
            {(selectedInstitution || { type: "" })["type"] === "district" ? (
              <Grid item xs={12}>
                <Select
                  placeholder={"Select School"}
                  id={"institution-list"}
                  options={schoolOptions[selectedInstitution.value]}
                  onChange={(e) => {
                    this.setState({
                      selectedSchool: e,
                    });
                  }}
                />
              </Grid>
            ) : null}
            {selectedInstitution ? (
              <Grid item xs={12}>
                <Select
                  placeholder={"Select School Level"}
                  id={"institution-list"}
                  options={
                    selectedSchool
                      ? selectedSchool.schoolLevels
                      : selectedInstitution.schoolLevels
                  }
                />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        <Grid xs={12}>
          <SmallMultiplesTable
            measureList={measureList}
            subgroupList={subgroupList}
            baseInstiutions={baseInstitutions}
            schoolOptions={schoolOptions}
            years={years}
          />
        </Grid>
      </Grid>
    );
  }
}

const mapState = (state) => {
  const {
    constants: { subgroups, measures },
    menu: {
      menu: { schools, districts, networks },
    },
  } = state;
  return {
    networks,
    subgroups,
    measures,
    schools,
    districts,
  };
};
const mapDispatch = (dispatch) => {
  return {};
};

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapState, mapDispatch)
)(SmallMultiples);
