import TableCell from "@material-ui/core/TableCell";
import Grid from "@material-ui/core/Grid";
import React from "react";
import tableStyle from "../../assets/jss/core-dashboard/components/tableStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import { HoverInfo } from "../../LCAP/helpers";
import arrow from "../../static/arrow.png";
import { addCommas } from "../../utils";
import CustomToolTip from "../Tooltip/CustomToolTip";
import {
  getSubscript,
  GetTableSubscript,
  SubscriptTooltip,
} from "../../Helpers/Table/Table";
import CardHeader from "../Card/CardHeader";

function RenderCell({ ...props }) {
  const { classes, cell, key, title, pageType, cellId } = props;
  const subgroupTitlesForSideBar = [
    "Subgroup Performance: Academic Domain",
    "Subgroup Performance: Social-Emotional & Culture-Climate Domain",
  ];
  let needSideBar = false;
  let text = {};
  let sidebarColor = "rgba(0,0,0,0)";
  if (cell === "NO DATA") {
    return (
      <TableCell
        id={cellId}
        style={{ whiteSpace: "nowrap", padding: "12px" }}
        className={classes.tableCell}
        key={key}
      >
        <Typography className={classes.noData}>No Data</Typography>
      </TableCell>
    );
  }
  if (subgroupTitlesForSideBar.includes(title)) {
    needSideBar = true;
  }

  switch (cell.color) {
    case "red":
      text = classes.redBackdrop;
      sidebarColor = "#ef4b4b";
      break;
    case "orange":
      text = classes.orangeBackdrop;
      sidebarColor = "#f4861f";
      break;
    case "green":
      text = classes.greenBackdrop;
      sidebarColor = "#67b948";
      break;
    default:
      text = classes.clearBackdrop;
      break;
  }

  switch (cell.type) {
    case "LLA TITLE":
      return (
        <TableCell
          id={cellId}
          style={{
            height: "50px",
            padding: "0px",
            margin: "0px",
            backgroundColor: cell.color,
          }}
          className={classes.tableCell}
          key={key}
        >
          <Typography
            className={classes.rowTitleGroup}
            style={{ marginLeft: "10px", color: cell.textColor || "black" }}
          >
            {cell.text}
          </Typography>
        </TableCell>
      );
    case "LLA DEMOGRAPHIC":
      return (
        <TableCell
          id={cellId}
          style={{
            maxWidth: cell.maxWidth,
            width: cell.maxWidth,
            minWidth: cell.maxWidth,
            height: "50px",
            padding: "0 0 0 10",
            margin: "0px",
          }}
          className={classes.tableCell}
          key={key}
        >
          <Typography className={classes.rowTitleGroup}>
            {cell.text} <small>{cell.subtext}</small>
          </Typography>
        </TableCell>
      );
    case "NUMERATOR":
      const measure = cell.measure;
      const distance = [
        "dfm_math",
        "dfm_ela",
        "sbac_dfm_math",
        "sbac_dfm_ela",
        "caa_dfm_math",
        "caa_dfm_ela",
      ].includes(measure);
      const growth = ["growth_ela", "growth_math"].includes(measure);
      const survey = [
        "cc_fam_fair",
        "cc_fam_ovr",
        "cc_fam_saf",
        "cc_fam_bel",
        "cc_fam_cs",
        "cc_stf_bel",
        "cc_stf_cs",
        "cc_stf_fair",
        "cc_stf_ovr",
        "cc_stf_saf",
        "cc_stu_bel",
        "cc_stu_ovr",
        "cc_stu_cs",
        "cc_stu_fair",
        "cc_stu_saf",
        "sel_stu_gm",
        "sel_stu_gmpos",
        "sel_stu_sa",
        "sel_stu_se",
        "sel_stu_sm",
      ].includes(measure);

      return (
        <TableCell
          id={cellId}
          padding="none"
          key={key}
          className={classes.tableCell}
        >
          <Grid
            style={{ textAlign: "left" }}
            className={classes.metricText}
            container
            direction={"column"}
          >
            <Grid
              style={{
                paddingLeft: "10px",
                borderBottom: "1px solid rgba(0,0,0,.05)",
              }}
              xs={12}
              item
            >
              {addCommas(`${cell.numerator}`) || "No Data"}
              {distance ? (
                <small className={classes.metricSubText}>
                  {" "}
                  Distance From Standards
                </small>
              ) : growth ? (
                <small className={classes.metricSubText}>
                  {" "}
                  Growth Percentile
                </small>
              ) : survey ? (
                <small className={classes.metricSubText}>
                  {" "}
                  Positive Responses
                </small>
              ) : (
                <small className={classes.metricSubText}> Students</small>
              )}
            </Grid>
            <Grid style={{ paddingLeft: "10px" }} xs={12} item>
              {addCommas(`${cell.denominator}`) || "No Data"}
              {survey ? (
                <small className={classes.metricSubText}>Total Responses</small>
              ) : (
                <small className={classes.metricSubText}>Total Students</small>
              )}
            </Grid>
          </Grid>
        </TableCell>
      );
    case "LCAP CELL": {
      const { color, list, pastYear, year } = cell;
      const borderColor =
        color === "red"
          ? "#ef4b4b"
          : color === "orange"
          ? "#e86d1f"
          : color === "yellow"
          ? "#CCCC00"
          : color === "green"
          ? "#67b948"
          : "#5590b5";
      return (
        <TableCell
          id={cellId}
          padding="none"
          key={key}
          className={classes[`${color}TransparentBackdrop`]}
        >
          <div className={classes[`${color}Border`]} />
          <div
            style={{
              fontWeight: "900",
              fontSize: "24px",
              marginLeft: ".75rem",
            }}
          >
            <Grid container direction={"column"}>
              {list.map((line, index) => (
                <Grid key={`grid:${line.text}`} item>
                  <Tooltip
                    key={`cell:${line.text}`}
                    title={
                      <HoverInfo line={line} pastYear={pastYear} year={year} />
                    }
                  >
                    <Typography
                      style={{
                        fontWeight: 400,
                        lineHeight: 1.2,
                        textAlign: "left",
                        padding: "5px",
                        borderBottom:
                          index < list.length - 1
                            ? `3px solid ${borderColor}`
                            : null,
                      }}
                    >
                      {line.text}
                    </Typography>
                  </Tooltip>
                </Grid>
              ))}
            </Grid>
          </div>
        </TableCell>
      );
    }
    case "LCAP PERFORMANCE":
      return (
        <TableCell
          id={cellId}
          padding="none"
          key={key}
          className={
            classes[`${cell.color}TransparentBackdrop`] +
            "  " +
            classes.tableCell
          }
        >
          <div className={classes[`${cell.color}Border`]} />
          <div
            style={{
              fontWeight: "900",
              fontSize: "24px",
              marginLeft: ".75rem",
            }}
          >
            {cell.text}
          </div>
        </TableCell>
      );
    case "GAP DIFFERENCE":
      return (
        <TableCell
          id={cellId}
          key={key}
          padding="none"
          style={{
            height: "50px",
            overflow: "hidden",
            position: "relative",
            whiteSpace: "nowrap",
            // borderBottom: "1px solid rgba(224, 224, 224, 1)",
            verticalAlign: "top",
          }}
        >
          <div
            key={key}
            className={text}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              height: "100%",
              width: "60px",
              display: "inline-block",
              paddingRight: "1px",
              verticalAlign: "top",
            }}
          >
            {cell.direction === "down" ? (
              <img src={arrow} className={classes.arrowIconDown} />
            ) : cell.direction === "up" ? (
              <img src={arrow} className={classes.arrowIconUp} />
            ) : cell.direction === "right" ? (
              <img src={arrow} className={classes.arrowIconRight} />
            ) : (
              ""
            )}
          </div>
          <div
            id={cellId + "-background2"}
            style={{
              height: "100%",
              width: "140px",
              display: "inline-block",
              marginLeft: "60px",
              paddingTop: "7.5%",
              verticalAlign: "bottom",
            }}
          >
            <span className={classes.indexChange} style={{ marginLeft: "30%" }}>
              {cell.text}
            </span>
          </div>
        </TableCell>
      );
    case "INDEX CHANGE":
      return (
        <TableCell
          id={cellId}
          key={key}
          className={classes.tableCell}
          padding="none"
          style={{
            height: "50px",
            overflow: "hidden",
            position: "relative",
            whiteSpace: "nowrap",
            textAlign: "center",
            verticalAlign: "center",
          }}
        >
          <div
            key={key}
            className={text}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              height: "100%",
              width: "60px",
              display: "inline-block",
              paddingRight: "1px",
              verticalAlign: "top",
            }}
          >
            {cell.color === "red" ? (
              <img src={arrow} className={classes.arrowIconDown} />
            ) : cell.color === "green" ? (
              <img src={arrow} className={classes.arrowIconUp} />
            ) : cell.color === null ? (
              <img src={arrow} className={classes.arrowIconRight} />
            ) : (
              ""
            )}
          </div>
          <span className={classes.indexChange} style={{ paddingLeft: "60px" }}>
            {cell.text}
          </span>
        </TableCell>
      );
    case "DEMOGRAPHIC":
      return (
        <TableCell
          id={cellId}
          className={classes.tableCell}
          style={{
            padding: "0px",
            verticalAlign: "center",
            whiteSpace: "nowrap",
          }}
        >
          <Grid container>
            <Grid item xs={cell.w} className={classes.rowTitleGroup}>
              <CustomToolTip code={cell.name} demo={true}>
                <Typography className={classes.rowTitleGroup}>
                  {cell.group}
                </Typography>
              </CustomToolTip>
            </Grid>
            <Grid item className={classes.demoCellText}>
              {cell.text}
            </Grid>
          </Grid>
        </TableCell>
      );
    case "NAME":
      return (
        <TableCell
          id={cellId}
          style={{
            borderBottom: "0px solid white",
            maxWidth: "200px",
            minWidth: "200px",
            height: "50px",
            padding: "0px",
            margin: "0px",
          }}
          key={key}
        >
          <div style={{ display: "inline-block" }}>
            <div style={{ display: "inline-block" }}>
              <CustomToolTip code={cell.code}>
                <Typography className={classes.rowTitleGroup}>
                  {cell.text}
                </Typography>
              </CustomToolTip>
            </div>
            <div style={{ display: "inline-block", cursor: "pointer" }}>
              <GetTableSubscript
                title={cell.text}
                classes={classes}
                style={"rowTitleGroup"}
              />
            </div>
          </div>
        </TableCell>
      );
    case "INDEX LEVEL":
      return (
        <TableCell
          id={cellId}
          className={classes.tableCell}
          padding="none"
          style={{
            // minWidth: "160px",
            height: "50px",
            overflow: "hidden",
            position: "relative",
            whiteSpace: "nowrap",
            // borderBottom: "1px solid rgba(224, 224, 224, 1)",
            textAlign: "center",
            maxWidth: "140px",
            verticalAlign: "bottom",
            paddingBottom: "10px",
            paddingRight: "0px",
          }}
        >
          <div
            id={cellId + "-background"}
            className={text}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              height: "100%",
              width: "60px",
              display: "inline-block",
              paddingRight: "1px",
              verticalAlign: "center",
            }}
          ></div>
          <span
            className={classes.metricText}
            style={{
              fontSize: "30px",
              paddingTop: "4px",
              float: "left",
              width: "50px",
            }}
          >
            {cell.text}
          </span>
          <p
            className={classes.metricSubText}
            style={{ fontSize: "16px", paddingLeft: "10px", fontWeight: "600" }}
          >
            out of 10
          </p>
        </TableCell>
      );
    case "INDEX LEVEL NO WIDTH":
      return (
        <TableCell
          id={cellId}
          className={classes.tableCell}
          padding="none"
          style={{
            // minWidth: "160px",
            height: "50px",
            overflow: "hidden",
            position: "relative",
            whiteSpace: "nowrap",
            // borderBottom: "1px solid rgba(224, 224, 224, 1)",
            textAlign: "center",
            verticalAlign: "bottom",
            paddingBottom: "10px",
            paddingRight: "0px",
          }}
        >
          <div
            id={cellId + "-background"}
            className={text}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              height: "100%",
              width: "60px",
              display: "inline-block",
              paddingRight: "1px",
              verticalAlign: "center",
            }}
          ></div>
          <span
            className={classes.metricText}
            style={{
              fontSize: "30px",
              paddingTop: "4px",
              float: "left",
              width: "50px",
            }}
          >
            {cell.text}
          </span>
          <p
            className={classes.metricSubText}
            style={{ fontSize: "16px", paddingLeft: "10px", fontWeight: "600" }}
          >
            out of 10
          </p>
        </TableCell>
      );
    case "SUBGROUP":
      return (
        <TableCell
          id={cellId}
          className={classes.tableCell}
          padding="none"
          key={key}
          style={{
            position: "relative",
            top: "0px",
            left: "0px",
            padding: "5px",
            verticalAlign: "center",
          }}
        >
          <div className={classes[`${cell.color}Border`]} />
          <div style={{ textAlign: "left", marginLeft: "1rem" }}>
            <Typography
              className={classes.indexLevelNum}
              style={{ marginBottom: "unset" }}
            >
              {cell.text}
            </Typography>
            <Typography
              className={classes.metricSubText}
              style={{ marginTop: "unset", marginRight: "0" }}
            >
              {cell.subtext}
            </Typography>
          </div>
        </TableCell>
      );
    case "METRIC RESULT":
      if (!needSideBar) {
        return (
          <TableCell
            className={classes.tableCell}
            style={{
              maxWidth: "150px",
              position: "relative",
              top: "0px",
              left: "0px",
              verticalAlign: "center",
            }}
            key={key}
          >
            {pageType === "LCAP" ? (
              <div className={classes[`${cell.lcapColor}Border`]} />
            ) : null}
            <div
              style={{
                marginLeft: pageType === "LCAP" ? "1rem" : null,
                textAlign: "left",
              }}
            >
              <Typography
                className={classes.indexLevelNum}
                style={{ marginBottom: "unset" }}
              >
                {cell.text}
              </Typography>
              <Typography
                className={classes.metricSubText}
                style={{ marginTop: "unset" }}
              >
                {cell.subtext}
              </Typography>
            </div>
          </TableCell>
        );
      }
      return (
        <TableCell
          className={classes.tableCell}
          id={cellId}
          padding="none"
          key={key}
          style={{
            // minWidth: '160px',
            maxWidth: "150px",
            position: "relative",
            top: "0px",
            left: "0px",
            verticalAlign: "center",
          }}
        >
          <div className={classes[`${cell.color}Border`]} />
          <div style={{ marginLeft: "1rem", padding: "5px" }}>
            <Typography
              className={classes.indexLevelNum}
              style={{ marginBottom: "unset" }}
            >
              {cell.text}
            </Typography>
            <Typography
              className={classes.metricSubText}
              style={{ marginTop: "unset" }}
            >
              {cell.subtext}
            </Typography>
          </div>
        </TableCell>
      );
    case "METRIC IMPROVE":
    case "METRIC CHANGE": {
      const percent = cell.text.charAt(cell.text.length - 1) === "%";
      const negative = cell.text.charAt(0) === "-";
      const len = cell.text.length;
      const size = 5 - len + (negative ? 1 : 0) + (percent ? 1 : 0);
      const beforeSpaces = `${size > 0 ? "\u2007" : ""}${
        size > 1 ? "\u2007" : ""
      }${size > 2 ? "\u2007" : ""}`;
      const displayText = `${beforeSpaces}${negative ? "" : "\u2004"}${
        cell.text
      }${percent ? "" : "\u3000"}`;
      return (
        <TableCell
          className={classes.tableCell}
          id={cellId}
          style={{
            maxWidth: "400px",
            textAlign: "left",
            position: "relative",
            top: "0px",
            left: "0px",
          }}
          key={key}
        >
          {pageType === "LCAP" ? (
            <div className={classes[`${cell.lcapColor}Border`]} />
          ) : null}
          <div style={{}}>
            <Typography className={classes.metricText}>
              {displayText}
            </Typography>
          </div>
        </TableCell>
      );
    }
    case "LOWEST PERFORMING":
      return (
        <TableCell
          className={classes.tableCell}
          id={cellId}
          padding="none"
          key={key}
          style={{
            minWidth: "140px",
            maxWidth: "140px",
            position: "relative",
            top: "0px",
            left: "0px",
            padding: "5px",
            verticalAlign: "center",
          }}
        >
          <div className={classes[`${cell.color}Border`]} />
          <div style={{ marginLeft: "1rem" }}>
            <Typography
              className={classes.indexLevelNum}
              style={{ marginBottom: "unset" }}
            >
              {cell.text}
              <b className={classes.lowestPerfSub}>({cell.group})</b>
            </Typography>
            <Typography
              className={classes.metricSubText}
              style={{ marginTop: "unset" }}
            >
              {cell.subtext}
            </Typography>
          </div>
        </TableCell>
      );
    default:
      return <TableCell className={classes.tableCell} key={key} />;
  }
}

export default withStyles(tableStyle)(RenderCell);
