import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/es/Typography/Typography";
import { compose } from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { percentToPixel } from "./Helpers/helpers";
import UpArrowSVG from "./Helpers/SVG/upArrow";
import DownArrowSVG from "./Helpers/SVG/downArrowSVG";
import NeutralArrowSVG from "./Helpers/SVG/neutralArrow";

const styles = (theme) => ({
  containerSpacing: {
    flexGrow: 1,
    paddingTop: "50px",
    paddingLeft: "15px",
    paddingRight: "10px",
  },
  subheader: {
    fontSize: "38px",
    color: "rgba(60,114,135,.9)",
    paddingLeft: "3%",
  },
  graph: {
    height: "550px",
    width: "1052px", //Added 2 extra pixels to see the linecap property
    // marginBottom: "10px",
    // backgroundColor: "#d7e4ed" //dev purposes
  },
  graphLine: {
    stroke: "#babbbb",
    strokeWidth: ".5px",
    strokeLinecap: "round",
  },
  yLabel: {
    fill: "#babbbb",
    textAnchor: "end",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    fontSize: "16px",
  },
  dots: {
    strokeWidth: "3",
    fill: "white",
  },
  dotText: {
    textAnchor: "middle",
    fontSize: "25px",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  shading: {
    fill: "#d8e5ec",
    opacity: ".6",
    stroke: "black",
    strokeWidth: "2",
  },
});

class PercentChangeGraph extends Component {
  render() {
    const {
      classes,
      measure_prior,
      measure_current,
      measure_delta,
      handleTitle,
      successOptions,
    } = this.props;
    const type =
      measure_prior === measure_current
        ? "neutral"
        : measure_prior < measure_current
        ? "positive"
        : "negative";
    const y1 = percentToPixel(400, measure_prior, 75);
    const y1Text = y1 + 8;
    const y2 = percentToPixel(400, measure_current, 75);
    const y2Text = y2 + 8;
    const diffAverage = (measure_prior + measure_current) / 2;
    const diffPositionUp = percentToPixel(400, diffAverage, 75) - 80;
    const diffPositionDown = percentToPixel(400, diffAverage, 75) - 40;
    const diffPositionNeutral = percentToPixel(400, diffAverage, 75) - 66;
    const path1 = `M120,${y1} M120,475 L980,475 L980,${y2} L120, ${y1} Z`;
    const path2 = `M120,${y1} L980,${y2} Z`;
    const color =
      type === "positive"
        ? "#598fb2"
        : type === "negative"
        ? "#f29b37"
        : "#91c24d";
    return (
      <Grid
        container
        className={classes.containerSpacing}
        alignItems={"center"}
      >
        <Grid container item xs={12} justifyContent={"center"}>
          <Typography className={classes.subheader}>
            {handleTitle(successOptions)}
          </Typography>
        </Grid>
        <Grid container item xs={12} justifyContent={"center"}>
          <svg className={classes.graph}>
            <g className={classes.graphLine}>
              <line x1="50" x2="1050" y1="75" y2="75" />
              <line x1="50" x2="1050" y1="115" y2="115"></line>
              <line x1="50" x2="1050" y1="155" y2="155"></line>
              <line x1="50" x2="1050" y1="195" y2="195"></line>
              <line x1="50" x2="1050" y1="235" y2="235"></line>
              <line x1="50" x2="1050" y1="275" y2="275"></line>
              <line x1="50" x2="1050" y1="315" y2="315"></line>
              <line x1="50" x2="1050" y1="355" y2="355"></line>
              <line x1="50" x2="1050" y1="395" y2="395"></line>
              <line x1="50" x2="1050" y1="435" y2="435"></line>
              <line x1="50" x2="1050" y1="475" y2="475"></line>
            </g>
            <g className={classes.yLabel}>
              <text x="46" y="80">
                100%
              </text>
              <text x="46" y="120">
                90%
              </text>
              <text x="46" y="160">
                80%
              </text>
              <text x="46" y="200">
                70%
              </text>
              <text x="46" y="240">
                60%
              </text>
              <text x="46" y="280">
                50%
              </text>
              <text x="46" y="320">
                40%
              </text>
              <text x="46" y="360">
                30%
              </text>
              <text x="46" y="400">
                20%
              </text>
              <text x="46" y="440">
                10%
              </text>
              <text x="46" y="480">
                0%
              </text>
            </g>
            <g className={classes.shading}>
              <path d={path1} stroke="none"></path>
              <path d={path2} style={{ stroke: color }}></path>
            </g>
            <g className={classes.dots} style={{ stroke: color }}>
              <circle cx={"120"} cy={y1} r={"40"}></circle>
              <circle cx={"980"} cy={y2} r={"40"}></circle>
            </g>
            <g
              className={classes.dotText}
              style={{ fill: color, stroke: color }}
            >
              <text x="120" y={y1Text}>
                {measure_prior}%
              </text>
              <text x="980" y={y2Text}>
                {measure_current}%
              </text>
            </g>
            {type === "positive" ? (
              <UpArrowSVG
                x={"450"}
                y={diffPositionUp}
                measure_delta={measure_delta}
              />
            ) : type === "negative" ? (
              <DownArrowSVG
                x={"450"}
                y={diffPositionDown}
                measure_delta={measure_delta}
              />
            ) : (
              <NeutralArrowSVG
                x={"450"}
                y={diffPositionNeutral}
                measure_delta={measure_delta}
              />
            )}
          </svg>
        </Grid>
      </Grid>
    );
  }
}

export default compose(withStyles(styles, { withTheme: true }))(
  PercentChangeGraph
);
