import React, { Component } from "react";
import Typography from "@material-ui/core/Typography/Typography";
import Grid from "@material-ui/core/Grid/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import { GetTableSubscript } from "../../Helpers/Table/Table";

const styles = {
  tableSetting: {
    marginBottom: "1.5rem",
    // marginTop: "5px",
    // TODO: Change when banner is removed.
    marginTop: "145px",
    //Removed to compile with Sean's guidelines
    //paddingLeft: "85px"
  },
  title: {
    marginBottom: "1rem",
  },
  titleText: {
    fontSize: "48px",
    color: "rgba(60,114,135,.9)",
  },
  secondTitleText: {
    fontSize: "32px",
    color: "rgba(60,114,135,.9)",
    fontWeight: "bold",
    paddingLeft: "20px",
    paddingTop: "4px",
  },
  subText: {
    color: "rgba(60,114,135,.9)",
  },
};

class TableTitle extends Component {
  render() {
    const { district, school, classes, pageTitle, hideInfo, secondaryTitle } =
      this.props;
    const info = [
      {
        title: "District",
        text: district.text,
      },
      {
        title: "School Name",
        text: school.text,
      },
      {
        title: "School Code",
        text: school.code,
      },
      {
        title: "Address",
        text: school.street
          ? `${school.street} ${school.city}, ${school.state} ${school.zip} `
          : "",
      },
    ];
    return (
      <Grid container item xs={12} className={classes.tableSetting}>
        <Grid container className={classes.title}>
          <div style={{ display: "inline-block" }}>
            <div style={{ display: "inline-block" }}>
              <Typography variant={"h3"} className={classes.titleText}>
                {pageTitle}{" "}
              </Typography>
            </div>
            <div style={{ display: "inline-block", cursor: "pointer" }}>
              <GetTableSubscript
                title={pageTitle}
                classes={classes}
                style={"titleText"}
              />
            </div>
          </div>
          {secondaryTitle ? (
            <Grid item>
              <Typography className={classes.secondTitleText}>
                {secondaryTitle}
              </Typography>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
        {!hideInfo &&
          info.map((curr) => (
            <Grid container item xs={3} key={curr.title} direction={"column"}>
              <Grid item>
                <Typography variant={"h6"} className={classes.subText}>
                  {curr.title}:
                </Typography>
              </Grid>
              <Grid item>
                <Typography>{curr.text}</Typography>
              </Grid>
            </Grid>
          ))}
      </Grid>
    );
  }
}

export default withStyles(styles)(TableTitle);
