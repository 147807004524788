import React, { Component } from "react";
import { compose } from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import connect from "react-redux/es/connect/connect";
import Grid from "@material-ui/core/Grid";
import Plot from "react-plotly.js";
import { onDemandHelpers } from "./helpers";
import TableTitle from "../StyledComponents/Table/TitleTable";
import Card from "../StyledComponents/Card/Card";
import CardHeader from "../StyledComponents/Card/CardHeader";
import CardBody from "../StyledComponents/Card/CardBody";
import CreateTable from "../StyledComponents/Table/TableList";
import tableStyle from "../assets/jss/core-dashboard/components/tableStyle";
import { PlotData } from "./PlotData";
import LoadingGrid from "../StyledComponents/Grid/LoadingGrid";
import { tableStyling } from "./plotDataStyles";
import { Typography } from "@material-ui/core";

class GapTrends extends Component {
  render() {
    const {
      gapTrends,
      subgroup,
      network,
      school,
      district,
      classes,
      measureInfo,
      subgroupName,
      loading,
      distance,
    } = this.props;
    const inverseGroup = subgroup + "inv";
    const keys = [school, district, network, "Overall"];
    const cols = [school, district, network, "Overall"];
    const college =
      this.props.match.path === "/dashboard/shiny/prelim/gaptrends";
    const years = Object.keys(
      Object.keys(gapTrends).reduce((years, key) => {
        Object.keys(gapTrends[key]).forEach((yr) => {
          years[yr] = yr.split("-")[1];
        });
        return years;
      }, {}) || {}
    ).sort((a, b) => {
      const cA = a.split("-")[1];
      const cB = b.split("-")[1];
      const c = parseInt(cA) - parseInt(cB);
      return c;
    });
    const titles = [
      `Comparison in Measure Rate: ${subgroupName}`,
      `Comparison in Measure Rate: Not ${subgroupName}`,
      `Gap In Measure Rate between ${subgroupName} and Complement Group`,
    ];
    const codes = [subgroup, inverseGroup, "gap"];
    const { subtext, percent, reverse, name } = measureInfo || {};
    const percentSymbol = percent || college ? "%" : "";
    const tables = codes.map((curr) => {
      return cols.map((col) =>
        [
          {
            type: "NAME",
            text: col,
          },
        ].concat(
          years.map((year) => {
            const currData = (gapTrends[col] || {})[year] || {} || {};
            switch (curr) {
              case subgroup: {
                const { measure_rate } = currData[subgroup] || {};
                return measure_rate !== undefined
                  ? {
                      type: "METRIC RESULT",
                      text: `${measure_rate}${percentSymbol}`,
                      subtext,
                    }
                  : "NO DATA";
              }
              case inverseGroup: {
                const { measure_rate } = currData[inverseGroup] || {};
                return measure_rate !== undefined
                  ? {
                      type: "METRIC RESULT",
                      text: `${measure_rate}${percentSymbol}`,
                      subtext,
                    }
                  : "NO DATA";
              }
              case "gap": {
                const { measure_rate } = currData[subgroup] || {};
                const meas = (currData[inverseGroup] || {})["measure_rate"];
                const diff = Math.round(100 * (meas - measure_rate)) / 100;
                const color = reverse
                  ? diff > 0
                    ? "green"
                    : diff === 0
                    ? null
                    : "red"
                  : diff > 0
                  ? "red"
                  : diff === 0
                  ? null
                  : "green";
                const direction =
                  diff > 0 ? "down" : diff === 0 ? "right" : "up";
                return meas !== undefined && measure_rate !== undefined
                  ? {
                      type: "GAP DIFFERENCE",
                      color,
                      direction,
                      text: `${Math.abs(diff)}${percentSymbol}`,
                      subtext: `Gap in ${subtext}`,
                    }
                  : "NO DATA";
              }
            }
          })
        )
      );
    });
    const { generateGapTrendsData, formatGapTrendsPlots } = onDemandHelpers;
    const { data, scatterMinY, scatterMaxY } = generateGapTrendsData({
      keys,
      gapTrends,
      subgroup,
      inverseGroup,
      subgroupName,
      measureInfo,
      dfmMeasure: distance,
    });
    const lineChart = formatGapTrendsPlots({ data, years });
    const { maxY, minY } = data.barChartLayout;
    const range = [scatterMinY - 20, scatterMaxY + 20];
    const tickvals =
      scatterMinY >= 0
        ? [0, 20, 40, 60, 80, 100]
        : Array.apply(
            null,
            new Array(Math.round(Math.abs(range[0] - range[1]) / 20 + 2))
          ).map((_, index) => {
            const val = Math.round((scatterMinY + 20 * index) / 10) * 10;
            return val % 20 ? val + 10 : val;
          });
    const ticktext = tickvals.map((val) => `<b>${val}</b>`);
    let min;
    let max;
    if (minY < 0 && maxY < 0) {
      min = minY;
      max = 0;
    } else if (minY > 0 && maxY > 0) {
      max = maxY;
      min = 0;
    } else {
      min = minY;
      max = maxY;
    }
    const columns = [
      {
        title: "Name",
        field: "name",
        cellStyle: tableStyling.firstData,
        headerStyle: tableStyling.firstHeader,
      },
      {
        title: "Year",
        field: "year",
        cellStyle: tableStyling.year,
        headerStyle: tableStyling.darkHeader,
      },
      {
        grouping: false,
        title: subgroupName,
        field: "measure_rate",
        cellStyle: tableStyling.dataRow,
        headerStyle: tableStyling.title,
        render: (rowData) =>
          rowData.measure_rate !== undefined ? (
            `${rowData.measure_rate}${percentSymbol}`
          ) : (
            <p style={{ margin: 5, color: "#d1d1d1" }}>No Data</p>
          ),
      },
      {
        title: "Complement Group",
        field: "inverse_measure_rate",
        grouping: false,
        cellStyle: tableStyling.dataRow,
        headerStyle: tableStyling.title,
        render: (rowData) =>
          rowData.inverse_measure_rate !== undefined ? (
            `${rowData.inverse_measure_rate}${percentSymbol}`
          ) : (
            <p style={{ margin: 5, color: "#d1d1d1" }}>No Data</p>
          ),
      },
      {
        title: "Gap",
        field: "gap",
        grouping: false,
        cellStyle: tableStyling.dataRow,
        headerStyle: tableStyling.title,
        render: (rowData) =>
          !(
            rowData.inverse_measure_rate !== undefined &&
            rowData.gap !== undefined &&
            rowData.measure_rate !== undefined
          ) ? (
            <p style={{ margin: 5, color: "#d1d1d1" }}>No Data</p>
          ) : (
            `${rowData.gap}${percentSymbol}`
          ),
      },
    ];
    const tableData = Object.keys(gapTrends).reduce((array, name) => {
      const dataRows = Object.keys(gapTrends[name]).map((year) => {
        const { measure_rate } = gapTrends[name][year][subgroup] || {};
        const inverse_measure_rate = (gapTrends[name][year][`${subgroup}inv`] ||
          {})["measure_rate"];
        const gap =
          measure_rate !== undefined && inverse_measure_rate !== undefined
            ? (measure_rate - inverse_measure_rate).toFixed(1)
            : "No Data";
        const splitYear = year.split("-");
        const parsedYear = `${splitYear[0].charAt(2)}${splitYear[0].charAt(
          3
        )}-${splitYear[1].charAt(2)}${splitYear[1].charAt(3)}`;
        return {
          name,
          year: parsedYear,
          measure_rate,
          inverse_measure_rate,
          gap,
        };
      });
      return array.concat(dataRows);
    }, []);
    const measureName = measureInfo ? measureInfo.name : "";
    const fileName = `GapTrends-${subgroup}-${years[1]}-${measureName}`;
    const yLabelMeasure =
      `Measure Rate for ${name} among ${subgroupName} and Complement Group`
        .split(" ")
        .reduce(
          (s, w) => {
            const wLen = w.length;
            if (s.chars + wLen > 35) {
              s.label += `<br>${w}`;
              s.chars = wLen;
            } else {
              s.label += ` ${w}`;
              s.chars += wLen;
            }
            return s;
          },
          {
            label: "",
            chars: 0,
          }
        ).label;
    const yLabelDistance =
      `Distance in ${name} between ${subgroupName} and Complement Group`
        .split(" ")
        .reduce(
          (s, w) => {
            const wLen = w.length;
            if (s.chars + wLen > 35) {
              s.label += `<br>${w}`;
              s.chars = wLen;
            } else {
              s.label += ` ${w}`;
              s.chars += wLen;
            }
            return s;
          },
          {
            label: "",
            chars: 0,
          }
        ).label;
    return (
      <Grid
        style={{ margin: "0 2.5% 0 2.5%", width: "95%" }}
        justifyContent={"center"}
        container
        direction={"row"}
      >
        <TableTitle
          school={school}
          district={district}
          hideInfo
          pageTitle={"Gap Trends"}
        />
        <Grid xs={12} item>
          <Card>
            <CardHeader className={classes.gradientBackground} color="primary">
              <h4
                className={classes.cardTitleBlue}
                style={{ fontSize: "2vh", margin: "10px" }}
              >
                Overview
              </h4>
            </CardHeader>
            <CardBody
              style={{
                paddingTop: "0px",
                width: "100%",
                marginTop: "10px",
                paddingBottom: "10px",
                textAlign: "center",
              }}
            >
              {loading ? (
                <LoadingGrid />
              ) : (
                <Grid container>
                  <Grid
                    container
                    item
                    xs={12}
                    justifyContent={"center"}
                    style={{ textAlign: "left" }}
                  >
                    <Grid
                      md={10}
                      xs={12}
                      className={classes.metricSubText}
                      style={{ padding: "10px" }}
                    >
                      Due to the lack of standardized testing during the
                      COVID-19 pandemic, ES/MS growth was not produced from
                      2019-20 to 2021-22, and HS growth was not produced from
                      2019-20.
                    </Grid>
                  </Grid>
                  {college ? (
                    <Grid
                      container
                      item
                      xs={12}
                      justifyContent={"center"}
                      style={{ textAlign: "left" }}
                    >
                      <Grid md={5} xs={12} className={classes.metricSubText}>
                        This display compares the performance of a given student
                        group to all students not in that student group, labeled
                        as the complement.
                      </Grid>
                      <Grid md={5} xs={12} className={classes.metricSubText}>
                        College data last processed by the NSC on 2/25/2018 and
                        do not include full spring 2018 college enrollment. 2
                        year persistence rate in 2017 and 1 year enrollment in
                        2018 may be incomplete.
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      container
                      item
                      xs={12}
                      justifyContent={"center"}
                      style={{ textAlign: "left" }}
                    >
                      <Grid
                        md={5}
                        xs={12}
                        className={classes.metricSubText}
                        style={{ padding: "10px" }}
                      >
                        This display compares the performance of a given student
                        group to all students not in that student group, labeled
                        as the complement.
                      </Grid>
                      <Grid
                        md={5}
                        xs={12}
                        className={classes.metricSubText}
                        style={{ padding: "10px" }}
                      >
                        The line graphs below compare the student group to its
                        complement over a three year span and the bar graphs
                        below show the magnitude and direction of the gap. A
                        negative value on a bar means that the student group's
                        metric value is less than its complement's metric value.
                        For comparison, the values for the entire CORE data
                        collaborative are displayed on the right.
                      </Grid>
                    </Grid>
                  )}
                  <Grid className={classes.plot} container item xs={12}>
                    <Grid item xs={12}>
                      <Plot
                        style={{ width: "100%" }}
                        data={lineChart.data}
                        layout={{
                          showlegend: true,
                          margin: {
                            t: 20,
                            l: "auto",
                            r: 20,
                            b: 100,
                          },
                          legend: {
                            orientation: "h",
                            x: 0.4,
                            y: 1,
                          },
                          autosize: true,
                          grid: {
                            rows: 1,
                            columns: 4,
                            pattern: "independent",
                          },
                          ...lineChart.xAxisPositions,
                          yaxis: {
                            range,
                            tickvals,
                            ticktext,
                            anchor: "y",
                            title: {
                              text: `<b>${yLabelMeasure}</b>`,
                              font: {
                                size: 12,
                              },
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Plot
                        style={{ width: "100%" }}
                        data={lineChart.barData}
                        layout={{
                          showlegend: false,
                          margin: {
                            t: "auto",
                            l: "auto",
                            r: 20,
                            b: 100,
                          },
                          autosize: true,
                          grid: {
                            rows: 1,
                            columns: 4,
                          },
                          ...lineChart.xAxisPositions,
                          yaxis: {
                            range: [min, max],
                            title: {
                              text: `<b>${yLabelDistance}</b>`,
                              font: {
                                size: 12,
                              },
                            },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </CardBody>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <PlotData
            loading={loading}
            fileName={fileName}
            classes={classes}
            data={tableData}
            columns={columns}
          />
        </Grid>
        {tables.map((t, index) => (
          <Grid key={`CORE-INDEX-TABLE:${index}`} item xs={12}>
            <CreateTable
              loading={loading}
              hideFooter
              data={t}
              header={["Name"].concat(
                years.map((year) => {
                  const splitYear = year.split("-");
                  return `${splitYear[0].charAt(2)}${splitYear[0].charAt(
                    3
                  )}-${splitYear[1].charAt(2)}${splitYear[1].charAt(3)}`;
                })
              )}
              title={titles[index]}
              index={index}
            />
          </Grid>
        ))}
      </Grid>
    );
  }
}

const findInMenu = (array, key) => {
  const res = {
    code: "",
    text: "",
  };
  if (!array) {
    return res;
  }

  return array.reduce((found, curr) => {
    if (curr.code === key) {
      found = curr;
    }
    return found;
  }, res);
};

const mapState = (state) => {
  const { selected, menuObject } = state.menu;
  const {
    constants: { measures, subgroups, collegeMeasures },
  } = state;
  const network = findInMenu(menuObject["Network"], selected["Network"]);
  const district = findInMenu(menuObject["District"], selected["District"]);
  const parsedNetwork =
    network.text === district.text
      ? `${network.text} Network`
      : `${network.text}`;
  const parsedDistrict =
    network.text === district.text
      ? `${district.text} District`
      : `${district.text}`;
  const school = findInMenu(menuObject["School"], selected["School"]);
  const measureLevel = selected["School Level"];
  const year = selected["School Year"] ? selected["School Year"] : "";
  const measure = selected["Measure"];
  const subgroup = selected["Student Groups"];
  const subgroupName = subgroups
    ? (subgroups[subgroup] || { name: "" }).name
    : "";
  const parsedName =
    subgroupName.split("(")[0].toLowerCase().includes("student") ||
    ["EL", "ELP", "RFEP"].includes(subgroup)
      ? subgroupName
      : `${subgroupName} Students`;
  const measureInfo =
    {
      ...measures,
      ...collegeMeasures,
    }[measure] || {};
  const { gapTrends, loading } = state.metrics;
  const distance = [
    "dfm_ela",
    "dfm_math",
    "sbac_dfm_math",
    "sbac_dfm_ela",
    "caa_dfm_math",
    "caa_dfm_ela",
  ].includes(measure);
  return {
    distance,
    subgroupName: parsedName,
    gapTrends,
    selected,
    network: parsedNetwork,
    district: parsedDistrict,
    school: school.text,
    year,
    measure,
    measureLevel,
    subgroup,
    measureInfo,
    loading,
  };
};

const mapDispatch = (dispatch) => {
  return {};
};

export default compose(
  withStyles(tableStyle, { withTheme: true }),
  connect(mapState, mapDispatch)
)(GapTrends);
