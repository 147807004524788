export const app_constants = {
  groupCodeToName: {
    AA: "African American",
    AI: "American Indian/ Alaskan Native",
    AN: "American Indian/ Alaskan Native",
    ALL: "All",
    AS: "Asian",
    CE: "Continuously Enrolled",
    EL: "ELs (Active ELs and Up to 4yr RFEPs",
    ELP: "Pure English Language Learners",
    ELRFEP: "RFEP Students",
    FI: "Filipino",
    FST: "Foster Students",
    HI: "Latinx",
    HMLS: "Homeless Students",
    MR: "Students of Multiple Races",
    PI: "Pacific Islander",
    RFEP: "Reclassified Fluent English Proficient",
    SD: "Socioeconomically Disadvantaged",
    SWD: "Students With Disabilities",
    WH: "White",
  },
  measureCodeToName: {
    chron_abs: "Chronic Absence",
    dfm_ela: "Distance - ELA",
    perc_prof_ela: "Proficiency - ELA",
    dfm_math: "Distance - Math",
    sbac_dfm_ela: "SBAC Distance - ELA",
    sbac_dfm_math: "SBAC Distance - MATH",
    caa_dfm_ela: "CAA Distance - ELA",
    caa_dfm_math: "CAA Distance - MATH",
    el_eli: "EL Indicator",
    el_redes: "EL Re-Designation",
    cc_fam_ovr: "Family Culture Climate",
    grad4y: "Graduation",
    sel_stu_gm: "Minimizing Fixed Mindset",
    // 'hsr': 'High School Readiness',
    perc_prof_math: "Math",
    susp_state: "Percent of Students Suspended",
    sel_stu_se: "Self-Efficacy",
    sel_stu_sm: "Self-Management",
    sel_stu_sa: "Social Awareness",
    cc_stf_ovr: "Staff Culture Climate",
    cc_stu_ovr: "Student Culture Climate",
    susp_core: "Suspensions",
    on_track_rate: "On-Track Status",
  },
  collegeMeasureCodeToName: {
    nsc_2yr4yr_enroll_fall:
      "NSC 2 & 4 Year College Fall Enroll Rate (First Year) (Preliminary)",
    nsc_2yr4yr_enroll_year:
      "NSC 2 & 4 Year College First Year Enroll Rate (Preliminary)",
    nsc_2yr4yr_persist_2yr:
      "NSC 2 & 4 Year College 2 Year Persistent Enrollment Rate (Preliminary)",
    nsc_2yr_enroll_fall:
      "NSC 2 Year College Fall Enroll Rate (First Year) (Preliminary)",
    nsc_2yr_enroll_year:
      "NSC 2 Year College First Year Enroll Rate (Preliminary)",
    nsc_2yr_persist_2yr:
      "NSC 2 Year College 2 Year Persistent Enrollment Rate (Preliminary)",
    nsc_4yr_enroll_fall:
      "NSC 4 Year College Fall Enroll Rate (First Year) (Preliminary)",
    nsc_4yr_enroll_year:
      "NSC 4 Year College First Year Enroll Rate (Preliminary)",
    nsc_4yr_grad_4yr: "NSC 4 Year College 4 Year Graduation Rate (Preliminary)",
    nsc_4yr_grad_5yr: "NSC 4 Year College 5 Year Graduation Rate (Preliminary)",
    nsc_4yr_grad_6yr: "NSC 4 Year College 6 Year Graduation Rate (Preliminary)",
    nsc_4yr_persist_2yr:
      "NSC 4 Year College 2 Year Persistent Enrollment Rate (Preliminary)",
  },
};
