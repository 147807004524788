import React, { useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import { IconButton, Tooltip, Typography } from "@material-ui/core";
import styles from "./styles";
import ChevronRight from "@material-ui/icons/ChevronRight";
import ChevronDown from "@material-ui/icons/ExpandMore";
import { getYears, handleOpen, handleSort, shownRows } from "./helpers";
import "./styles.css";
import arrow from "../../static/arrow.png";

const GrowthTable = (props) => {
  const [opened, setOpened] = useState({});
  const { selectedGroup, data, subgroups, districtOverall } = props;
  const years = getYears(data, selectedGroup);
  const [sort, setSort] = useState({
    type: years[years.length - 1],
    order: true,
  });

  const rows = shownRows(
    data,
    selectedGroup,
    opened,
    sort,
    years,
    districtOverall
  );
  const nYears = years.length;
  const nMax = districtOverall ? 2 : 3;
  const slimmedYears = years.reduce((slimmed, year, index) => {
    if (nYears > nMax) {
      if (nYears - index < nMax + 1) {
        return slimmed.concat(year);
      } else {
        return slimmed;
      }
    }
    return slimmed.concat(year);
  }, []);
  const width = districtOverall ? 30 : 45;
  const nYearsSlim = slimmedYears.length;
  const widthYears = nYearsSlim ? width / nYearsSlim : width;
  return (
    <Table className={props.classes.table}>
      <GrowthHeader
        districtOverall={districtOverall}
        years={slimmedYears}
        sort={sort}
        setSort={setSort}
        classes={props.classes}
      />
      <GrowthBody
        districtOverall={districtOverall}
        subgroups={subgroups}
        years={slimmedYears}
        widthYears={widthYears}
        opened={opened}
        setOpened={setOpened}
        rows={rows}
        {...props}
      />
    </Table>
  );
};

const GrowthHeader = (props) => {
  const { sort, setSort, years, districtOverall } = props;
  return (
    <TableHead>
      <TableRow>
        <TableCell className={props.classes.tableHead} style={{ width: "5%" }}>
          Expand Data
        </TableCell>
        {districtOverall && (
          <TableCell className={props.classes.tableHead}>
            <Grid justifyContent={"center"} alignItems={"center"} container item>
              <Grid item>District Name</Grid>
              <Grid item>
                <SortIcon
                  classes={props.classes}
                  setSort={setSort}
                  type={"district_name"}
                  sort={sort}
                />
              </Grid>
            </Grid>
          </TableCell>
        )}
        <TableCell
          className={props.classes.tableHead}
          style={{ minWidth: "250px" }}
        >
          <Grid justifyContent={"center"} alignItems={"center"} container item>
            <Grid item>School Name</Grid>
            <Grid item>
              <SortIcon
                classes={props.classes}
                setSort={setSort}
                type={"school_name"}
                sort={sort}
              />
            </Grid>
          </Grid>
        </TableCell>
        <TableCell className={props.classes.tableHead}>Grade</TableCell>
        <TableCell
          className={props.classes.tableHead}
          style={{ maxWidth: "100px" }}
        >
          Subgroup
        </TableCell>
        {years.map((year) => (
          <TableCell className={props.classes.tableHead}>
            <Grid justifyContent={"center"} alignItems={"center"} container item>
              <Grid item>
                20
                {`${year.charAt(0)}${year.charAt(1)}-20${year.charAt(
                  2
                )}${year.charAt(3)}`}
              </Grid>
              <Grid item>
                <SortIcon
                  classes={props.classes}
                  setSort={setSort}
                  type={year}
                  sort={sort}
                />
              </Grid>
            </Grid>
          </TableCell>
        ))}
        <TableCell className={props.classes.tableHead} style={{ width: "10%" }}>
          <Grid justifyContent={"center"} alignItems={"center"} container item>
            <Grid style={{ whiteSpace: "nowrap" }} item xs={8}>
              Difference
            </Grid>
            <Grid item>
              <SortIcon
                classes={props.classes}
                setSort={setSort}
                type={"change"}
                sort={sort}
              />
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

const GrowthBody = (props) => {
  const {
    selectedGroup,
    classes,
    opened,
    setOpened,
    rows,
    years,
    widthYears,
    subgroups,
    districtOverall,
  } = props;
  return (
    <TableBody>
      {!rows.length && (
        <TableRow
          style={{ border: "2px solid #BBCAD0", borderTop: "0px solid white" }}
        >
          <TableCell colSpan={5} className={classes.tableCell}>
            <Typography
              style={{
                paddingLeft: 20,
                color: "rgba(0,0,0,.1)",
                fontWeight: "900",
                fontSize: "16px",
              }}
            >
              No Data
            </Typography>
          </TableCell>
        </TableRow>
      )}
      {rows.map((row) => (
        <GrowthRow
          districtOverall={districtOverall}
          subgroups={subgroups}
          shownYears={years}
          widthYears={widthYears}
          classes={classes}
          row={row}
          opened={opened}
          setOpened={setOpened}
          selectedGroup={selectedGroup}
        />
      ))}
    </TableBody>
  );
};

const GrowthRow = ({
  districtOverall,
  subgroups,
  row,
  classes,
  opened,
  setOpened,
  selectedGroup,
  shownYears,
  widthYears,
}) => {
  const {
    layer,
    grade_level,
    subgroup,
    years,
    district_name,
    school_name,
    school_code,
    district_code,
  } = row;
  const codes = {
    district_code,
    school_code,
    grade_level,
  };

  return (
    <TableRow
      id={`${district_code}-${school_code}-${grade_level}-${subgroup}`}
      className={classes.tableRow + "  growthRow"}
    >
      <TableCell
        className={classes.tableCellHead}
        style={{ borderLeft: "0px solid white", padding: 0 }}
      >
        <Grid container item style={{ width: "100%" }}>
          <Grid item xs={6}>
            {layer === 0 ? (
              <IconButton
                onClick={() => {
                  handleOpen(codes, opened, setOpened, layer, 0);
                }}
                style={{ padding: 0 }}
              >
                {opened[school_code] ? (
                  <ChevronDown
                    className={"rotatingDown"}
                    style={{
                      fontSize: 36,
                      color: "rgba(61,114,135,.8)",
                    }}
                  />
                ) : (
                  <ChevronRight
                    className={"rotatingUp"}
                    style={{
                      fontSize: 36,
                      color: "rgba(61,114,135,.8)",
                    }}
                  />
                )}
              </IconButton>
            ) : null}
          </Grid>
          <Grid item xs={6}>
            {selectedGroup === "ALL" &&
            ((layer === 0 && opened[school_code]) || layer === 1) ? (
              <IconButton
                onClick={() => {
                  handleOpen(codes, opened, setOpened, layer, 1);
                }}
                style={{ padding: 0 }}
              >
                {opened[school_code][grade_level] !== undefined ? (
                  <ChevronDown
                    className={"rotatingDown"}
                    style={{
                      fontSize: 36,
                      color: "rgba(61,114,135,.8)",
                    }}
                  />
                ) : (
                  <ChevronRight
                    className={"rotatingUp"}
                    style={{
                      fontSize: 36,
                      color: "rgba(61,114,135,.8)",
                    }}
                  />
                )}
              </IconButton>
            ) : null}
          </Grid>
        </Grid>
      </TableCell>
      {districtOverall && (
        <TableCell
          title={layer === 0 ? district_name : null}
          className={classes.tableCellHead}
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textAlign: "left",
            maxWidth: "200px",
            maxHeight: 45,
            textOverflow: "ellipsis",
          }}
        >
          {layer === 0 ? district_name : ""}
        </TableCell>
      )}
      <TableCell
        title={layer === 0 ? school_name : null}
        className={classes.tableCellHead}
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textAlign: "left",
          maxWidth: "200px",
          maxHeight: 45,
          textOverflow: "ellipsis",
        }}
      >
        {layer === 0 ? school_name : ""}
      </TableCell>
      <TableCell className={classes.tableCellHead}>
        {layer === 1 || layer === 0 ? grade_level.split("Grade ")[1] : ""}
      </TableCell>
      <TableCell className={classes.tableCellHead}>
        <Tooltip
          placement={"top"}
          classes={{
            tooltip: classes.test,
            popper: classes.test,
          }}
          title={
            <div className={classes.growthTooltip} style={{ padding: 10 }}>
              {(subgroups[subgroup] || {})["name"] || subgroup}
            </div>
          }
        >
          <Typography className={classes.toolTipTypography}>
            {(subgroups[subgroup] || {})["alias"] || subgroup}
          </Typography>
        </Tooltip>
      </TableCell>
      {shownYears.map((year) => (
        <DataCell
          grade={grade_level}
          subgroup={(subgroups[subgroup] || { name: "" })["name"]}
          year={year}
          school={school_name}
          classes={classes}
          layer={layer}
          widthYears={widthYears}
          data={years[year]}
        />
      ))}
      <DataCell
        classes={classes}
        layer={layer}
        type={"change"}
        data={{
          prev:
            shownYears.length > 1
              ? years[shownYears[shownYears.length - 2]]
              : {},
          curr: years[shownYears[shownYears.length - 1]],
        }}
      />
    </TableRow>
  );
};

const DataCell = ({
  grade,
  school,
  year,
  subgroup,
  data,
  classes,
  layer,
  type,
  widthYears,
}) => {
  if (!data) {
    return (
      <TableCell
        className={classes.tableCell}
        style={{
          width: `${widthYears}%`,
          padding: "0px",
          paddingLeft: 20,
          color: "rgba(0,0,0,.1)",
          fontWeight: "900",
          fontSize: "16px",
        }}
      >
        No Data
      </TableCell>
    );
  }
  switch (type) {
    case "change": {
      const { prev, curr } = data;
      const prevMeasRate = (prev || {})["meas_rate"];
      const currMeasRate = (curr || {})["meas_rate"];
      if (prevMeasRate === undefined || currMeasRate === undefined) {
        return (
          <TableCell
            className={classes.tableCell}
            style={{
              width: "5%",
              padding: "0px",
              paddingLeft: 20,
              color: "rgba(0,0,0,.1)",
              fontWeight: "900",
              fontSize: "16px",
            }}
          >
            No Data
          </TableCell>
        );
      }
      const dist = currMeasRate - prevMeasRate;
      return (
        <TableCell
          className={classes.tableCell}
          style={{
            position: "relative",
            height: "100%",
            padding: 0,
            width: "5%",
          }}
        >
          <div
            // className={divClass} This line adds color and texture behind "Difference" column arrow in Growth Ordered Bar Charts
            style={{
              width: "50%",
              height: "100%",
              top: 0,
              left: "0%",
              position: "absolute",
              borderBottom: "1px solid white",
              opacity: layer === 0 ? 1 : layer === 1 ? 0.75 : 0.3,
            }}
          >
            {dist < 0 ? (
              <img src={arrow} className={classes.changeDownArrow} />
            ) : dist > 0 ? (
              <img src={arrow} className={classes.changeUpArrow} />
            ) : dist === 0 ? (
              <img src={arrow} className={classes.changeSideArrow} />
            ) : (
              ""
            )}
          </div>
          <Typography
            style={{
              textAlign: "center",
              width: "50%",
              left: "50%",
              position: "absolute",
              fontSize: layer ? 16 : 24,
              fontWeight: 800,
              zIndex: 5,
              top: layer ? "20%" : "10%",
            }}
          >
            {Math.abs(dist)}
          </Typography>
        </TableCell>
      );
    }
    default: {
      const { meas_rate, color, n_unique_stu, ci_upper, ci_lower } = data;
      return (
        <TableCell
          className={classes.tableCell}
          style={{
            position: "relative",
            height: "100%",
            padding: 0,
            width: `${widthYears}%`,
          }}
        >
          <Tooltip
            placement={"top"}
            classes={{
              tooltip: classes.test,
              popper: classes.test,
            }}
            title={
              <Grid container className={classes.growthTooltip}>
                <Grid
                  container
                  item
                  xs={12}
                  style={{ borderBottom: "2px solid #BBCAD0", padding: 5 }}
                >
                  <Grid item xs={12} style={{ paddingBottom: 5, fontSize: 16 }}>
                    {school}
                  </Grid>
                  <Grid item xs={12} style={{ paddingBottom: 5 }}>
                    {grade} -{" "}
                    {`20${year.charAt(0)}${year.charAt(1)}-20${year.charAt(
                      2
                    )}${year.charAt(3)}`}
                  </Grid>
                  <Grid item xs={12}>
                    {subgroup}
                  </Grid>
                </Grid>
                <Grid item style={{ padding: 5 }} xs={12}>
                  Growth Percentile: {meas_rate}%
                </Grid>
                <Grid item style={{ padding: 5 }} xs={12}>
                  Confidence Interval: {ci_lower}-{ci_upper}
                </Grid>
                <Grid item style={{ padding: 5 }} xs={12}>
                  Number of Students: {n_unique_stu}
                </Grid>
              </Grid>
            }
          >
            <div
              style={{
                width: "100%",
                opacity: 0,
                position: "absolute",
                left: 0,
                top: 0,
                zIndex: 5,
                height: "100%",
              }}
            />
          </Tooltip>
          <div
            className={classes[`${color}Bar`]}
            style={{
              opacity: layer === 0 ? 1 : layer === 1 ? 0.75 : 0.3,
              position: "absolute",
              left: 0,
              top: 5,
              zIndex: 4,
              width: `${meas_rate}%`,
              height: "80%",
            }}
          ></div>
          <Typography
            style={{
              position: "absolute",
              fontSize: layer ? 16 : 24,
              fontWeight: 800,
              zIndex: 5,
              top: layer ? "20%" : "10%",
              right: "5px",
            }}
          >
            {meas_rate}
          </Typography>
        </TableCell>
      );
    }
  }
};

const SortIcon = ({ sort, type, setSort, classes }) => {
  const topColor =
    type === sort.type && sort.order
      ? "rgba(29,94,119,.75)"
      : "rgba(0,0,0,.25)";
  const bottomColor =
    type === sort.type && !sort.order
      ? "rgba(29,94,119,.75)"
      : "rgba(0,0,0,.25)";
  return (
    <svg
      onClick={() => {
        handleSort(sort, setSort, type);
      }}
      viewBox={"0 0 24 24"}
      className={classes.sortIcon}
      style={{}}
    >
      <path
        fill={topColor}
        strokeLinejoin={"miter"}
        shapeRendering={"geometricPrecision"}
        strokeWidth={0}
        d={"M12 2 L 18 10 L 6 10 L 12 2z"}
      />
      <path
        fill={bottomColor}
        strokeLinejoin={"miter"}
        shapeRendering={"geometricPrecision"}
        strokeWidth={0}
        d={"M12 22 L 18 14 L 6 14 L 12 22z"}
      />
    </svg>
  );
};

export default withStyles(styles, { withTheme: true })(GrowthTable);
