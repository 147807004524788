import React from "react";
import { echartHelper, seriesFormatter } from "../Helpers/EchartHelpers";
import { value } from "lodash/seq";

/**
 *  Creates an echart for a stacked barchart
 * @param props.height: height of the chart
 * @param props.id: id for the element we are referring to
 * @param props.data: data that is going to be displayed as well as how it is going to be displayed
 * EX: DATA FORMAT:
 * {
 *         xAxisLabels: ['17-18', '18-19', '19-20', '20-21'],
 *         legendOrder: ['Post-Secondary_Competitive', 'Post-Secondary_Promising'],
 *         colors: {
 *             'Post-Secondary_Competitive': '#127EA8',
 *             ' Post-Secondary_Promising': '#67B948'
 *         },
 *         data: {
 *             '17-18': {
 *                 'Post-Secondary_Competitive': {
 *                     name: 'Post-Secondary Competitive',
 *                     value: 10
 *                 },
 *                 'Post-Secondary_Promising': {
 *                     name: 'Post-Secondary Promising',
 *                     value: 13
 *                 }
 *             }
 *             , '18-19': {
 *                 'Post-Secondary_Competitive': {
 *                     name: 'Post-Secondary Competitive',
 *                     value: 10
 *                 },
 *                 'Post-Secondary_Promising': {
 *                     name: 'Post-Secondary Promising',
 *                     value: 13
 *                 }
 *             }
 *         }
 *     }
 * @returns {JSX.Element}
 * @author Justin Keeling & Alex Harry
 */
export const StackedBarchart = (props) => {
  const { id, height, data } = props;

  const seriesDefaultFormat = {
    silent: false,
    name: "",
    color: "",
    type: "bar",
    stack: "total",
    label: {
      show: true,
      formatter: "{c}%",
      silent: true,
      textStyle: {
        fontFamily: "Roboto",
      },
    },
    backgroundStyle: {
      shadowBlur: 0,
    },
    data: [],
  };
  const seriesObject = seriesFormatter({ data, seriesDefaultFormat });
  const option = {
    animation: false,
    legend: {
      show: false,
    },
    tooltip: {
      position: function (point, params) {
        if (params["dataIndex"] > 2) {
          return "left";
        } else {
          return "right";
        }
      },
      formatter: function (params) {
        const legendVal = params["seriesName"].split(" ").join("_");
        const x_axis_label = params["name"];
        const numerator = data["data"][x_axis_label][legendVal]["numerator"];
        return `${params["seriesName"]}<br/>Number of Students: ${numerator}`;
      },
      transitionDuration: 0,
    },
    yAxis: {
      max: 100,
      min: 0,
      interval: 10,
      type: "value",
      name: "Percent",
      nameLocation: "center",
      nameGap: 60,
      nameTextStyle: {
        fontSize: 20,
        color: "#5676AC",
        fontFamily: "Roboto",
      },
      axisLabel: {
        formatter: "{value}%",
        fontFamily: "Roboto",
        fontSize: "12px",
      },
    },
    xAxis: {
      type: "category",
      data: data ? data.xAxisLabels : [],
      axisLabel: {
        fontSize: 14,
        color: "#5676AC",
        fontWeight: "bold",
        fontFamily: "Roboto",
      },
      axisTick: {
        show: false,
      },
    },
    grid: {
      left: 100,
      right: 25,
    },

    series: seriesObject,
  };

  return echartHelper({ option, id, height, width: "100%" });
};
