import React, { Component } from "react";
import Plot from "react-plotly.js";

export default class ScatterPlot extends Component {
  render() {
    let data = {
      x: [],
      y: [],
      color: [],
      text: [],
    };
    const big = {
      x: [],
      y: [],
      color: [],
    };
    let i = 0;
    for (i; i < 50; i++) {
      const newX = Math.random() * 20;
      const newY = Math.random() * 20;
      data.x.push(newX);
      data.y.push(newY);
      data.text.push(["Hello", "Hello", "Hello"]);
      big.x.push(newX + 20);
      big.y.push(newY);
      const r = Math.floor(Math.random() * 255);
      const g = Math.floor(Math.random() * 255);
      const b = Math.floor(Math.random() * 255);
      const color = `rgb(${r},${g},${b})`;
      data.color.push(color);
      big.color.push(color);
    }
    return (
      <Plot
        onHover={(data) => {}}
        data={[
          {
            x: data.x,
            y: data.y,
            mode: "markers",
            type: "scatter",
            name: "Test",
            text: data.text,
            marker: {
              size: 6,
              color: data.color,
            },
          },
          {
            x: big.x,
            y: big.y,
            mode: "markers",
            type: "scatter",
            name: "Big",
            marker: {
              size: 12,
              color: big.color,
            },
          },
        ]}
      />
    );
  }
}
