const gradeOrder = [
  "Grade Overall",
  "Grade 12",
  "Grade 11",
  "Grade 10",
  "Grade 9",
  "Grade 8",
  "Grade 7",
  "Grade 6",
  "Grade 5",
  "Grade 4",
  "Grade 3",
  "Grade 2",
  "Grade 1",
];

export const getYears = (data, group) => {
  let years = {};
  Object.keys(data || {}).forEach((district) => {
    const districtData = data[district] || { name: "", data: {} };
    Object.keys(districtData["data"]).forEach((school) => {
      const schoolData = districtData["data"][school] || { name: "", data: {} };
      Object.keys(schoolData["data"]).forEach((grade) => {
        const gradeData = schoolData["data"][grade];
        Object.keys(gradeData).forEach((subgroup) => {
          if (group === "ALL" || subgroup === group) {
            const subgroupYears = gradeData[subgroup];
            Object.keys(subgroupYears).forEach((year) => {
              years[year] = "";
            });
          }
        });
      });
    });
  });
  return Object.keys(years);
};

// Generate a list of rows shown based on opened rows, assigning layers as we dig through
/**
 *
 * @param data
 * @param group
 * @param opened
 * @returns {Array}
 */
export const shownRows = (
  data,
  group,
  opened,
  sort,
  years,
  districtOverall
) => {
  const { type, order } = sort;
  const prevYear = years.length > 1 ? years[years.length - 2] : "";
  const currYear = years.length ? years[years.length - 1] : "";
  // Create a list of top levels
  const schoolList =
    (
      Object.keys(data || {})
        .reduce((rows, district_code) => {
          const districtData = data[district_code] || { name: "", data: {} };
          const school_list = Object.keys(districtData["data"] || {}).map(
            (school_code) => {
              return {
                ...districtData["data"][school_code],
                school_code,
                district_code,
                district_name: districtData["name"],
              };
            }
          );
          return rows.concat(school_list);
        }, [])
        .sort((a, b) => {
          const aData = a;
          const bData = b;
          switch (type) {
            case "change": {
              const aSubgroupDataPrev = ((aData["data"]["Grade Overall"] || {})[
                group
              ] || {})[prevYear] || { meas_rate: null };
              const aSubgroupData = ((aData["data"]["Grade Overall"] || {})[
                group
              ] || {})[currYear] || { meas_rate: null };
              const bSubgroupDataPrev = ((bData["data"]["Grade Overall"] || {})[
                group
              ] || {})[prevYear] || { meas_rate: null };
              const bSubgroupData = ((bData["data"]["Grade Overall"] || {})[
                group
              ] || {})[currYear] || { meas_rate: null };
              const aNull =
                aSubgroupData["meas_rate"] === null ||
                aSubgroupDataPrev["meas_rate"] === null;
              const bNull =
                bSubgroupData["meas_rate"] == null ||
                bSubgroupDataPrev["meas_rate"] === null;
              const both = aNull && bNull;
              if (both) {
                return 1;
              } else if (aNull) {
                return 1;
              } else if (bNull) {
                return -1;
              } else {
                const aDist =
                  aSubgroupData["meas_rate"] - aSubgroupDataPrev["meas_rate"];
                const bDist =
                  bSubgroupData["meas_rate"] - bSubgroupDataPrev["meas_rate"];
                return order ? bDist - aDist : aDist - bDist;
              }
            }
            case "district_name":
              return order
                ? aData["district_name"].localeCompare(bData["district_name"])
                : bData["district_name"].localeCompare(aData["district_name"]);
            case "school_name":
              return order
                ? aData["name"].localeCompare(bData["name"])
                : bData["name"].localeCompare(aData["name"]);
            default: {
              const aSubgroupData = ((aData["data"]["Grade Overall"] || {})[
                group
              ] || {})[type] || { meas_rate: null };
              const bSubgroupData = ((bData["data"]["Grade Overall"] || {})[
                group
              ] || {})[type] || { meas_rate: null };
              const aNull = aSubgroupData["meas_rate"] === null;
              const bNull = bSubgroupData["meas_rate"] == null;
              const both = aNull && bNull;
              if (both) {
                return 1;
              } else if (aNull) {
                return 1;
              } else if (bNull) {
                return -1;
              } else {
                return order
                  ? bSubgroupData["meas_rate"] - aSubgroupData["meas_rate"]
                  : aSubgroupData["meas_rate"] - bSubgroupData["meas_rate"];
              }
            }
          }
        }) || []
    ).map((curr) => {
      const { school_code, district_code } = curr;
      return {
        school_code,
        district_code,
      };
    }) || [];
  const rows = schoolList.reduce((rows, keys) => {
    const { school_code, district_code } = keys;
    const districtData = data[district_code] || { name: "", data: {} };
    const school_key_list = [school_code];
    const schoolRows = school_key_list.reduce((schoolRows, school) => {
      // School Data  is list of grades within a school
      const schoolData = districtData["data"][school] || { name: "", data: {} };
      const gradeRows = gradeOrder.reduce((gradeRows, grade) => {
        const gradeData = schoolData["data"][grade] || null;
        const groups =
          group === "ALL"
            ? [group].concat(
                Object.keys(gradeData || {}).filter(
                  (subgroup) => subgroup !== group
                )
              )
            : [group];
        const subgroupRows = gradeData
          ? groups.reduce((subgroupRows, subgroup) => {
              const subgroupData = gradeData[subgroup] || null;
              if (!subgroupData) {
                return subgroupRows;
              }
              const districtName = districtData["name"];
              const schoolName = schoolData["name"];
              const layer =
                grade === "Grade Overall" && subgroup === group
                  ? 0
                  : subgroup === group
                  ? 1
                  : 2;
              const showRow =
                layer === 0
                  ? true
                  : layer === 1
                  ? group === subgroup && opened[school]
                  : (opened[school] || {})[grade] !== undefined;
              const rowData = {
                district_name: districtName,
                school_name: schoolName,
                school_code: school_code,
                district_code: district_code,
                subgroup,
                grade_level: grade,
                layer,
                years: {
                  ...subgroupData,
                },
              };
              return showRow ? subgroupRows.concat(rowData) : subgroupRows;
            }, [])
          : [];
        const sortedRows =
          subgroupRows.sort((aData, bData) => {
            if (aData["layer"] > bData["layer"]) {
              return 1;
            } else if (bData["layer"] > aData["layer"]) {
              return -1;
            } else {
              switch (type) {
                case "change": {
                  const aSubgroupDataPrev = aData["years"][prevYear] || {
                    meas_rate: null,
                  };
                  const aSubgroupData = aData["years"][currYear] || {
                    meas_rate: null,
                  };
                  const bSubgroupDataPrev = bData["years"][prevYear] || {
                    meas_rate: null,
                  };
                  const bSubgroupData = bData["years"][currYear] || {
                    meas_rate: null,
                  };
                  const aNull =
                    aSubgroupData["meas_rate"] === null ||
                    aSubgroupDataPrev["meas_rate"] === null;
                  const bNull =
                    bSubgroupData["meas_rate"] == null ||
                    bSubgroupDataPrev["meas_rate"] === null;
                  const both = aNull && bNull;
                  if (both) {
                    return 1;
                  } else if (aNull) {
                    return 1;
                  } else if (bNull) {
                    return -1;
                  } else {
                    const aDist =
                      aSubgroupData["meas_rate"] -
                      aSubgroupDataPrev["meas_rate"];
                    const bDist =
                      bSubgroupData["meas_rate"] -
                      bSubgroupDataPrev["meas_rate"];
                    return order ? bDist - aDist : aDist - bDist;
                  }
                }
                case "school_name":
                  return 1;
                default: {
                  const aSubgroupData = aData["years"][type] || {
                    meas_rate: null,
                  };
                  const bSubgroupData = bData["years"][type] || {
                    meas_rate: null,
                  };
                  const aNull = aSubgroupData["meas_rate"] === null;
                  const bNull = bSubgroupData["meas_rate"] == null;
                  const both = aNull && bNull;
                  if (both) {
                    return 1;
                  } else if (aNull) {
                    return 1;
                  } else if (bNull) {
                    return -1;
                  } else {
                    return order
                      ? bSubgroupData["meas_rate"] - aSubgroupData["meas_rate"]
                      : aSubgroupData["meas_rate"] - bSubgroupData["meas_rate"];
                  }
                }
              }
            }
          }) || [];
        return gradeRows.concat(sortedRows);
      }, []);
      return schoolRows.concat(gradeRows);
    }, []);
    return rows.concat(schoolRows);
  }, []);
  return rows;
};

export const handleOpen = (codes, opened, setOpened, layer, level) => {
  const { school_code, district_code, grade_level } = codes;
  switch (layer) {
    case 0: {
      switch (level) {
        case 0: {
          if (opened[school_code]) {
            const newOpened = { ...opened };
            delete newOpened[school_code];
            setOpened({ ...newOpened });
          } else {
            setOpened({
              ...opened,
              [school_code]: {},
            });
          }
          break;
        }
        case 1: {
          if (opened[school_code][grade_level] !== undefined) {
            const newOpened = { ...opened };
            delete newOpened[school_code][grade_level];
            setOpened({ ...newOpened });
          } else {
            setOpened({
              ...opened,
              [school_code]: {
                ...opened[school_code],
                [grade_level]: "",
              },
            });
          }
          break;
        }
        default:
          break;
      }
      break;
    }
    case 1: {
      switch (level) {
        case 1: {
          if (opened[school_code][grade_level] !== undefined) {
            const newOpened = { ...opened };
            delete newOpened[school_code][grade_level];
            setOpened({ ...newOpened });
          } else {
            setOpened({
              ...opened,
              [school_code]: {
                ...opened[school_code],
                [grade_level]: "",
              },
            });
          }
          break;
        }
        default:
          break;
      }
      break;
    }
    default:
      break;
  }
};

export const handleSort = (sort, setSort, type) => {
  if (sort.type !== type) {
    setSort({
      type,
      order: true,
    });
  } else {
    setSort({
      ...sort,
      order: !sort.order,
    });
  }
};
