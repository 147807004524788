import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import CssBaseline from "@material-ui/core/CssBaseline";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import red from "@material-ui/core/colors/red";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import coreLogo from "../assets/images/coreLogo.png";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import { connect } from "react-redux";
import {compose} from "redux"
import { login, test } from "./actions";
import CommitId from "../System/CommitId";

const API = process.env.REACT_APP_API;

const styles = (theme) => ({
  main: {
    width: "auto",
    height: "calc(100vh - 220px)",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  Media: {
    height: "100%",
    width: "100%",
  },
  card: {
    maxWidth: 800,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  actions: {
    display: "flex",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
});

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { expanded: false };
  }

  handleExpandClick = () => {
    this.setState((state) => ({ expanded: !state.expanded }));
  };
  handleChange = (event) => {
    const {
      target: { value, name },
    } = event;
    this.setState({
      [name]: value,
    });
  };
  handleSubmit = () => {
    const { login } = this.props;
    const { email, password } = this.state;
    const input = { email, password };
    login(input);
  };
  render() {
    const { classes, tester } = this.props;

    return (
      <main className={classes.main}>
        <CssBaseline />
        <Card className={classes.card}>
          <CardHeader title="Core Districts Login" />
          <CardMedia
            style={{ backgroundSize: "contain" }}
            className={classes.media}
            image={coreLogo}
          />
          <CardContent>
            <Typography component="p">
              <a style={{ width: "100%" }} href={API + "/auth/saml/login"}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Click HERE to login from Core Districts
                </Button>
              </a>
            </Typography>
          </CardContent>
          <CardActions className={classes.actions} disableActionSpacing>
            <IconButton
              className={classnames(classes.expand, {
                [classes.expandOpen]: this.state.expanded,
              })}
              onClick={this.handleExpandClick}
              aria-expanded={this.state.expanded}
              aria-label="Show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          </CardActions>
          <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <Paper className={classes.paper}>
                <Typography component="h1" variant="h5" />
                <form
                  style={{ margin: "1rem", paddingBottom: ".75rem" }}
                  className={classes.form}
                  onSubmit={this.handleSubmit}
                >
                  <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="email">Email Address</InputLabel>
                    <Input
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                      id="email"
                      name="email"
                      autoComplete="email"
                      autoFocus
                    />
                  </FormControl>
                  <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="password">Password</InputLabel>
                    <Input
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                      name="password"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                    />
                  </FormControl>
                  <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Remember me"
                  />
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      this.handleSubmit();
                    }}
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Local Login
                  </Button>
                </form>
              </Paper>
              <CommitId />
            </CardContent>
          </Collapse>
        </Card>
      </main>
    );
  }
}

const mapLogin = (state) => {
  return {};
};

const mapDispatch = (dispatch) => {
  return {
    login: (input) => {
      dispatch(login(input));
    },
  };
};

LoginForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapLogin, mapDispatch)
)(LoginForm);
