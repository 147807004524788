import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import { compose } from "redux";
import connect from "react-redux/es/connect/connect";
import { visualizationActions } from "./actions";
import { visualizationHelpers } from "./helpers";
import Plot from "react-plotly.js";
import withStyles from "@material-ui/core/styles/withStyles";
import TableTitle from "../StyledComponents/Table/TitleTable";
import Grid from "@material-ui/core/Grid";
import Card from "../StyledComponents/Card/Card";
import CardHeader from "../StyledComponents/Card/CardHeader";
import CardBody from "../StyledComponents/Card/CardBody";

const styles = (theme) => ({
  tableMargin: {
    marginTop: "0px",
    marginBottom: "0px",
    marginLeft: "2rem",
    marginRight: "2rem",
  },
});
class DataCompleteness extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialized: false,
      measureLevel: null,
      selectedSchool: null,
      selectedYear: null,
      chart: {},
      sunburst: {
        text: ["Basic"],
        layers: [],
      },
    };
    this.updateSunburst = this.updateSunburst.bind(this);
    this.handleHover = this.handleHover.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }
  updateSunburst = (newSunburst) => {
    this.setState({ sunburst: newSunburst });
  };

  handleClick = (canvas, e) => {
    const { sunburst, measureLevel, selectedSchool, selectedYear } = this.state;
    const { completeness } = this.props;
    const selected = {
      measureLevel,
      selectedSchool,
      selectedYear,
    };
    const hovered = visualizationHelpers.canvasMouseOver({
      canvas,
      e,
      sunburst,
    });
    const hoveredItem = hovered
      ? visualizationHelpers.getItemFromId({ sunburst, itemId: hovered })
      : null;
    const newSunburst = hoveredItem
      ? visualizationHelpers.createSunburstLayers({
          selected,
          data: completeness,
        })
      : sunburst;
    const chart = visualizationHelpers.createCompletionChart({
      selected,
      data: completeness,
    });
    if (hoveredItem) {
      this.setState({
        sunburst: newSunburst,
      });
    }
    visualizationHelpers.drawSunburst({
      selected,
      sunburst: newSunburst,
      drawIds: false,
    });
  };

  handleHover = (canvas, e) => {
    const { level, school, year } = this.props;
    const { sunburst } = this.state;
    const selected = {
      measureLevel: level,
      selectedSchool: school,
      selectedYear: year,
    };
    const hovered = visualizationHelpers.canvasMouseOver({
      canvas,
      e,
      sunburst,
    });
    const hoveredItem = hovered
      ? visualizationHelpers.getItemFromId({ sunburst, itemId: hovered })
      : null;
    const newSunburst = !hoveredItem
      ? sunburst
      : {
          ...sunburst,
          text: hoveredItem.text,
        };
    if (hoveredItem) {
      this.setState({
        sunburst: newSunburst,
      });
    }
    visualizationHelpers.drawSunburst({
      selected,
      sunburst: newSunburst,
      drawIds: false,
    });
  };

  componentWillUpdate = (nextProps, nextState, snapshot) => {
    const { measureLevel, selectedSchool, initialized } = nextState;
    const {
      district,
      completeness,
      year,
      level,
      school,
      network,
      subgroups,
      measures,
    } = nextProps;
    const prevDistrict = this.props.district;
    if (prevDistrict !== district) {
      this.props.getData(district);
    }
    if (
      initialized &&
      (this.props.network !== network ||
        this.props.district !== district ||
        this.props.school !== school ||
        this.props.year !== year ||
        this.props.level !== level)
    ) {
      this.setState({
        initialized: false,
      });
    }
    if (!initialized && Object.keys(completeness).length) {
      const selected = {
        selectedSchool: school,
        selectedYear: year,
        measureLevel: level,
      };
      const heatmap = school === "District Overall";
      const nSchools = completeness[level]
        ? Object.keys(completeness[level]).length
        : 1;
      const sunburst = visualizationHelpers.createSunburstLayers({
        selected,
        data: completeness,
        heatmap,
        nSchools,
        subgroups,
        measures,
      });
      visualizationHelpers.drawSunburst({ selected, sunburst, drawIds: false });
      const chart = !heatmap
        ? visualizationHelpers.createCompletionChart({
            selected,
            data: completeness,
          })
        : visualizationHelpers.createCompletionHeatmap({
            selected,
            data: completeness,
          });
      // visualizationHelpers.drawCompletionChart({chart,heatmap, nSchools, measures})
      const canvas = document.getElementById("canvas_0");
      this.setState({
        initialized: true,
        sunburst,
        chart,
      });
      canvas.onmousemove = function (e) {
        this.handleHover(canvas, e);
      }.bind(this);
    }
  };

  plotChart = (chart, heatmap, nSchools) => {
    const valid = Object.keys(chart).length;
    if (!valid) {
      return {
        plotData: {},
        xVals: [],
        yVals: [],
      };
    }
    const { groups, demographics } = chart;
    const xVals = Object.keys(demographics);
    const yVals = Object.keys(groups).reduce((array, curr) => {
      const group = groups[curr];
      return array.concat(Object.keys(group));
    }, []);
    const plotData = xVals.reduce(
      (plot, subgroup) => {
        Object.keys(groups).forEach((key) => {
          const group = groups[key];
          Object.keys(group).forEach((measureKey) => {
            const measure = group[measureKey] || {};
            const groupVal = measure[subgroup] || 0;
            plot.x.push(subgroup);
            plot.y.push(measureKey);
            if (heatmap) {
              const percent = Math.round(100 * (groupVal / nSchools));
              const c = Math.abs(50 - percent);
              const b = 2.55 * Math.round(100 - percent);
              const r = 2.55 * Math.round(percent);
              const color = `rgb(${r},${0},${b})`;
              plot.marker.color.push(color);
              plot.text.push(
                `<b>Percent Complete Across District: ${percent}</b>`
              );
            } else {
              const color = groupVal ? "rgb(0,0,0)" : "rgb(255,255,255)";
              plot.marker.color.push(color);
              const available = groupVal ? "True" : "False";
              plot.text.push(`<b>Available: ${available}</b>`);
            }
          });
        });
        return plot;
      },
      {
        type: "scatter",
        mode: "markers",
        x: [],
        y: [],
        text: [],
        marker: {
          color: [],
          symbol: "square",
          size: 9,
          line: {
            width: 1,
            color: "rgb(0,0,0)",
          },
        },
      }
    );
    return { plotData, xVals, yVals };
  };

  render() {
    const height = window.innerHeight;
    const width = window.innerWidth;
    const { sunburst, chart } = this.state;
    const { classes, school, level, completeness, measures, subgroups } =
      this.props;
    const nSchools = completeness[level]
      ? Object.keys(completeness[level]).length
      : 1;
    const heatmap = school === "District Overall";
    const { plotData, xVals, yVals } = this.plotChart(chart, heatmap, nSchools);
    return (
      <Grid
        style={{ margin: "2.5%", width: "95%" }}
        justifyContent={"space-evenly"}
        container
        direction={"row"}
      >
        <TableTitle school={{}} district={{}} pageTitle={"Data Completion"} />
        <Grid item xs={12}>
          <Card>
            <CardHeader className={classes.gradientBackground} color="primary">
              {/* Left the Next Card Header in Place to bring back Primary Color at a later time*/}
              {/*<CardHeader color="primary" >*/}
              <h4
                className={classes.cardTitleBlue}
                style={{ fontSize: "2vh", margin: "10px" }}
              >
                {"Completion Chart"}
              </h4>
              {/*<p className={classes.cardCategoryWhite}>*/}
              {/*	Here is a subtitle for this table*/}
              {/*</p>*/}
            </CardHeader>
            <CardBody
              id={"card-body"}
              style={{
                width: "100%",
                height: `${0.8 * height}px`,
                padding: "0px",
              }}
            >
              <Plot
                style={{ width: "100%", height: "100%" }}
                data={[plotData]}
                layout={{
                  hovermode: "closest",
                  margin: {
                    r: 100,
                    l: 250,
                    t: 25,
                    b: 50,
                  },
                  yaxis: {
                    showgrid: false,
                    tickvals: yVals,
                    ticktext: yVals.map((val) => {
                      const { name } = measures[val] || { name: val };
                      return `<b>${name}</b>`;
                    }),
                    linewidth: 6,
                    linecolor: "rgb(9, 75, 101)",
                  },
                  xaxis: {
                    showgrid: false,
                    tickvals: xVals,
                    ticktext: xVals.map((val) => {
                      const { name } = subgroups[val] || { name: val };
                      return `<b>${val}</b>`;
                    }),
                    linewidth: 6,
                    linecolor: "rgb(9, 75, 101)",
                  },
                }}
              />
              {/*<canvas style={{positon:'fixed', left:'100%'}} id={'canvas_1'} height={.8*height} width={.35*width}></canvas>*/}
            </CardBody>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader className={classes.gradientBackground} color="primary">
              {/* Left the Next Card Header in Place to bring back Primary Color at a later time*/}
              {/*<CardHeader color="primary" >*/}
              <h4
                className={classes.cardTitleBlue}
                style={{ fontSize: "2vh", margin: "10px" }}
              >
                {"Sunburst"}
              </h4>
              {/*<p className={classes.cardCategoryWhite}>*/}
              {/*	Here is a subtitle for this table*/}
              {/*</p>*/}
            </CardHeader>
            <CardBody
              id={"card-body"}
              style={{ width: "100%", padding: "0px" }}
            >
              <canvas
                id={"canvas_0"}
                height={0.8 * height}
                width={0.8 * width}
              ></canvas>
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    );
  }
}
const findInMenu = (array, key) => {
  const res = {
    code: "",
    text: "",
  };
  if (!array) {
    return res;
  }
  return array.reduce((found, curr) => {
    if (curr.code === key) {
      found = curr;
    }
    return found;
  }, res);
};
const mapState = (state) => {
  const { selected } = state.menu;
  const year = selected["School Year"];
  const level = selected["School Level"];
  const {
    menu: {
      menuObject,
      selected: { Network, District, School },
    },
    constants: { collegeMeasures, measures, subgroups },
    visualizations: { completeness },
  } = state;
  const fullMeasures = {};
  Object.keys(collegeMeasures).forEach((measure) => {
    fullMeasures[measure] = collegeMeasures[measure];
  });
  Object.keys(measures).forEach((measure) => {
    fullMeasures[measure] = measures[measure];
  });
  return {
    network: Network,
    district: District,
    school: findInMenu(menuObject["School"], School).text,
    year,
    level,
    measures: fullMeasures,
    subgroups,
    completeness,
  };
};

const mapDispatch = (dispatch) => {
  return {
    getData: (district) => {
      dispatch(visualizationActions.getCompletness(district));
    },
  };
};

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapState, mapDispatch)
)(DataCompleteness);
