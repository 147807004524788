import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import coreImg from "../../assets/images/coreLogo.png";
import TableCell from "@material-ui/core/TableCell";
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import tableStyle from "../../assets/jss/core-dashboard/components/tableStyle";
function RenderCell({ ...props }) {
  const { classes } = props;
  return (
    <Grid spacing={8} style={{ margin: "10px" }} container>
      <Grid item>
        <Typography className={classes.noData}>Fetching Data</Typography>
      </Grid>
      <Grid item>
        <img
          src={coreImg}
          style={{ height: "40px", width: "40px" }}
          className={"rotatingImage"}
        />
      </Grid>
    </Grid>
  );
}

export default withStyles(tableStyle)(RenderCell);
