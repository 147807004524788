import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import "core-js/es/set";
import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import {createTheme} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import * as serviceWorker from "./serviceWorker";
import store from "./store";
import App from "./App";
const API = process.env.REACT_APP_API;
const theme = createTheme({
  palette: {
    primary: {
      main: "#1d5e77", //This is the darker blue used in the original CORE AppBar
      light: "#5590b5", //This is the blue used in the CORE Logo
    },
    secondary: {
      main: "#67b948", //This is the green used in the CORE Logo ...see end of page for additional colors
      //light: "#F5a68d"
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1024,
      lg: 1280,
      xl: 1435, //Custom xl breakpoint set for AppBar scalability
    },
  },
});

const MuiApp = () => (
  <MuiThemeProvider theme={theme}>
    <Provider store={store}>
      <Fragment>
        <CssBaseline />
        <a id={"saml-click"} href={`${API}/auth/saml/login`} />
        <div id={"hoverinfo"} />
        <App />
      </Fragment>
    </Provider>
  </MuiThemeProvider>
);
ReactDOM.render(<MuiApp />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

//CORE Logo Colors
//Blue - #5590b5
//Green - #67b948
//Orange - #f79c1c

//LL Logo Colors:
//Blue - #00b5cc
//Yellow - #f5e781
//Reddish/Orange - #e86d1f

//Additional Site Colors
//CORE AppBar Blue - #1d5e77
//CORE INDEX Table Text (Blue) - #618d9f
//CORE INDEX Table Red - #ef4b4b
//CORE INDEX Table Orange - #f4861f
//CORE INDEX Table Green - #67b948
