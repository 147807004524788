import axios from "axios";

const API = process.env.REACT_APP_API;

export const FETCH_METRICS = "FETCH_METRICS";
export const FETCH_METRICS_SUCCESS = "FETCH_METRICS_SUCCESS";

export const metricActions = {
  getIndexMetrics,
  getFiveByFiveSchool,
  getGrowthSchoolReport,
  getMetricTrends,
  getOnDemandScatter,
  getCollegeDistribution,
  getCollegeBarPlot,
  getSchoolSummary,
  getLongitudinalView,
  getPredictiveAnalytics,
};

function fetchMetric() {
  return { type: FETCH_METRICS };
}

function fetchMetricSuccess(metrics, name) {
  return { type: FETCH_METRICS_SUCCESS, metrics, name };
}

function getIndexMetrics(
  selected,
  publicRoute,
  lcapOverview,
  fivebyfiveStudents
) {
  return (dispatch) => {
    dispatch(fetchMetric());
    let params = "?";
    const list = publicRoute
      ? [
          "Network",
          "District",
          "School Level",
          "School",
          "School Year",
          "Pathway",
        ]
      : lcapOverview
      ? [
          "Network",
          "District",
          "School Level",
          "School",
          "School Year",
          "Pathway",
          "Student Groups",
        ]
      : fivebyfiveStudents
      ? [
          "Network",
          "District",
          "School Level",
          "School",
          "School Year",
          "Pathway",
          "Measure",
        ]
      : [
          "Network",
          "District",
          "School Level",
          "School",
          "School Year",
          "Pathway",
        ];
    list.forEach((key) => {
      params += `${key}=${selected[key]}&`;
    });
    const route = publicRoute
      ? "public"
      : lcapOverview
      ? "lcapoverview"
      : fivebyfiveStudents
      ? "fivebyfivestudents"
      : "index";
    axios.get(API + `/metrics/${route}${params}`).then((response) => {
      const { data } = response;
      dispatch(fetchMetricSuccess(data, "metrics"));
    });
  };
}

function getFiveByFiveSchool(selected, publicRoute) {
  let params = "?";
  [
    "Network",
    "District",
    "School Level",
    "School Year",
    "Data Points",
    "Measure",
    // "Student Groups"
  ].forEach((key) => {
    params += `${key}=${selected[key]}&`;
  });
  const route = publicRoute ? "public/" : "";
  return (dispatch) => {
    dispatch(fetchMetric());
    axios
      .get(API + `/metrics/${route}fivebyfiveschools${params}`)
      .then((response) => {
        const { data } = response;
        dispatch(fetchMetricSuccess(data, "schools5x5"));
      });
  };
}

function getGrowthSchoolReport(selected, type) {
  let params = "?";
  [
    "Network",
    "District",
    "School Level",
    "School",
    "School Year",
    "Student Groups",
    "Growth Subject",
  ].forEach((key) => {
    params += `${key}=${selected[key]}&`;
  });
  return (dispatch) => {
    dispatch(fetchMetric());
    switch (type) {
      case "SCHOOL_REPORT": {
        axios
          .get(API + `/metrics/growth/schoolreport${params}`)
          .then((response) => {
            const { data } = response;
            dispatch(fetchMetricSuccess(data, "growthSchoolReport"));
          });
        break;
      }
      case "SCATTER_PLOT": {
        axios
          .get(API + `/metrics/growth/scatterplot${params}`)
          .then((response) => {
            const { data } = response;
            dispatch(fetchMetricSuccess(data, "growthScatterPlot"));
          });
        break;
      }
      case "BAR_CHARTS": {
        axios
          .get(API + `/metrics/growth/barcharts${params}`)
          .then((response) => {
            const { data } = response;
            dispatch(fetchMetricSuccess(data, "growthBarCharts"));
          });
        break;
      }
      default:
        break;
    }
  };
}

function getMetricTrends(selected, type, path) {
  let params = "?";
  [
    "Network",
    "District",
    "School Level",
    "School",
    "Student Groups",
    "Measure",
    "Pathway",
  ].forEach((key) => {
    params += `${key}=${selected[key]}&`;
  });
  const base = path.includes("prelim") ? "college" : "ondemand";
  const route = type === "METRIC_TRENDS" ? "metrictrends" : "gaptrends";
  const name = type === "METRIC_TRENDS" ? "metricTrends" : "gapTrends";
  return (dispatch) => {
    dispatch(fetchMetric());
    axios.get(API + `/metrics/${base}/${route}${params}`).then((response) => {
      const { data } = response;
      dispatch(fetchMetricSuccess(data, name));
    });
  };
}

function getOnDemandScatter(selected, path) {
  const nsc = path.includes("prelim") ? "college" : "ondemand";
  let params = `?`;
  [
    "Network",
    "School Year",
    "Data Points",
    "xAxis",
    "yAxis",
    "Student Groups",
  ].forEach((key) => {
    params += `${key}=${selected[key]}&`;
  });
  return (dispatch) => {
    dispatch(fetchMetric());
    axios
      .get(API + `/metrics/${nsc}/scatterplots${params}`)
      .then((response) => {
        const { data } = response;
        dispatch(fetchMetricSuccess(data, "scatterPlots"));
      });
  };
}

function getCollegeDistribution(selected) {
  let params = "?";
  [
    "Network",
    "District",
    "School",
    "School Year",
    "Measure",
    "Student Groups",
  ].forEach((key) => {
    params += `${key}=${selected[key]}&`;
  });
  return (dispatch) => {
    dispatch(fetchMetric());
    axios
      .get(API + `/metrics/college/distribution${params}`)
      .then((response) => {
        const { data } = response;
        dispatch(fetchMetricSuccess(data, "collegeDistribution"));
      });
  };
}

function getCollegeBarPlot(selected) {
  let params = "?";
  [
    "Network",
    "District",
    "School",
    "School Year",
    "Measure",
    "Student Groups",
  ].forEach((key) => {
    params += `${key}=${selected[key]}&`;
  });
  return (dispatch) => {
    dispatch(fetchMetric());
    axios.get(API + `/metrics/college/barplot${params}`).then((response) => {
      const { data } = response;
      dispatch(fetchMetricSuccess(data, "collegeBarPlot"));
    });
  };
}

function getSchoolSummary(selected) {
  let params = "?";
  ["Network", "District", "School", "School Year", "Student Groups"].forEach(
    (key) => {
      params += `${key}=${selected[key]}&`;
    }
  );
  return (dispatch) => {
    dispatch(fetchMetric());
    axios
      .get(API + `/metrics/linkedlearning/schoolsummary${params}`)
      .then((response) => {
        const { data } = response;
        dispatch(fetchMetricSuccess(data, "schoolSummary"));
      });
  };
}

function getLongitudinalView(selected) {
  let params = "?";
  [
    "Network",
    "District",
    "School",
    "School Year",
    "Student Groups",
    "Pathway",
  ].forEach((key) => {
    params += `${key}=${selected[key]}&`;
  });
  return (dispatch) => {
    dispatch(fetchMetric());
    axios
      .get(API + `/metrics/linkedlearning/pathwaylongitudinal${params}`)
      .then((response) => {
        const { data } = response;
        dispatch(fetchMetricSuccess(data, "pathwayLongitudinal"));
      });
  };
}

function getPredictiveAnalytics(selected, type) {
  let params = "?";
  ["Network", "District", "School Level", "School"].forEach((key) => {
    params += `${key}=${selected[key]}&`;
  });
  const route = type === "SCHOOL_VIEW" ? "schoolview" : "networkview";
  const name = type === "SCHOOL_VIEW" ? "schoolViewPA" : "networkViewPA";
  return (dispatch) => {
    dispatch(fetchMetric());
    axios
      .get(API + `/metrics/predictiveanalytics/${route}${params}`)
      .then((response) => {
        const { data } = response;
        dispatch(fetchMetricSuccess(data, name));
      });
  };
}
