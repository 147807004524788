import TableCell from "@material-ui/core/TableCell";
import React from "react";
import tableStyle from "../../assets/jss/core-dashboard/components/tableStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";

function RenderCell({ ...props }) {
  const { classes, key, text } = props;
  return (
    <TableCell className={classes.noDataRow} key={key}>
      {text ? (
        <Typography className={classes.noData}>No Data</Typography>
      ) : null}
    </TableCell>
  );
}

export default withStyles(tableStyle)(RenderCell);
