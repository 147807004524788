import {
  SUBMIT_LOGIN,
  SUBMIT_LOGOUT,
  UPDATE_USER_BETA_SUCCESS,
  VERIFY_TOKEN,
  VERIFY_TOKEN_FAILURE,
  VERIFY_TOKEN_SUCCESS,
} from "./actions";

const initialState = {
  user: {},
  token: "",
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case SUBMIT_LOGIN:
      return {
        ...state,
        user: action.email,
      };
    case VERIFY_TOKEN:
      return {
        ...state,
        token: action.token,
      };
    case VERIFY_TOKEN_SUCCESS:
      return {
        ...state,
        user: action.user,
      };
    case VERIFY_TOKEN_FAILURE:
      return {
        ...state,
        token: "Token Failed to Retrieve",
      };
    case UPDATE_USER_BETA_SUCCESS:
      return {
        ...state,
        user: action.user.user,
      };
    case SUBMIT_LOGOUT:
      return initialState;
    default:
      return state;
  }
}
