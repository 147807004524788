import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { compose } from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import connect from "react-redux/es/connect/connect";
import Typography from "@material-ui/core/Typography";
import tableStyle from "../../assets/jss/core-dashboard/components/tableStyle.jsx";
import CardHeader from "../../StyledComponents/Card/CardHeader";
import CardBody from "../../StyledComponents/Card/CardBody";
import Card from "../../StyledComponents/Card/Card";
import TitleTable from "../../StyledComponents/Table/TitleTable";
import LoadingGrid from "../../StyledComponents/Grid/LoadingGrid";
import GrowthTable from "./GrowthTable";
import CustomToolTip from "../../StyledComponents/Tooltip/CustomToolTip";

class GrowthBarCharts extends Component {
  render() {
    const {
      district: { code },
      title,
      classes,
      loading,
      growthBarCharts,
      group,
      subgroups,
      selected,
    } = this.props;
    return (
      <Grid style={{ margin: "0 2.5% 0 2.5%", width: "95%" }}>
        <TitleTable
          hideInfo
          school={{}}
          district={{}}
          pageTitle={"Ordered Bar Charts"}
        />
        <Grid
          container
          item
          style={{ paddingLeft: 10, paddingRight: 10 }}
          xs={12}
        >
          <Grid item style={{ padding: 10 }} xs={6}>
            <Typography>
              A school's or a grade level team's growth percentile represents
              how they've done at moving students from point A to point B
              relative to schools serving similar youth. If a school has a
              growth percentile of 50, students have achieved{" "}
              <b style={{ textDecoration: "underline", color: "#f4861f" }}>
                average growth
              </b>
              . Growth percentiles above 70 represent{" "}
              <b style={{ textDecoration: "underline", color: "#67b948" }}>
                above average growth
              </b>
              . Growth percentiles below 31 represent{" "}
              <b style={{ textDecoration: "underline", color: "#ef4b4b" }}>
                below average growth
              </b>
              .
            </Typography>
            <Typography>
              Due to the lack of standardized testing during the COVID-19
              pandemic, ES/MS growth was not produced from 2019-20 to 2021-22,
              and HS growth was not produced from 2019-20.
            </Typography>
          </Grid>
          <Grid item style={{ padding: 10 }} xs={6}>
            <Typography>
              The CORE Growth Model compares each students' actual achievement
              to those in his/her peer group. The peer group is determined by
              the students' prior achievement, his/her demographics and the
              average prior achievement and demographics of students in his/her
              school. Using a students' peer group, we can see if a student did
              better than, about as well as or worse than his/her peers. We
              aggregate that information across the students in grade level or
              at a school to determine who is having the strongest and the
              weakest impact on student learning.
            </Typography>
          </Grid>
        </Grid>
        <Grid container xs={12} item>
          <Card>
            <CardHeader className={classes.gradientBackground} color="primary">
              {/* Left the Next Card Header in Place to bring back Primary Color at a later time*/}
              {/*<CardHeader color="primary" >*/}
              <CustomToolTip code={selected["Growth Subject"]}>
                <h4 className={classes.cardTitleBlue}>{title}</h4>
              </CustomToolTip>
              {/*<p className={classes.cardCategoryWhite}>*/}
              {/*	Here is a subtitle for this table*/}
              {/*</p>*/}
            </CardHeader>
            <CardBody
              style={{
                paddingTop: "0px",
                marginTop: "10px",
                paddingBottom: "10px",
              }}
            >
              {loading ? (
                <LoadingGrid />
              ) : (
                <GrowthTable
                  districtOverall={code === "overall"}
                  data={growthBarCharts}
                  selectedGroup={group}
                  subgroups={subgroups}
                />
              )}
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

const findInMenu = (array, key) => {
  const res = {
    code: "",
    text: "",
  };
  if (!array) {
    return res;
  }

  return array.reduce((found, curr) => {
    if (curr.code === key) {
      found = curr;
    }
    return found;
  }, res);
};

const mapState = (state) => {
  const { selected, menuObject } = state.menu;
  const {
    constants: { measures, subgroups },
  } = state;
  const network = findInMenu(menuObject["Network"], selected["Network"]);
  const district = findInMenu(menuObject["District"], selected["District"]);
  const school = findInMenu(menuObject["School"], selected["School"]);
  const group = selected["Student Groups"];
  const measureLevel = selected["School Level"];
  const year = selected["School Year"] ? selected["School Year"] : "";
  const measure = selected["Measure"];
  const growthSubject = selected["Growth Subject"];
  const subgroupName = subgroups ? (subgroups[group] || { name: "" }).name : "";
  const parsedName =
    subgroupName.split("(")[0].toLowerCase().includes("student") ||
    group === "EL"
      ? subgroupName
      : `${subgroupName} Students`;
  const growthName =
    (growthSubject || "growth_ela") === "growth_ela"
      ? "Growth in English Language Arts"
      : "Growth in Math";
  const title = `${growthName} among ${parsedName} at ${measureLevel} level`;
  const { growthBarCharts, loading } = state.metrics;
  return {
    network,
    district,
    school,
    growthName,
    growthBarCharts,
    selected,
    year,
    measure,
    title,
    measureLevel,
    loading,
    subgroups,
    group,
  };
};

const mapDispatch = (dispatch) => {
  return {};
};

export default compose(
  withStyles(tableStyle, { withTheme: true }),
  connect(mapState, mapDispatch)
)(GrowthBarCharts);
