import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table/Table";
import TableHead from "@material-ui/core/TableHead/TableHead";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableBody from "@material-ui/core/TableBody/TableBody";
import Paper from "@material-ui/core/Paper";
export const helpers = {
  createFiveByFive,
  parseFiveByFiveLabels,
  formatFiveByFive,
  fiveByFiveTable,
};

const generateLabel = (text, values, color, info) => {
  const { percent, subtext, reverse } = info;
  const parseSubtext =
    subtext === "Graduated Class of " ? "Percent Graduated" : subtext;
  const dir = color === "red" ? "worse" : "better";
  const parseValues =
    color === "yellow"
      ? values[0] === values[1]
        ? [values[0]]
        : values
      : color === "red"
      ? [
          reverse
            ? Math.min(values[0], values[1])
            : Math.max(values[0], values[1]),
        ]
      : color === "blue"
      ? [
          reverse
            ? Math.max(values[0], values[1])
            : Math.min(values[0], values[1]),
        ]
      : values;
  switch (parseValues.length) {
    case 1:
      if (color === "yellow") {
        return `${text} ${parseValues[0]} in ${parseSubtext}`;
      }
      return `${text} ${parseValues[0]} or ${dir} in ${parseSubtext}`;
    case 2:
      return `${text} between ${parseValues[0]} & ${parseValues[1]} in ${parseSubtext}`;
    default:
      return `${text}`;
  }
  return text;
};

function parseFiveByFiveLabels({ currentInfo, text, measureInfo }) {
  if (!currentInfo) {
    return {
      topBar: {
        red: "",
        orange: "",
        yellow: "",
        green: "",
        blue: "",
      },
      sideBar: {
        red: "",
        orange: "",
        yellow: "",
        green: "",
        blue: "",
      },
    };
  }
  if (!Object.keys(currentInfo).length) {
    return {
      topBar: {
        red: "",
        orange: "",
        yellow: "",
        green: "",
        blue: "",
      },
      sideBar: {
        red: "",
        orange: "",
        yellow: "",
        green: "",
        blue: "",
      },
    };
  }
  const reverse = measureInfo.reverse || false;
  return Object.keys(text).reduce((object, axis, index) => {
    object[axis] = {};
    const currText = text[axis];
    const currInfo = currentInfo[axis];
    Object.keys(currText).forEach((color, index) => {
      const textColor = currText ? currText[color] : "";
      if (reverse) {
        color = Object.keys(currText)[Object.keys(currText).length - index - 1];
      }
      const valueColor = currInfo ? currInfo[color] : [];
      object[axis][color] = generateLabel(
        textColor,
        valueColor,
        color,
        measureInfo || {}
      );
    });
    return object;
  }, {});
}

function createFiveByFive({ data, otherYear, metrics, measure, subgroups }) {
  return Object.keys(data).reduce(
    (obj, key) => {
      const curr = data[key];
      const split = key.split("inv");
      const pastYear = otherYear ? metrics[measure][otherYear] : undefined;
      const pastRow = pastYear ? pastYear[key] : undefined;
      const pastValue = pastRow ? pastRow["meas_rate"] : undefined;
      const { lcap_color_status, lcap_color_change, meas_rate } = curr;
      if (lcap_color_change && lcap_color_change && split.length === 1)
        obj[lcap_color_status][lcap_color_change].push({
          change:
            pastValue !== undefined && meas_rate !== undefined
              ? Math.round(100 * (meas_rate - pastValue)) / 100
              : "NO DATA",
          past_measure: pastValue !== undefined ? pastValue : "NO DATA",
          curr_measure: meas_rate !== undefined ? meas_rate : "NO DATA",
          text: subgroups
            ? subgroups[key]
              ? subgroups[key]["name"]
              : key
            : key,
        });
      return obj;
    },
    {
      red: {
        red: [],
        orange: [],
        yellow: [],
        green: [],
        blue: [],
      },
      orange: {
        red: [],
        orange: [],
        yellow: [],
        green: [],
        blue: [],
      },
      yellow: {
        red: [],
        orange: [],
        yellow: [],
        green: [],
        blue: [],
      },
      green: {
        red: [],
        orange: [],
        yellow: [],
        green: [],
        blue: [],
      },
      blue: {
        red: [],
        orange: [],
        yellow: [],
        green: [],
        blue: [],
      },
    }
  );
}

export const HoverInfo = ({ line, pastYear, year }) => (
  <Grid style={{ color: "white" }} container direction={"column"}>
    <Grid style={{ textAlign: "center", borderBottom: "1px solid white" }} item>
      <Typography
        style={{ fontSize: "14px", fontWeight: "700" }}
        color={"inherit"}
      >
        {line.text}
      </Typography>
    </Grid>
    <Grid item>
      <Typography color={"inherit"}>
        {year}: {line.curr_measure}
      </Typography>
    </Grid>
    <Grid item>
      <Typography color={"inherit"}>
        {pastYear}: {line.past_measure}
      </Typography>
    </Grid>
    <Grid item>
      <Typography color={"inherit"}>Change: {line.change}</Typography>
    </Grid>
  </Grid>
);

function formatFiveByFive({
  fiveByFive,
  sideBar,
  topBar,
  year,
  pastYear,
  currGrid,
}) {
  if (!currGrid) {
    return [];
  }
  const colors = ["red", "orange", "yellow", "green", "blue"];
  const colorReverse = ["blue", "green", "yellow", "orange", "red"];
  const body = colorReverse.map((rowColor, index) => {
    const label = [{ type: "NAME", text: sideBar[rowColor], color: null }];
    return label.concat(
      colors.map((colColor, rIndex) => {
        return {
          type: "LCAP CELL",
          color:
            currGrid[rowColor][colColor] === "na"
              ? "gray"
              : currGrid[rowColor][colColor],
          list: fiveByFive[rowColor][colColor],
          pastYear,
          year,
        };
      })
    );
  });
  const head = [""].concat(colors.map((color) => topBar[color]));
  return {
    head,
    body,
  };
}

function fiveByFiveTable({ head, body, pastYear, year, classes }) {
  if (!head || !body) {
    return <p>No</p>;
  }
  return (
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            {head.map((header) => (
              <TableCell>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {body.map((row, rIndex) => (
            <TableRow>
              {row.map((cell, cIndex) => (
                <TableCell
                  key={`cell:${rIndex}/${cIndex}`}
                  style={{
                    backgroundColor: colorToBG(cell.color),
                    borderLeft: `20px solid ${cell.color}`,
                  }}
                >
                  {cell.type === "LABEL" ? (
                    <Typography>{cell.text}</Typography>
                  ) : (
                    <Grid container direction={"column"}>
                      {cell.list.map((line) => (
                        <Grid key={`grid:${line.text}`} item>
                          <Tooltip
                            key={`cell:${line.text}`}
                            title={
                              <HoverInfo
                                line={line}
                                pastYear={pastYear}
                                year={year}
                              />
                            }
                          >
                            <Typography>{line.text}</Typography>
                          </Tooltip>
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

const colorToBG = (color) => {
  switch (color) {
    case "red":
      return "rgba(170,0,0,.5)";
    case "blue":
      return "rgba(0,0,170,.5)";
    case "green":
      return "rgba(0,170,0,.5)";
    case "yellow":
      return "rgba(204,204,0,.5)";
    case "orange":
      return "rgba(255,153,0,.5)";
    default:
      return null;
  }
};
