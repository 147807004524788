import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { compose } from "redux";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = (theme) => ({
  containerSpacing: {
    flexGrow: 1,
    paddingTop: "30px",
    paddingLeft: "15px",
    paddingRight: "10px",
  },
  graph: {
    height: "550px",
    width: "1052px",
    marginBottom: "50px",
  },
  graphLine: {
    stroke: "#babbbb",
    strokeWidth: ".5px",
    strokeLinecap: "round",
  },
  yLabel: {
    fill: "#babbbb",
    textAnchor: "end",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    fontSize: "16px",
  },
  text: {
    textAnchor: "middle",
    fontSize: "96px",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    fill: "rgba(0,0,0,.1)",
    fontWeight: "900",
  },
});

class NoDataSchoolView extends Component {
  render() {
    const { classes } = this.props;
    return (
      <Grid
        container
        className={classes.containerSpacing}
        alignItems={"center"}
      >
        <Grid container item xs={12} justifyContent={"center"}>
          <svg className={classes.graph}>
            <g className={classes.graphLine}>
              <line x1="50" x2="1050" y1="75" y2="75" />
              <line x1="50" x2="1050" y1="115" y2="115" />
              <line x1="50" x2="1050" y1="155" y2="155" />
              <line x1="50" x2="1050" y1="195" y2="195" />
              <line x1="50" x2="1050" y1="235" y2="235" />
              <line x1="50" x2="1050" y1="275" y2="275" />
              <line x1="50" x2="1050" y1="315" y2="315" />
              <line x1="50" x2="1050" y1="355" y2="355" />
              <line x1="50" x2="1050" y1="395" y2="395" />
              <line x1="50" x2="1050" y1="435" y2="435" />
              <line x1="50" x2="1050" y1="475" y2="475" />
            </g>
            <g className={classes.yLabel}>
              <text x="46" y="80">
                100%
              </text>
              <text x="46" y="120">
                90%
              </text>
              <text x="46" y="160">
                80%
              </text>
              <text x="46" y="200">
                70%
              </text>
              <text x="46" y="240">
                60%
              </text>
              <text x="46" y="280">
                50%
              </text>
              <text x="46" y="320">
                40%
              </text>
              <text x="46" y="360">
                30%
              </text>
              <text x="46" y="400">
                20%
              </text>
              <text x="46" y="440">
                10%
              </text>
              <text x="46" y="480">
                0%
              </text>
            </g>
            <g className={classes.text}>
              <text x="550" y={"295"}>
                No Data
              </text>
            </g>
          </svg>
        </Grid>
      </Grid>
    );
  }
}

export default compose(withStyles(styles, { withTheme: true }))(
  NoDataSchoolView
);
