import TableCell from "@material-ui/core/TableCell";
import React from "react";
import tableStyle from "../../assets/jss/core-dashboard/components/tableStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import coreImg from "../../assets/images/coreLogo.png";
import "../../app.css";
function RenderCell({ ...props }) {
  const { classes, key, text, cellStyle } = props;
  return (
    <TableCell
      className={classes.noDataRow}
      style={{
        ...(cellStyle || {}),
      }}
      key={key}
    >
      {text ? (
        <Grid item style={{ position: "relative", width: 250 }}>
          <span
            className={classes.noData}
            style={{ paddingLeft: "10px", paddingRight: "50px" }}
          >
            Fetching Data
          </span>
          <img
            src={coreImg}
            style={{
              height: "40px",
              width: "40px",
              position: "absolute",
              right: 5,
            }}
            className={"rotatingImage"}
          />
        </Grid>
      ) : null}
    </TableCell>
  );
}

export default withStyles(tableStyle)(RenderCell);
