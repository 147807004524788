import axios from "axios";

const API = process.env.REACT_APP_API;
export const LOAD_ONTRACK_TABLE = "LOAD_ONTRACK_TABLE";
export const LOAD_ONTRACK_ECHART_BAR = "LOAD_ONTRACK_ECHART_BAR";
export const LOAD_ONTRACK_ECHART_LINE = "LOAD_ONTRACK_ECHART_LINE";
export const LOAD_ONTRACK_GRADE_DIST = "LOAD_ONTRACK_GRADE_DIST";
export const LOADING = "LOADING";

const generateGrade = (selected) => {
  if (selected["grades"].toLowerCase() !== "all") {
    const gradeNumber = Number(
      selected["grades"].toLowerCase().replace("grade ", "")
    );
    return `GRADE ${gradeNumber < 10 ? "0" : ""}${gradeNumber}`;
  }
  return selected["School Level"];
};

export const getOntrackStatus = async (selected, dispatch) => {
  const { Network, District, School } = selected;
  const schoolYears = selected["School Year"].split("-");
  const school_year = schoolYears[0].slice(2) + schoolYears[1].slice(2);

  try {
    const { data } = await axios.post(API + "/on_track_status/get_overview", {
      network: Network,
      district: District,
      school_level: generateGrade(selected),
      school_type: selected["School Type"],
      school: School,
      school_year,
      student_groups: selected["Student Groups"],
    });

    dispatch({
      type: LOAD_ONTRACK_TABLE,
      payload: data,
    });
  } catch (e) {
    console.error("Network or Database Error:", e);
  }
};

export const getOntrackEchartData = async (selected, dispatch, route) => {
  const { Network, District, School } = selected;
  try {
    const { data } = await axios.post(API + "/on_track_status/" + route, {
      network_code: Network,
      district_code: District,
      measure_level: generateGrade(selected),
      school_code: School,
      subgroup_code: selected["Student Groups"],
      school_year: selected["School Year"],
    });

    if (data) {
      dispatch({
        type:
          route === "get_lanes"
            ? LOAD_ONTRACK_ECHART_BAR
            : LOAD_ONTRACK_ECHART_LINE,
        payload: data,
      });
    }
  } catch (e) {
    console.error("Network or Database Error:", e);
  }
};

export const getOnTrackGradeDistribution = async (selected, dispatch) => {
  const { Network, District, School } = selected;
  dispatch({ type: LOADING, payload: true });
  try {
    const { data } = await axios.post(
      API + "/on_track_status/get_grade_distribution",
      {
        network_code: Network,
        district_code: District,
        measure_level: generateGrade(selected),
        school_code: School,
        subgroup_code: selected["Student Groups"],
        school_year: selected["School Year"],
      }
    );

    if (data) {
      dispatch({
        type: LOAD_ONTRACK_GRADE_DIST,
        payload: data,
      });
    }
  } catch (e) {
    console.error("Network or Database Error:", e);
  }
  dispatch({ type: LOADING, payload: false });
};
