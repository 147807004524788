import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import { compose } from "redux";
import Link from "@material-ui/icons/Link";
import IconButton from "@material-ui/core/IconButton/index";
import { closeDeepLink, generateDeeplink } from "../Menu/actions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import Cancel from "@material-ui/icons/Cancel";
import FileCopy from "@material-ui/icons/FileCopy";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  linkStyle: {
    color: "black",
    fontSize: "5vh",
    transform: "rotate(45deg)",
    width: "60px !important",
  },
});

class Deeplink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: false,
    };
  }

  handleDeepLink = () => {
    const { DeepLink, selected, path, sliders } = this.props;
    DeepLink(path, selected, sliders);
  };
  handleCloseDeepLink = () => {
    this.props.exitDeepLink();
  };

  copyDeepLink = () => {
    const text = document.getElementById("deeplink-text");
    text.select();
    document.execCommand("copy");
    this.setState({ copied: true });
  };

  render() {
    const { deepLinkURL, showDeeplink, classes } = this.props;
    return (
      <div>
        <Dialog
          onBackdropClick={() => {
            this.handleCloseDeepLink();
          }}
          open={showDeeplink}
        >
          <DialogContent style={{ color: "white", backgroundColor: "#1d5e77" }}>
            <Grid container direction={"column"}>
              <Grid item xs={12}>
                <IconButton
                  color={"inherit"}
                  style={{ float: "right" }}
                  onClick={() => {
                    this.handleCloseDeepLink();
                  }}
                >
                  <Cancel color={"inherit"} />
                </IconButton>
              </Grid>
              <Grid xs={12} item>
                <Typography color={"inherit"}>{deepLinkURL}</Typography>
              </Grid>
              <Grid alignItems={"center"} container item>
                <Grid item>
                  <IconButton
                    onClick={() => this.copyDeepLink()}
                    color={"inherit"}
                  >
                    <FileCopy color={"inherit"} />
                  </IconButton>
                </Grid>
                {this.state.copied ? (
                  <Grid item>
                    <Typography color={"inherit"}>Copied!</Typography>
                  </Grid>
                ) : null}
              </Grid>
              <input
                style={{ position: "fixed", left: "100%" }}
                type={"text"}
                id={"deeplink-text"}
                value={deepLinkURL}
              />
            </Grid>
          </DialogContent>
        </Dialog>
        <IconButton
          onClick={() => {
            this.handleDeepLink();
          }}
          style={{
            position: "fixed",
            right: "1%",
            bottom: "1%",
            zIndex: "100",
          }}
        >
          <Link className={classes.linkStyle} />
        </IconButton>
      </div>
    );
  }
}

const mapState = (state) => {
  const { selected, sliders, deepLink, deepLinkURL, showDeeplink } = state.menu;
  return {
    selected,
    sliders,
    deepLink,
    deepLinkURL,
    showDeeplink,
  };
};
const mapDispatch = (dispatch) => {
  return {
    DeepLink: (path, selected, sliders) => {
      dispatch(generateDeeplink(path, selected, sliders));
    },
    exitDeepLink: () => {
      dispatch(closeDeepLink());
    },
  };
};

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapState, mapDispatch)
)(Deeplink);
