import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import DonutChart from "./DonutChart";
import ScatterPlot from "./ScatterPlot";
export default class Plots extends Component {
  render() {
    return (
      <Grid container direction={"column"}>
        <Grid item>
          <DonutChart />
        </Grid>
        <Grid item>
          <ScatterPlot />
        </Grid>
      </Grid>
    );
  }
}
