export const getBasePathway = ({ pathway, selected, type }) => {
  return !pathway || !selected["Pathway"]
    ? type.subtype === "pathwaylongitudinal"
      ? Object.keys(pathway)[0]
      : ""
    : !!pathway[selected["Pathway"]]
    ? selected["Pathway"]
    : type.subtype === "pathwaylongitudinal"
    ? Object.keys(pathway)[0]
    : "";
};
