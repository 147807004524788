import React, { Component } from "react";
import { Route, Router } from "react-router-dom";
import history from "../history";
import Login from "../Login/Login";
import Token from "../Login/Token";
import { ProtectedRoute } from "./ProtectedRoutes";
import Dashboard from "../Dashboard/Dashboard";
import connect from "react-redux/es/connect/connect";
import { compose } from "redux";
import { getToken } from "../Login/actions";
import Plots from "../Plots/Plots";
import Overview from "../Overviews/LcapOverview";
import DeepLink from "./DeepLink";
import LoadingScreen from "../Login/LoadingScreen";

//Matomo
history.listen((location) => {
  const { pathname } = location;
  window._paq.push(["setCustomUrl", pathname]);
  window._paq.push(["trackPageView"]);
});

class Routes extends Component {
  constructor(props) {
    super(props);
    props.getToken();
  }
  render() {
    const { token } = this.props;
    return (
      <Router history={history}>
        <div>
          <Route exact path={"/"} component={Login} />
          <Route
            path={"/login"}
            render={({ match }) => <Login token={match.params.id} />}
          />
          <Route
            path={"/token/:id/redirect=/:redirect"}
            render={({ match, location }) => {
              const { pathname } = location;
              const token = pathname
                .split("/token/")[1]
                .split("/redirect=/")[0];
              const redirect = pathname.split("/redirect=/")[1];
              return <Token token={token} redirect={redirect} />;
            }}
          />
          <Route path={"/loading/:path"} component={LoadingScreen} />
          <Route
            path={"/input/:hash"}
            render={({ match }) => (
              <DeepLink history={history} hash={match.params.hash} />
            )}
          />
          <Route path={"/public"} component={Dashboard} />
          <ProtectedRoute
            token={token}
            path={"/dashboard"}
            component={Dashboard}
          />
          <ProtectedRoute token={token} path={"/lcap"} component={Overview} />
          <Route path={"/plots"} component={Plots} />
        </div>
      </Router>
    );
  }
}

const mapState = (state) => {
  return {
    token: state.user.token,
  };
};

const mapDispatch = (dispatch) => {
  return {
    getToken: () => {
      dispatch(getToken());
    },
  };
};

export default compose(connect(mapState, mapDispatch))(Routes);
