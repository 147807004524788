import axios from "axios";
import { menu_constants } from "./menu_constants";
import { metricActions } from "../Metrics/actions";
import {
  getBaseAxis,
  getBaseDistrict,
  getBaseGroup,
  getBaseMeasure,
  getBaseSchool,
  getBaseSchoolLevel,
  getMeasureList,
  getPageType,
  getStudentGroups,
} from "./helpers";
import {
  fetchInitialConstantsSuccess,
  fetchInitialDefinitionsSuccess,
} from "../Constants/actions";
import { visualizationActions } from "../Visualizations/actions";
import { generateSchoolList, scatterPlotSchoolList } from "./helpers/schools";
import { getBasePathway } from "./helpers/pathway";
import { getDistrictMenu } from "./helpers/district";
import { LOGIN_SUCCESS, verifyTokenSuccess } from "../Login/actions";
import { districtNameExtractor } from "../Helpers/Menu/menuHelper";
import {
  getOntrackEchartData,
  getOnTrackGradeDistribution,
  getOntrackStatus,
} from "../actions/Ontrack/ontrack";

/**
 * CONSTANT VARIABLES
 */
const API = process.env.REACT_APP_API;

/**
 * ACTION TYPES
 */
export const FETCH_MENU = "FETCH_MENU";
export const FETCH_MENU_SUCCESS = "FETCH_MENU_SUCCESS";
export const DEEP_LINK_SUCCESS = "DEEP_LINK_SUCCESS";
export const GENERATE_DEEP_LINK_SUCCESS = "GENERATE_DEEP_LINK_SUCCESS";
export const CLOSE_DEEPLINK = "CLOSE_DEEPLINK";
export const MOVE_SLIDER = "MOVE_SLIDER";

export const TOGGLE_VISIBILITY = "TOGGLE_VISIBILITY";
export const SCATTER_PLOT_NEW_VALUE = "SCATTER_PLOT_NEW_VALUE";

/**
 * ACTION CREATORS
 */

const fetchMenuSuccess = (menu, selected, menuObject) => {
  return { type: FETCH_MENU_SUCCESS, menu, selected, menuObject };
};
const deepLinkSuccess = (selected, sliders) => ({
  type: DEEP_LINK_SUCCESS,
  selected,
  sliders,
});
export const slider = (key, value) => ({ type: MOVE_SLIDER, key, value });

export const generateDeepLinkSuccess = (url) => ({
  type: GENERATE_DEEP_LINK_SUCCESS,
  url,
});
export const closeDeepLink = () => ({ type: CLOSE_DEEPLINK });

export const toggleVisibility = (name) => ({ type: TOGGLE_VISIBILITY, name });
export const setScatterPlotValue = (name, key, value) => ({
  type: SCATTER_PLOT_NEW_VALUE,
  name,
  key,
  value,
});

/**
 * THUNK CREATORS
 */
const shapes = [
  "circle",
  "square",
  "diamond",
  "triangle-up",
  "triangle-down",
  "triangle-right",
  "triangle-left",
  "cross",
  "pentagon",
  "hexagon",
  "octagon",
  "star",
  "bowtie",
  "hourglass",
];

const colors = ['#470f49', '#1f5ca5', '#8a3e8d',
'#0fb9a3','#c0dce2', '#e65076', '#004545',
'#289ef7', '#c4a5c7', '#f7e473','#82b167', '#5d2f13', '#0092b8', '#a0abd3', '#f1bf1a']
const multiSelectList = (name, selected, newList, isList) => {
  const currList = isList ? selected[name].list : selected[name];
  let usedColorsObject = {}
  if(isList){
    usedColorsObject = {...selected["multi_school"].list, ...selected["multi_district"].list}
  }
  if (newList === "CLEAR") {
    return {};
  }
  const oldCodes = Object.keys(currList);
  const codes = newList.map((curr) => curr.value);
  const res = {};
  if (codes.length > oldCodes.length) {
    codes.forEach((code, i) => {
      if (currList[code]) {
        res[code] = currList[code];
      } else {
        switch (name) {
          case "multi_groups": {
            const usedShapes = oldCodes.map((curr) => currList[curr]);
            let i = 0;
            let found = false;
            let shape = "circle";
            while (i < shapes.length && !found) {
              shape = shapes[i];
              if (!usedShapes.includes(shape)) {
                found = true;
              }
              i++;
            }
            res[code] = shape;
            break;
          }
          case "multi_college_level":
          case "multi_school_type":
          case "multi_level": {
            res[code] = "#36454f";
            break;
          }
          default: {
            const hex = [
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9",
              "A",
              "B",
              "C",
              "D",
            ];
            if(Object.keys(usedColorsObject).length >= colors.length){
            let color = "";
            for (let i = 0; i < 6; i++) {
              color += hex[Math.floor(Math.random() * hex.length)];
            }
            res[code] = `#${color}`;
            }
            else {
              for (let color of colors) {
                if (!Object.values(usedColorsObject).includes(color)) {
                  res[code] = color; // Set uniqueColor to the current color
                  break; // Exit the loop early
                }
              }
            }       
            break;
          }
        }
      }
    });
  } else {
    oldCodes.forEach((code) => {
      if (codes.includes(code)) {
        res[code] = currList[code];
      }
    });
  }
  return res;
};

const generateNewSelected = ({ name, selected, value }) => {
  switch (name) {
    case "multi_school_type":
    case "multi_college_level":
    case "multi_district":
    case "multi_school":
    case "multi_level":
    case "multi_groups":
      const isList = ["multi_district", "multi_school"].includes(name);
      const newList = multiSelectList(name, selected, value, isList);
      return isList
        ? {
            ...selected,
            [name]: {
              ...selected[name],
              list: newList,
            },
          }
        : {
            ...selected,
            [name]: newList,
          };
    default:
      return {
        ...selected,
        [name]: value,
      };
  }
};

const getStep = (name) => {
  switch (name) {
    case "Network":
      return 0;
    case "District":
      return 1;
    case "School Level":
      return 2;
    case "School Type":
      return 3;
    case "School":
      return 4;
    default:
      return 100;
  }
};

export const defaultValue = (path, measureToggler) => {
  switch (path) {
    case "/dashboard/shiny/prelim/barplotdistributions":
      return "enroll_fall";
    case "/dashboard/shiny/prelim/metrictrends":
    case "/dashboard/shiny/prelim/collegedistribution":
    case "/dashboard/shiny/prelim/gaptrends":
      return "nsc_2yr4yr_enroll_year";
    case "/dashboard/ontrack/gradedistribution":
      return "on_track_rate";
    case "/dashboard/ondemand/metrictrends":
      return "on_track_rate";
    default:
      return Object.keys(measureToggler)[0];
  }
};

export const select =
  (input, menu, selected, path, constants, availableOptions) => (dispatch) => {
    document.cookie = `callback=${path}`;
    const { name, value } = input;
    const type = getPageType(path);
    const measureToggler =
      ["ondemand"].includes(type.type) || ["5x5"].includes(type.subtype)
        ? constants.measures
        : constants.collegeMeasures;
    const schoolLevelsExtractor = availableOptions["School Year"][0];
    // If the user switches tabs then these values will be overridden
    if (!name) {
      if (
        selected["District"] === "overall" &&
        (["prelim"].includes(type.type) ||
          ["schoolreport", "5x5", "gaptrends", "metrictrends"].includes(
            type.subtype
          ))
      ) {
        selected["District"] = districtNameExtractor(menu, selected);
      }
      selected["School Year"] = [
        "barplotdistributions",
        "collegedistribution",
      ].includes(type.subtype)
        ? "2016-2017"
        : schoolLevelsExtractor;
      selected["Measure"] = defaultValue(path, measureToggler);
      selected["Student Groups"] = [
        "/dashboard/shiny/prelim/gaptrends",
      ].includes(path)
        ? "SD"
        : "ALL";
    }
    // If the user changes the network, then the district will reset to overall if available on the page
    if (name === "Network") {
      if (
        !["prelim"].includes(type.type) ||
        !["schoolreport", "5x5", "gaptrends", "metrictrends"].includes(
          type.subtype
        )
      ) {
        selected["District"] = "overall";
      }
    }
    const newSelected = generateNewSelected({ name, selected, value });
    const nth = getStep(name);
    const newSelect = generateSelected(
      menu,
      newSelected,
      nth,
      path,
      type,
      constants
    );
    document.cookie = `selected=${JSON.stringify(newSelect)}`;
    const menuObject = generateMenuOptions(
      menu,
      newSelect,
      path,
      type,
      constants
    );
    dispatch(fetchMenuSuccess(menu, newSelect, menuObject));
    const isMulti = name.substring(0, 5) === "multi";
    if (!isMulti) {
      switch (path) {
        case "/public/lcap/lcap-5x5schools":
          dispatch(metricActions.getFiveByFiveSchool(newSelect, true));
          break;
        case "/dashboard/lcap":
          dispatch(metricActions.getIndexMetrics(newSelect, false, true));
          break;
        case "/dashboard/lcap/lcap-5x5schools":
          dispatch(metricActions.getFiveByFiveSchool(newSelect, false));
          break;
        case "/dashboard/academic/schoolreport":
          dispatch(
            metricActions.getGrowthSchoolReport(newSelect, "SCHOOL_REPORT")
          );
          break;
        case "/dashboard/academic/barcharts":
          dispatch(
            metricActions.getGrowthSchoolReport(newSelect, "BAR_CHARTS")
          );
          break;
        case "/dashboard/academic/scatterplot":
          if (nth !== 4)
            dispatch(
              metricActions.getGrowthSchoolReport(newSelect, "SCATTER_PLOT")
            );
          break;
        case "/dashboard/shiny/prelim/metrictrends":
        case "/dashboard/ondemand/metrictrends":
          dispatch(
            metricActions.getMetricTrends(newSelect, "METRIC_TRENDS", path)
          );
          break;
        case "/dashboard/shiny/prelim/gaptrends":
        case "/dashboard/ondemand/gaptrends":
          dispatch(
            metricActions.getMetricTrends(newSelect, "GAP_TRENDS", path)
          );
          break;
        case "/dashboard/shiny/prelim/scatterplots":
        case "/dashboard/ondemand/scatterplots":
          dispatch(metricActions.getOnDemandScatter(newSelect, path));
          break;
        case "/dashboard/shiny/prelim/collegedistribution":
          dispatch(metricActions.getCollegeDistribution(newSelect));
          break;
        case "/dashboard/shiny/prelim/barplotdistributions":
          dispatch(metricActions.getCollegeBarPlot(newSelect));
          break;
        case "/dashboard/linkedlearning/schoolsummary":
          dispatch(metricActions.getSchoolSummary(newSelect));
          break;
        case "/dashboard/linkedlearning/pathwaylongitudinal":
          dispatch(metricActions.getLongitudinalView(newSelect));
          break;
        case "/public":
        case "/public/":
        case "/public/core":
        case "/public/lcap":
        case "/public/lcap/lcap-5x5studentgroup":
          dispatch(metricActions.getIndexMetrics(newSelect, true));
          break;
        case "/dashboard/visualizations/completeness":
          dispatch(visualizationActions.getCompletness(newSelect["District"]));
          break;
        case "/dashboard/predictiveanalytics/schoolview":
          dispatch(
            metricActions.getPredictiveAnalytics(newSelect, "SCHOOL_VIEW")
          );
          break;
        case "/dashboard/predictiveanalytics/networkview":
          dispatch(
            metricActions.getPredictiveAnalytics(newSelect, "NETWORK_VIEW")
          );
          break;
        case "/dashboard/ontrack":
          getOntrackStatus(newSelect, dispatch).then();
          break;
        case "/dashboard/ontrack/ontracklanes":
          getOntrackEchartData(newSelect, dispatch, "get_lanes").then();
          break;
        case "/dashboard/ontrack/ratesbysubject":
          getOntrackEchartData(
            newSelect,
            dispatch,
            "get_status_by_subject"
          ).then();
          break;
        case "/dashboard/ontrack/gradedistribution":
          getOnTrackGradeDistribution(newSelect, dispatch).then();
          break;
        default:
          dispatch(metricActions.getIndexMetrics(newSelect, false));
          break;
      }
    }
  };

function getCookie(cname) {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

const onTrackSelectionMeasureKeys = ["on_track_rate"];
const getMeasureMenu = (route, type, constants) => {
  if (route === "/dashboard/ontrack/gradedistribution") {
    const tempMeasureArray = [];
    onTrackSelectionMeasureKeys.forEach((key) => {
      if (key in constants.measures) {
        tempMeasureArray.push(constants.measures[key]);
      }
    });
    return tempMeasureArray;
  } else if (
    ["ondemand"].includes(type.type) ||
    ["5x5"].includes(type.subtype)
  ) {
    return constants.measures;
  } else {
    return constants.collegeMeasures;
  }
};

export const getMenu =
  (
    route,
    history,
    isPublic,
    baseSelected
    // , deepLinkSelection, deepLink
  ) =>
  async (dispatch) => {
    try {
      const token = localStorage.getItem("token") || "";
      axios.defaults.headers.common["AUTHORIZATION"] = token;
      const req_path = isPublic
        ? `${API}/menu/public`
        : `${API}/menu/initial?route=${route}`;

      const res = await axios.get(req_path);
      const { constants, user, menu, measure_definitions } = res.data;
      localStorage.setItem("data", JSON.stringify(res.data));
      const reversed = ["chron_abs", "susp_core", "susp_state"];
      reversed.forEach((meas) => {
        constants.measures[meas].reverse = true;
      });
      if (constants.measures["demo_pct"]) {
        delete constants.measures["demo_pct"];
      }
      const type = getPageType(route);
      const myCookie = getCookie("selected") || "{}";
      const cookieSelect = JSON.parse(myCookie) || {};
      const deeplink = !!Object.keys(baseSelected).length;
      const hasCookie = !!Object.keys(cookieSelect).length;
      const startFresh = !deeplink && !hasCookie;
      const startSelect = deeplink ? baseSelected : cookieSelect;
      const district = Object.keys(menu.districts)[0];
      const school = Object.keys(menu.districts[district])[0];
      const schoolYearKeys = Object.keys(
        menu.districts[district][school]["schoolYears"]
      );
      const schoolYear = schoolYearKeys[schoolYearKeys.length - 1];
      const measureToggler = getMeasureMenu(route, type, constants);
      const defaultOptions = {
        District:
          ["prelim"].includes(type.type) ||
          ["schoolreport", "5x5", "gaptrends", "metrictrends"].includes(
            type.subtype
          )
            ? district
            : "overall",
        Measure: defaultValue(route, measureToggler),
        Network: Object.keys(menu.networks)[0],
        School: school,
        "School Level": "ES",
        "School Year": schoolYear,
        "School Type": "All",
        "Data Points": "Schools",
        "Growth Subject": "growth_ela",
        Pathway: "",
        "Student Groups": "ALL",
        multi_college_level: { "Two Year": "#66A5AD", "Four Year": "#07575B" },
        multi_district: { visibility: true, list: {} },
        multi_groups: { ALL: "circle" },
        multi_level: { ES: "#36454f", MS: "#36454f", HS: "#36454f" },
        multi_school: { visibility: true, list: {} },
        multi_school_type: { Traditional: "#36454f" },
        xAxis: "perc_prof_ela",
        yAxis: "chron_abs",
      };

      let schoolLevelsExtractor;
      if (sessionStorage.getItem("hasRendered")) {
        schoolLevelsExtractor = startSelect["School Year"]
          ? startSelect["School Year"]
          : startSelect["Network"]
          ? Object.keys(
              menu["districts"][startSelect["Network"]][
                districtNameExtractor(menu, startSelect)
              ].schoolYears
            ).slice(-1)[0]
          : "";
      } else {
        schoolLevelsExtractor = schoolYear;
      }

      // This is needed for initial login with no cookies.
      const startSelectCheck = !Object.keys(startSelect).length
        ? defaultOptions
        : {
            //This overrides values if a user refreshes the browser while being on CORE
            ...startSelect,
            District:
              ["prelim"].includes(type.type) ||
              ["schoolreport", "5x5", "gaptrends", "metrictrends"].includes(
                type.subtype
              )
                ? districtNameExtractor(menu, startSelect)
                : "overall",
            School: ["prelim"].includes(type.type)
              ? startSelect["School"]
              : "overall",
            "School Year": [
              "barplotdistributions",
              "collegedistribution",
            ].includes(type.subtype)
              ? "2016-2017"
              : schoolLevelsExtractor,
            Measure: defaultValue(route, measureToggler),
            "Student Groups": ["/dashboard/shiny/prelim/gaptrends"].includes(
              route
            )
              ? "SD"
              : "ALL",
          };
      const selected = generateSelected(
        menu,
        startSelectCheck,
        startFresh ? -1 : 100,
        route,
        type,
        constants
      );
      const menuObject = generateMenuOptions(
        menu,
        selected,
        route,
        type,
        constants,
        onTrackSelectionMeasureKeys
      );
      dispatch(fetchMenuSuccess(menu, selected, menuObject));
      dispatch(verifyTokenSuccess(user));
      dispatch(fetchInitialConstantsSuccess(constants));
      dispatch(fetchInitialDefinitionsSuccess(measure_definitions));
      history.push(route);
      switch (route) {
        case "/dashboard/lcap":
          dispatch(metricActions.getIndexMetrics(selected, false, true));
          break;
        case "/dashboard/lcap/lcap-5x5schools":
          dispatch(metricActions.getFiveByFiveSchool(selected, false));
          break;
        case "/dashboard/lcap/lcap-5x5studentgroup":
          dispatch(metricActions.getIndexMetrics(selected, false, false, true));
          break;
        case "/dashboard/academic/schoolreport":
          dispatch(
            metricActions.getGrowthSchoolReport(selected, "SCHOOL_REPORT")
          );
          break;
        case "/dashboard/academic/scatterplot":
          dispatch(
            metricActions.getGrowthSchoolReport(selected, "SCATTER_PLOT")
          );
          break;
        case "/dashboard/academic/barcharts":
          dispatch(metricActions.getGrowthSchoolReport(selected, "BAR_CHARTS"));
          break;
        case "/dashboard/shiny/prelim/metrictrends":
        case "/dashboard/ondemand/metrictrends":
          dispatch(
            metricActions.getMetricTrends(selected, "METRIC_TRENDS", route)
          );
          break;
        case "/dashboard/shiny/prelim/gaptrends":
        case "/dashboard/ondemand/gaptrends":
          dispatch(
            metricActions.getMetricTrends(selected, "GAP_TRENDS", route)
          );
          break;
        case "/dashboard/shiny/prelim/scatterplots":
        case "/dashboard/ondemand/scatterplots":
          dispatch(metricActions.getOnDemandScatter(selected, route));
          break;
        case "/dashboard/shiny/prelim/collegedistribution":
          dispatch(metricActions.getCollegeDistribution(selected));
          break;
        case "/dashboard/shiny/prelim/barplotdistributions":
          dispatch(metricActions.getCollegeBarPlot(selected));
          break;
        case "/dashboard/linkedlearning/schoolsummary":
          dispatch(metricActions.getSchoolSummary(selected));
          break;
        case "/dashboard/linkedlearning/pathwaylongitudinal":
          dispatch(metricActions.getLongitudinalView(selected));
          break;
        case "/dashboard/visualizations/completeness":
          dispatch(visualizationActions.getCompletness(selected["District"]));
          break;
        case "/public":
        case "/public/":
        case "/public/core":
        case "/public/lcap":
        case "/public/lcap/lcap-5x5schools":
          dispatch(metricActions.getIndexMetrics(selected, true));
          break;
        case "/public/lcap/lcap-5x5schools":
          dispatch(metricActions.getFiveByFiveSchool(selected, true));
          break;
        case "/dashboard/predictiveanalytics/schoolview":
          dispatch(
            metricActions.getPredictiveAnalytics(selected, "SCHOOL_VIEW")
          );
          break;
        case "/dashboard/predictiveanalytics/networkview":
          dispatch(
            metricActions.getPredictiveAnalytics(selected, "NETWORK_VIEW")
          );
          break;
        case "/dashboard/ontrack":
          await getOntrackStatus(selected, dispatch);
          break;
        case "/dashboard/ontrack/ontracklanes":
          await getOntrackEchartData(selected, dispatch, "get_lanes");
          break;
        case "/dashboard/ontrack/ratesbysubject":
          await getOntrackEchartData(
            selected,
            dispatch,
            "get_status_by_subject"
          );
          break;
        case "/dashboard/ontrack/gradedistribution":
          await getOnTrackGradeDistribution(selected, dispatch);
          break;
        default:
          dispatch(metricActions.getIndexMetrics(selected, false));
          break;
      }
      sessionStorage.setItem("hasRendered", "true");
    } catch (error) {
      console.error(error);
      dispatch({ type: LOGIN_SUCCESS });
      document.cookie = `selected=${JSON.stringify({})}`;
      history.push("/");
    }
  };

export const generateDeeplink = (path, selected, sliders) => (dispatch) => {
  const info = {
    path,
    selected,
    sliders,
  };
  axios.post(API + "/deeplink/generate", info).then((response) => {
    const { hash } = response.data;
    dispatch(generateDeepLinkSuccess(`${hash}`));
  });
};

export const getDeepLink = (hash, history) => (dispatch) => {
  axios
    .get(API + `/deeplink/?hash=${hash}`)
    .then((response) => {
      const { path, selected, sliders } = response.data;
      dispatch(deepLinkSuccess(selected, sliders));
      history.push(path);
    })
    .catch((err) => {});
};

/**
 * HELPER FUNCTIONS
 */

const generateSelected = (menu, selected, step, path, type, constants) => {
  const networkKeys = Object.keys(menu["networks"]);
  let baseNetwork =
    step < 0 ? menu["networks"][networkKeys[0]]["code"] : selected["Network"];
  const districtKeys = Object.keys(menu["districts"][baseNetwork]);
  const baseDistrictKey = Object.keys(menu["districts"][baseNetwork])[0];
  let baseDistrict = selected["District"]
    ? getBaseDistrict({ menu, selected, step, type, baseNetwork, districtKeys })
    : menu["districts"][baseNetwork][baseDistrictKey]["code"];

  const baseSchoolLevel = getBaseSchoolLevel({
    menu,
    selected,
    step,
    baseDistrict,
    type,
  });
  const baseSchoolType =
    step < 3 && selected["District"] !== "overall"
      ? "All"
      : selected["School Type"];
  const baseMultiSchoolType = selected["multi_school_type"]
    ? selected["multi_school_type"]
    : { Traditional: "#36454f" };
  //TODO schoolList undefined on prelim college and not core index || add catch
  const schoolList = generateSchoolList({
    menu,
    selected,
    baseSchoolType,
    baseDistrict,
    baseSchoolLevel,
    path,
  });
  const defaultSchool =
    getBaseSchool({
      menu,
      selected,
      step,
      schoolList,
      type,
      baseSchoolLevel,
    }) || schoolList[Object.keys(schoolList)[0]];
  const schoolYears = Object.keys(defaultSchool["schoolYears"]).sort((a, b) => {
    return parseInt(a.split("-")[1]) - parseInt(b.split("-")[1]);
  });
  const parsedYears = schoolYears;

  var baseYear =
    step < 5
      ? selected["School Year"]
        ? schoolYears.includes(selected["School Year"])
          ? selected["School Year"]
          : schoolYears[parsedYears.length - 1]
        : schoolYears[schoolYears.length - 1]
      : schoolYears.includes(selected["School Year"])
      ? selected["School Year"]
      : schoolYears[parsedYears.length - 1];
  if (!baseYear) {
    baseYear = "none";
  }
  const pathway = menu["pathways"][defaultSchool.code] || {};
  const basePathway = getBasePathway({ pathway, selected, type });
  const baseGroup = getBaseGroup({ selected, step, type });
  const baseMeasure = getBaseMeasure({ selected, type, constants });
  const xAxis = getBaseAxis({ x: true, selected, type, constants });
  const yAxis = getBaseAxis({ x: false, selected, type, constants });
  return {
    Network: baseNetwork,
    District: baseDistrict,
    multi_district: selected["multi_district"] || {
      visibility: true,
      list: {},
    },
    multi_level: selected["multi_level"] || {
      ES: "#36454f",
      MS: "#36454f",
      HS: "#36454f",
    },
    multi_college_level: selected["multi_college_level"] || {
      "Two Year": "#66A5AD",
      "Four Year": "#07575B",
    },
    multi_school: selected["multi_school"] || { visibility: true, list: {} },
    "School Level": baseSchoolLevel,
    "School Type": baseSchoolType,
    multi_school_type: baseMultiSchoolType,
    School: defaultSchool.code,
    "School Year": baseYear,
    Pathway: basePathway,
    "Student Groups": baseGroup,
    multi_groups: selected["multi_groups"] || { [baseGroup]: "circle" },
    Measure: baseMeasure,
    "Data Points": selected["Data Points"] || "Schools",
    "Growth Subject": selected["Growth Subject"] || "growth_ela",
    xAxis,
    yAxis,
    grades: selected["grades"] ? selected["grades"] : "All",
  };
};

const generateMenuOptions = (
  menu,
  selected,
  path,
  type,
  constants,
  onTrackSelectionMeasureKeys
) => {
  const { menuOptions } = menu_constants;
  const network = selected["Network"];
  const district =
    (["prelim", "linkedlearning"].includes(type.type) ||
      ["mapview"].includes(type.subtype)) &&
    selected["District"] === "overall"
      ? Object.keys(menu["districts"][network])[0]
      : selected["District"];
  const level =
    district !== "overall"
      ? menu["schools"][district][selected["School Level"]]
        ? selected["School Level"]
        : Object.keys(menu["schools"][district])[0]
      : "overall";
  const schoolType = selected["School Type"];
  const school = selected["School"];
  const schools =
    district === "overall" ? {} : menu["schools"][district][level];
  const currSchool = Object.keys(schools).reduce((found, key) => {
    const row = schools[key];
    if (row[school]) {
      found = row[school];
    }
    return found;
  }, undefined) || {
    code: "",
    text: "",
  };
  const districtOverall = [
    {
      code: "overall",
      text: "District Overall",
    },
  ];
  const basePathway = [
    {
      code: "",
      text: "",
    },
  ];
  var baseLevels = [
    "barcharts",
    "schoolview",
    "networkview",
    "mapview",
  ].includes(type.subtype)
    ? ["ES", "K8", "MS", "HS"]
    : type.subtype === "ontrack"
    ? ["K-8", "MS", "HS"]
    : ["ES", "K8", "MS", "HS", "Overall"];
  const gradeSelectionList = {
    K8: ["Grade 7", "Grade 8"],
    MS: ["Grade 7", "Grade 8"],
    HS: ["Grade 9", "Grade 10", "Grade 11"],
  };
  const baseSchools =
    ["prelim", "linkedlearning"].includes(type.type) ||
    ["mapview"].includes(type.subtype)
      ? []
      : districtOverall;
  const multiple_districts = sort(
    flatten(menu["districts"][selected["Network"]]),
    0
  );
  return {
    Network: sort(flatten(menu["networks"]), 0),
    District: getDistrictMenu({ menu, type, network: selected["Network"] }),
    multi_district: multiple_districts,
    "School Level": ["5x5"].includes(type.subtype)
      ? ["ES", "K8", "MS", "HS"]
      : district !== "overall"
      ? baseLevels.reduce((array, curr) => {
          const inDistrict = Object.keys(menu["schools"][district]).includes(
            curr
          );
          switch (type.subtype) {
            case "5x5":
              return curr === "Overall" || !inDistrict
                ? array
                : array.concat(curr);
            default:
              return array.concat(curr);
            // return inDistrict ? array.concat(curr) : array
          }
        }, [])
      : baseLevels,
    multi_school: scatterPlotSchoolList({ menu, selected, multiple_districts }),
    multi_level: type.type === "prelim" ? ["HS"] : ["ES", "K8", "MS", "HS"],
    multi_college_level: ["Two Year", "Four Year"],
    "School Type":
      school !== "overall"
        ? ["All"].concat(sort(Object.keys(menu["schools"][district][level]), 1))
        : [],
    School:
      schoolType === "All" && district !== "overall"
        ? baseSchools.concat(
            sort(
              Object.keys(schools).reduce((array, key) => {
                return array.concat(flatten(schools[key]));
              }, []),
              0
            )
          )
        : district === "overall"
        ? []
        : districtOverall.concat(sort(flatten(schools[schoolType]), 0)),
    "School Year":
      district !== "overall" && school !== "overall"
        ? sort(Object.keys(currSchool["schoolYears"]), 2)
        : district === "overall"
        ? sort(Object.keys(menu["networks"][network]["schoolYears"]), 2)
        : sort(
            Object.keys(menu["districts"][network][district]["schoolYears"]),
            2
          ),
    Pathway:
      menu["pathways"][currSchool["code"]] && school !== "overall"
        ? basePathway.concat(
            sort(flatten(menu["pathways"][currSchool["code"]]), 0).map(
              (curr) => {
                const { code, text } = curr;
                return {
                  text: `${text} (${code})`,
                  code,
                };
              }
            )
          )
        : [],
    "Student Groups": getStudentGroups({
      subgroups: constants.subgroups,
      type,
    }),
    multi_groups: Object.keys(constants.subgroups).map((curr) => {
      const { alias, name, code } = constants.subgroups[curr];
      const text = `${name} (${alias || code})`;
      return {
        code,
        text,
      };
    }),
    multi_school_type:
      school !== "overall"
        ? ["All"].concat(sort(Object.keys(menu["schools"][district][level]), 1))
        : [],
    Measure: getMeasureList({
      type,
      constants,
      path,
      onTrackSelectionMeasureKeys,
    }),
    "College Measures": menuOptions["College Measures"],
    "College Measure": menuOptions["College Measure"],
    "Data Points": menuOptions["Data Points"],
    "Growth Subject": menuOptions["Growth Subject"],
    grades: ["All"].concat(gradeSelectionList[selected["School Level"]] || []),
  };
};

export const sort = (object, type) => {
  switch (type) {
    case 0:
      return object.sort((a, b) => a.text.localeCompare(b.text));
    case 1:
      return object.sort((a, b) => a.localeCompare(b));
    case 2:
      return object.sort((a, b) => b.localeCompare(a));
    default:
      return object;
  }
};

export const flatten = (object) => {
  return Object.keys(object).map((key) => object[key]);
};
