//Predictive Analytics: Both School and Network View Constants
export const schoolAndNetworkView = {
  overview:
    "In these beta dashboard pages, we display the results from statistical models that predict the likelihood that a student in grades 3 through 12 will (1) graduate from high school, (2) graduate from high school having met the A-G course requirements, and (3) persist into their second year at a four-year college or university. We make these predictions using data from students' ELA and math test scores, attendance, course grades, course levels, and program participation (ELL, special education, and gifted and talented). Note that these models are still under development and predictions may change as we make improvements to the models.",
};

//The options may have to be replaced with dynamic options brought in through mapState (this.props.options) depending on how the data is organized. These are placeholders for the time being.

export const successOptions = [
  {
    name: "high school graduation with A-G requirements met",
    value: "probability_grad_ag",
  },
  { name: "high school graduation", value: "probability_grad" },
  {
    name: "persistence into 2nd year of 4 year college/university",
    value: "probability_persist",
  },
];

const yearOptions = [
  { name: "2019", value: "2019" },
  { name: "2018", value: "2018" },
];

const predictOptions = [
  { name: "improvement in prediction of", value: "measure_delta" },
  { name: "prediction of", value: "measure_current" },
];

//Predictive Analytics: School View Constants
export const schoolView = {
  menuSelect: [
    {
      type: "text",
      text: "What is the school's prediction of (and improvement in prediction of)",
    },
    {
      type: "select",
      name: "success_options",
      options: successOptions,
    },
    {
      type: "text",
      text: "for students in ",
    },
    {
      type: "selectGrade",
      name: "grade_options",
    },
    {
      type: "text",
      text: "in ",
    },
    {
      type: "select",
      name: "year_options",
      options: yearOptions,
    },
    {
      type: "text",
      text: "?",
    },
  ],
};

//Predictive Analytics: Network View Constants

export const networkView = {
  menuSelect: [
    {
      type: "text",
      text: "Which schools have the highest ",
    },
    {
      type: "select",
      name: "predict_options",
      options: predictOptions,
    },
    {
      type: "select",
      name: "success_options",
      options: successOptions,
    },
    {
      type: "text",
      text: "for students in ",
    },
    {
      type: "selectGrade",
      name: "grade_options",
    },
    {
      type: "text",
      text: "in ",
    },
    {
      type: "select",
      name: "year_options",
      options: yearOptions,
    },
    {
      type: "text",
      text: "?",
    },
  ],
};
