import axios from "axios";

/**
 * CONSTANT VARIABLES
 */
const API = process.env.REACT_APP_API;

/*
 *  ACTION TYPES
 */

export const FETCH_INITIAL_CONSTANTS = "FETCH_INITIAL_CONSTANTS";
export const FETCH_INITIAL_CONSTANTS_SUCCESS =
  "FETCH_INITIAL_CONSTANTS_SUCCESS";
export const FETCH_INITIAL_DEFINITIONS_SUCCESS =
  "FETCH_INITIAL_DEFINITIONS_SUCCESS";

export const FETCH_LCAP_CONSTANTS = "FETCH_LCAP_CONSTANTS";
export const FETCH_LCAP_CONSTANTS_SUCCESS = "FETCH_LCAP_CONSTANTS_SUCCESS";

/*
 *  ACTION CREATORS
 */

const fetchInitialConstants = () => ({ type: FETCH_INITIAL_CONSTANTS });
export const fetchInitialConstantsSuccess = (constants) => ({
  type: FETCH_INITIAL_CONSTANTS_SUCCESS,
  constants,
});
export const fetchInitialDefinitionsSuccess = (measure_definitions) => ({
  type: FETCH_INITIAL_DEFINITIONS_SUCCESS,
  measure_definitions,
});

const fetchLcapConstants = () => ({ type: FETCH_LCAP_CONSTANTS });
const fetchLcapConstantsSuccess = (constants) => ({
  type: FETCH_LCAP_CONSTANTS_SUCCESS,
  constants,
});
/*
 * THUNK CREATORS
 */

export const initialConstants = () => (dispatch) => {
  dispatch(fetchInitialConstants());
  axios.get(API + `/constants/initial`).then((response) => {
    const { data } = response;
    dispatch(fetchInitialConstantsSuccess(data));
  });
  return "hello";
};

export const lcapConstants = () => (dispatch) => {
  dispatch(fetchLcapConstants());
  axios.get(API + `/constants/lcap`).then((response) => {
    const { data } = response;
    dispatch(fetchLcapConstantsSuccess(data));
  });
};

const convertType = (selected, type) => {
  switch (selected) {
    case "subgroups": {
      switch (type) {
        case "alias":
          return "subgroup_alias";
        case "name":
          return "subgroup_name";
        default:
          return type;
      }
    }
    case "measures": {
      switch (type) {
        case "name":
          return "measure_name";
        default:
          return type;
      }
    }
    default:
      return type;
  }
};

export const updateConstants = (selected, changes) => (dispatch) => {
  const changeObject = Object.keys(changes).reduce((obj, key) => {
    obj[key] = Object.keys(changes[key]["changes"]).reduce((object, type) => {
      object[convertType(selected, type)] =
        changes[key]["changes"][type]["curr"];
      return object;
    }, {});
    return obj;
  }, {});
  const input = {
    changes: changeObject,
  };
  axios
    .post(API + `/constants/${selected}/update`, input)
    .then((response) => {});
};

export const updateLcapValues = (selected, measure, changes) => (dispatch) => {
  switch (selected) {
    case "values": {
      const update = Object.keys(changes).reduce((object, level) => {
        const { colorGrid, sliders, reverse } = changes[level];
        object[level] = {
          f_color_grid: colorGrid,
        };
        ["topBar", "sideBar"].forEach((axis) => {
          const { yellowRange, values, styles } = sliders["topBar"];
          const prefix = axis === "topBar" ? "x_" : "y_";
          values.forEach((val, index) => {
            const color = `${prefix}${styles[index].backgroundColor}`;
            if (!object[level][color]) {
              object[level][color] = [val];
            } else {
              object[level][color].push(val);
            }
          });
        });
        return object;
      }, {});
      const data = {
        measure,
        changes: update,
      };
      axios
        .post(API + `/constants/lcap/values/update`, data)
        .then((response) => {});
      break;
    }
    case "grids": {
      axios
        .post(API + `/constants/lcap/grids/update`, {
          id: measure,
          changes,
        })
        .then((response) => {});
      break;
    }
    default:
      break;
  }
};
