const initialState = {
  measures: {},
  subgroups: {},
  institutions: {},
  config: {
    xAxis: "measures",
    yAxis: "institutions",
    options: "subgroups",
  },
};

export default function smallMultiples(state = initialState, action) {
  switch (action) {
    default:
      return state;
  }
}
