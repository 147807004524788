export const sort = (object, type) => {
  switch (type) {
    case 0:
      return object.sort((a, b) => a.text.localeCompare(b.text));
    case 1:
      return object.sort((a, b) => a.localeCompare(b));
    case 2:
      return object.sort((a, b) => b.localeCompare(a));
    default:
      return object;
  }
};

export const flatten = (object) => {
  return Object.keys(object).map((key) => object[key]);
};
