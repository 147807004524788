import {
  dangerColor,
  defaultFont,
  grayColor,
  infoColor,
  primaryColor,
  roseColor,
  successColor,
  warningColor,
} from "./core-dashboard";

const mapViewStyle = (theme) => ({
  plot: {
    border: "2px solid #BBCAD0",
    boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
  },
  gradientBackground: {
    background:
      "linear-gradient(90deg, rgba(216,229,237,1) 69%, rgba(255,255,255,1) 82%) !important",
  },
  parentDiv: {
    paddingTop: "10px",
    height: "76vh",
    width: "100%",
  },
  cardTitleBlue: {
    color: "#4D7E91",
    marginTop: "0px",
    minHeight: "auto",
    fontSize: "18px",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "0px",
    textDecoration: "none",
    fontWeight: "850",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  legend: {
    paddingLeft: 15,
    paddingTop: 10,
    paddingBottom: 10,
  },
  legendText: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#000000",
    fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
  },
  legendContainer: {
    paddingLeft: 5,
  },
  colorBlock: {
    width: "40px",
    height: "40px",
  },
  orangeBackdrop: {
    textAlign: "center",
    backgroundColor: "#f4861f",
    backgroundImage:
      "repeating-linear-gradient(-45deg,transparent,transparent 8.125px,rgba(0,0,0,.06) 0,rgba(0,0,0,.06) 9.125px)",
  },
  greenBackdrop: {
    textAlign: "center",
    backgroundColor: "#67b948",
    backgroundImage:
      "repeating-linear-gradient(-45deg,transparent,transparent 5.5px,rgba(0,0,0,.06) 0,rgba(0,0,0,.06) 6.5px)",
  },

  redBackdrop: {
    textAlign: "center",
    backgroundColor: "#ef4b4b",
    backgroundImage:
      "repeating-linear-gradient(45deg,transparent,transparent 10.5px,rgba(0,0,0,.06) 0,rgba(0,0,0,.06) 11.5px)",
  },
  yellowBackdrop: {
    textAlign: "center",
    backgroundColor: "#f0f41f",
    backgroundImage:
      "repeating-linear-gradient(90deg,transparent,transparent 5px,rgba(0,0,0,.06) 0,rgba(0,0,0,.06) 6px)",
  },
  blueBackdrop: {
    textAlign: "center",
    backgroundColor: "#127ea8",
    backgroundImage:
      "repeating-linear-gradient(0deg,transparent,transparent 10px,rgba(0,0,0,.06) 0,rgba(0,0,0,.06) 11px)",
  },
  legendIndex: {
    paddingRight: "1rem",
    color: "black",
    fontSize: "12px",
    fontWeight: "500",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  },
});

export default mapViewStyle;
