import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import { initialLcapValueInfo, yellowRangeChange } from "./helpers";
import { LcapGridTable, LcapValues } from "./components";

export default class LcapUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: "values",
      subSelected: "chron_abs",
      changes: {},
      grid: {},
      info: {},
      selectedCell: [null, null],
    };
  }
  handleGridSelect = (row, col) => {
    this.setState({
      selectedCell: [row, col],
    });
  };
  componentDidUpdate(prevProps, prevState, snapShot) {
    const { lcap, measures } = this.props;
    const { selected, subSelected, info } = this.state;
    const prevSelected = prevState["selected"];
    const prevSubselected = prevState["subSelected"];
    if (
      selected !== prevSelected ||
      subSelected !== prevSubselected ||
      !Object.keys(info).length
    ) {
      switch (selected) {
        case "values": {
          const values = lcap["values"][subSelected];
          const newInfo = initialLcapValueInfo(values);
          if (Object.keys(newInfo).length) {
            this.setState({
              info: newInfo,
            });
          }
          break;
        }
        case "grids": {
          break;
        }
        default:
          break;
      }
    }
  }
  handleGridChange = (level, event) => {};
  handleColorSelect = (row, col, color) => {
    const { changes } = this.state;
    const newChanges = { ...changes };
    if (!newChanges[row]) {
      newChanges[row] = {};
    }
    newChanges[row][col] = color;
    this.setState({
      changes: newChanges,
      selectedCell: [null, null],
    });
  };
  handleCheck = (level, axis) => {
    const { info } = this.state;
    const current = info[level]["sliders"][axis];
    const newAxis = yellowRangeChange(current);
    this.setState({
      info: {
        ...info,
        [level]: {
          ...info[level],
          ["sliders"]: {
            ...info[level]["sliders"],
            [axis]: newAxis,
          },
        },
      },
    });
  };
  handleSlider = (axis, level, newValues) => {
    const { info } = this.state;
    this.setState({
      info: {
        ...info,
        [level]: {
          ...info[level],
          ["sliders"]: {
            ...info[level]["sliders"],
            [axis]: {
              ...info[level]["sliders"][axis],
              values: newValues,
            },
          },
        },
      },
    });
  };
  handleChange = (event) => {
    this.setState({ subSelected: event.target.value });
  };
  handleSubmit = () => {
    const { selected, subSelected, info, changes } = this.state;
    const { submit } = this.props;
    switch (selected) {
      case "values": {
        submit(selected, subSelected, info);
        break;
      }
      case "grids": {
        const {
          lcap: { grids },
        } = this.props;
        const colors = ["red", "orange", "yellow", "green", "blue"];
        const flattened = colors.reduce((array, rColor) => {
          colors.forEach((cColor) => {
            const change = changes[rColor] ? changes[rColor][cColor] : null;
            const curr = change || grids[subSelected][rColor][cColor];
            array.push(curr);
          });
          return array;
        }, []);
        submit(selected, subSelected, flattened);
        break;
      }
    }
  };
  render() {
    const { selectedCell, subSelected, selected, changes, info } = this.state;
    const { lcap } = this.props;
    const current = lcap[selected] || {};
    const list = current ? Object.keys(current) : [];
    const gridList = lcap["grids"] ? Object.keys(lcap["grids"]) : [];
    return (
      <Paper>
        <Grid container>
          <Grid item>
            <Button
              onClick={() => {
                this.setState({
                  selected: "values",
                  changes: {},
                  subSelected: "chron_abs",
                });
              }}
            >
              Values
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => {
                this.setState({
                  selected: "grids",
                  subSelected: "1",
                  changes: {},
                });
              }}
            >
              Grids
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => {
                this.setState({ selected: "text" });
              }}
            >
              Text
            </Button>
          </Grid>
        </Grid>
        {selected === "values" ? (
          <LcapValues
            onSelect={this.handleChange.bind(this)}
            onChange={this.handleGridChange.bind(this)}
            submit={this.handleSubmit.bind(this)}
            onCheck={this.handleCheck.bind(this)}
            onSlide={this.handleSlider.bind(this)}
            measureList={list}
            selectedMeasure={subSelected}
            gridList={gridList}
            info={info}
          />
        ) : (
          <LcapGridTable
            onGridSelect={this.handleGridSelect.bind(this)}
            onSelect={this.handleChange.bind(this)}
            selectColor={this.handleColorSelect.bind(this)}
            submit={this.handleSubmit.bind(this)}
            changes={changes}
            selectedCell={selectedCell}
            gridList={gridList}
            selectedGrid={subSelected}
            grids={lcap["grids"]}
          />
        )}
      </Paper>
    );
  }
}
