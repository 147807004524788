import React, { Component } from "react";
import { compose } from "redux";
import connect from "react-redux/es/connect/connect";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Typography } from "@material-ui/core/es";
import { metricActions } from "../Metrics/actions";
import TableTitle from "../StyledComponents/Table/TitleTable";
import Card from "../StyledComponents/Card/Card";
import CardHeader from "../StyledComponents/Card/CardHeader";
import CardBody from "../StyledComponents/Card/CardBody";
import Plot from "react-plotly.js";
import CreateTable from "../StyledComponents/Table/TableList";
import TableCell from "@material-ui/core/TableCell";
import tableStyle from "../assets/jss/core-dashboard/components/tableStyle";
import LoadingGrid from "../StyledComponents/Grid/LoadingGrid";
import { addCommas } from "../utils";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  titleHeader: {
    fontSize: "3.0em",
  },
  titleText: {
    fontSize: "3.0em",
  },
});

class SchoolSummary extends Component {
  render() {
    const {
      subgroups,
      selected,
      schoolSummary,
      district,
      school,
      subGroup,
      year,
      classes,
      loading,
    } = this.props;
    const demographics = schoolSummary["demo_pct"]
      ? ["ALL"].concat(
          Object.keys(schoolSummary["demo_pct"]).reduce((arr, curr) => {
            if (curr !== "ALL") {
              arr.push(curr);
            }
            return arr;
          }, [])
        )
      : [];
    const pathways = Object.keys(
      demographics.reduce((obj, curr) => {
        const c = schoolSummary["demo_pct"][curr];
        Object.keys(c).forEach((pathway) => {
          obj[pathway] = "";
        });
        return obj;
      }, {}) || {}
    );
    const sorted = ["No Pathway"].concat(
      pathways.reduce((arr, curr) => {
        if (curr != "No Pathway") {
          return arr.concat(curr);
        }
        return arr;
      }, [])
    );
    const colors = [
      "#ef4b4b",
      "#5590b5",
      "#67b948",
      "#f79c1c",
      "#f5e781",
      "#e86d1f",
      "#36454f",
      "#fffdd0",
      "#294f29",
    ];
    const pathwayColors = sorted.reduce((obj, pathway, index) => {
      obj[pathway] = colors[index];
      return obj;
    }, {});
    const demoOrder = ["ALL", "SD", "SWD", "EL", "AA", "HI", "WH", "AS"];
    const demoAlias = {
      SWD: "SwD",
      SD: "econ Dis",
    };
    const pathwayTable = sorted.map((pathway) => {
      const title = [
        {
          type: "LLA TITLE",
          text: pathway,
          color: pathwayColors[pathway],
        },
      ];
      const data = demoOrder.map((group) => {
        if (schoolSummary["demo_pct"]) {
          const { meas_rate, numerator } = schoolSummary["demo_pct"][group][
            pathway
          ] || { meas_rate: 0, numerator: 0 };
          return group !== "ALL"
            ? {
                type: "LLA DEMOGRAPHIC",
                maxWidth: "175px",
                subtext: `(${addCommas(`${numerator}`)} students)`,
                text: `${meas_rate}%`,
              }
            : {
                type: "LLA DEMOGRAPHIC",
                maxWidth: "145px",
                text: `${addCommas(`${numerator}`)} students`,
              };
        }
        return {};
      });
      return title.concat(data);
    });
    const headers = ["Name"].concat(
      demoOrder.map((curr) => {
        if (curr === "ALL") {
          return "Student Count";
        } else {
          const { name, alias } = subgroups[curr];
          return `${name} (${demoAlias[curr] || alias || curr})`;
        }
        return demoAlias[curr] || curr;
      })
    );
    const chartOrder = [
      "chron_abs",
      "perc_prof_math",
      "perc_prof_ela",
      "susp_core",
      "lla_pip",
      "lla_psc",
    ];
    const titles = [
      "Chronic Absence",
      "Percent Proficient Math",
      "Perfect Proficient ELA",
      "Suspension Rate",
      "Student Persistence in Pathway",
      "Post Secondary Credits",
    ];
    const reverse = sorted.map((c) => c).reverse();
    const barCharts = chartOrder.map((key) => {
      const data = (schoolSummary[key] || {})[subGroup];
      if (!data) {
        return "NO DATA";
      }
      return reverse.reduce(
        (obj, pathway) => {
          const currData = data[pathway];
          if (currData) {
            const { meas_rate, numerator, denominator } = currData;
            obj.x.push(meas_rate);
            obj.y.push(pathway);
            obj.text.push(`<b>${meas_rate}%</b>`);
            const text =
              `<b style="font-size:16px; text-align:center;border-bottom: 2px solid black">${pathway}</b><br>` +
              `<b>Year: </b>${year.split("-")[1]}<br>` +
              `<b>School: </b>${school.text}<br>` +
              `<b>District: </b>${district.text}<br>` +
              `<b>Numerator: </b>${numerator}<br>` +
              `<b>Denominator: </b>${denominator}<br>` +
              `<b>Percentage: </b>${meas_rate}%<br>` +
              `<extra></extra>`;
            obj.hovertemplate.push(text);
            obj.marker.color.push(pathwayColors[pathway]);
          }
          return obj;
        },
        {
          marker: {
            color: [],
          },
          type: "bar",
          x: [],
          y: [],
          text: [],
          hoverinfo: "none",
          hovertemplate: [],
          textposition: "outside",
          outsidetextfont: {
            size: "16px",
            color: "rgb(9, 75, 101)",
          },
          orientation: "h",
        }
      );
    });
    return (
      <Grid
        style={{ margin: "0 2.5% 0 2.5%", width: "95%" }}
        justifyContent={"center"}
        container
      >
        <TableTitle
          school={{}}
          district={{}}
          hideInfo
          pageTitle={"School Pathway Summary"}
        />
        <Grid xs={12} item>
          <CreateTable
            loading={loading}
            data={pathwayTable}
            header={headers}
            title={"Pathway School Demographics"}
            hideFooter
            index={0}
          />
        </Grid>
        <Grid xs={12} container item>
          <CardBody id={"card-body"} style={{ width: "100%", padding: "0px" }}>
            <Grid justifyContent={"space-evenly"} xs={12} container item>
              {barCharts.map((chart, index) => (
                <Grid xs={10} lg={5} item>
                  <Card>
                    <CardHeader
                      className={classes.gradientBackground}
                      color="primary"
                    >
                      <h4 className={classes.cardTitleBlue}>{titles[index]}</h4>
                    </CardHeader>
                    <CardBody
                      style={{
                        paddingTop: "0px",
                        width: "100%",
                        marginTop: "10px",
                        paddingBottom: "10px",
                        textAlign: "center",
                      }}
                    >
                      {loading ? (
                        <LoadingGrid />
                      ) : chart !== "NO DATA" ? (
                        <div className={classes.plot} style={{ width: "100%" }}>
                          <Plot
                            style={{ width: "100%" }}
                            data={[chart]}
                            layout={{
                              margin: {
                                l: 100,
                                t: 10,
                                b: 80,
                                r: 30,
                              },
                              yaxis: {
                                tickvals: reverse,
                                ticktext: reverse.map((pw) => {
                                  const nPw = pw.split(" ").reduce((s, c) => {
                                    const split = s.split("<br>");
                                    const br =
                                      split[split.length - 1].length +
                                        c.length >
                                      20
                                        ? "<br>"
                                        : " ";
                                    return `${s}${br}${c}`;
                                  }, "");
                                  return `<b>${nPw}</b>`;
                                }),
                                automargin: true,
                                linewidth: 6,
                                linecolor: "rgb(9, 75, 101)",
                              },
                              xaxis: {
                                range: [0, 100],
                                tickvals: [0, 20, 40, 60, 80, 100],
                                ticktext: [
                                  "<b>0</b>",
                                  "<b>20</b>",
                                  "<b>40</b>",
                                  "<b>60</b>",
                                  "<b>80</b>",
                                  "<b>100</b>",
                                ],
                                title: {
                                  text: "<b>Measure Rate (%)</b>",
                                  font: {
                                    color: "rgb(9, 75, 101)",
                                  },
                                },
                                linewidth: 6,
                                linecolor: "rgb(9, 75, 101)",
                              },
                            }}
                          />
                        </div>
                      ) : (
                        <Typography
                          style={{
                            marginLeft: "5%",
                            float: "left",
                            color: "rgba(0,0,0,.1)",
                            fontWeight: "900",
                            fontSize: "36px",
                          }}
                        >
                          No Data
                        </Typography>
                      )}
                    </CardBody>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </CardBody>
        </Grid>
      </Grid>
    );
  }
}

const findInMenu = (array, key) => {
  const res = {
    code: "",
    text: "",
  };
  if (!array) {
    return res;
  }
  return array.reduce((found, curr) => {
    if (curr.code === key) {
      found = curr;
    }
    return found;
  }, res);
};

const mapState = (state) => {
  const { subgroups } = state.constants;
  const { selected, menuObject } = state.menu;
  const district = findInMenu(menuObject["District"], selected["District"]);
  const school = findInMenu(menuObject["School"], selected["School"]);
  const year = selected["School Year"] ? selected["School Year"] : "";
  const subGroup = selected["Student Groups"];
  const { schoolSummary, loading } = state.metrics;
  return {
    subgroups,
    selected,
    district,
    school,
    year,
    subGroup,
    schoolSummary,
    loading,
  };
};

const mapDispatch = (dispatch) => {
  return {
    getSchoolSummary: (selected) => {
      dispatch(metricActions.getSchoolSummary(selected));
    },
  };
};

export default compose(
  withStyles(tableStyle, { withTheme: true }),
  connect(mapState, mapDispatch)
)(SchoolSummary);
