import React, { Component } from "react";
import Sidebar from "./Navigation/Sidebar";
import Deeplink from "./Deeplink";
import history from "../history";
import connect from "react-redux/es/connect/connect";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    const url = this.props.location.pathname;
    const menu = this.props.menu;
    if (!Object.keys(menu).length) {
      history.push(`/loading${url}`);
    }
  }
  render() {
    const url = this.props.location.pathname;
    const menu = this.props.menu;
    if (!Object.keys(menu).length) {
      return null;
    }
    return (
      <div>
        <Sidebar path={url} />
        <Deeplink path={url} />
      </div>
    );
  }
}

const mapState = (state) => {
  const { menu } = state.menu;
  return {
    menu,
  };
};

export default connect(mapState, null)(Dashboard);
