import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import { UpdateTable, Footer, ChangeList } from "./components";

export default class AppWide extends Component {
  constructor(props) {
    super(props);
    const nPages = Math.floor(Object.keys(props["group"]).length / 5) + 1;
    this.state = {
      showReview: false,
      changes: {},
      table: {
        page: 0,
        numResults: 5,
        nPages,
      },
      info: {},
    };
  }
  componentDidUpdate(prevProps, prevState, snapShot) {
    const { selected, group } = this.props;
    const prevSelected = prevProps["selected"];
    if (
      selected !== prevSelected ||
      Object.keys(group).length !== Object.keys(prevProps["group"]).length
    ) {
      const { numResults } = prevState.table;
      const nPages = Math.floor(Object.keys(group).length / numResults) + 1;
      this.setState({
        table: {
          numResults,
          page: 0,
          nPages,
        },
        info: {},
      });
    }
  }
  handleNav = (direction) => {
    const {
      table: { page, nPages },
    } = this.state;
    switch (direction) {
      case "PREV":
        this.setState({
          table: {
            ...this.state.table,
            page: Math.max(0, page - 1),
          },
        });
        break;
      case "NEXT":
        this.setState({
          table: {
            ...this.state.table,
            page: Math.min(nPages, page + 1),
          },
        });
        break;
      default:
        break;
    }
  };
  toggleEdit = (cell) => {
    const { info } = this.state;
    const { group } = this.props;
    const { code } = cell;
    const data = group[code];
    const parsed = Object.keys(data).reduce((obj, key) => {
      obj[key] = data[key] === undefined ? "" : data[key];
      return obj;
    }, {});
    const cellObject = info[code];
    if (!cellObject || (cellObject ? !cellObject["edit"] : true)) {
      this.setState({
        info: {
          ...info,
          [code]: {
            ...parsed,
            edit: true,
          },
        },
      });
    } else {
      this.setState({
        info: {
          ...this.state.info,
          [code]: {
            edit: false,
          },
        },
      });
    }
    // this.setState({edit: !this.state.edit})
  };
  handleChange = (cell, checkbox) => (event) => {
    const { info } = this.state;
    const cellObj = info[cell.code] || {};
    this.setState({
      info: {
        ...info,
        [cell.code]: {
          ...cellObj,
          [cell.type]: checkbox ? event.target.checked : event.target.value,
        },
      },
    });
  };
  closeReview = () => {
    this.setState({
      showReview: false,
    });
  };
  handleReview = () => {
    const { info } = this.state;
    const { group, selected } = this.props;
    const name = selected === "subgroups" ? "name" : "text";
    const changes = Object.keys(info).reduce((obj, key) => {
      obj[key] = Object.keys(info[key]).reduce(
        (differences, currKey) => {
          const old = group[key][currKey];
          const curr = info[key][currKey];
          if (currKey !== "edit" && curr !== old) {
            differences["changes"][currKey] = {
              old,
              curr,
            };
          }
          return differences;
        },
        {
          title: `${key} - ${group[key]["name"]}`,
          changes: {},
        }
      );
      if (!Object.keys(obj[key].changes).length) {
        delete obj[key];
      }
      return obj;
    }, {});
    this.setState({
      showReview: true,
      changes,
    });
  };
  handleSubmit = () => {
    const { sendUpdate, selected } = this.props;
    const { changes } = this.state;
    sendUpdate(selected, changes);
  };
  render() {
    const { info, table } = this.state;
    const { selected, group } = this.props;
    const columns =
      selected === "subgroups"
        ? ["code", "name", "alias", "racial_or_ethnic"]
        : ["code", "name", "text", "subtext", "percent", "college"];
    const editButton = {
      type: "EDIT",
    };
    const infoTable = Object.keys(group).map((code) => {
      return [
        {
          ...editButton,
          code,
        },
      ].concat(
        columns.map((type) => {
          return {
            type,
            code,
            value: type === "code" ? code : group[code][type] || "",
            editable: type !== "code",
          };
        })
      );
    });
    return (
      <Paper>
        <ChangeList
          submit={this.handleSubmit.bind(this)}
          close={this.closeReview.bind(this)}
          open={this.state.showReview}
          updates={this.state.changes}
        />
        <UpdateTable
          columns={columns}
          infoTable={infoTable}
          table={table}
          updateInfo={info}
          toggleEdit={this.toggleEdit.bind(this)}
          onChange={this.handleChange.bind(this)}
        />
        <Footer
          handleNav={this.handleNav.bind(this)}
          table={this.state.table}
          submit={this.handleReview.bind(this)}
        />
      </Paper>
    );
  }
}
