import { Component } from "react";
import Grid from "@material-ui/core/Grid/Grid";
import { compose } from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import connect from "react-redux/es/connect/connect";
import React from "react";
import CardHeader from "../StyledComponents/Card/CardHeader";
import Card from "../StyledComponents/Card/Card";
import tableStyle from "../assets/jss/core-dashboard/components/tableStyle";
import { footer } from "../Helpers/GlobalHelpers";
import { StackedBarchart } from "../StyledComponents/DataVisualizations/StackedBarchart";

const styles = (theme) => ({
  onTrackLanesRoot: {
    paddingTop: "225px",
    width: "100%",
  },
  overviewText: {
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "25px",
    fontSize: "20px",
    color: "rgba(60,114,135,.9)",
    lineHeight: "40px",
  },
  legend: {
    paddingLeft: 100,
  },
});

class OnTrackLanes extends Component {
  render() {
    const { classes, data } = this.props;
    return (
      <Grid container justifyContent="center" className={classes.onTrackLanesRoot}>
        <Card className={classes.onTrackCard}>
          <CardHeader className={classes.gradientBackground} color="primary">
            <h4 className={classes.cardTitleBlue}>
              Distribution of On-Track Lanes by Year
            </h4>
          </CardHeader>
        </Card>
        <Grid container>
          <StackedBarchart
            id={"OnTrackLanesBarchart"}
            data={data}
            height={500}
          />
          <Grid container item className={classes.legend}>
            {footer({
              pageType: "OnTrackLanes",
              title: "On-Track Lanes",
              classes,
            })}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapState = (state) => {
  const { lanes } = state.onTrack;

  return {
    data: lanes,
  };
};

export default compose(
  withStyles((theme) => ({
    ...tableStyle(theme),
    ...styles(theme),
  })),
  connect(mapState)
)(OnTrackLanes);
