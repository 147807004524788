import { Component, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid/Grid";
import { compose } from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import connect from "react-redux/es/connect/connect";
import React from "react";
import CardHeader from "../StyledComponents/Card/CardHeader";
import Card from "../StyledComponents/Card/Card";
import tableStyle from "../assets/jss/core-dashboard/components/tableStyle";
import { LineChart } from "../StyledComponents/DataVisualizations/LineChart";
import _ from "lodash";

const styles = (theme) => ({
  onTrackRatesBySubjectRoot: {
    paddingTop: "225px",
    width: "100%",
  },
  overviewText: {
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "25px",
    fontSize: "20px",
    color: "rgba(60,114,135,.9)",
    lineHeight: "40px",
  },
  onTrackRatesLineGraph: {
    paddingTop: 10,
    paddingBottom: 10,
  },
});

const OnTrackRatesBySubject = React.memo((props) => {
  const { classes, data } = props;

  const [displayData, setDisplayData] = useState([]);

  useEffect(() => {
    if (data && !_.eq(displayData, data)) {
      setDisplayData(data);
    }
  }, [data]);

  return (
    <Grid
      container
      justifyContent="center"
      className={classes.onTrackRatesBySubjectRoot}
    >
      <Card className={classes.onTrackCard}>
        <CardHeader className={classes.gradientBackground} color="primary">
          <h4 className={classes.cardTitleBlue}>A-G D/F Rates by Subject</h4>
        </CardHeader>
      </Card>
      <Grid container>
        {data &&
          data.map((value, i) => {
            return (
              <Grid item xs={3} className={classes.onTrackRatesLineGraph}>
                <LineChart
                  id={`OnTrackRatesBySubject_${i}`}
                  data={value}
                  height={300}
                />
              </Grid>
            );
          })}
      </Grid>
    </Grid>
  );
});

const mapState = (state) => {
  const { ratesBySubject } = state.onTrack;
  return {
    data: ratesBySubject,
  };
};

export default compose(
  withStyles((theme) => ({
    ...tableStyle(theme),
    ...styles(theme),
  })),
  connect(mapState)
)(OnTrackRatesBySubject);
