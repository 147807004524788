import { menu_constants } from "./menu_constants";

//First helper call. 2nd Tier.
export const getPageType = (path) => {
  if (path === "dashboard") {
    return "default";
  }
  return path.split("/").reduce(
    (type, curr) => {
      switch (curr) {
        case "lcap":
        case "prelim":
        case "ondemand":
        case "academic":
        case "linkedlearning":
        case "predictiveanalytics":
          return {
            ...type,
            type: curr,
          };
        case "schoolreport":
        case "scatterplots":
        case "gaptrends":
        case "collegedistribution":
        case "barcharts":
        case "barplotdistributions":
        case "pathwaylongitudinal":
        case "metrictrends":
        case "schoolview":
        case "networkview":
        case "mapview":
          return {
            ...type,
            subtype: curr,
          };
        case "ontrack":
          return {
            ...type,
            subtype: curr,
          };
        case "lcap-5x5schools":
        case "lcap-5x5studentgroup":
          return {
            ...type,
            subtype: "5x5",
          };

        default:
          return type;
      }
    },
    {
      type: "",
      subtype: "",
    }
  );
};

//Third helper call getMenu()-->generateSeleted(). Third tier.
export const getBaseDistrict = ({
  menu,
  selected,
  step,
  type,
  baseNetwork,
  districtKeys,
}) => {
  const networkDistricts = menu["districts"][baseNetwork];
  const keys = Object.keys(networkDistricts);
  const inList = selected["District"]
    ? selected["District"] === "overall" ||
      !!networkDistricts[selected["District"]]
    : false;
  const district = inList
    ? selected["District"]
    : networkDistricts[keys[0]]["code"];
  switch (type.type) {
    default:
      switch (type.subtype) {
        case "5x5":
        case "prelim":
        case "ondemand":
        case "academic":
        case "schoolreport":
        case "predictiveanalytics":
          return !district || district === "overall"
            ? menu["districts"][baseNetwork][districtKeys[0]]["code"]
            : district;
        default:
          return step < 1 && district !== "overall"
            ? inList
              ? district
              : menu["districts"][baseNetwork][districtKeys[0]]["code"]
            : district;
      }
  }
};

//Fourth helper call getMenu()-->generateSeleted(). Third tier.
///I can limit menu for lla here
export const getBaseSchoolLevel = ({
  menu,
  selected,
  step,
  baseDistrict,
  type,
}) => {
  const possibleLevels = [
    "barcharts",
    "5x5",
    "metrictrends",
    "gaptrends",
    "schoolview",
  ].includes(type.subtype)
    ? ["ES", "K8", "MS", "HS"]
    : type.subtype === "ontrack"
    ? ["K8", "MS", "HS"]
    : ["ES", "K8", "MS", "HS", "Overall"];
  const networkKeys = Object.keys(menu["networks"]);
  let baseNetwork =
    step < 0 ? menu["networks"][networkKeys[0]]["code"] : selected["Network"];
  const baseDistrictKey = Object.keys(menu["districts"][baseNetwork])[0];

  const levels = possibleLevels;
  const included = selected["School Level"]
    ? levels.includes(selected["School Level"])
    : false;

  if (!included && type.subtype === "ontrack") return levels[0];

  switch (type.type) {
    case "prelim":
    case "linkedlearning":
      const list = menu["schools"][baseDistrict]
        ? Object.keys(menu["schools"][baseDistrict] || {})
        : Object.keys(
            menu["schools"][
              menu["districts"][baseNetwork][baseDistrictKey]["code"]
            ]
          );
      const hasHS = list.includes("HS");
      return selected["School Level"]
        ? hasHS
          ? "HS"
          : selected["School Level"]
        : hasHS
        ? "HS"
        : list[0];
    case "lcap":
      return selected["School Level"] === "overall"
        ? levels[0]
        : selected["School Level"];

    default: {
      return step < 2 && selected["District"] !== "overall"
        ? !included
          ? levels[levels.length - 1] === "Overall"
            ? levels[levels.length - 2]
            : levels[levels.length - 1]
          : selected["School Level"]
        : selected["School Level"];
    }
  }
};

//Fifth helper call getMenu()-->generateSeleted(). Third tier.
export const getBaseSchool = ({
  menu,
  selected,
  step,
  schoolList,
  type,
  baseSchoolLevel,
}) => {
  const school = selected["School"];
  const district = selected["District"];
  const inList = !!schoolList[school];
  if (type.type === "prelim" || type.type === "linkedlearning") {
    if (district === "overall") {
      return {
        code: "overall",
        name: "District Overall",
        schoolYears: menu["networks"][selected["Network"]]["schoolYears"],
      };
    } else {
      return inList
        ? schoolList[school]
        : schoolList[Object.keys(schoolList)[0]];
    }
  } else {
    if (district === "overall") {
      return {
        code: "overall",
        name: "District Overall",
        schoolYears: menu["networks"][selected["Network"]]["schoolYears"],
      };
    }
    switch (school) {
      case "overall": {
        switch (step) {
          default: {
            const defaultLevel = Object.keys(
              menu["schools"][selected["District"]]
            )[0];
            const currDistrict = menu["schools"][selected["District"]][
              baseSchoolLevel
            ]
              ? menu["schools"][selected["District"]][baseSchoolLevel]
              : menu["schools"][selected["District"]][defaultLevel];
            const yearList = Object.keys(currDistrict).reduce((years, curr) => {
              const schoolsList = currDistrict[curr];
              Object.keys(schoolsList).forEach((currSchool) => {
                const currentList = schoolsList[currSchool]["schoolYears"];
                if (
                  selected["School Type"] === "All" ||
                  curr === selected["School Type"]
                ) {
                  Object.keys(currentList).forEach((year) => {
                    years[year] = "";
                  });
                }
              });
              return years;
            }, {});
            return {
              code: "overall",
              name: "District Overall",
              schoolYears: yearList,
            };
          }
        }
      }
      default: {
        switch (step) {
          case 2:
            if (inList) return schoolList[school];
          case -1:
          case 0:
          case 1:

          case 3: {
            const first = Object.keys(schoolList).reduce((first, curr) => {
              const firstText = schoolList[first].text;
              const currText = schoolList[curr].text;
              const compare = firstText.localeCompare(currText);
              if (compare > 0) {
                return curr;
              }
              return first;
            }, Object.keys(schoolList)[0]);
            return schoolList[first];
          }
          default:
            return schoolList[school];
        }
      }
    }
  }
};

//Sixth helper call getMenu()-->generateSeleted(). Third tier.
export const getBaseGroup = ({ selected, step, type }) => {
  const group = selected["Student Groups"];
  switch (type.subtype) {
    case "gaptrends":
      return group ? group : "SD";
    default:
      return group ? group : "ALL";
  }
};

const findInList = (list, code) => {
  return list.reduce((found, curr) => {
    if (curr.code === code) {
      return true;
    }
    return found;
  }, false);
};
//7th/8th/9th helper call getMenu()-->generateSeleted(). Third tier.

export const getBaseMeasure = ({ selected, type, constants }) => {
  const measure = selected["Measure"];
  const { menuOptions } = menu_constants;
  switch (type.type) {
    case "ondemand": {
      switch (type.subtype) {
        case "metrictrends": {
          const list = parseConstant({
            ...constants.measures,
            ...constants.collegeMeasures,
          });
          const found = findInList(list, measure);
          return found ? measure : "chron_abs";
        }
        default: {
          const list = parseConstant(constants.measures);
          const found = findInList(list, measure);
          return found ? measure : "chron_abs";
        }
      }
    }
    case "prelim": {
      switch (type.subtype) {
        case "barplotdistributions": {
          const list = menuOptions["College Measures"];
          const found = findInList(list, measure);
          return found ? measure : list[0].code;
        }
        default: {
          const list = parseConstant(constants.collegeMeasures);
          const found = findInList(list, measure);
          return found ? measure : list[0].code;
        }
      }
    }
    default: {
      const list = parseConstant(constants.measures);
      const found = findInList(list, measure);
      return found ? measure : "chron_abs";
    }
  }
};

const parseConstant = (list) => {
  const unsorted = Object.keys(list).map((key) => {
    const { name, measure_family } = list[key];
    return {
      text: name,
      code: key,
      measure_family,
    };
  });
  return unsorted.sort((a, b) => {
    return a.text.localeCompare(b.text);
  });
};

const getMetricTrendsList = (list) => {
  const filterArray = [
    "chron_abs",
    "cohort_ag",
    "growth_ela",
    "growth_math",
    "on_track_rate",
    "perc_prof_ela",
    "perc_prof_math",
    "susp_state",
  ];
  const tempArray = [];
  filterArray.forEach((key) => {
    const index = list.findIndex((val) => val.code === key);
    if (index >= 0) {
      tempArray.push(list[index]);
      list.splice(index, 1);
    }
  });
  const sortedArray = list.sort((a, b) => {
    return a.text - b.text;
  });

  return tempArray.concat(sortedArray);
};

export const getMeasureList = ({ type, constants, path }) => {
  const { menuOptions } = menu_constants;
  switch (type.type) {
    case "lcap": {
      if (type.subtype === "5x5") {
        const list = [
          "chron_abs",
          "perc_prof_math",
          "sel_stu_gm",
          "susp_core",
          "perc_prof_ela",
          "sel_stu_sa",
          "grad4y",
          "dfm_ela",
          // "hsr",
          "sel_stu_sm",
          "sel_stu_se",
          "susp_state",
          "dfm_math",
          "sbac_dfm_ela",
          "sbac_dfm_math",
          "caa_dfm_ela",
          "caa_dfm_math",
          "on_track_rate",
        ].reduce((obj, key) => {
          const curr = constants.measures[key];
          obj[key] = curr;
          return obj;
        }, {});
        return getMetricTrendsList(parseConstant(list));
      } else {
        const list = Object.keys(constants.measures).reduce((obj, key) => {
          const curr = constants.measures[key];
          if (curr.lcap_measure) {
            obj[key] = curr;
          }
          return obj;
        }, {});
        return getMetricTrendsList(parseConstant(list));
      }
      const list = Object.keys(constants.measures).reduce((obj, key) => {
        const curr = constants.measures[key];
        if (curr.lcap_measure) {
          obj[key] = curr;
        }
        return obj;
      }, {});
      return getMetricTrendsList(parseConstant(list));
    }
    case "prelim": {
      switch (type.subtype) {
        case "barplotdistributions":
          return menuOptions["College Measures"];
        default:
          return getMetricTrendsList(parseConstant(constants.collegeMeasures));
      }
    }
    case "ondemand": {
      return getMetricTrendsList(parseConstant(constants.measures));
    }
    default:
      if (path === "/dashboard/ontrack/gradedistribution") {
        const list = ["on_track_rate"].reduce((obj, key) => {
          const curr = constants.measures[key];
          obj[key] = curr;
          return obj;
        }, {});
        return getMetricTrendsList(parseConstant(list));
      }
      return getMetricTrendsList(parseConstant(constants.measures));
  }
};

const parseSubgroups = (subgroups) => {
  const sorted = Object.keys(subgroups).sort((a, b) => {
    if (a === "ALL") {
      return -100;
    } else {
      return subgroups[a]["name"].localeCompare(subgroups[b]["name"]);
    }
  });
  return sorted.map((key) => {
    const { name, code, alias } = subgroups[key];
    return {
      text: `${name} (${alias || key})`,
      code,
    };
  });
};

export const getStudentGroups = ({ type, subgroups }) => {
  const { menuOptions } = menu_constants;
  switch (type.type) {
    case "academic": {
      switch (type.subtype) {
        case "barcharts":
        case "schoolreport": {
          const toLose = ["CE", "HMLS", "ELRFEP", "FST"];
          const trimmed = Object.keys(subgroups).reduce((obj, key) => {
            if (!toLose.includes(key)) {
              obj[key] = subgroups[key];
            }
            return obj;
          }, {});
          return parseSubgroups(trimmed);
        }
        default: {
          const toLose = ["CE", "HMLS", "ELRFEP", "FST", "LOWPRE"];
          const trimmed = Object.keys(subgroups).reduce((obj, key) => {
            if (!toLose.includes(key)) {
              obj[key] = subgroups[key];
            }
            return obj;
          }, {});
          return parseSubgroups(trimmed);
        }
      }
    }
    default: {
      const toLose = ["CE", "LOWPRE"];
      const trimmed = Object.keys(subgroups).reduce((obj, key) => {
        if (!toLose.includes(key)) {
          obj[key] = subgroups[key];
        }
        return obj;
      }, {});
      return parseSubgroups(trimmed);
    }
  }
};

export const getBaseAxis = ({ x, selected, type, constants }) => {
  const curr = x ? selected["xAxis"] : selected["yAxis"];
  switch (type.type) {
    case "prelim": {
      const list = parseConstant({
        ...constants.collegeMeasures,
        sbac_dfm_ela: {
          code: "sbac_dfm_ela",
          name: "SBAC Distance - ELA",
        },
        sbac_dfm_math: {
          code: "sbac_dfm_math",
          name: "SBAC Distance - MATH",
        },
        caa_dfm_ela: {
          code: "caa_dfm_ela",
          name: "CAA Distance - ELA",
        },
        caa_dfm_math: {
          code: "caa_dfm_math",
          name: "CAA Distance - MATH",
        },
        dfm_math: {
          code: "dfm_math",
          name: "DFM - Math",
        },
        dfm_ela: {
          code: "dfm_ela",
          name: "DFM - ELA",
        },
        el_redis: {
          code: "el_redis",
          name: "EL Re-Designation",
        },
        perc_prof_math: {
          code: "perc_prof_math",
          name: "Proficiency - Math",
        },
        perc_prof_ela: {
          code: "perc_prof_ela",
          name: "Proficiency - ELA",
        },
        susp_core: {
          code: "susp_core",
          name: "CORE Suspension Rate",
        },
        susp_state: {
          code: "susp_state",
          name: "LCAP Suspension Rate",
        },
      });
      const found = findInList(list, curr);
      if (x) {
        return found ? curr : list[0].code;
      } else {
        return found ? curr : list[1].code;
      }
    }
    default: {
      const list = parseConstant({
        ...constants.measures,
        prior_dfm_ela: { name: "Prior DFM - ELA" },
        prior_dfm_math: { name: "Prior DFM - Math" },
        prior_growth_ela: { name: "Prior Growth - ELA" },
        prior_growth_math: { name: "Prior Growth - Math" },
        prior_perc_prof_ela: { name: "Prior Proficiency - ELA" },
        prior_perc_prof_math: { name: "Prior Proficiency - Math" },
      });
      const found = findInList(list, curr);
      if (x) {
        return found ? curr : "perc_prof_ela";
      } else {
        return found ? curr : "chron_abs";
      }
    }
  }
};

// Get the index of an array that matches search
// -1 = index is not found.
export const getArrayObjectIndex = (array, key, matchItem) => {
  let index = array.findIndex((measure) => measure[key] === matchItem);
  return index === -1 ? array[0] : array[index];
};
