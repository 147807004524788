import React, { Component } from "react";
import Routes from "./Routes/Routes";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = (theme) => ({
  root: {
    height: "100%",
    width: "100%",
    backgroundImage: `radial-gradient(#fff 0%, #edf7ff 100%), url(https://dashboard.coredistricts.org/images/bg-texture.png)`,
    backgroundSize: `auto, 46px`,
    backgroundBlendMode: "multiply",
  },
});

class App extends Component {
  render() {
    return (
      <div>
        <Routes />
      </div>
    );
  }
}
//Testing new build out for Slack integration

export default withStyles(styles, { withTheme: true })(App);
