import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
import React from "react";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

/**
 * Creates a sidebar menu item with a hover tool tip.
 */

const SideBarToolTip = (props) => {
  const {
    title,
    classes,
    NavLink,
    pathname,
    dropDown,
    onClick,
    openPrelim,
    link = null,
    navPath = "/dashboard",
  } = props;
  const text = getToolTipText(title);
  return (
    <HoverTooltip
      placement={"right"}
      style={{ whiteSpace: "pre-line" }}
      title={text}
      className={classes.sidebarStyleOverriding}
    >
      {dropDown ? (
        <MenuItem button onClick={onClick}>
          <ListItemText>
            <span className={classes.menuItemText}>{title}</span>
          </ListItemText>
          {openPrelim ? (
            <ExpandLess className={classes.iconStyles} />
          ) : (
            <ExpandMore className={classes.iconStyles} />
          )}
        </MenuItem>
      ) : (
        <MenuItem
          component={NavLink}
          to={navPath}
          selected={navPath === pathname}
          classes={{ selected: classes.selected }}
        >
          {link ? (
            <a href={link} className={classes.link}>
              <ListItemText>
                <span className={classes.menuItemText}>{title}</span>
              </ListItemText>
            </a>
          ) : (
            <ListItemText>
              <span className={classes.menuItemText}>{title}</span>
            </ListItemText>
          )}
          {navPath === pathname ? <div className={classes.arrow} /> : null}
        </MenuItem>
      )}
    </HoverTooltip>
  );
};

const getToolTipText = (title) => {
  switch (title) {
    case "CORE Index":
      return "View demographics and CORE Index results for the Academic, Social-Emotional, and Culture-Climate domains.";
    case "LCAP":
      return "Review performance by LCAP priortity areas, and preview the school and student group 5X5 grids.";
    case "Academic Measures":
      return "View academic growth school reports, status versus growth scatter plot, and ordered bar charts.";
    case "On-Demand":
      return "Examine metric trends and gap trends for any metric and any student group, benchmaring school performance to district, network and overall collaborative performance. Use advanced scatterplots to look at relationships between measures and find similar schools.";
    case "Preliminary College":
      return "CORE's preliminary, historic analysis of National Student Clearninghouse data.  (Note analyses are in the process of being updated with more current data).";
    case "Linked Learning":
      return "Examine Linked Learning pathway performance with high schools.";
    case "External Links":
      return "See additional information and key resources for understanding dashboard metrics.";
    case "On-Track Status":
      return "Explore the on track components: Attendance, UC A-G course performance, GPA and credits earned.";
    default:
      return "";
  }
};

const HoverTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#ffffff",
    color: "#4D7E91",
    maxWidth: 220,
    fontSize: 14,
    border: "1px solid #4D7E91",
  },
}))(Tooltip);

export { SideBarToolTip };
