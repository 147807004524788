import Grid from "@material-ui/core/Grid";
import React from "react";
import * as echarts from "echarts";

/**
 *  This function allows echarts to be dynamically called with the option to use different options and different element
 *  id's
 * @param props.option: options for the graph to be displayed
 * @param props.id: id determines the element we are referring to
 * @returns {JSX.Element}
 */
export const echartHelper = (props) => {
  const { option, id, height, width } = props;

  let myChart;
  const chartDom = document.getElementById(id);
  if (chartDom) {
    // need if statement since onTrackLanesBarChart does not exist yet until it is rendered at least once
    myChart = echarts.init(chartDom);
  }
  if (option && myChart) {
    if (option.series.length === 0) {
      myChart.clear();
    }
    myChart.setOption(option);
  }
  return (
    <Grid container>
      <Grid container item id={id} style={{ height: height, width: width }} />
    </Grid>
  );
};

/**
 * Formats data into correct series object for echarts visual
 * @param props.data: data needed for stacked barchart
 * @param props.seriesDefaultFormat: default series object
 * @returns array of series objects
 */
export const seriesFormatter = (props) => {
  if (props.data) {
    const {
      data: { xAxisLabels, legendOrder, color, data, nameMap },
      seriesDefaultFormat,
    } = props;
    const series = {};

    xAxisLabels.forEach((label) => {
      // looped over to keep series data arrays in correct order
      legendOrder.forEach((legendVal) => {
        // looping to create unique series objects
        // instantiates default series object and sets values with data
        if (!(legendVal in series)) {
          const tempSeries = JSON.parse(JSON.stringify(seriesDefaultFormat)); // deep copying defaultFormat
          if (color) {
            tempSeries.color = color[legendVal];
          }
          if (nameMap) {
            tempSeries.name = nameMap[legendVal];
          }
          series[legendVal] = tempSeries;
        }
        series[legendVal].data.push(
          legendVal in data[label] ? data[label][legendVal]["percent"] : null
        );
      });
    });
    return Object.values(series);
  } else {
    return [{ data: [] }];
  }
};
