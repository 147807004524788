export const visualizationHelpers = {
  canvasMouseOver,
  createSunburstLayers,
  drawSunburst,
  getItemFromId,
  createCompletionChart,
  drawCompletionChart,
  createCompletionHeatmap,
};

function createCompletionHeatmap({ selected, data }) {
  const { measureLevel, selectedSchool, selectedYear } = selected;
  if (!selectedYear || !measureLevel || !selectedSchool) {
    return [];
  }
  const info = data[measureLevel];
  const measureList = getFullMeasures(measureLevel);
  Object.keys(info).forEach((key) => {});
  const { numMeasures, groups, demographics } = Object.keys(measureList).reduce(
    (object, curr, index) => {
      const currGroup = measureList[curr];
      object["groups"][curr] = {};
      currGroup.forEach((measure, index) => {
        if (!object["groups"][curr][measure]) {
          object["groups"][curr][measure] = {};
        }
        Object.keys(info).forEach((school) => {
          const schoolInfo = info[school][selectedYear]
            ? info[school][selectedYear][measure] || {}
            : {};
          if (schoolInfo) {
            Object.keys(schoolInfo).forEach((subgroup) => {
              if (object["groups"][curr][measure][subgroup]) {
                object["groups"][curr][measure][subgroup] += 1;
              } else {
                object["groups"][curr][measure][subgroup] = 1;
              }
            });
          }
          Object.keys(schoolInfo).forEach((subgroup) => {
            object["demographics"][subgroup] = "";
          });
        });
      });
      object["numMeasures"] += currGroup.length;
      return object;
    },
    {
      numMeasures: 0,
      groups: {},
      demographics: {},
    }
  );
  return {
    numMeasures,
    groups,
    demographics,
  };
}

function createCompletionChart({ selected, data }) {
  const { measureLevel, selectedSchool, selectedYear } = selected;
  if (!selectedYear || !measureLevel || !selectedSchool) {
    return [];
  }
  const info = data[measureLevel][selectedSchool][selectedYear];
  const measureList = getFullMeasures(measureLevel);
  const { numMeasures, groups, demographics } = Object.keys(measureList).reduce(
    (object, curr, index) => {
      const currGroup = measureList[curr];
      object["groups"][curr] = {};
      currGroup.forEach((measure, index) => {
        object["groups"][curr][measure] = info[measure];
        if (info[measure]) {
          Object.keys(info[measure]).forEach((subgroup) => {
            object["demographics"][subgroup] = "";
          });
        }
      });
      object["numMeasures"] += currGroup.length;
      return object;
    },
    {
      numMeasures: 0,
      groups: {},
      demographics: {},
    }
  );
  return {
    numMeasures,
    groups,
    demographics,
  };
}

function drawCompletionChart({ chart, heatmap, nSchools, measures }) {
  const canvas = document.getElementById("canvas_1");
  const context = canvas.getContext("2d");
  context.restore();
  context.clearRect(0, 0, canvas.width, canvas.height);
  context.lineWidth = 20;
  context.save();
  context.translate(0, 80);
  const { demographics } = chart;
  context.save();
  context.translate(170, 0);
  context.font = "17px Arial";
  Object.keys(demographics).forEach((group) => {
    context.save();
    context.rotate(-Math.PI / 2.0);
    context.fillText(group, 0, 0);
    context.restore();
    context.translate(25, 0);
  });
  context.restore();
  context.translate(10, 0);
  context.font = "12px Arial";
  drawMeasureText({ chart, context, heatmap, nSchools, measures });
  context.restore();
}

const drawMeasureText = ({ chart, context, heatmap, nSchools, measures }) => {
  const { demographics } = chart;
  Object.keys(chart["groups"]).forEach((group) => {
    const currGroup = chart["groups"][group];
    Object.keys(currGroup).forEach((measure) => {
      context.translate(0, 20);
      context.save();
      const text = measures[measure] ? measures[measure]["name"] : measure;
      const trimmed = text.split(" ").reduce((str, txt) => {
        switch (txt) {
          case "Percent":
            str += "% ";
            break;
          case "Year":
            str += "yr ";
            break;
          case "First":
            str += "1st ";
            break;
          case "Enrollment":
            str += "Enroll ";
            break;
          case "Rate":
          case "Persistent":
          case "(Preliminary)":
          case "(First":
          case "Year)":
          case "College":
            break;
          case "Graduation":
            str += "Grad ";
            break;
          default:
            str += `${txt} `;
        }
        return str;
      }, "");
      context.fillText(trimmed, 0, 0);
      context.translate(145, -10);
      const measureInfo = currGroup[measure];
      context.lineWidth = 1;
      context.fillStyle = "#000000";
      context.lineWidth = 3;
      context.strokeStyle = "#000000";
      Object.keys(demographics).forEach((group, index) => {
        context.beginPath();
        if (measureInfo) {
          if (measureInfo[group]) {
            if (heatmap) {
              const percent = Math.round(100 * (measureInfo[group] / nSchools));
              const c = Math.abs(50 - percent);
              const b = 2.55 * Math.round(100 - percent);
              const r = 2.55 * Math.round(percent);
              const fillStyle = `rgb(${r},${0},${b})`;
              context.fillStyle = fillStyle;
            }
            context.rect(0, 0, 15, 15);
            context.fill();
            context.stroke();
          } else {
            context.rect(0, 0, 15, 15);
            if (heatmap) {
              context.fillStyle = "rgb(0,0,255)";
              context.fill();
            }
            context.stroke();
          }
        } else {
          context.rect(0, 0, 15, 15);
          context.stroke();
        }
        context.closePath();
        context.translate(25, 0);
      });
      context.restore();
    });
  });
};

function createSunburstLayers({
  selected,
  data,
  heatmap,
  nSchools,
  subgroups,
  measures,
}) {
  const dataLayers = heatmap
    ? createHeatmapLayers({ selected, data, nSchools, subgroups, measures })
    : createDataLayers({ selected, data, subgroups, measures });
  return {
    text: [""],
    dataLayers,
  };
}

const getFullMeasures = (measureLevel) => {
  const baseMeasure = {
    "Family Culture & Climate": [
      "cc_fam_saf",
      "cc_fam_cs",
      "cc_fam_ovr",
      "cc_fam_fair",
      "cc_fam_bel",
    ],
    "Staff Culture & Climate": [
      "cc_stf_saf",
      "cc_stf_ovr",
      "cc_stf_fair",
      "cc_stf_cs",
      "cc_stf_bel",
    ],
    "Student Culture & Climate": [
      "cc_stu_saf",
      "cc_stu_fair",
      "cc_stu_cs",
      "cc_stu_bel",
      "cc_stu_ovr",
      "sel_stu_sa",
      "sel_stu_se",
      "sel_stu_sm",
      "sel_stu_gm",
      "demo_pct",
      "susp_core",
      "susp_state",
      "el_eli",
      "el_redes",
      "chron_abs",
    ],
    Academic: [
      "perc_prof_ela",
      "growth_ela",
      "dfm_ela",
      "perc_prof_math",
      "growth_math",
      "dfm_math",
      "sbac_dfm_ela",
      "sbac_dfm_math",
      "caa_dfm_ela",
      "caa_dfm_math",
    ],
  };
  switch (measureLevel) {
    case "ES": {
      return baseMeasure;
    }
    case "MS": {
      return {
        ...baseMeasure,
        //TODO: Change if needed
        Academic: baseMeasure["Academic"].concat(["on_track_rate"]),
      };
    }
    case "HS": {
      return {
        ...baseMeasure,
        Academic: baseMeasure["Academic"].concat(["grad4y"]),
        College: [
          "nsc_2yr_enroll_fall",
          "nsc_2yr4yr_enroll_year",
          "nsc_2yr4yr_persist_2yr",
          "nsc_2yr4yr_enroll_fall",
          "nsc_4yr_enroll_year",
          "nsc_4yr_enroll_fall",
          "nsc_2yr_persist_2yr",
          "nsc_2yr_enroll_year",
          "nsc_4yr_persist_2yr",
          "nsc_4yr_grad_6yr",
          "nsc_4yr_grad_5yr",
          "nsc_4yr_grad_4yr",
        ],
      };
    }
  }
};

function createHeatmapLayers({
  selected,
  data,
  nSchools,
  subgroups,
  measures,
}) {
  const chart = createCompletionHeatmap({ selected, data });
  const { measureLevel, selectedSchool, selectedYear } = selected;
  if (!selectedYear) {
    return [];
  }
  const info = chart.groups;
  const measureList = getFullMeasures(measureLevel);
  const { numMeasures, groups } = Object.keys(measureList).reduce(
    (object, curr) => {
      const currGroup = measureList[curr];
      object["numMeasures"] += currGroup.length;
      object["groups"][curr] = currGroup.length;
      return object;
    },
    {
      numMeasures: 0,
      groups: {},
    }
  );
  const mAngle = (2 * Math.PI) / numMeasures;
  const { angles } = Object.keys(groups).reduce(
    (object, key) => {
      const curr = info[key];
      const { currAngle } = object;
      const currGroup = measureList[key];
      const startAngle = currAngle;
      currGroup.forEach((measure, index) => {
        const currMeasure = curr[measure];
        const sum = Object.keys(currMeasure).reduce((sum, currKey) => {
          sum += currMeasure[currKey];
          return sum;
        }, 0);
        const avg = sum / Object.keys(currMeasure).length;
        const percent = Math.round((100 * avg) / nSchools) || 0;
        const b = 2.55 * Math.round(100 - percent);
        const r = 2.55 * Math.round(percent);
        const color = `rgb(${r},${0},${b})`;
        object["angles"][measure] = {
          sAngle: startAngle + mAngle * index,
          eAngle: startAngle + mAngle * (index + 1),
          color,
          percent,
          measureGroup: key,
        };
      });
      object["currAngle"] = currAngle + mAngle * currGroup.length;
      return object;
    },
    {
      currAngle: 0,
      angles: {},
    }
  );
  const subgroupList = Object.keys(chart.demographics);
  const subgroupIndexes = subgroupList.reduce((object, key, index) => {
    object[key] = {
      depth: index,
      color: randomColor(),
    };
    return object;
  }, {});
  let ind = 0;
  const measureLayer = Object.keys(info).reduce(
    (object, measureGroup, index) => {
      const currGroup = info[measureGroup];
      Object.keys(currGroup).forEach((measure) => {
        const currentInfo = info[measureGroup][measure];
        const id = ind > 9 ? `#${ind}0000` : `#0${ind}0000`;
        ind++;
        object[measure] = {
          text: [
            `${angles[measure]["percent"]}% of schools`,
            `${measures[measure] ? measures[measure]["name"] : measure}`,
          ],
          sAngle: angles[measure]["sAngle"],
          eAngle: angles[measure]["eAngle"],
          color: angles[measure]["color"],
          id,
          child: subgroupList.reduce((groups, group, cIndex) => {
            const end = cIndex > 9 ? `${cIndex}1` : `0${cIndex}1`;
            const subgroupId = `${id.charAt(0)}${id.charAt(1)}${id.charAt(
              2
            )}${id.charAt(3)}${end}`;
            const depth = subgroupIndexes[group]
              ? subgroupIndexes[group]["depth"]
              : null;
            const rate = currentInfo[group] || 0;
            const percent = Math.round((100 * rate) / nSchools);
            const b = 2.55 * Math.round(100 - percent);
            const r = 2.55 * Math.round(percent);
            const color = `rgb(${r},${0},${b})`;
            if (depth && color) {
              groups[group] = {
                text: [
                  `${percent}% of schools`,
                  `${measures[measure] ? measures[measure]["name"] : measure}`,
                  `${subgroups[group] ? subgroups[group]["name"] : group}`,
                ],
                depth,
                color,
                id: subgroupId,
              };
            }
            return groups;
          }, {}),
        };
      });
      return object;
    },
    {}
  );
  return [measureLayer];
  // return [];
}

function createDataLayers({ selected, data, subgroups, measures }) {
  const { measureLevel, selectedSchool, selectedYear } = selected;
  if (!selectedYear) {
    return [];
  }
  const info = data[measureLevel][selectedSchool][selectedYear];
  const measureList = getFullMeasures(measureLevel);
  const { numMeasures, groups } = Object.keys(measureList).reduce(
    (object, curr) => {
      const currGroup = measureList[curr];
      object["numMeasures"] += currGroup.length;
      object["groups"][curr] = currGroup.length;
      return object;
    },
    {
      numMeasures: 0,
      groups: {},
    }
  );
  const mAngle = (2 * Math.PI) / numMeasures;
  const { angles } = Object.keys(groups).reduce(
    (object, key) => {
      const { currAngle } = object;
      const currGroup = measureList[key];
      const startAngle = currAngle;
      currGroup.forEach((measure, index) => {
        object["angles"][measure] = {
          sAngle: startAngle + mAngle * index,
          eAngle: startAngle + mAngle * (index + 1),
        };
      });
      object["currAngle"] = currAngle + mAngle * currGroup.length;
      return object;
    },
    {
      currAngle: 0,
      angles: {},
    }
  );
  const subgroupList = info["demo_pct"] ? Object.keys(info["demo_pct"]) : [];
  const nMeasures = measureList.length;
  const measureAngle = (2 * Math.PI) / nMeasures;
  const nSubgroups = subgroupList.length;
  const subgroupIndexes = subgroupList.reduce((object, key, index) => {
    object[key] = {
      depth: index,
      color: randomColor(),
    };
    return object;
  }, {});
  const subgroupAngle = measureAngle / nSubgroups;
  const padding = (2 * Math.PI) / 360;
  const measureLayer = Object.keys(info).reduce((object, measure, index) => {
    const id = index > 9 ? `#3${index}000` : `#30${index}000`;
    if (angles[measure]) {
      object[measure] = {
        text: [measure],
        sAngle: angles[measure]["sAngle"],
        eAngle: angles[measure]["eAngle"],
        color: randomColor(),
        id,
        child: Object.keys(info[measure]).reduce((groups, group, cIndex) => {
          const end = cIndex > 9 ? `${cIndex}1` : `0${cIndex}1`;
          const subgroupId = `${id.charAt(0)}${id.charAt(1)}${id.charAt(
            2
          )}${id.charAt(3)}${end}`;
          const depth = subgroupIndexes[group]
            ? subgroupIndexes[group]["depth"]
            : null;
          const color = subgroupIndexes[group]
            ? subgroupIndexes[group]["color"]
            : null;
          if (depth && color) {
            groups[group] = {
              text: [`${measure}`, `${group}`],
              depth,
              color,
              id: subgroupId,
            };
          }
          return groups;
        }, {}),
      };
    }
    return object;
  }, {});
  return [measureLayer];
}

async function drawSunburst({ selected, sunburst, drawIds }) {
  const canvas = document.getElementById("canvas_0");
  const ctx = canvas.getContext("2d");
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  const height = canvas.height;
  const width = canvas.width;
  const center = {
    x: width / 2.0,
    y: height / 2.0,
  };
  ctx.lineWidth = height / 12;
  const { dataLayers, text } = sunburst;
  drawInnerText({ ctx, center, text });
  drawDataLayers({ ctx, center, dataLayers, drawIds });
}

function drawInnerText({ ctx, center, text }) {
  ctx.font = "30px Arial";
  ctx.save();
  text.forEach((txt) => {
    ctx.fillText(txt, center.x - 150, center.y + 350);
    ctx.translate(0, 30);
  });
  ctx.restore();
}

function drawDataLayers({ ctx, center, dataLayers, drawIds }) {
  dataLayers.forEach((layer, index) => {
    Object.keys(layer).forEach((key) => {
      const cell = layer[key];
      const { sAngle, eAngle, color, id, child } = cell;
      ctx.lineWidth = 40;
      ctx.beginPath();
      ctx.strokeStyle = drawIds ? id : color;
      ctx.arc(
        center.x,
        center.y,
        90 + (5 + ctx.lineWidth) * index,
        sAngle,
        eAngle
      );
      ctx.stroke();
      ctx.lineWidth = 10;
      Object.keys(child).forEach((sg) => {
        const cellChild = child[sg];
        const cColor = cellChild["color"];
        const cId = cellChild["id"];
        ctx.beginPath();
        ctx.strokeStyle = drawIds ? cId : cColor;
        ctx.arc(
          center.x,
          center.y,
          105 + ctx.lineWidth * cellChild["depth"],
          sAngle,
          eAngle
        );
        ctx.stroke();
      });
    });
  });
}

function canvasMouseOver({ canvas, e, sunburst }) {
  const rect = canvas.getBoundingClientRect();
  const x = e.clientX - rect.left;
  const y = e.clientY - rect.top;
  const ctx = canvas.getContext("2d");
  const check = ctx.getImageData(x, y, 1, 1).data;
  if (check[0] || check[1] || check[2]) {
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    drawSunburst({ selected: {}, sunburst, drawIds: true });
    const checkId = ctx.getImageData(x, y, 1, 1).data;
    const hex = rgbToHex(checkId[0], checkId[1], checkId[2]);
    const selection = parseInt(hex.charAt(1)) < 3;
    const hovered = selection
      ? {
          id: hex,
          layer: parseInt(hex.charAt(1)),
          index: parseInt(hex.charAt(2) + hex.charAt(3)),
        }
      : {
          id: hex,
          layer: parseInt(hex.charAt(1)),
          year: parseInt(hex.charAt(2)),
          measure: parseInt(hex.charAt(3) + hex.charAt(4)),
          subgroup: parseInt(hex.charAt(5) + hex.charAt(6)),
        };
    return hovered;
  }
  return null;
}

function getItemFromId({ sunburst, itemId }) {
  if (!itemId) {
    return null;
  }
  const { id } = itemId;
  const searchLayer = sunburst.dataLayers[0];
  return Object.keys(searchLayer).reduce((found, key) => {
    const cell = searchLayer[key];
    if (cell.id === id) {
      found = cell;
    }
    Object.keys(cell.child).forEach((cKey) => {
      const cCell = cell.child[cKey];
      if (cCell.id === id) {
        found = cCell;
      }
    });
    return found;
  }, null);
}

function rgbToHex(r, g, b) {
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

const randomColor = () => {
  const letters = "0123456789";
  let res = "#";
  for (let i = 0; i < 6; i++) {
    res += letters[Math.floor(Math.random() * letters.length)];
  }
  return res;
};
