import React, { Component } from "react";
import {compose} from "redux"
import Grid from "@material-ui/core/Grid";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import ListItemText from "@material-ui/core/ListItemText";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { withStyles } from "@material-ui/core/styles";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";
import Typography from "@material-ui/core/Typography";
import ArrowRight from "@material-ui/icons/ArrowRight";
import { logout, updateBeta } from "../../Login/actions";
import { connect } from "react-redux";

const styles = (theme) => ({
  logoutButton: {
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "230px",
    height: "65px",
    padding: "10px 20px",
    justifyContent: "center",
    backgroundImage:
      "linear-gradient(270deg,rgba(18,126,168,0),rgba(18,126,168,.2))",
    color: "rgba(1,61,84,.7)",
    fontSize: "15px",
  },
  beta: {
    backgroundColor: "#ffee88",
    "&:hover": {
      backgroundColor: "#ffee88",
    },
    "&:active": {
      backgroundColor: "#ffee88",
    },
    "&:focus": {
      backgroundColor: "#ffee88",
    },
  },
});

class Logout extends Component {
  constructor() {
    super();
    this.state = {
      openLogout: false,
      openBeta: false,
    };
  }

  handleToggleLogoutMenu = () => {
    this.setState((state) => ({
      openLogout: !state.openLogout,
      openBeta: false,
    }));
  };

  handleCloseLogoutMenu = (event) => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }
    this.setState({ openLogout: false, openBeta: false });
  };

  handleClickLogout = () => {
    this.props.handleClickLogout();
  };

  handleClickBeta = () => {
    this.setState((state) => ({
      openBeta: !state.openBeta,
      openLogout: false,
    }));
  };

  handleCancelBeta = () => {
    this.setState((state) => ({ openBeta: false }));
  };

  handleUpdateBeta = (user, values) => {
    this.props.updateBeta(user, values);
    this.setState((state) => ({ openBeta: false }));
  };

  handleClickChangePassword = () => {
    this.props.handleClickChangePassword();
  };

  handleClickAdmin = () => {
    this.props.handleClickAdmin();
  };

  handleClickSystemDashboard = () => {
    this.props.handleClickSystemDashboard();
  };

  render() {
    const { classes, username, user } = this.props;
    const { openBeta } = this.state;
    return (
      <div>
        <Button
          className={classes.logoutButton}
          ref={(node) => {
            this.anchorEl = node;
          }}
          aria-owns={this.state.openLogout ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={this.handleToggleLogoutMenu}
        >
          <Grid>
            <Grid container item xs={12} justifyContent="center" alignItems="center">
              {username ? <Typography>You're logged in as:</Typography> : null}
            </Grid>
            <Grid container item xs={12} justifyContent="center" alignItems="center">
              {/*Make User dynamic one added to backend...data already mapped in*/}
              <Typography style={{ fontSize: 12, fontWeight: "bold" }}>
                {username}
              </Typography>
              {<ArrowRight />}
            </Grid>
          </Grid>
        </Button>

        <Popper
          open={this.state.openLogout}
          anchorEl={this.anchorEl}
          placement={"bottom"}
          transition
          disablePortal
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} id="menu-list-grow">
              <Paper>
                <ClickAwayListener onClickAway={this.handleCloseLogoutMenu}>
                  <MenuList>
                    <MenuItem onClick={this.handleClickLogout}>
                      <ListItemText secondary={"Logout"} />
                    </MenuItem>
                    <MenuItem onClick={this.handleToggleLogoutMenu}>
                      <ListItemText secondary={"Admin"} />
                    </MenuItem>
                    <MenuItem onClick={this.handleToggleLogoutMenu}>
                      <ListItemText secondary={"System Dashboard"} />
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}

const mapState = (state) => {
  const { user } = state;
  return {
    user,
    username: user.user.username,
  };
};

const mapDispatch = (dispatch) => {
  return {
    handleClickLogout: () => dispatch(logout()),
    updateBeta: (user, values) => {
      dispatch(updateBeta(user, values));
    },
  };
};

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapState, mapDispatch)
)(Logout);
